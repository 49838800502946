import React, { cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Icon from 'mdi-material-ui/ArrowDecision';
import { forms } from '.';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const AddChangeOrderButton = forwardRef((props, forwardedRef) => {
  const classes = useStyles(props);
  const { job, updateFormProps, trigger: propsTrigger, onClose, ...buttonProps } = props;

  const trigger = propsTrigger === undefined
    ? (
      <Button className={classes.root} {...buttonProps} ref={forwardRef}>
        <Icon /><span>Change Order</span>
      </Button>

    ) : cloneElement(propsTrigger, { ref: forwardedRef });

  return (
    <forms.ChangeOrderForm_v1.UpdateForm
      onClose={onClose}
      job={job}
      {...updateFormProps}
      fixtures={{ jobs: [{ id: job.id }] }}
      trigger={trigger}
    />
  );
});

AddChangeOrderButton.displayName = 'AddChangeOrderButton';
AddChangeOrderButton.propTypes = {
};
AddChangeOrderButton.defaultProps = {
};

export { AddChangeOrderButton };
