import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from 'src/kiska/components/Grid';
import { ExpensePieChart } from './ExpensePieChart';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const ExpensesCharts = (props) => {
  const { displayParams: { showCharts, showPrice, showCost }, groupBy } = props;
  const classes = useStyles(props);

  if (!showCharts) return null;

  const sizes = { xs: 12, md: 4 };

  const charts = {
    status: (
      <>
        {showCost && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="status" title="Cost by Status" enabledSets={['cost']} />
          </Grid>
        )}
        {showPrice && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="status" title="Price by Status" enabledSets={['price']} />
          </Grid>
        )}
      </>
    ),
    category: (
      <>
        {showCost && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="category" title="Cost by Category" enabledSets={['cost']} />
          </Grid>
        )}
        {showPrice && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="category" title="Price by Category" enabledSets={['price']} />
          </Grid>
        )}
      </>
    ),
    job: (
      <>
        {showCost && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="job" title="Cost by Job" enabledSets={['cost']} />
          </Grid>
        )}
        {showPrice && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="job" title="Price by Job" enabledSets={['price']} />
          </Grid>
        )}
      </>
    ),
    customer: (
      <>
        {showCost && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="customer" title="Cost by Customer" enabledSets={['cost']} />
          </Grid>
        )}
        {showPrice && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="customer" title="Price by Customer" enabledSets={['price']} />
          </Grid>
        )}
      </>
    ),
    vendor: (
      <>
        {showCost && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="vendor" title="Cost by Vendor" enabledSets={['cost']} />
          </Grid>
        )}
        {showPrice && (
          <Grid item {...sizes}>
            <ExpensePieChart groupBy="vendor" title="Price by Vendor" enabledSets={['price']} />
          </Grid>
        )}
      </>
    ),
  };

  return groupBy.map((grouper) => <React.Fragment key={grouper}>{charts[grouper]}</React.Fragment>);
};

ExpensesCharts.propTypes = {
  groupBy: PropTypes.array,
};
ExpensesCharts.defaultProps = {
  groupBy: ['category'],
};

export { ExpensesCharts };
