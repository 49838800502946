import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNodeQuery, useNode } from 'src/kiska/hooks/useNode';
import deepFreeze from 'deep-freeze';
import { useAuth0 } from '@auth0/auth0-react';
import { normalizeUser } from 'src/kiska/utils/normalizeUser';

const UserContext = createContext({});
const useUser = () => useContext(UserContext);

const UserProvider = (props) => {
  const [timeoutElapsed, setTimeoutElapsed] = useState(false);
  const { user: auth0User, isLoading: auth0Loading, error: auth0Error } = useAuth0();
  const userId = auth0User
    ? auth0User.sub.replace(/^.*?\|/, '') // remove "auth0|" prefix from Auth0-generated IDs
    : null;
  const { result, node } = useNode({ type: 'user', id: userId, skip: !userId });
  const { children } = props;
  const loading = auth0Loading || (result.loading && !node);

  if (auth0Error) {
    const msg = `<UserContext> sees error in AuthO context.`;
    console.error(msg, auth0Error);
  }

  if (result.error) {
    const msg = `<UserContext> sees error getting Kiska user node.`;
    console.error(msg, result.error);
  }

  useEffect(() => {
    const timeout = setTimeout(() => setTimeoutElapsed(true), 5000);

    return () => clearTimeout(timeout);
  }, []);

  if (loading) {
    return null;
  }

  const value = {
    user: normalizeUser({ kiskaUser: node, auth0User }),
  };

  deepFreeze(value);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

const withUser = (WrappedComponent) => (props) => (
  <UserContext.Consumer>
    {(value) => <WrappedComponent user={value} {...props} />}
  </UserContext.Consumer>
);

export { withUser, UserProvider, UserContext, useUser };
