import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Workbox } from 'workbox-window';
import { useLog } from './kiska/components/contexts/LogContext';

// const getAndroidMajorVersion = (ua) => {
//   ua = (ua || navigator.userAgent).toLowerCase();
//   const match = ua.match(/android\s([0-9\.]*)/i);
//   const version = match ? match[1] : undefined;
//   const majorVersion = version ? parseInt(version, 10) : undefined;
//   return majorVersion;
// };

const ServiceWorkerContext = createContext({});
const useServiceWorker = () => useContext(ServiceWorkerContext);

const swSupported = 'serviceWorker' in navigator;
const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
const wb = new Workbox(swUrl);

const ServiceWorkerProvider = (props) => {
  const { children } = props;
  const [reg, setReg] = useState(null);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const log = useLog();

  const update = useCallback(() => {
    if (!swSupported) {
      log.error(`<ServiceWorkerContext> Tried to update() but service worker not supported.`);
      return;
    }
    setIsUpdating(true);
    console.log('Updating...');

    // Backup reload in case above event doesn't fire
    setTimeout(() => window.location.reload(), 5000);

    wb.addEventListener('controlling', (event) => {
      window.location.reload();
    });

    wb.messageSkipWaiting();
  }, [log]);

  const handleWaiting = useCallback((event) => {
    setUpdateAvailable(true);
  }, []);

  const handleExternalWaiting = useCallback((event) => {
    setUpdateAvailable(true);
  }, []);

  const handleMessage = useCallback((event) => {
  }, []);

  const handleControlling = useCallback((event) => {
  }, []);

  // const handleRedundant = useCallback((event) => {
  // }, []);

  const handleInstalled = useCallback((event) => {
  }, []);

  const handleActivated = useCallback((event) => {
    if (!event.isUpdate) {
      // console.log('Service worker activated for the first time.');
    } else {
      // console.log('Service worker activated.');
    }
  }, []);

  const registerSw = useCallback(() => {
    if (!swSupported) return;

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', handleWaiting);
    wb.addEventListener('externalwaiting', handleExternalWaiting);
    wb.addEventListener('activated', handleActivated);
    wb.addEventListener('message', handleMessage);
    wb.addEventListener('controlling', handleControlling);
    wb.addEventListener('instaled', handleInstalled);

    try {
      wb.register()
        .then((r) => {
          console.log('Service Worker registered.');
          setReg(r);
        })
        .catch((error) => {
          log.warning('<ServiceWorkerContext> Failed to register service worker.', error);
        });
    } catch (error) {
      log.warning('<ServiceWorkerContext> Failed to register service worker (caught).', error);
    }
  }, [handleActivated, handleControlling, handleExternalWaiting, handleInstalled, handleMessage, handleWaiting, log]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (reg) {
        try {
          reg.update();
        } catch (error) {
          // forget
        }
      }
    }, 1000 * 60 * 5);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      try {
        registerSw();
      } catch (error) {
        log.error('Error registering service worker in <ServiceWorkerContext>', error);
      }
    } else {
      // setTimeout(() => setUpdateAvailable(true), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    updateAvailable,
    update,
    isUpdating,
  };

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export { ServiceWorkerProvider, ServiceWorkerContext, useServiceWorker };
