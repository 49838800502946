import React from 'react';
// import { useMediaQuery } from '@material-ui/core';
// import { useTheme } from '@material-ui/core/styles';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { Redirect } from 'react-router-dom';

const DashboardRouter = (props) => {
  // const settings = useContext(SettingsContext);
  const { user } = useUser();
  // const theme = useTheme();
  // const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const homepage = user.preferences.homepage;
  // if (sm) homepage = '/app/job-switcher';

  console.log('Dashboard redirecting to: ', homepage);

  return <Redirect to={homepage} />;
};

export { DashboardRouter };
