/* eslint-disable react/prop-types */
import React from 'react';

export default {
  types: {
    task: {
      renderers: {
        inline: ({ node }) => <>{node.label}</>,
      },

      selections: {
        default: `
          id label description
        `,
      },

      fields: {
        label: {
          type: 'String',
          validation: {
            required: true,
          },
        },
        description: {
          type: 'String',
          validation: {
            required: false,
          },
        },

      },
    },
  },
};
