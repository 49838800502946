import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parseISO, format } from 'date-fns';
import { formatCurrency, formatDuration } from 'src/utils';
import classNames from 'classnames';
import Color from 'color';
import { replaceNewLinesWithBreaks } from 'src/kiska/utils';
import { useUser } from 'src/kiska/components/contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 0, 1),
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.border.light,
    '&:last-child': {
      borderBottom: 'none',
    },
    backgroundColor: theme.palette.background.default,
  },
  open: {
    animation: theme.kiska.type !== 'print' ? `$throb 2s ease-in-out infinite` : undefined,
  },
  '@keyframes throb': {
    '0%': {
      backgroundColor: theme.palette.background.red,
    },
    '10%': {
      backgroundColor: theme.palette.background.default,
    },
    '60%': {
      backgroundColor: theme.palette.background.red,
    },
    '100%': {
      backgroundColor: theme.palette.background.red,
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: Color(theme.palette.background.default).darken(0.1).string(),
      cursor: 'pointer',
    },
  },
  left: {
    padding: theme.spacing(0.5, 1, 0.5, 0),
    '& > *': {
      lineHeight: 1.2,
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
  },
  stat: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 80,
    padding: theme.spacing(0, 1, 0, 1),
    borderLeft: `solid 1px`,
    borderLeftColor: theme.palette.border.light,
  },
  center: {
    // flex: 1,
    alignSelf: 'center',
    padding: theme.spacing(0.5, 1, 0.5, 1),
  },
  notes: {
    fontStyle: 'italic',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    padding: theme.spacing(0.25, 0.5),
    color: theme.palette.text.notes,
  },
}));

const WorkEntryRow = (props) => {
  const { user } = useUser();
  const classes = useStyles(props);
  const { node, displayParams, onClick } = props;
  const { showNotes, showUser, showJob, showPayRate, showTask, showFinancials, showTimes, showCustomer } = displayParams;

  const times = `${format(parseISO(node.start), `h:mmaaaaa'm`)} - ${node.end ? format(parseISO(node.end), `h:mmaaaaa'm`) : 'now'}`;

  const leftRows = [
    [showUser && node.user.displayName, showTask && node.task.label, showPayRate && node.payRate.label].filter((v) => !!v),
    [showJob && `${node.job.title}${showCustomer ? ` (${node.job.customer.company || node.job.customer.name})` : ''}`].filter((v) => !!v),
  ];

  return (
    <div className={classNames(classes.root, !node.end && classes.open, onClick && classes.hover)} onClick={onClick}>
      <div className={classes.left}>
        {showTimes && (
          <Typography variant="body1" color="textPrimary">
            <b>{times}</b>
          </Typography>
        )}
        <Typography variant="body1" color="textSecondary">
          {leftRows[0] && leftRows[0].join(' > ')}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {leftRows[1] && leftRows[1].join(' > ')}
        </Typography>
      </div>

      {showNotes && node.notes && (
        <div className={classes.center}>
          <Typography variant="body2" className={classes.notes}>
            {replaceNewLinesWithBreaks(node.notes)}
          </Typography>
        </div>
      )}

      <div className={classes.right}>
        {showFinancials && (
          <>
            <div className={classes.stat}>
              <Typography variant="body1" color="textSecondary">
                ${formatCurrency(node.price)}
              </Typography>
            </div>

            <div className={classes.stat}>
              <Typography variant="body1" color="textSecondary">
                ${formatCurrency(node.cost)}
              </Typography>
            </div>
          </>
        )}

        <div className={classes.stat}>
          <Typography variant="body1">
            {formatDuration(node.duration)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

WorkEntryRow.propTypes = {
  node: PropTypes.object.isRequired,
  allowUpdate: PropTypes.bool,
  displayParams: PropTypes.object.isRequired,
};
WorkEntryRow.defaultProps = {
  allowUpdate: true,
};

export { WorkEntryRow };
