/* eslint-disable react/prop-types */
import React from 'react';
import { fragments } from './fragments';

export default {
  types: {
    resource: {
      renderers: {
        inline: ({ node }) => <>{node.title}</>,
      },

      selections: {
        default: `
          id title description link createdAt updatedAt userId expires
          tags(
            order_by: [{tag: {label: asc}}]
          ){
            id tag{id label type color icon}
          }
          files
        `,
      },

      fields: {
        title: { type: 'String', validation: { required: true } },
        expires: { type: 'Date', validation: { required: false } },
        userId: { type: 'String' },
        jobs: { type: 'job', hasMany: true },
        description: { type: 'String', validation: { required: false } },
        link: { type: 'String', validation: { required: false } },
        files: { type: 'file', embedded: true, hasMany: true, validation: { required: false }, defaultValue: [] },
        tags: { type: 'tag', hasMany: true, validation: { required: false }, defaultValue: [] },
      },
    },
  },
};
