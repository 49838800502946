import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { secondsToFormattedHms, getDuration } from 'src/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: '"Lucida Console", Monaco, monospace',
  },
}));

const DurationTicker = ({ start, end, includeSeconds, duration: propsDuration, className, fixture }) => {
  const classes = useStyles();

  const getFormattedDuration = useCallback(() => {
    let duration = 0;
    duration += fixture;
    if (start) duration += (propsDuration || getDuration(start));
    return secondsToFormattedHms(duration, includeSeconds);
  }, [fixture, start, propsDuration, includeSeconds]);
  const [formattedDuration, setFormattedDuration] = useState(getFormattedDuration());

  useEffect(() => {
    const interval = window.setInterval(() => setFormattedDuration(getFormattedDuration()), 1000);

    return () => { window.clearInterval(interval); };
  }, [getFormattedDuration]);

  return <span className={classNames(classes.root, className)}>{formattedDuration}</span>;
};

DurationTicker.propTypes = {
  end: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  start: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  duration: PropTypes.number,
  includeSeconds: PropTypes.bool,
  className: PropTypes.string,
  fixture: PropTypes.number,
  unformatted: PropTypes.bool,
};
DurationTicker.defaultProps = {
  start: undefined,
  end: undefined,
  duration: undefined,
  includeSeconds: false,
  className: '',
  fixture: 0,
  unformatted: false,
};

export default DurationTicker;
