import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2, 2),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {

  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: 38,
  },
}));

const PageTitle = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.icon && <props.icon className={classes.icon} />}
      <Typography variant="h4" align="center" className={classes.title} color="inherit">{children}</Typography>
    </div>
  );
};

PageTitle.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.elementType,
};
PageTitle.defaultProps = {
  children: undefined,
  icon: undefined,
};

export { PageTitle };
