import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CountUp from 'react-countup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    width: 'auto',
  },
  value: {
    // fontWeight: 700,
    // fontFamily: '"Lucida Console", Monaco, "Courier New", Courier, monospace',
  },
  label: {

  },
}));

const Stat = (props) => {
  const { label, value, disableCountUp, valueColor, labelColor, decimals: propsDecimals, currency } = props;
  const classes = useStyles(props);
  const decimals = currency ? 2 : propsDecimals;

  return (
    <Grid container direction="column" alignItems="center" className={classes.root}>
      <Typography variant="overline" style={{ marginBottom: -8 }} color={labelColor} className={classes.label}>{label}</Typography>
      <Typography variant="h4" className={classes.value} color={valueColor}>
        {disableCountUp
          ? value
          : <><CountUp start={0} end={Number(value)} duration={1} decimals={decimals} preserveValue separator="," useEasing prefix={currency ? '$' : undefined} /></>}
      </Typography>
    </Grid>
  );
};

Stat.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  disableCountUp: PropTypes.bool,
  valueColor: PropTypes.string,
  labelColor: PropTypes.string,
  decimals: PropTypes.number,
  currency: PropTypes.bool,
};
Stat.defaultProps = {
  label: '',
  value: 0,
  disableCountUp: false,
  labelColor: 'textSecondary',
  valueColor: 'textPrimary',
  decimals: 1,
  currency: false,
};

export { Stat };
