import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';

import { useNode } from 'src/kiska/hooks/useNode';
import NodeViewPage from 'src/components/layouts/NodeViewPage';
import { useParams } from 'react-router-dom';
import CustomerTabs from './CustomerTabs';

// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

const CustomerPageView = (props) => {
  const { id } = useParams();
  const { result, node } = useNode({ type: 'contact', id });
  const title = node ? node.company || node.name : '';

  return (
    <NodeViewPage queryResult={result} component={CustomerTabs} title={title} />
  );
};

CustomerPageView.propTypes = {
};
CustomerPageView.defaultProps = {
};

export default CustomerPageView;
