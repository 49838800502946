import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO, differenceInCalendarDays } from 'date-fns';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const JobDates = (props) => {
  const classes = useStyles(props);
  const { job } = props;

  let start = _.get(job, 'firstWorkEntry[0].start');
  let end = _.get(job, 'lastWorkEntry[0].start');
  let totalDays = 0;
  if (start && end) {
    start = parseISO(start);
    end = parseISO(end);
    totalDays = differenceInCalendarDays(end, start);
  }

  const workOrderCreated = format(parseISO(job.createdAt), 'MMM d, yyy');
  const workStarted = start ? format(start, 'MMM d, yyyy') : 'Not started';
  const lastWorkDone = end ? format(end, 'MMM d, yyyy') : 'Not started';

  return (
    <>
      <Typography variant="body1" color="textSecondary" component="span">
        Work Started:&nbsp;
      </Typography>
      <Typography variant="body1" color="textPrimary" component="span">
        {workStarted}
      </Typography>
      <br />
      <Typography variant="body1" color="textSecondary" component="span">
        Latest Work:&nbsp;
      </Typography>
      <Typography variant="body1" color="textPrimary" component="span">
        {lastWorkDone}
      </Typography>
    </>
  );
};

JobDates.propTypes = {
};
JobDates.defaultProps = {
};

export { JobDates };
