import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import { formatDate } from 'src/kiska/utils';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  text: {
    marginTop: theme.spacing(-1),
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: 75,
    backgroundColor: 'hsla(0, 0%, 95%, 1)',
    borderRadius: 1,
    padding: 4,
    border: 'solid 1px rgba(0,0,0,.2)',
  },
}));

const Signature = (props) => {
  const settings = useSettings();
  const classes = useStyles(props);
  const { name, title, date, file } = props.node;

  const src = file && `${settings.public.kiskaOrigin}${file.url}`;

  return (
    <div>
      <img src={src} className={classes.image} alt="Signature" />
      <Grid container spacing={1} className={classes.text} justify="space-between">
        <Grid item>
          <Typography variant="body1" style={{ lineHeight: 1.2 }}>{name}</Typography>
          <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{formatDate(date, `MMM d, yyyy 'at' h:mmaaaaa'm'`)}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

Signature.propTypes = {
  node: PropTypes.object.isRequired,
};
Signature.defaultProps = {
};

export { Signature };
