/* eslint-disable react/prop-types */
import React from 'react';

import { formatDate, formatTime, parseISO, replaceNewLinesWithBreaks } from 'src/kiska/utils';

const scalerRenderers = {
  ID: ({ value }) => <>{value}</>,
  String: ({ value }) => <>{replaceNewLinesWithBreaks(value ? value.toString() : '')}</>,
  Int: ({ value }) => <>{value}</>,
  Float: ({ value }) => <>{value}</>,
  Boolean: ({ value }) => {
    if (value === true) return <>Yes</>;
    if (value === false) return <>No</>;
    return <>No value</>;
  },
  Timestamp: ({ value }) => {
    if (!value) return '';
    const date = parseISO(value);
    return (
      <>
        {formatDate(date, 'MMM d, yyyy')}<br />
        {formatDate(date, 'h:mma').toLowerCase()}
      </>
    );
  },
  Time: ({ value }) => {
    if (!value) return '';
    return formatTime(value, 'h:mma').toLowerCase();
  },
  Date: ({ value }) => {
    if (!value) return '';
    return formatDate(value, 'MMM d, yyyy');
  },
  Json: ({ value }) => (
    <pre style={{ fontFamily: '"Courier New", Courier, monospace' }}>
      {JSON.stringify(value, null, 2)}
    </pre>
  ),
};

export default scalerRenderers;
