import React, { createContext, useContext } from 'react';
import Rollbar from 'rollbar';

import { settings } from 'src/kiska/utils/settings';

const useRollbar = settings.public.log.logger === 'rollbar';

const rollbarLogger = new Rollbar({
  enabled: useRollbar,
  accessToken: settings.public.log.rollbar.accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: settings.public.devMode ? 'development' : 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_KISKA_CODE_VERSION,
        guess_uncaught_frames: true,
      },
    },
    server: {
      branch: process.env.REACT_APP_KISKA_CODE_BRANCH || 'unknown',
      host: `${settings.public.projectName}.slick.systems` || 'unknown',
    },
  },
});

const originalError = rollbarLogger.error.bind(rollbarLogger);
const originalWarning = rollbarLogger.warning.bind(rollbarLogger);
const originalInfo = rollbarLogger.info.bind(rollbarLogger);

rollbarLogger.error = (...args) => {
  console.error(...args);
  originalError(...args);
};
rollbarLogger.warning = (...args) => {
  console.warn(...args);
  originalWarning(...args);
};
rollbarLogger.info = (...args) => {
  console.log(...args);
  originalInfo(...args);
};

export const LogContext = createContext();
export const useLog = () => useContext(LogContext);

export const LogContextProvider = ({ children }) => {
  return (
    <LogContext.Provider value={rollbarLogger}>
      {children}
    </LogContext.Provider>
  );
};

export const withLog = (WrappedComponent) => (props) => (
  <LogContext.Consumer>
    {(value) => <WrappedComponent log={value} {...props} />}
  </LogContext.Consumer>
);
