import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNodeList } from 'src/kiska/components/NodeList';
import { extractDateRangeFromVariables } from 'src/utils';
import { format, parseISO } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const ReportingDates = (props) => {
  const classes = useStyles(props);
  const { variables, nodes } = useNodeList();

  const { lte, gte } = extractDateRangeFromVariables(variables);
  let datesStr;
  if (lte && gte) {
    datesStr = `${format(parseISO(gte), `MMM d yyyy`)} - ${format(parseISO(lte), `MMM d yyyy`)}`;
  } else {
    datesStr = 'All';
  }

  return (
    <>
      <Typography variant="body1" color="textSecondary" component="span">
        Reporting Period:&nbsp;
      </Typography>
      <Typography variant="body1" color="textPrimary" component="span">
        {datesStr}
      </Typography>
      <br />
      <Typography variant="body1" color="textSecondary" component="span">
        Report Generated:&nbsp;
      </Typography>
      <Typography variant="body1" color="textPrimary" component="span">
        {format(new Date(), `MMM d, yyyy h:mmaaaaa'm`)}
      </Typography>
    </>
  );
};

ReportingDates.propTypes = {
};
ReportingDates.defaultProps = {
};

export { ReportingDates };
