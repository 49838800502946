import { createMuiTheme } from '@material-ui/core/styles';
import { orange, blue, indigo, purple, red, deepPurple, blueGrey } from '@material-ui/core/colors';
import _ from 'lodash';

import 'src/style/fonts/fonts.css';
import 'src/style/main.css';

const defaultMuiTheme = createMuiTheme();

const commonTheme = {
  spacing: 8,

  typography: {
    h1: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    h2: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    h3: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    h4: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    h5: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    h6: {
      fontFamily: `'Exo 2', sans-serif`,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    subtitle2: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    button: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    overline: {
      fontFamily: `'Exo 2', sans-serif`,
    },

    body1: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    body2: {
      fontFamily: `'Exo 2', sans-serif`,
    },
    caption: {
      fontFamily: `'Exo 2', sans-serif`,
    },

  },

  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: '.9rem',
        lineHeight: 1.1,
      },
    },

    MuiButton: {
      label: {
        lineHeight: 1.2,
        textAlign: 'center',
        '& svg:first-child:not(:only-child)': {
          marginRight: 8 * 1,
        },
      },
    },

    MuiFab: {
      label: {
        '& svg:first-child:not(:only-child)': {
          marginRight: 8 * 1,
        },
      },
    },

    SnackbarItem: {
      message: {
        color: '#FFF',
        fontSize: '3rem',
      },
    },

    MuiTooltip: {
      tooltip: {
        fontFamily: '"Exo 2", sans-serif',
        fontSize: 16,
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        padding: '8px 8px',
      },
    },

    MuiAccordionDetails: {
      root: {
        padding: defaultMuiTheme.spacing(1, 1, 1),
      },
    },

    MuiInputBase: {
      root: {
        fontFamily: '"Exo 2", sans-serif',
      },
      input: {
        fontSize: 18,
      },
      inputMultiline: {
        fontSize: 18,
      },
    },

    MuiInputLabel: {
      root: {
        fontSize: 18,
      },
      outlined: {
        transform: 'translate(14px, 8px) scale(1)',
      },
      shrink: {
        transform: 'translate(13px, -15px) scale(0.9) !important',
      },
    },

    MuiOutlinedInput: {
      input: {
        padding: '8px 12px',
      },
      root: {
        '& legend': {
          fontSize: 16,
        },
      },
      multiline: {
        padding: '8px 12px',
      },
    },

    MuiSelect: {
      root: {
        lineHeight: 1.2,
      },
    },
  },
};

const dark = createMuiTheme(_.merge({}, commonTheme, {
  kiska: {
    name: 'dark',
    type: 'screen',
  },

  palette: {
    type: 'dark',
    primary: {
      main: 'hsla(195, 104%, 32%, 1)', // #007aa3
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: 'hsla(36, 100%, 60%, 1)', // #cc7a00
      contrastText: '#000',
    },
    background: {
      secondary: 'hsla(0, 0%, 0%, .4)',
      formSection: 'hsla(60, 0%, 100%, .1)',
      formHeading: 'hsla(200, 15%, 40%, 1)',
      // formHeading: 'hsla(38, 27%, 38%, 1)',
      formValue: 'hsla(0, 0%, 100%, .05)',
      red: '#422',
    },
    text: {
      formHeading: '#FFF',
      // formHeading: '#E8E8E8',
      danger: '#F44',
      notes: 'hsla(120, 100%, 69%, 1)',
    },
    border: {
      light: 'hsla(60, 0%, 100%, .1)',
      strong: 'hsla(60, 0%, 100%, .2)',
      formSection: 'hsla(60, 0%, 100%, .2)',
    },
    brand: {
      appBar: 'hsla(195, 100%, 20%, 1)',
    },
  },

  overrides: {
    MuiTableCell: {
      head: {
        color: '#CCC',
        fontWeight: 700,
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      },
    },

    MuiOutlinedInput: {
      root: {
        backgroundColor: 'rgba(255,255,255,.15)',
      },
      input: {
        backgroundColor: 'rgba(255,255,255,0)',
      },
      notchedOutline: {
        borderColor: 'rgba(255, 255, 255, 0.23)',
      },
    },

    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'rgba(255, 255, 255, 0.7)',
        },
      },
    },

    MuiLink: {
      root: {
        color: 'hsla(195, 100%, 60%, 1)',
      },
    },

    MuiPopover: {
      root: {
        // '& > div[aria-hidden="true"]': {
          background: 'rgba(0,0,0,.7) !important',
        // },
      },
    },
  },
}));

const light = createMuiTheme(_.merge({}, commonTheme, {
  kiska: {
    name: 'light',
    type: 'screen',
  },

  palette: {
    type: 'light',
    primary: {
      main: 'hsla(195, 104%, 32%, 1)',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: 'hsla(36, 100%, 40%, 1)',
      contrastText: '#FFFFFF',
    },
    background: {
      secondary: 'hsla(0, 0%, 0%, .04)',
      default: 'hsla(0, 0%, 98%, 1)',
      formSection: 'hsla(60, 0%, 0%, .015)',
      formHeading: 'hsla(200, 100%, 94%, 1)',
      // formHeading: 'hsla(38, 60%, 90%, 1)',
      formValue: 'hsla(0, 0%, 0%, .05)',
      red: '#FDD',
    },
    text: {
      formHeading: '#5E5E5E',
      danger: '#800',
      notes: 'hsla(120, 100%, 15%, 1)',
    },
    border: {
      light: 'hsla(60, 0%, 0%, .05)',
      strong: 'hsla(60, 0%, 0%, .2)',
      formSection: 'hsla(60, 0%, 0%, .2)',
    },
    brand: {
      appBar: 'hsla(195, 104%, 32%, 1)',
    },
  },

  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
    },

    MuiOutlinedInput: {
      root: {
        backgroundColor: 'rgba(0,0,0,.04)',
      },
      input: {
        backgroundColor: 'rgba(0,0,0,0)',
      },
      notchedOutline: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },

    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.7)',
        },
      },
    },

    MuiPopover: {
      root: {
        '& > div[aria-hidden="true"]': {
          background: 'rgba(255,255,255,.7) !important',
        },
      },
    },
  },
}));

const print = createMuiTheme(_.merge({}, commonTheme, light, {
  kiska: {
    name: 'print',
    type: 'print',
  },

  spacing: 4,

  palette: {
    background: {
      secondary: 'rgba(0,0,0,.03)',
      default: '#FFF',
      paper: '#FFF',
      formSection: 'hsl(60, 0%, 0%, 0)',
      formHeading: 'hsl(0, 0%, 100%, 1)',
      formValue: 'hsla(0, 0%, 0%, 0)',
    },
    text: {
      notes: '#000',
      formHeading: '#000',
    },
    border: {
      // light: 'hsla(60, 0%, 0%, .05)',
      // strong: 'hsla(60, 0%, 0%, .2)',
      formSection: 'hsla(0, 0%, 0%, .5)',
    },
  },

  // Force printing at a media width between lg and xl
  breakpoints: {
    values: {
      xs: 0,
      sm: 300,
      md: 310,
      lg: 320,
      xl: 100000,
    },
  },

  overrides: {
    MuiLink: {
      root: {
        color: light.palette.text.primary,
      },
    },
  },
  typography: {
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
  },
}));

const themes = {
  default: dark,
  dark,
  light,
  print,
};

//  console.log('Themes: ', themes);

export { themes };
