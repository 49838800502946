import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Hidden, Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { Stop as StopIcon, PlayArrow as PlayIcon, Create } from '@material-ui/icons';
import EditButton from 'src/components/updates/EditButton';
import classNames from 'classnames';
import { LocalNodeProvider } from 'src/kiska/components/LocalNodeContext';
import { FormSection } from 'src/components/forms/FormSection';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { ShareMenu } from 'src/kiska/components/share/ShareMenu';
import { useNode } from 'src/kiska/hooks/useNode';
import { SignatureUpdateForm } from './SignatureUpdateForm';

import { FormUpdateForm } from './FormUpdateForm';
import { Signature } from './Signature';
import { findForm } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  actions: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  formContainer: {
    padding: theme.spacing(0, 0),
  },
}));

const FormView = (props) => {
  const formRef = useRef();
  const { printMode } = usePrint();
  const classes = useStyles();
  const { node: propsNode, onSigning, allowDelete } = props;
  const { node } = useNode({ type: 'form', id: propsNode.id });

  if (!node || !node.id) return null;
  const form = findForm(node.name, node.version);

  return (
    <LocalNodeProvider node={node} type="form">
      <div className={classes.root}>
        <Grid container justify="space-between" alignItems="center" spacing={2} className={classes.actions}>

          <Grid container item spacing={2} justify="flex-start" style={{ width: 'auto' }}>

            <Grid item>
              <form.UpdateForm node={node} form={form} trigger={<EditButton color="primary" variant="contained">Edit Form</EditButton>} allowDelete={allowDelete} />
            </Grid>

          </Grid>

          <Grid item xs="auto">
            <ShareMenu
              name={`${form.title}.pdf`}
              targetRef={formRef}
              emailData={{
                subject: `${form.title} PDF`,
                body: `Hello,\n\nYou have been sent a "${form.title}" form . Please find the attached PDF file.`,
              }}
            />
          </Grid>
        </Grid>

        <div className={classes.formContainer} ref={formRef}>
          {printMode && <Typography variant="h4" align="center" gutterBottom>{form.title}</Typography>}
          <form.Form view />
        </div>

      </div>
    </LocalNodeProvider>
  );
};

FormView.propTypes = {
  node: PropTypes.object,
};
FormView.defaultProps = {
  node: undefined,
};

export default FormView;
