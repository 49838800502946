import { useUser } from 'src/kiska/components/contexts/UserContext';

const useDisplayParamsPermsFilter = (params) => {
  const { user: { permissions: perms } } = useUser();

  if (!perms.canViewPayRates()) params.showFinancials = false;

  return params;
};

export { useDisplayParamsPermsFilter };
