import React from 'react';

export default {
  types: {
    file: {
      renderers: {
        inline: ({ node }) => <>{node.name}</>,
      },

      selections: {
        default: `id size mimeType name url`,
      },

      fields: {
        size: {
          type: 'Int',
        },

        mimeType: {
          type: 'String',
        },

        name: {
          type: 'String',
        },

        url: {
          type: 'String',
        },

      },
    },
  },
};
