import React, { useState, useEffect } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { ButtonGroup, InputAdornment, Button, Input, Typography, Select, MenuItem } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import DiagramSvg from './DiagramSvg';
import { closestFraction, fractions } from './fractions';
import { dies, thicknesses } from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  svg: {
    width: 'auto',
    height: 200,
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      height: 150,
    },
  },
  select: {
    minWidth: 200,
    fontSize: '1.5em',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  input: {
    width: 200,
    fontSize: '1.5em',
    '& input': {
      textAlign: 'right',
    },
  },
  label: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  formInput: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  dieOptionColor: {
    display: 'inline-block',
    width: 30,
    height: 30,
    borderRadius: '50%',
    marginRight: 8,
  },
  myButtonGroup: {
    '& button': {
      borderRadius: 0,
    },
    '& button:first-of-type': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '& button:last-of-type': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
}));

const renderErrors = (errors) => (
  <>
    <Typography variant="body1">To find the distance between brake marks, you must first:</Typography>
    <ol>
      {errors.map((e) => (
        <li key={e}>
          <Typography variant="body1" align="left">
            {/* <span style={{ color: '#C00' }}>Error: </span> */}
            {e}
          </Typography>
        </li>
      ))}
    </ol>
  </>
);

const PrecisionButton = ({ value, setMethod, denominator }) => (
  <Button onClick={() => setMethod(denominator)} color={value === denominator ? 'primary' : 'default'} variant="outlined" size="small">
    <sup>1</sup>/<sub>{denominator}</sub>"
  </Button>
);

const PressBrakeTool = () => {
  const classes = useStyles();
  const [mode, setMode] = useState('outside');
  const [thickness, setThickness] = useState('');
  const [measurement, setMeasurement] = useState('');
  const [precision, setPrecision] = useState(16);
  const [addFraction, setAddFraction] = useState(0);
  const [die, setDie] = useState('');
  const formedRadius = _.get(dies, `${die}.formedRadius.${thickness}`);
  let D;
  let formattedD;
  const errors = [];
  const addFractions = _.pickBy(fractions, (f) => f.denominator <= 16);

  const totalMeasurement = Number(measurement) + (addFraction && addFraction.value);

  if (!totalMeasurement || totalMeasurement <= 0) errors.push('Enter a Drawing Measurement above');
  if (!thicknesses[thickness]) errors.push('Select a Material Thickness above');
  if (!die) errors.push('Select a valid Bottom Die above');

  if (!errors.length) {
    if (mode === 'inside') {
      D = totalMeasurement + (thicknesses[thickness].value * 2) - (formedRadius * 2);
    } else if (mode === 'outside') {
      D = totalMeasurement - (formedRadius * 2);
    }
  }

  if (D) formattedD = closestFraction(D, precision);

  useEffect(() => {
    if (die && !formedRadius) {
      setDie('');
    }
  }, [die, formedRadius]);

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="center" spacing={1}>
        {/* Left Column */}
        <Grid container item sm={12} md={6} alignItems="center" spacing={2}>

          <Grid item container xs={12} sm={6} className={classes.label}>
            <Typography variant="body1">Drawing Measurement:</Typography>
          </Grid>
          <Grid item container xs={12} sm={6} className={classes.formInput}>
            <Input
              value={measurement}
              
              className={classes.input}
              style={{ width: 100 }}
              // endAdornment={<InputAdornment position="end">inches</InputAdornment>}
              onChange={(event) => setMeasurement(event.target.value)}
            />
            &nbsp;
            <Select
              style={{ width: 55, minWidth: 55 }}
              value={addFraction.label || 0}
              onChange={({ target: { value } }) => (setAddFraction(value ? fractions[value] : 0))}
              className={classes.select}
            >
              <MenuItem key={0} value={0}>&nbsp;</MenuItem>
              {_.map(addFractions, (f) => <MenuItem key={f.label} value={f.label} style={{ fontSize: '2em', fontWeight: 700 }}>{f.formattedFraction}</MenuItem>)}
            </Select>
            <Typography variant="body2" style={{ alignSelf: 'center' }}>&nbsp;inches</Typography>
          </Grid>

          <Grid item container xs={12} sm={6} className={classes.label}>
            <Typography variant="body1">Drawing Measurement Type:</Typography>
          </Grid>
          <Grid item container xs={12} sm={6} className={classes.formInput}>
            <ButtonGroup variant="contained">
              <Button onClick={() => setMode('outside')} color={mode === 'outside' ? 'primary' : 'default'}>Outside</Button>
              <Button onClick={() => setMode('inside')} color={mode === 'inside' ? 'primary' : 'default'}>Inside</Button>
            </ButtonGroup>
          </Grid>

          <Grid item container xs={12} sm={6} className={classes.label}>
            <Typography variant="body1">Material Thickness:</Typography>
          </Grid>
          <Grid item container xs={12} sm={6} className={classes.formInput}>
            <Select value={thickness} onChange={(event) => setThickness(event.target.value)} className={classes.select}>
              {_.map(thicknesses, (t) => <MenuItem key={t.value} value={t.label}>{t.label}</MenuItem>)}
            </Select>
          </Grid>

          <Grid item container xs={12} sm={6} className={classes.label}>
            <Typography variant="body1">Bottom Die:</Typography>
          </Grid>
          <Grid item container xs={12} sm={6} className={classes.formInput}>
            <Select value={die} onChange={(event) => setDie(event.target.value)} className={classes.select}>
              {_.map(dies, (d, name) => {
                const valid = _.get(d.formedRadius, `${thickness}`);
                return (
                  <MenuItem key={name} value={name} disabled={!valid}>
                    <span className={classes.dieOptionColor} style={{ backgroundColor: d.color }} />
                    <span style={{ textDecoration: valid ? 'none' : 'line-through' }}>{d.label}</span>
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>

        {/* Right Column */}
        <Grid container item sm={12} md={6}>
          <br /><br />
          <Grid item container justify="center" xs={12}>
            <DiagramSvg
              className={classes.svg}
              mode={mode}
              text={totalMeasurement ? `${totalMeasurement}"` : '?'}
              thicknessText={thickness || '?'}
            />
          </Grid>
        </Grid>

        {/*  Results */}
        <Grid item>
          <Typography variant="h6" align="center" gutterBottom color="textSecondary">Distance Between Marks:</Typography>
          {errors.length
            ? renderErrors(errors)
            : (
              <>
                <Typography variant="h4" align="center" color="secondary" gutterBottom>{formattedD}</Typography>
                <Grid container justify="center" alignItems="center" className={classes.myButtonGroup}>
                  <PrecisionButton denominator={8} setMethod={setPrecision} value={precision} />
                  <PrecisionButton denominator={16} setMethod={setPrecision} value={precision} />
                  <PrecisionButton denominator={32} setMethod={setPrecision} value={precision} />
                  <PrecisionButton denominator={64} setMethod={setPrecision} value={precision} /><br />
                </Grid>
                <Typography variant="subtitle2" align="center" color="textSecondary">Most precise: {D}</Typography>
              </>
            )}
        </Grid>
      </Grid>
    </div>
  );
};

PressBrakeTool.propTypes = {
};
PressBrakeTool.defaultProps = {
};

export { PressBrakeTool };
