import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from 'src/components/modules/user/UserAvatar';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { SimpleTable } from './SimpleTable';
import { UserUpdateForm } from './UserUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  tasks: {
    '& > *': {
      display: 'inline-block',
      margin: theme.spacing(0.5, 0.5),
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
}));

const UsersPanel = () => {
  const { user } = useUser();
  const classes = useStyles();

  const initial = {
    orderBy: [{ displayName: 'asc' }],
    where: !user.options.systemAccount ? { _not: { options: { _contains: { systemAccount: true } } } } : undefined,
  };

  const columns = [
    { label: 'Name', fieldName: 'displayName', Renderer: ({ parentNode }) => <><UserAvatar node={parentNode} withName /></>, orderByPath: 'displayName' },
    // 'email',
    {
      label: 'Pay Rates',
      fieldName: 'payRates',
      orderByPath: null,
      Renderer: ({ value }) => (
        <div className={classes.tasks}>
          {value && value.map(({ pay_rate: { id, label } }) => (
            <span key={id}>
              <Chip label={label} />
            </span>
          ))}
        </div>
      ),
    },
  ];

  const tableProps = {
    hideFilterButtons: true,
    hideOrderByButtons: false,
    hideHeader: false,
    hidePageControls: true,
  };

  return (
    <>
      <SimpleTable
        type="user"
        initial={initial}
        rowClickAction="edit"
        enableAdd={false}
        UpdateForm={UserUpdateForm}
        columns={columns}
        tableProps={tableProps}
      />
    </>
  );
};

UsersPanel.propTypes = {
};
UsersPanel.defaultProps = {
};

export { UsersPanel };
