import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { QueryVariablesContext } from 'src/kiska/components/NodeList/QueryVariablesContext';
import { useNodeList } from 'src/kiska/components/NodeList';
import ClearFiltersIcon from 'mdi-material-ui/FilterRemoveOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    '&.active': {
      display: 'block',
    },
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const EmptyData = (props) => {
  const classes = useStyles(props);
  const { updateVariables, variables, nodes } = useNodeList();

  const clearFilters = () => {
    if (variables.args !== undefined) updateVariables({ path: 'args', value: {} });
    if (variables.where !== undefined) updateVariables({ path: 'where', value: {} });
  };

  return (
    <div className={classNames(classes.root, (!nodes || !nodes.length) && 'active')}>
      <div className={classes.inner}>
        <Typography variant="h6" color="textSecondary" align="center" style={{ marginBottom: 4 }}>
          No data to show.<br />
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center" style={{ marginBottom: 16 }}>
          Try adjusting your dates and filters.
        </Typography>
        <Button color="secondary" variant="contained" onClick={clearFilters}>
          <ClearFiltersIcon />
          <span>Clear All Filters</span>
        </Button>
      </div>
    </div>
  );
};

EmptyData.propTypes = {
  overlay: PropTypes.bool,
};
EmptyData.defaultProps = {
  overlay: true,
};

export { EmptyData };
