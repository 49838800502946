import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from 'src/kiska/components/Grid';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { ReportTitle } from './ReportTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));

const ReportTopContent = (props) => {
  const classes = useStyles(props);
  const { printMode } = usePrint();
  const { children, title, subtitle } = props;

  return (
    <div className={classes.root}>
      {printMode && title && <ReportTitle title={title} subtitle={subtitle} />}
      <Grid container spacing={2} justify="flex-start" className={classes.grid}>
        {children}
      </Grid>
    </div>
  );
};

ReportTopContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
ReportTopContent.defaultProps = {
  children: undefined,
  title: undefined,
  subtitle: undefined,
};

export { ReportTopContent };
