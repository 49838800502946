import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import { useUser } from './UserContext';

const ThemeProviderContext = createContext();
const useThemeProvider = () => useContext(ThemeProviderContext);

const ThemeProvider = ({ initialActiveThemeName, themes: propsThemes, children }) => {
  const { user } = useUser();
  const parentThemeProvider = useThemeProvider();
  const themes = propsThemes || parentThemeProvider.themes;
  const userTheme = _.get(user, 'preferences.themeName');

  const [activeThemeName, setActiveThemeName] = useState(initialActiveThemeName);

  let resolvedActiveThemeName = activeThemeName;
  if (['inherit', 'unset'].includes(activeThemeName)) {
    resolvedActiveThemeName = parentThemeProvider ? parentThemeProvider.activeThemeName : 'default';
  }

  if (userTheme && resolvedActiveThemeName === 'default') resolvedActiveThemeName = userTheme;

  const activeTheme = themes[resolvedActiveThemeName];

  const contextValue = {
    activeThemeName,
    activeTheme,
    themes,
    setActiveThemeName,
  };

  return (
    <ThemeProviderContext.Provider value={contextValue}>
      <MuiThemeProvider theme={activeTheme}>
        {children}
      </MuiThemeProvider>
    </ThemeProviderContext.Provider>
  );
};

ThemeProvider.propTypes = {
  initialActiveThemeName: PropTypes.string,
  themes: PropTypes.object,
  children: PropTypes.node,
};

ThemeProvider.defaultProps = {
  children: null,
  initialActiveThemeName: 'default',
  themes: undefined,
};

export { ThemeProvider, ThemeProviderContext, useThemeProvider };
