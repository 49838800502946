import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { forms } from './forms';
import { StartFormButton } from './StartFormButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const StartNewFormList = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {_.map(forms, (form) => <StartFormButton key={form.id} form={form} />)}
      </Grid>
    </div>
  );
};

StartNewFormList.propTypes = {
};
StartNewFormList.defaultProps = {
};

export { StartNewFormList };
