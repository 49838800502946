import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { SchemaContext } from '../contexts/SchemaContext';
import { QueryVariablesProvider } from './QueryVariablesContext';
import { NodeList } from './index';

const NodeListWithVars = (props) => {
  const { schema } = useContext(SchemaContext);
  const { type, fixtures, initial, ...nodeListProps } = props;

  const memoedInitial = useMemo(() => {
    const schemaType = _.get(schema, `types[${type}]`);
    const functionTableMode = schemaType.isFunctionTable;
    let defaultOrderBy;

    if (schemaType.fields.updatedAt) {
      defaultOrderBy = [{ updatedAt: 'desc_nulls_last' }];
    } else {
      defaultOrderBy = [{ id: 'desc_nulls_last' }];
    }

    const result = _.merge({
      limit: 25,
      offset: 0,
      where: {},
      args: functionTableMode ? {} : undefined,
    }, initial);

    if (!result.orderBy) result.orderBy = defaultOrderBy;

    return result;
  }, [initial, schema, type]);

  return (
    <QueryVariablesProvider initial={memoedInitial} fixtures={fixtures} type={type}>
      <NodeList {...nodeListProps} />
    </QueryVariablesProvider>
  );
};

NodeListWithVars.propTypes = {
  type: PropTypes.string.isRequired,
  initial: PropTypes.object,
  fixtures: PropTypes.any,
};
NodeListWithVars.defaultProps = {
  initial: undefined,
  fixtures: undefined,
};

export { NodeListWithVars };
