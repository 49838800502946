import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import { formatCurrency, formatDuration } from 'src/utils';
import { WorkEntryRow } from './dashboards/WorkEntryRow';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, theme.kiska.type === 'print' ? 0 : 2, 0),
    backgroundColor: theme.palette.background.formSection,
    borderRadius: theme.kiska.type === 'print' ? 0 : 4,
    // border: 'solid 1px',
    borderColor: theme.palette.border.formSection,
    overflow: 'hidden',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 0, 1),
    backgroundColor: theme.palette.background.formHeading,
    color: theme.palette.text.formHeading,
  },
  date: {
    fontWeight: 300,
  },
  year: {
    marginLeft: theme.spacing(1),
    opacity: 0.7,
  },
  rows: {
    padding: theme.spacing(0),
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  stat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: 80,
    padding: theme.spacing(0, 1, 0, 1),
    borderLeft: `solid 1px`,
    borderLeftColor: theme.palette.border.light,
  },
  statLabel: {
    marginBottom: -8,
    fontSize: '.6rem',
    opacity: 0.7,
  },
  statValue: {
    fontSize: '1.1rem',
  },
}));

const WorkEntryDayGroup = (props) => {
  const classes = useStyles(props);
  const { group, displayParams, onRowClick } = props;
  const { showFinancials: propsShowFinancials } = displayParams;
  const startOfPeriod = parseISO(group.startOfPeriod);
  const showFinancials = propsShowFinancials;
  // const showFinancials = propsShowFinancials && user.permissions.canViewFinancials();

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div>
          <Typography variant="h6" component="span" color="inherit" className={classes.date}>
            {format(startOfPeriod, `EEEE, MMM d`)}
          </Typography>
          <Typography variant="overline" component="span" color="inherit" className={classes.year}>{format(startOfPeriod, `yyyy`)}</Typography>
        </div>

        <div className={classes.stats}>
          {showFinancials && (
            <>
              <div className={classes.stat}>
                <Typography variant="overline" color="inherit" className={classes.statLabel}>Price</Typography>
                <Typography variant="body1" color="inherit" className={classes.statValue}>${formatCurrency(group.sumPrice)}</Typography>
              </div>
              <div className={classes.stat}>
                <Typography variant="overline" color="inherit" className={classes.statLabel}>Cost</Typography>
                <Typography variant="body1" color="inherit" className={classes.statValue}>${formatCurrency(group.sumCost)}</Typography>
              </div>
            </>
          )}
          <div className={classes.stat}>
            <Typography variant="overline" color="inherit" className={classes.statLabel}>Hours</Typography>
            <Typography variant="body1" color="inherit" className={classes.statValue}>{formatDuration(group.sumDuration)}</Typography>
          </div>
        </div>
      </div>

      <div className={classes.rows}>
        {group.entries.map((entry) => {
          return (
            <WorkEntryRow key={entry.id} node={entry.workEntry} displayParams={displayParams} onClick={() => onRowClick && onRowClick(entry)} />
          );
        })}
      </div>
    </Paper>
  );
};

WorkEntryDayGroup.propTypes = {
  group: PropTypes.object.isRequired,
  displayParams: PropTypes.object.isRequired,
};
WorkEntryDayGroup.defaultProps = {
};

export { WorkEntryDayGroup };
