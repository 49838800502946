import React, { Component, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { settings } from 'src/kiska/utils/settings';

const SettingsContext = createContext({});
const useSettings = () => useContext(SettingsContext);

class SettingsProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const { children } = this.props;

    return (
      <SettingsContext.Provider value={settings}>
        {children}
      </SettingsContext.Provider>
    );
  }
}

const withSettings = (WrappedComponent) => (props) => (
  <SettingsContext.Consumer>
    {() => <WrappedComponent settings={settings} {...props} />}
  </SettingsContext.Consumer>
);

export { withSettings, SettingsProvider, SettingsContext, useSettings };
