import React from 'react';
import PropTypes from 'prop-types';
import { } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import CrudForm from '../../updates/CrudForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const UserUpdateFormInner = ({ variant }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">

        <Grid item xs={12} sm={12}>
          <NodeField name="payRates" label="" />
        </Grid>

      </Grid>
    </div>
  );
};

UserUpdateFormInner.propTypes = {
  variant: PropTypes.string,
};
UserUpdateFormInner.defaultProps = {
  variant: undefined,
};

const UserUpdateForm = ({ id, trigger, onClose, open, variant }) => {
  return (
    <CrudForm
      type="user"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderRichTitle={(n) => (n ? <>Edit Pay Rates for {n.displayName}</> : '')}
      renderTitle={(n) => (n ? `Edit Pay Rates for ${n.displayName}` : '')}
      maxWidth="sm"
      allowDelete={false}
    >
      <UserUpdateFormInner variant={variant} />
    </CrudForm>
  );
};

UserUpdateForm.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string,
};
UserUpdateForm.defaultProps = {
  id: undefined,
  trigger: undefined,
  onClose: undefined,
  open: undefined,
  variant: '',
};

export { UserUpdateForm };
