import React from 'react';
import PropTypes from 'prop-types';
import { Typography, LinearProgress, ButtonBase, Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { fileToIconSrc } from './fileToIcon';

import imageIcon from './images/image.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1, 1),
    backgroundColor: 'hsla(0, 0%, 0%, .2)',
    overflow: 'hidden',
    borderRadius: 4,
    position: 'relative',
    '&.small': {
      width: 30,
    },
  },
  thumb: {
    height: 100,
    width: '100%',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    '&.icon': {
      padding: theme.spacing(1),
    },
    '&.icon.small': {
      padding: theme.spacing(0),
    },
    '&.noName': {
      height: 124,
    },
    '&.small': {
      height: 30,
    },
  },
  name: {
    height: 24,
    lineHeight: 1.2,
    padding: theme.spacing(0.5, 0.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    fontSize: '.85rem',
    zIndex: 10,
  },
  progress: {
    height: 24,
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  deleteButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 10,
    color: '#C00',
    backgroundColor: 'hsla(0, 0%, 0%, .7)',
    '&:hover': {
      backgroundColor: 'hsla(0, 0%, 0%, .8)',
    },
    padding: 6,
  },
}));

const FilePreview = (props) => {
  const settings = useSettings();
  const classes = useStyles(props);
  const { showProgress, showName, file, onClick, onDelete, className, size } = props;
  const Component = onClick ? ButtonBase : Paper;
  const icon = fileToIconSrc(file);
  const { printMode } = usePrint();

  if (!file) return null;

  const isImage = file.mimeType.startsWith('image/');

  let url = icon;

  if (isImage) {
    if (file.src) {
      // Files being uploaded have a preview encoded as base64 in the src prop
      url = file.src;
    } else {
      // The file URLs from the database have no origin. We need to add it based on the settings.
      url = `${settings.public.kiskaOrigin}${file.url}`;
    }
  }

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    if (onDelete) onDelete();
  };

  const showingFileName = showName && !isImage;

  return (
    <Component className={classNames(classes.root, className, size)} onClick={onClick} component={onClick ? 'a' : 'div'}>
      {onDelete && (
        <IconButton component="div" onClick={handleDeleteClick} className={classes.deleteButton}><DeleteIcon color="inherit" /></IconButton>
      )}
      <img
        className={classNames(
          classes.thumb, (!showingFileName || size === 'small') && 'noName',
          size,
          !printMode && isImage && 'image',
          (printMode || !isImage) && 'icon',
        )}
        src={isImage && printMode ? imageIcon : url}
        style={{ objectFit: isImage ? 'cover' : 'contain' }}
      />

      {showingFileName && (
        <Typography variant="body1" color="textSecondary" align="center" className={classes.name}>
          {file.name}
        </Typography>
      )}
      {showProgress && file.progress !== undefined && (
        <LinearProgress value={file.progress} className={classes.progress} variant="determinate" />
      )}
    </Component>
  );
};

FilePreview.propTypes = {
  showProgress: PropTypes.bool,
  showName: PropTypes.bool,
  file: PropTypes.object,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
};
FilePreview.defaultProps = {
  showProgress: true,
  showName: true,
  file: undefined,
  onClick: undefined,
  onDelete: undefined,
  className: undefined,
  size: 'normal',
};

export { FilePreview };
