import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from 'mdi-material-ui/Magnify';

import { NodeListContext } from '.';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textField: {
    // maxWidth: '100%',
    // width: '20em',
    width: '100%',
  },
  searchInput: {
    // paddingLeft: theme.spacing(1),
  },
});

class LikeFilter extends Component {
  static contextType = NodeListContext

  static propTypes = {
    label: PropTypes.node,
    classes: PropTypes.object.isRequired,
    not: PropTypes.bool,
    path: PropTypes.string,
    paths: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    caseSensitive: PropTypes.bool,
    autoWildcards: PropTypes.bool,
    textFieldProps: PropTypes.object,
    search: PropTypes.bool,
    placeholder: PropTypes.string,
    searchInputClassName: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    label: undefined,
    not: false,
    caseSensitive: false,
    autoWildcards: true,
    textFieldProps: {},
    search: false,
    paths: undefined,
    path: undefined,
    placeholder: undefined,
    searchInputClassName: undefined,
    className: undefined,
  }

  handleChange = (event) => {
    const { autoWildcards } = this.props;
    let value = event.target.value;
    if (value && autoWildcards) value = `%${value}%`;
    this.change(value);
  }

  getCondition = () => {
    const { not, caseSensitive } = this.props;
    // Returns _like, _ilike, _nlike, or _nilike
    const condition = `_${not ? 'n' : ''}${caseSensitive ? '' : 'i'}like`;
    return condition;
  }

  normalizePaths = () => {
    const { path: propsPath, paths: propsPaths } = this.props;
    let paths;
    if (propsPath) paths = [propsPath];
    else if (!Array.isArray(propsPaths)) paths = propsPaths.split(/[,\s]+/);
    else paths = propsPaths;
    return paths;
  }

  change = (value) => {
    const { updateVariables } = this.context;
    const condition = this.getCondition();
    const nValue = value || undefined;

    const paths = this.normalizePaths();

    const pathValuePairs = paths.map((path, index) => {
      return { path: `where._or[${index}].${path}.${condition}`, value: nValue };
    });

    updateVariables({ pathValuePairs });
  }

  clear = (event) => {
    this.change(undefined);
  }

  render() {
    const { variables } = this.context;
    const { classes, label: propsLabel, not, placeholder, className, searchInputClassName, autoWildcards, textFieldProps: propsTextFieldProps, search } = this.props;
    const condition = this.getCondition();
    const paths = this.normalizePaths();

    let label;
    if (propsLabel === undefined) {
      label = not ? 'Does not contain the text:' : 'Contains the text:';
    } else {
      label = propsLabel;
    }

    let value = _.get(variables.where, `_or[0].${paths[0]}.${condition}`);
    if (!value) value = '';
    if (autoWildcards) {
      value = value.slice(1, -1);
    }

    let textFieldProps = propsTextFieldProps;
    if (search) {
      textFieldProps = {
        variant: 'outlined',
        InputProps: {
          startAdornment: <InputAdornment position="start">&nbsp;<SearchIcon color="primary" /></InputAdornment>,
          inputProps: {
            className: classNames(classes.searchInput, searchInputClassName),
          },
        },
        ...textFieldProps,
      };
    }

    return (
      <div className={classNames(classes.root, className)}>
        <TextField className={classes.textField} placeholder={placeholder} label={label} value={value} onChange={this.handleChange} {...textFieldProps} />
      </div>
    );
  }
}

LikeFilter = withStyles(styles)(LikeFilter);
export default LikeFilter;
