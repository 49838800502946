import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

import FormIcon from 'mdi-material-ui/ClipboardCheckOutline';
import { useNode } from 'src/kiska/hooks/useNode';
import { PageTitle } from 'src/components/layouts/components/PageTitle';
import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
import FormView from './FormView';
import { useParams } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

const FormPageView = (props) => {
  const { id } = useParams();
  const { setTitles } = useContext(PageMetaContext);
  const { result, node } = useNode({
    type: 'form',
    id,
  });

  useEffect(() => {
    setTitles(node ? `${node.title}` : `Form`);
  }, [node, setTitles]);

  return (
    <>
      <PageTitle icon={FormIcon}>
        {node ? node.title : 'Form'}
      </PageTitle>
      {node ? <FormView node={node} /> : <CircularProgress size={50} thickness={2} />}
    </>
  );
};

FormPageView.propTypes = {
};
FormPageView.defaultProps = {
};

export { FormPageView };
