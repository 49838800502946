import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import _ from 'lodash';
import { FormLabel } from '@material-ui/core';

import splitFieldName from '../splitFieldName';
import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';
import NodeSelector from '../../NodeSelector';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexBasis: 'column',
    justifyContent: 'center',
  },
  formLabel: {
    marginBottom: theme.spacing(0.5),
  },
});

class NodeField extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    label: PropTypes.string,
    initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isMulti: PropTypes.bool,
    relatedType: PropTypes.string,
    creatable: PropTypes.bool,
    creatableInputFieldMapping: PropTypes.string,
    buildCreatableNode: PropTypes.func,
    where: PropTypes.any,
    creatableUpdateForm: PropTypes.elementType,
    filterOptions: PropTypes.func,
    renderOption: PropTypes.func,
    isSearchable: PropTypes.bool,
    onChange: PropTypes.func,
    orderBy: PropTypes.array,
    creatableFixtures: PropTypes.object,
    searchFields: PropTypes.array,
  }

  static defaultProps = {
    helperText: undefined,
    label: undefined,
    initialValue: undefined,
    isMulti: undefined,
    relatedType: undefined,
    creatable: undefined,
    creatableInputFieldMapping: undefined,
    buildCreatableNode: undefined,
    where: undefined,
    creatableUpdateForm: undefined,
    filterOptions: undefined,
    renderOption: undefined,
    isSearchable: undefined,
    onChange: undefined,
    orderBy: undefined,
    creatableFixtures: undefined,
    searchFields: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;
    const { initialValue } = props;

    registerField(props.name, { initialValue });
  }

  handleChange = (value) => {
    const { onChange } = this.context;
    const { name, onChange: propsOnChange } = this.props;

    onChange(name, value);
    if (propsOnChange) propsOnChange(name, value);
  }

  render() {
    const { name, classes, where, orderBy, searchFields, helperText, filterOptions, creatableFixtures, renderOption, label: propsLabel, isSearchable, isMulti, relatedType: propsRelatedType, creatableInputFieldMapping, creatable, creatableUpdateForm, buildCreatableNode } = this.props;
    const { getFieldValue, fixtures, getFieldErrors, pendingMutation, schema, schemaType, type } = this.context;
    const value = getFieldValue(name);
    const errors = getFieldErrors(name);
    const schemaField = schema.types[type].fields[name];
    const relatedType = propsRelatedType !== undefined
      ? propsRelatedType
      : schema.types[schemaType.fields[name].type].name;
    let label = propsLabel !== undefined ? propsLabel : schemaField.label;
    const disabled = !!fixtures[name] || pendingMutation;
    const required = schemaField.validation.required;

    if (label && required) label = <>{label}&nbsp;*</>;

    return (
      <NodeSelector
        type={relatedType}
        onChange={this.handleChange}
        value={value}
        isMulti={isMulti !== undefined ? isMulti : schemaType.fields[name].hasMany}
        creatable={creatable}
        creatableInputFieldMapping={creatableInputFieldMapping}
        buildCreatableNode={buildCreatableNode}
        where={where}
        disabled={disabled}
        creatableUpdateForm={creatableUpdateForm}
        label={label}
        helperText={helperText}
        errors={errors}
        filterOptions={filterOptions}
        renderOption={renderOption}
        isSearchable={isSearchable}
        orderBy={orderBy}
        creatableFixtures={creatableFixtures}
        searchFields={searchFields}
      />
    );
  }
}

NodeField = _.flow(
  withStyles(styles),
)(NodeField);

export default NodeField;
export { NodeField };
