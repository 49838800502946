import { useMemo } from 'react';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { useNodes } from 'src/kiska/hooks/useNode';
import _ from 'lodash';

const customerToString = (customer) => {
  if (!customer) return '';

  const lines = [];
  if (customer.company) lines.push(customer.company);
  if (customer.name) lines.push(customer.name);
  if (customer.address1) lines.push(customer.address1);
  lines.push(`${customer.city}, ${customer.state}; ${customer.country} ${customer.postalCode}`);
  if (customer.email) lines.push(customer.email);
  if (customer.phone) lines.push(customer.phone);

  return lines.join('\n');
};

const usePrevDailySafetyFormValues = ({ job }) => {
  const { schema } = useSchema();
  const { result, nodes } = useNodes({
    type: 'form',
    where: {
      jobs: { jobId: { _eq: job.id } },
      name: { _eq: 'DailySafetyForm' },
    },
    orderBy: [{ createdAt: 'desc' }],
    limit: 1,
  });
  const latestNode = nodes[0];

  const initialValues = useMemo(() => {
    if (result.loading) return null;

    const values = {};

    if (latestNode) {
      _.forEach(schema.types.form.fields, (field) => {
        if (!/^data./.test(field.name)) return;
        if (!_.get(field, 'app.copyForward')) return;
        values[field.name] = _.get(latestNode, field.name);
      });
    }

    values['data.dailySafety.jobLocation'] = values['data.dailySafety.jobLocation'] || customerToString(job.customer);

    return values;
  }, [job.customer, latestNode, result.loading, schema.types.form.fields]);

  const required = true; //! !job.tasks.find(({ task }) => task.id === 'asbestos');

  return {
    ready: !result.loading,
    initialValues,
    latestNode,
    required,
  };
};

export { usePrevDailySafetyFormValues };
