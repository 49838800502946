import React from 'react';
// import PropTypes from 'prop-types';
// import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CustomerList } from 'src/components/dashboards/CustomerList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

const Customers = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomerList />
    </div>
  );
};

Customers.propTypes = {
};
Customers.defaultProps = {
};

export default Customers;
