const buildPaperStyles = (theme) => {
  const backgroundColor = 'hsla(0, 0%, 98%, 100%)';
  // const backgroundColor = 'blue';

  return {
    box: {
      position: 'relative',
      boxShadow: `-3px 3px 6px 0px rgba(0, 0, 0, 0.15), inset 0 0 40px rgba(0, 0, 0, 0.08)`,
    },
    beforeAndAfter: {
      position: 'absolute',
      width: '40%',
      height: 10,
      content: '" "',
      left: 12,
      bottom: 12,
      background: 'transparent',
      boxShadow: `0 6px 12px rgba(0, 0, 0, 0.3)`,
      zIndex: -1,
    },
    before: {
      transform: `skew(-5deg) rotate(-5deg) translateZ(-1px)`,
    },
    after: {
      left: 'auto',
      right: 12,
      transform: `skew(5deg) rotate(5deg) translateZ(-1px)`,
    },
    topRightFold: {
      width: 0,
      height: 0,
      position: 'absolute',
      top: -1,
      right: -1,
      borderTop: `20px solid ${backgroundColor}`,
      borderLeft: `20px solid hsla(60, 100%, 90%, 100%)`,
      boxShadow: `-3px 3px 3px rgba(0,0,0,0.1)`,
    },
  };
};

export { buildPaperStyles };
