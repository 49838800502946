import React from 'react';
// import PropTypes from 'prop-types';
import { } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import CrudForm from 'src/components/updates/CrudForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const CustomerUpdateFormInner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>

        <Grid item xs={12} sm={12}>
          <TextField name="company" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField name="name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="phone" type="tel" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="email" type="email" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField name="address1" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField name="address2" />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField name="city" initialValue="Powell River" />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField name="state" initialValue="BC" />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField name="postalCode" initialValue="V8A" />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField name="country" initialValue="Canada" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField name="notes" rows={4} />
        </Grid>
      </Grid>
    </div>
  );
};

CustomerUpdateFormInner.propTypes = {
  // variant: PropTypes.string,
};
CustomerUpdateFormInner.defaultProps = {
  // variant: '',
};

const CustomerUpdateForm = ({ id, trigger, onClose, open, variant, initialValues, onSuccess }) => (
  <CrudForm
    type="contact"
    id={id}
    trigger={trigger}
    onClose={onClose}
    open={open}
    renderTitle={(node) => (node ? node.company || node.name : 'Create Contact')}
    initialValues={initialValues}
    onSuccess={onSuccess}
    fixtures={{ systemTags: [{ id: 'contact:customer' }] }}
  >
    <CustomerUpdateFormInner variant={variant} />
  </CrudForm>
);

const ContactUpdateForm = ({ id, trigger, onClose, open, variant, initialValues, onSuccess }) => (
  <CrudForm
    type="contact"
    id={id}
    trigger={trigger}
    onClose={onClose}
    open={open}
    renderTitle={(node) => (node ? node.company || node.name : 'Create Contact')}
    initialValues={initialValues}
    onSuccess={onSuccess}
    schemaExtensions={{ types: { contact: { fields: { email: { validation: { required: true } } } } } }}
    fixtures={{ systemTags: [{ id: 'contact:contact' }] }}
  >
    <CustomerUpdateFormInner variant="contact" />
  </CrudForm>
);

export { CustomerUpdateForm, ContactUpdateForm };
