import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button, Menu, MenuItem, useMediaQuery, ListItemIcon, ListItemText } from '@material-ui/core';
import PdfIcon from 'mdi-material-ui/FilePdf';
import { Print as PrintIcon, Share as ShareIcon } from '@material-ui/icons';
import DownloadIcon from 'mdi-material-ui/Download';
import EmailIcon from 'mdi-material-ui/Email';
import ViewIcon from 'mdi-material-ui/Eye';
import { makeStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import { GeneratePdfButton } from 'src/kiska/components/PdfGenerator';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  trigger: {
    // color: '#FFFFFF',
    // backgroundColor: purple[500],
    '&:hover': {
      // backgroundColor: purple[700],
    },
  },
}));

const ShareMenu = (props) => {
  const mobileDevice = useMediaQuery('(max-width:700px)', { noSsr: true });
  const { targetRef, className, name, label: propsLabel, style, icon: propsIcon, component, emailData, ...triggerProps } = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);

  let label = propsLabel;
  if (label === undefined) label = '';
  let icon = propsIcon;
  if (icon === undefined) icon = PdfIcon;
  const Component = Button || IconButton;

  const handleTriggerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = () => {
    handleClose();
  };

  const itemProps = {
    component: MenuItem,
    targetRef,
    onClick: handleItemClick,
    name,
  };

  return (
    <>
      <Component
        onClick={handleTriggerClick}
        color="default"
        style={style}
        variant="contained"
        {...triggerProps}
        className={classNames(classes.trigger, className)}
      >
        <PdfIcon color="inherit" />
        {label && <span>{label}</span>}
      </Component>
      <Menu
        className={classNames(classes.menu)}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <GeneratePdfButton {...itemProps} mode="download">
          <ListItemIcon><DownloadIcon /></ListItemIcon>
          <ListItemText primary="Download as PDF" />
        </GeneratePdfButton>
        <GeneratePdfButton {...itemProps} mode="email" emailData={emailData}>
          <ListItemIcon><EmailIcon /></ListItemIcon>
          <ListItemText primary="Email PDF" />
        </GeneratePdfButton>
        {!mobileDevice && (
          <GeneratePdfButton {...itemProps} mode="print">
            <ListItemIcon><PrintIcon /></ListItemIcon>
            <ListItemText primary="Print" />
          </GeneratePdfButton>
        )}
        {!mobileDevice && (
          <GeneratePdfButton {...itemProps} mode="view">
            <ListItemIcon><ViewIcon /></ListItemIcon>
            <ListItemText primary="View PDF" />
          </GeneratePdfButton>
        )}
      </Menu>
    </>
  );
};

ShareMenu.propTypes = {
};
ShareMenu.defaultProps = {
};

export { ShareMenu };
