import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import { FileField } from 'src/kiska/components/MutationContext/FormFields/FileField';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import BooleanField from 'src/kiska/components/MutationContext/FormFields/BooleanField';
import SelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import { FormSection, GridLineBreak } from 'src/components/forms';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { ColorField } from 'src/kiska/components/MutationContext/FormFields/ColorField';
import { SimpleTableInner } from 'src/components/dashboards/settings/SimpleTable';
import { ArrayFieldEditor } from 'src/components/ArrayFieldEditor';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { LocalNodeProvider } from 'src/kiska/components/LocalNodeContext';
import CrudForm from '../../updates/CrudForm';
import { ResourceUpdateForm } from '../resource/ResourceUpdateForm';
import { ResourceViewDialog } from '../resource/ResourceViewDialog';
import { useResourceTableColumns } from '../resource/useResourceTableColumns';

const themeOptions = [
  { value: 'dark', label: 'Dark' },
  { value: 'light', label: 'Light' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 0, 0),
  },
}));

const UserUpdateFormInner = ({ variant }) => {
  const classes = useStyles();
  const { getAllFieldValues } = useMutationContext();
  const { user } = useUser();
  const allowedToEditRole = ['admin', 'manager', 'office-admin'].includes(user.role.id);
  const node = getAllFieldValues({ jsonFieldsToObjects: true });
  const { columns } = useResourceTableColumns({ showTags: true });

  return (
    <LocalNodeProvider node={node} type="user">
      <div className={classes.root}>

        <FormSection title="Profile">
          <Grid item container justify="flex-start" xs={12}>
            <FileField name="avatar" label="Profile Picture" />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField name="displayName" helperText="How your name appears in the app" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField name="firstName" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="lastName" />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField name="preferences.title" />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField name="email" />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField name="phone" />
          </Grid>

          {allowedToEditRole && (
            <>
              <Grid item xs={12} sm={12}>
                <NodeField name="role" filterOptions={(options) => options.filter((option) => !['admin'].includes(option.id))} />
              </Grid>

              <Grid item xs={12} sm={12}>
                <NodeField name="payRates" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <ColorField name="preferences.calendarColor" />
              </Grid>
            </>
          )}
        </FormSection>

        <FormSection title="Preferences">
          <Grid item xs={12} sm={6}>
            <SelectField name="preferences.themeName" label="Color Theme" options={themeOptions} />
          </Grid>
          <GridLineBreak />
          <Grid item xs={12} sm={12}>
            <BooleanField name="preferences.soundEfxOnSwitchJobs" label="Play sound effect when starting or switching jobs" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <BooleanField name="preferences.soundEfxOnStopWork" label="Play sound effect when stopping work" />
          </Grid>
        </FormSection>

      </div>
    </LocalNodeProvider>
  );
};

UserUpdateFormInner.propTypes = {
  variant: PropTypes.string,
};
UserUpdateFormInner.defaultProps = {
  variant: '',
};

const UserUpdateForm = ({ id, trigger, onClose, open, variant }) => {
  const initialValues = {
    'preferences.themeName': 'dark',
    'preferences.soundEfxOnSwitchJobs': true,
    'preferences.soundEfxOnStopWork': true,
  };

  return (
    <CrudForm
      type="user"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderTitle={(n) => (n ? `${n.displayName}` : 'Create User')}
      maxWidth="sm"
      allowDelete={false}
      initialValues={initialValues}
    >
      <UserUpdateFormInner variant={variant} />
    </CrudForm>
  );
};

UserUpdateForm.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string,
};
UserUpdateForm.defaultProps = {
  id: undefined,
  trigger: undefined,
  onClose: undefined,
  open: undefined,
  variant: '',
};

export { UserUpdateForm };
