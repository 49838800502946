import React from 'react';

export default {
  types: {
    tag: {

      renderers: {
        inline: ({ node }) => <>{node.label}</>,
      },

      selections: {
        default: 'id label',
      },

      fields: {
        label: { type: 'String', validation: { required: true } },
        type: { type: 'String', validation: { required: true } },
        audience: { type: 'Json', validation: { required: true } },
        color: { type: 'String', validation: { required: true } },
        icon: { type: 'String', validation: { required: true } },
      },

    },

    system_tag: {

      renderers: {
        inline: ({ node }) => <>{node.label}</>,
      },

      selections: {
        default: 'id label',
      },

      fields: {
        label: { type: 'String', validation: { required: true } },
      },

    },
  },
};
