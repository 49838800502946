import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DateRangePicker } from 'src/kiska/components/NodeList/DateRangePicker';
import { Grid, FormControlLabel, Checkbox, useMediaQuery } from '@material-ui/core';
import { Hideable } from 'src/kiska/components/Hideable';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import produce from 'immer';
import { useNodeList } from 'src/kiska/components/NodeList';
import FilterIcon from 'mdi-material-ui/Filter';
import { GridLineBreak } from 'src/components/forms';
import { ToggleButtonOptionsFilter } from 'src/kiska/components/NodeList/ToggleButtonOptionsFilter';
import NodeFilter from 'src/kiska/components/NodeList/NodeFilter';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const BooleanDisplayParam = ({ name, label, displayParams, onChange }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={displayParams[name]} onChange={(event) => onChange(name, event.target.checked)} />}
      label={label}
    />
  );
};

const ExpenseListParams = (props) => {
  const classes = useStyles(props);
  const { availableFilters, displayParams, onDisplayParamsChange, initialDateRange, enabledDisplayParams, dateRangeOptions } = props;
  const [laundry, setLaundry] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const { updateVariables } = useNodeList();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  const handleChange = (name) => () => {
    setLaundry(produce((draft) => {
      draft[name] = true;
    }));
  };

  const handleDisplayParamsChange = (name, value) => {
    const newDisplayParams = produce(displayParams, (draft) => {
      draft[name] = value;
    });
    onDisplayParamsChange(newDisplayParams);
  };

  const clearFilters = () => {
    const reducerArgs = [
      { path: `where.jobId`, value: null },
      { path: `where.job.customerId`, value: null },
      { path: `where.category`, value: null },
      { path: `where.status`, value: null },
    ];
    updateVariables(reducerArgs);
  };

  const handleShowFiltersChange = (event, value) => {
    setShowFilters(value);
    if (!value) {
      clearFilters();
    }
  };

  const areAllDirty = (names) => names.reduce((acc, name) => {
    if (!laundry[name]) return false;
    return acc;
  }, true);

  const displayParamsProps = {
    onChange: handleDisplayParamsChange,
    displayParams,
  };

  const displayParamProps = {};

  const lgFilters = (
    <>
      <Grid item>
        <ToggleButtonOptionsFilter rootPath="where" path="category" label="" />
      </Grid>
    </>
  );
  const moreFilters = (
    <>
      {availableFilters.includes('vendor') && (
        <Grid item xs={12} md={4}>
          <NodeFilter rootPath="where" path="vendor" label="Vendor" />
        </Grid>
      )}
      {availableFilters.includes('job') && (
        <Grid item xs={12} md={4}>
          <NodeFilter rootPath="where" path="job" label="Job" />
        </Grid>
      )}
      {availableFilters.includes('customer') && (
        <Grid item xs={12} md={4}>
          <NodeFilter rootPath="where" path="job.customer" label="Customer" />
        </Grid>
      )}
    </>
  );

  return (
    <>
      <Grid item>
        <DateRangePicker rootPath="where" path="start" onChange={handleChange('dates')} initial={initialDateRange} options={dateRangeOptions} />
      </Grid>
      <Grid item>
        <ToggleButtonOptionsFilter rootPath="where" path="status" label="" />
      </Grid>
      {lg && lgFilters}
      <Grid item>
        <ToggleButtonGroup value={showFilters} exclusive size="small" onChange={handleShowFiltersChange}>
          <ToggleButton value={true}><FilterIcon color="inherit" />More Filters</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <GridLineBreak />
      <Hideable hidden={!showFilters}>
        {!lg && lgFilters}
        <GridLineBreak />
        {moreFilters}
        <GridLineBreak />
      </Hideable>
      {!!enabledDisplayParams.length && (
        <>
          {enabledDisplayParams.includes('showPhotos') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showPhotos" label="Show Photos/Files" {...displayParamsProps} />
            </Grid>
          )}
          {enabledDisplayParams.includes('showCost') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showCost" label="Show Cost" {...displayParamsProps} />
            </Grid>
          )}
          {enabledDisplayParams.includes('showPrice') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showPrice" label="Show Price" {...displayParamsProps} />
            </Grid>
          )}
          {enabledDisplayParams.includes('showDescription') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showDescription" label="Show Description" {...displayParamsProps} />
            </Grid>
          )}
          {enabledDisplayParams.includes('showCharts') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showCharts" label="Show Charts" {...displayParamsProps} />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

ExpenseListParams.propTypes = {
  availableFilters: PropTypes.array.isRequired,
  onDisplayParamsChange: PropTypes.func.isRequired,
  displayParams: PropTypes.object.isRequired,
  initialDateRange: PropTypes.string,
  enabledDisplayParams: PropTypes.array,
  dateRangeOptions: PropTypes.array,
};
ExpenseListParams.defaultProps = {
  initialDateRange: 'all-time',
  enabledDisplayParams: [],
  dateRangeOptions: undefined,
};

export { ExpenseListParams };
