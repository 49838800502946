import React, { Component, createContext, useCallback, useContext, useState } from 'react';

const ControlledLocationContext = createContext({});
const useSettings = () => useContext(ControlledLocationContext);

const ControlledLocationProvider = (props) => {
  const { children, id } = props;

  const value = {
    locationId: id,
  };

  return (
    <ControlledLocationContext.Provider value={value}>
      {children}
    </ControlledLocationContext.Provider>
  );
};

const withControlledLocation = (WrappedComponent) => (props) => (
  <ControlledLocationContext.Consumer>
    {(value) => <WrappedComponent locationId={value.locationId} {...props} />}
  </ControlledLocationContext.Consumer>
);

export { withControlledLocation, ControlledLocationProvider, ControlledLocationContext, useSettings };
