import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { NodeListContext } from 'src/kiska/components/NodeList';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const EmptyList = () => {
  const classes = useStyles();
  const { nodes, type, schemaType } = useContext(NodeListContext);

  if (nodes && nodes.length) return null;

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <Typography variant="body1" align="center" color="textSecondary">
        <br />
        No {schemaType.pluralLabel} found.<br />
        Try adjusting your search criteria.
      </Typography>
    </Grid>
  );
};

EmptyList.propTypes = {
};
EmptyList.defaultProps = {
};

export { EmptyList };
