import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';

import defaultAvatar from './defaultAvatar.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 'inherit',
  },
  name: {
    marginLeft: theme.spacing(1),
    fontSize: 'inherit',
  },
}));

const UserAvatar = (props) => {
  const classes = useStyles(props);
  const { node, size, className, withName, nameVariant, ...rest } = props;

  const url = _.get(node, 'avatar.url');
  // if (!url) {
  //   url = defaultAvatar;
  // }

  let content;

  const avatar = (
    <Avatar
      src={url}
      alt={`${node.displayName}'s avatar`}
      className={classNames(className, classes.root)}
      style={{
        height: size,
        width: size,
        fontSize: size / 3,
      }}
      {...rest}
    />
  );

  if (withName) {
    content = (
      <div className={classes.root}>
        {avatar}
        <Typography variant={nameVariant} className={classes.name}>{node.displayName}</Typography>
      </div>
    );
  } else {
    content = avatar;
  }

  return content;
};

UserAvatar.propTypes = {
  node: PropTypes.object.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
  withName: PropTypes.bool,
  nameVariant: PropTypes.string,
};
UserAvatar.defaultProps = {
  size: 40,
  className: undefined,
  withName: false,
  nameVariant: 'body1',
};

export default UserAvatar;
