import React, { } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography, Grid } from '@material-ui/core';
// import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
import { format, parseISO } from 'date-fns';
import { ToggleButtonOptionsFilter } from 'src/kiska/components/NodeList/ToggleButtonOptionsFilter';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { formatDate } from 'src/kiska/utils';
import _ from 'lodash';
import { JobUpdateForm } from '../modules/job/JobUpdateForm';
import { SimpleTable } from './settings/SimpleTable';
import { UpdateJobStatusButton } from '../modules/job/UpdateJobStatusButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    padding: theme.spacing(0.25, 1),
  },
}));

const JobList = ({ fixtures, updateFixtures, hideCreateButton, insertFixtures, updateFormProps, showCount }) => {
  const { appSettings } = useAppSettings();
  const theme = useTheme();
  const { getOption } = useSchema();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const md = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const classes = useStyles();
  const initial = {
    limit: 50,
    orderBy: [
      { updatedAt: 'desc' },
    ],
    where: {
      // status: { _in: ['electricians'] },
    },
  };

  const searchPaths = 'title shortId customer.name customer.company customer.email customer.phone';
  const searchPlaceholder = 'Search by title, customer, or #';

  const getRowColors = (node) => {
    const jobStatusColors = _.get(appSettings, `data.jobs.statusColors.${node.status}`);
    return jobStatusColors;
  };

  const columns = [];

  // if (!xs) {
  columns.push({
    label: 'Stage',
    fieldName: 'status',
    orderByPath: null,
    Renderer: ({ value, parentNode: node }) => (
      <div onClick={(event) => event.stopPropagation()}>
        <UpdateJobStatusButton
          node={node}
          variant="outlined"
          color="default"
          size="small"
          className={classes.button}
        />
      </div>
    ),
  });
  // }

  if (md) {
    columns.push({
      label: 'Type',
      fieldName: 'type',
      Renderer: ({ value, parentNode: node }) => {
        return getOption('job', 'type', node).primary;
      },
    });
  }

  columns.push({
    label: 'Title',
    fieldName: 'title',
    Renderer: ({ parentNode: node }) => {
      return (
        <>
          <Typography variant="body1" color="textPrimary">
            {node.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ marginTop: -4 }}>
            {node.customer.company || node.customer.name || node.customer.email || node.customer.phone}
          </Typography>
        </>
      );
    },
  });

  // if (lg) {
  //   columns.push({
  //     label: 'Updated',
  //     fieldName: 'updatedAt',
  //     Renderer: ({ value }) => {
  //       const date = parseISO(value);
  //       return (
  //         <Typography variant="body2" color="textSecondary">
  //           {format(date, `MMM d, yyyy`)}<br />
  //           {format(date, `h:mmaaaaa'm`)}
  //         </Typography>
  //       );
  //     },
  //   });
  // }

  if (lg) {
    columns.push({
      label: 'Created',
      fieldName: 'createdAt',
      Renderer: ({ value }) => (
        <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1.2 }}>
          {formatDate(value, `MMM d, ''yy`)}
        </Typography>
      ),
      orderByPath: 'createdAt',
    });
  }

  if (!xs) {
    columns.push({
      label: 'Hours',
      fieldName: 'hours',
      Renderer: ({ parentNode: node }) => {
        return (
          <>
            <Typography variant="h6" color="textSecondary">
              {(node.workEntries_aggregate.aggregate.sum.duration / 60 / 60).toFixed(1)}
            </Typography>
          </>
        );
      },
      orderByPath: 'workEntries_aggregate.sum.duration',
    });
  }

  const filters = (
    <>
      <Grid item xs={12}>
        <ToggleButtonOptionsFilter rootPath="where" path="status" label="" showCount={showCount} />
      </Grid>
    </>
  );

  return (
    <div className={classes.root}>
      <SimpleTable
        searchPlaceholder={searchPlaceholder}
        searchPaths={searchPaths}
        type="job"
        initial={initial}
        fixtures={fixtures}
        insertFixtures={insertFixtures}
        updateFixtures={updateFixtures}
        addLabel="New Job"
        columns={columns}
        UpdateForm={JobUpdateForm}
        updateFormProps={updateFormProps}
        clickUrl={(node) => `/app/jobs/${node.id}`}
        actionsOnBottom={false}
        actionsOnTop={false}
        enableAdd={!hideCreateButton}
        floatingAdd
        enableEdit
        showSearchBox
        filters={filters}
        getRowColors={getRowColors}
      />
    </div>
  );
};

JobList.propTypes = {
  fixtures: PropTypes.any,
  hideCreateButton: PropTypes.bool,
  insertFixtures: PropTypes.object,
  updateFixtures: PropTypes.object,
  updateFormProps: PropTypes.object,
  showCount: PropTypes.bool,
};
JobList.defaultProps = {
  fixtures: undefined,
  hideCreateButton: false,
  insertFixtures: undefined,
  updateFixtures: undefined,
  updateFormProps: undefined,
  showCount: false,
};

export { JobList };
