import React, { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { JobForm } from './JobForm';
import { LocalNodeProvider } from '../../../kiska/components/LocalNodeContext';

export const useStyles = makeStyles((theme) => ({

}));

const JobViewForm = forwardRef((props, ref) => {
  const classes = useStyles(props);
  const { node } = props;

  return (
    <LocalNodeProvider node={node} type="job">
      <JobForm ref={ref} />
    </LocalNodeProvider>
  );
});

JobViewForm.propTypes = {
  node: PropTypes.object.isRequired,
};
JobViewForm.defaultProps = {
};
JobViewForm.displayName = 'JobViewForm';

export { JobViewForm };
