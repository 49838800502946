import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, Card, CardContent, CardActionArea, CardMedia } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';

import pressBrakeThumb from './PressBrake/thumb.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  cardRoot: {
    maxWidth: 300,
  },
  cardMedia: {
    backgroundSize: 'contain',
    height: 200,
  },
}));

const Tool = ({ image, title, description, url, children }) => {
  const classes = useStyles();
  return (
    <Card className={classes.cardRoot}>
      <CardActionArea component={Link} to={`/app/online-tools/${url}`}>
        <CardMedia className={classes.cardMedia} image={image} title="" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">{title}</Typography>
          <Typography variant="body2">
            {children}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const ToolsGrid = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="flex-start" spacing={4}>
        <Grid container justify="center" item xs={12} sm={6} md={4}>
          <Tool title="Press Brake Bends" url="press-brake-bends" image={pressBrakeThumb}>
            Calculate the distance between brake marks based on
            finished inside or outside dimension, material thickness, and bottom die.
          </Tool>
        </Grid>
      </Grid>
    </div>
  );
};

ToolsGrid.propTypes = {
};
ToolsGrid.defaultProps = {
};

export { ToolsGrid };
