import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { TextField, DateField, FileField, NodeField, BooleanField, SelectField } from 'src/kiska/components/ViewOrUpdateFields';
import _ from 'lodash';
import { useLocalNode } from 'src/kiska/components/LocalNodeContext';
import { Grid } from 'src/kiska/components/Grid';
import { FormSection, GridLineBreak } from 'src/components/forms';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import { FormUpdateForm } from '../FormUpdateForm';
import { FormSignatures } from './FormSignatures';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  signatureWarning: {
    color: theme.palette.text.danger,
    margin: theme.spacing(2, 0, 1, 0),
    lineHeight: 1.2,
  },
  saveThenSign: {
    margin: theme.spacing(2, 0, 1, 0),
    lineHeight: 1.2,
  },
}));

const form = {
  title: 'Daily Safety Record',
  name: 'DailySafetyForm',
  version: '1',
  versionDate: new Date('2020-08-26'),
  subtitle: (node) => undefined,
};

const DailySafetyForm = (props) => {
  const { view, update } = useViewOrUpdate();
  const classes = useStyles(props);
  const { node } = props;

  const checkboxesWidths = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 4,
  };

  const handleSignatureSuccess = () => {
    if (view) {
      // close the form. ...but that's hard.
    }
  };

  return (
    <>
      <FormSection title="Job Info" alwaysInitiallyExpanded>

        <Grid item xs={12}>
          <DateField name="data.dailySafety.date" label="Today's Date" initialValue={new Date()} />
        </Grid>
        <Grid item xs={12}>
          <TextField name="data.dailySafety.jobLocation" rows={6} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField name="data.dailySafety.emergencyPhone" initialValue="911" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="data.dailySafety.firstAidAttendent" />
        </Grid>

        <Grid item xs={12}>
          <TextField name="data.dailySafety.daysObjectives" rows={6} />
        </Grid>
        <Grid item xs={12}>
          <TextField name="data.dailySafety.safeWorkProcedures" rows={6} />
        </Grid>

      </FormSection>

      <FormSection title="Personal Protective Equipment (PPE)" spacing={2} alwaysInitiallyExpanded>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary" align="center" style={{ marginTop: -16, marginBottom: 16 }}>
            Check and review job specific safety requirements:
          </Typography>
        </Grid>

        <Grid item {...checkboxesWidths}>
          <BooleanField name="data.dailySafety.ppe.hardHats" switchy={false} />
        </Grid>
        <Grid item {...checkboxesWidths}>
          <BooleanField name="data.dailySafety.ppe.hearingProtection" switchy={false} />
        </Grid>
        <Grid item {...checkboxesWidths}>
          <BooleanField name="data.dailySafety.ppe.safetyGlasses" switchy={false} />
        </Grid>
        <Grid item {...checkboxesWidths}>
          <BooleanField name="data.dailySafety.ppe.respirator" switchy={false} />
        </Grid>
        <Grid item {...checkboxesWidths}>
          <BooleanField name="data.dailySafety.ppe.safetyBootes" switchy={false} />
        </Grid>
        <Grid item {...checkboxesWidths}>
          <BooleanField name="data.dailySafety.ppe.rainGear" switchy={false} />
        </Grid>
        <Grid item {...checkboxesWidths}>
          <BooleanField name="data.dailySafety.ppe.gloves" switchy={false} />
        </Grid>
        <GridLineBreak />

        <Grid item xs={12}>
          <TextField name="data.dailySafety.ppe.other" />
        </Grid>
        <GridLineBreak />
      </FormSection>

      <FormSection title="Documentation Onsite and Reviewed" alwaysInitiallyExpanded>
        <Grid item xs={12} sm={6}>
          <BooleanField name="data.dailySafety.docs.hazMatSurvey" switchy={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BooleanField name="data.dailySafety.docs.riskAssessment" switchy={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BooleanField name="data.dailySafety.docs.safeWorkProcedures" switchy={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BooleanField name="data.dailySafety.docs.whmis" switchy={false} />
        </Grid>
      </FormSection>

      <FormSignatures onSuccess={handleSignatureSuccess} />
    </>
  );
};

DailySafetyForm.propTypes = {
};
DailySafetyForm.defaultProps = {
};

form.Form = DailySafetyForm;
form.UpdateForm = (props) => <FormUpdateForm form={form} {...props} allowDelete={false} />;

export default form;
export { DailySafetyForm };
