import { fragments } from './fragments';

export default {
  types: {
    grouping: {

      selections: {
        default: `
          id groups type
        `,
      },

      fields: {
      },
    },
  },
};
