/* eslint-disable react/prop-types */
import React from 'react';

export default {
  types: {
    pay_rate: {
      renderers: {
        inline: ({ node }) => <>{node.label}</>,
      },

      selections: {
        default: `
          id label description price cost
          tasks {id task {id label description}}
        `,
      },

      fields: {
        label: {
          type: 'String',
          validation: {
            required: true,
          },
        },
        description: {
          type: 'String',
          validation: {
            required: false,
          },
        },
        price: {
          type: 'Float',
          validation: {
            required: true,
          },
        },
        cost: {
          type: 'Float',
          validation: {
            required: true,
          },
        },
        tasks: {
          type: 'task',
          hasMany: true,
          validation: {
            required: true,
          },
        },

      },
    },
  },
};
