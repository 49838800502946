import React from 'react';
// import PropTypes from 'prop-types';
import { formatDate, parseDate } from 'src/kiska/utils';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { truncateString } from 'src/utils';
import { useMediaQuery, Typography, Badge } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { FilePreview } from 'src/kiska/components/MutationContext/FormFields/FileField/FilePreview';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  files: {
    margin: theme.spacing(-0.5, 0),
  },
  badge: {
    color: '#CCC',
    backgroundColor: '#282828',
    top: theme.spacing(1.3),
    right: theme.spacing(1),
  },
}));

const useSafetyRecordColumns = (props) => {
  const { displayParams } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  // const { getOption } = useSchema();

  const columns = [];

  columns.push({
    label: 'Date',
    fieldName: 'data.dailySafety.date',
    Renderer: ({ value }) => (
      <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1.2 }}>
        {formatDate(value, `MMM d, ''yy`)}
      </Typography>
    ),
  });

  // columns.push({
  //   label: 'Files',
  //   fieldName: 'data.changeOrder.files',
  //   Renderer: ({ value }) => (
  //     <div className={classes.files}>
  //       {value && value.length
  //         ? (
  //           <Badge badgeContent={value.length} classes={{ badge: classes.badge }}>
  //             <FilePreview className={classes.filePreview} file={value[0]} size="small" showName={false} />
  //           </Badge>
  //         )
  //         : 0}
  //     </div>
  //   ),
  // });

  columns.push({
    label: 'Signatures',
    fieldName: 'signatures',
    Renderer: ({ value }) => (
      <div>
        {value.length}
      </div>
    ),
  });

  return { columns };
};

export { useSafetyRecordColumns };
