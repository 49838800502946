import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpenseList } from '../expense/ExpenseList';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const JobExpenses = (props) => {
  const { job } = props;
  const classes = useStyles(props);

  return (
    <>
      <ExpenseList job={job} fixtures={{ path: 'where.jobId', value: { _eq: job.id } }} insertFixtures={{ job }} />
    </>
  );
};

JobExpenses.propTypes = {
};
JobExpenses.defaultProps = {
};

export { JobExpenses };
