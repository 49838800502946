import PropTypes from 'prop-types';
import React, { } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import classNames from 'classnames';
import { SupportButton } from 'src/components/SupportPage/SupportPage';
import { useAuth0 } from '@auth0/auth0-react';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(0),
    marginBottom: 0,
    backgroundColor: '#222',
    width: '100%',
    padding: theme.spacing(2),
    color: '#FFF',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  copyright: {
    opacity: 0.3,
    lineHeight: 1.4,
  },
  version: {
    opacity: 0.5,
  },
  athsButton: {
    display: 'none',
  },
});

const Footer = ({ classes, className }) => {
  const auth = useAuth0();

  return (
    <Grid component="footer" container spacing={2} className={classNames(className, classes.root)} justify="space-between">
      <Grid item xs={12} container direction="column" alignItems="center" style={{ width: 'auto' }}>
        <SupportButton />
      </Grid>

      <Grid item>
        <Typography variant="caption" component="p" className={classes.copyright} align="left" color="inherit">
          &copy; Copyright 2020<br />
          Slick Systems Business Managment Solutions<br />
          7-3965 Yaroshuk Road, Powell River, BC
        </Typography>
      </Grid>

      <Grid item container direction="column" alignItems="center" style={{ width: 'auto' }}>
        <Typography variant="caption" className={classes.version} align="center" color="inherit">
          Version: {process.env.REACT_APP_KISKA_CODE_VERSION.slice(0, 7).toUpperCase()}<br />
          <span
            onClick={(event) => {
              event.preventDefault();
              auth.logout();
            }}
            style={{ opacity: 0.4, cursor: 'pointer' }}
          >
            Logout
          </span>
        </Typography>
      </Grid>

    </Grid>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default withStyles(styles)(Footer);
