import React, { createContext, useContext, useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { SchemaContext } from '../contexts/SchemaContext';
import buildQuery from './buildQuery';
import { getTopLevelSelections } from '../../utils';
import { QueryVariablesContext } from './QueryVariablesContext';
import { useLog } from '../contexts/LogContext';

const NodeListContext = createContext(null);
const useNodeList = () => useContext(NodeListContext);

const NodeList = (props) => {
  const log = useLog();
  const { variables, updateVariables, type } = useContext(QueryVariablesContext);
  const { schema } = useContext(SchemaContext);
  const { queryOptions, children } = props;

  const schemaType = _.get(schema, `types[${type}]`);
  if (!schemaType) throw new Error(`Could not find schema for type "${type}".`);

  const selections = useMemo(() => props.selections || _.get(schemaType, `selections.${props.selectionSet}`), []);
  const topLevelSelections = useMemo(() => getTopLevelSelections(selections), []);

  const query = useMemo(() => buildQuery({
    operation: `NodeList_${type}`,
    type,
    schema,
    selections,
  }), [schema, selections, type]);

  const handleError = (error) => {
    log.error('<NodeList> Query error', { error, variables, type, selections, topLevelSelections });
    console.error(`Error in NodeList query for ${type}: `, error);
  };

  const handleCompleted = (data) => {

  };

  const queryResult = useSubscription(query, {
    variables,
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted,
    onError: handleError,
    ...queryOptions,
  });

  const nodes = _.get(queryResult, 'data.returning.nodes', []);

  const contextValue = {
    schema,
    schemaType,
    selections,
    topLevelSelections,
    type,
    queryResult,
    error: queryResult.error,
    nodes,
    count: _.get(queryResult, 'data.returning.aggregate.count'),
    loading: queryResult.loading,
    variables,
    updateVariables,
  };

  return (
    <NodeListContext.Provider value={contextValue}>
      {children}
    </NodeListContext.Provider>
  );
};

NodeList.propTypes = {
  children: PropTypes.node,
  selections: PropTypes.string,
  selectionSet: PropTypes.string,
  queryOptions: PropTypes.object,
};

NodeList.defaultProps = {
  children: null,
  selections: '',
  selectionSet: 'default',
  queryOptions: undefined,
};

export { NodeList, NodeListContext, useNodeList };
