import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const JobBoardJobView = (props) => {
  const classes = useStyles(props);
  const { job } = props;
  const { getOption } = useSchema();

  const type = getOption('job', 'type', job).primary;

  return (
    <>
      <Typography variant="h6" color="textPrimary">{job.title} - {type}</Typography>
      <Typography variant="body1" color="textSecondary">{job.customer.company || job.customer.name}</Typography>
    </>
  );
};

JobBoardJobView.propTypes = {
  job: PropTypes.object.isRequired,
};
JobBoardJobView.defaultProps = {
};

export { JobBoardJobView };
