import React, { } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, ListItemIcon, ListSubheader, Divider } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from './contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    '& > ul': {
      marginTop: theme.spacing(-3),
    },
    '& p, div, span': {
      whiteSpace: 'nowrap',
    },
  },
  header: {
    // marginBottom: theme.spacing(-3),
    // lineHeight: 1,
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  matched: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& svg': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const ListItemWithMatch = ({ to, className, children, ...rest }) => {
  const classes = useStyles();
  const matchable = typeof to === 'string';
  // const stringToMatch = matchable ? `${_.trimEnd(to, '/*')}/*` : 'none';

  const match = useRouteMatch(to);
  const matched = matchable && match;

  return (
    <ListItem {...rest} to={to} className={classNames(className, classes.listItem, matched && classes.matched)}>
      {children}
      {matched && <ChevronRight color="inherit" style={{ fontSize: '2em' }} />}
    </ListItem>
  );
};

const Menu = React.forwardRef(({ menu, context, _parentRoles }, ref) => {
  const classes = useStyles();
  const auth = useAuth0();
  const { user } = useUser();
  const menuRoles = menu.roles || _parentRoles;

  if (!menuRoles.includes(user.role.id)) return null;

  const valueOrFunc = (value) => {
    if (typeof value === 'function') return value({ auth, user });
    return value;
  };

  const handleClick = (item) => () => {
    if (item.action) {
      item.action({ auth, user });
    }
  };

  const itemsList = menu.items.map((item, index) => {
    const itemRoles = item.roles || menuRoles;

    if (item.contexts) {
      const contexts = Array.isArray(item.contexts) ? item.contexts : item.contexts.split(/[\s,]+/);
      if (context && !contexts.includes(context)) return null;
    }
    if (!itemRoles.includes(user.role.id)) return null;

    // Menu Divider
    if (item.divider) {
      return <Divider key={index} />;
    }

    // Menu Header
    if (item.header) {
      return (
        <ListSubheader disableSticky key={index} className={classes.header}>{valueOrFunc(item.header)}</ListSubheader>
      );
    }

    // Now we'll need to render an actual menu item so prepare the content
    const menuItemContent = (
      <>
        {item.icon && <ListItemIcon>{valueOrFunc(item.icon)}</ListItemIcon>}
        <ListItemText primary={valueOrFunc(item.primary)} secondary={item.secondary} />
        {item.items && <ChevronRight />}
      </>
    );

    // Nested menu, render the next level
    if (item.items) {
      return (
        <React.Fragment key={index}>
          <ListSubheader className={classes.header} disableSticky>{valueOrFunc(item.primary)}</ListSubheader>
          <Menu menu={item} _parentRoles={itemRoles} />
        </React.Fragment>
      );
    }

    // Normal menu item link
    const localLink = !!item.to;
    const linkComponent = localLink ? Link : 'a';

    return (
      <ListItemWithMatch
        button
        onClick={handleClick(item)}
        key={index}
        to={valueOrFunc(item.to)}
        href={valueOrFunc(item.href)}
        component={linkComponent}
      >
        {menuItemContent}
      </ListItemWithMatch>
    );
  });

  if (menu.group) {
    return itemsList;
  }

  return (
    <>
      <List className={classes.root}>
        {itemsList}
      </List>
    </>
  );
});

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
  context: PropTypes.string,
  _parentRoles: PropTypes.array,
};
Menu.defaultProps = {
  context: undefined,
  _parentRoles: undefined,
};
Menu.displayName = 'Menu';

export default Menu;
