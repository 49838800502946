const splitFieldName = (fieldName) => {
  const rootFieldName = fieldName.split('.').shift();
  const extFieldName = fieldName.split('.').slice(1).join('.');
  let valueFieldName = `${rootFieldName}.value`;
  if (extFieldName) valueFieldName += `.${extFieldName}`;

  return { rootFieldName, extFieldName, valueFieldName };
};

export default splitFieldName;
