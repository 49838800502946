import React, { } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'src/kiska/components/Grid';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import SelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import { Hideable } from 'src/kiska/components/Hideable';
import BooleanField from 'src/kiska/components/MutationContext/FormFields/BooleanField';
import { FormSection, GridLineBreak } from 'src/components/forms';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { LocalNodeProvider } from 'src/kiska/components/LocalNodeContext';
import CrudForm from '../../updates/CrudForm';

const typeOptions = [
  { value: 'node-edge', label: 'Node Edge' },
  { value: 'node-insert', label: 'Node Insert' },
  { value: 'node-update', label: 'Node Update' },
  { value: 'node-delete', label: 'Node Delete' },
];

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'disabled', label: 'Disabled' },
];

const notificationChannelOptions = [
  { value: 'in-app', label: 'In-App' },
];

const TriggerUpdateFormInner = ({ variant }) => {
  const { getAllFieldValues } = useMutationContext();
  const node = getAllFieldValues({ jsonFieldsToObjects: true });

  return (
    <LocalNodeProvider node={node} type="trigger">
      <FormSection title="Common Settings">
        <Grid item xs={12} md={12}>
          <TextField name="label" />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField name="description" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField name="type" options={typeOptions} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField name="status" options={statusOptions} />
        </Grid>

        <Grid item xs={12} md={12}>
          <NodeField name="users" />
        </Grid>

        <Grid item xs={12} md={12}>
          <NodeField name="roles" />
        </Grid>
      </FormSection>

      <FormSection title="Notifications">
        <Grid item xs={12} md={6}>
          <SelectField name="config.notifications.channels" label="Notification Channels" multiple options={notificationChannelOptions} />
        </Grid>
        <GridLineBreak />
        <Grid item xs={12} md={6}>
          <BooleanField name="config.notifications.snoozable" label="Snoozable" />
        </Grid>
      </FormSection>

      <FormSection title="Configuration">
        <Grid item xs={12} md={12}>
          <TextField name="config.message" />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField name="config.actionLabel" />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField name="config.actionUrl" />
        </Grid>

        <Hideable dep="type" showIfOneOf="node-edge node-insert node-update node-delete">
          <Grid item xs={12} md={6}>
            <TextField name="config.type" />
          </Grid>
        </Hideable>

        <Hideable dep="type" showIfEq="node-edge">
          <Grid item xs={12} md={12}>
            <TextField name="config.field" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <BooleanField name="config.anyOldValue" />
          </Grid>
          <Hideable dep="config.anyOldValue" showIfFalsey>
            <Grid item xs={12} sm={6}>
              <TextField name="config.oldValue" />
            </Grid>
          </Hideable>
          <GridLineBreak />

          <Grid item xs={12} sm={6}>
            <BooleanField name="config.anyNewValue" />
          </Grid>
          <Hideable dep="config.anyNewValue" showIfFalsey>
            <Grid item xs={12} sm={6}>
              <TextField name="config.newValue" />
            </Grid>
          </Hideable>
          <GridLineBreak />
        </Hideable>
      </FormSection>

    </LocalNodeProvider>
  );
};

TriggerUpdateFormInner.propTypes = {
  variant: PropTypes.string,
};
TriggerUpdateFormInner.defaultProps = {
  variant: undefined,
};

const TriggerUpdateForm = ({ id, trigger, onClose, open, variant }) => {
  return (
    <CrudForm
      type="trigger"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderTitle={(n) => (n ? `Edit Trigger ${n.label}` : 'Create Trigger')}
      maxWidth="md"
      allowDelete
    >
      <TriggerUpdateFormInner variant={variant} />
    </CrudForm>
  );
};

TriggerUpdateForm.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string,
};
TriggerUpdateForm.defaultProps = {
  id: undefined,
  trigger: undefined,
  onClose: undefined,
  open: undefined,
  variant: '',
};

export { TriggerUpdateForm };
