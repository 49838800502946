import React, { forwardRef } from 'react';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { parseDate } from 'src/kiska/utils';
import { } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import _ from 'lodash';
import { format, parseISO } from 'date-fns';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { plural, singular } from 'pluralize';

const getDuration = (start, endOrNull) => {
  if (!start) return 0;

  const end = endOrNull || new Date();
  const seconds = differenceInMilliseconds(parseDate(end), parseDate(start)) / 1000;

  return seconds;
};

const secondsToHms = (seconds) => {
  if (!seconds) return { hours: 0, minutes: 0, seconds: 0 };

  const hoursPart = Math.floor(seconds / 60 / 60);
  const minutesPart = Math.floor((seconds - hoursPart * 60 * 60) / 60);
  const secondsPart = Math.floor(seconds - hoursPart * 60 * 60 - minutesPart * 60);

  return { hours: hoursPart, minutes: minutesPart, seconds: secondsPart };
};

const secondsToFormattedHms = (s, includeSeconds) => {
  const { hours, minutes, seconds } = secondsToHms(s);

  const fHours = hours || '';
  const fMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const fSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${fHours}${fHours ? ':' : ''}${fMinutes}${includeSeconds ? `:${fSeconds}` : ''}`;
};

const findUsersPayRatesThatIncludesTask = (user, taskId) => {
  const result = (user.payRates || []).filter((rate) => !!rate.pay_rate.tasks.find((task) => task.task.id === taskId)).map((rate) => rate.pay_rate);
  return result;
};

const findJobTasksThatUserHasAPayRateFor = (user, job) => {
  return (job.tasks || []).tasks.filter(({ task }) => !!findUsersPayRatesThatIncludesTask(user, task.id).length).map(({ task }) => task);
};

const findTaskOptionsForUserOnJob = (user, job) => {
  const options = [];
  (job.tasks || []).forEach(({ task }) => {
    const payRates = findUsersPayRatesThatIncludesTask(user, task.id);
    if (payRates.length < 1) return;
    options.push({
      id: task.id,
      value: task.id,
      label: task.label,
      payRate: payRates[0],
      allPayRates: payRates,
    });
  });
  return options;
};

const extractDateRangeFromVariables = (variables) => {
  let and = _.get(variables, `args.where._and`);
  if (!and) and = _.get(variables, `where._and`, []);
  let lte;
  let gte;

  and.forEach((clause) => {
    if (clause.start && clause.start._lte) lte = clause.start._lte;
    else if (clause.start && clause.start._gte) gte = clause.start._gte;
  });

  return { lte, gte };
};

const extractDateRangeStringFromVariables = (variables) => {
  const { lte, gte } = extractDateRangeFromVariables(variables);
  let datesStr;
  if (lte && gte) {
    datesStr = `${format(parseISO(gte), `MMM d yyyy`)} - ${format(parseISO(lte), `MMM d yyyy`)}`;
  }

  return datesStr;
};

const formatDuration = (duration) => {
  return (duration / 60 / 60).toFixed(1);
};

const formatCurrency = (amount, includeCents) => {
  try {
    return Number((amount).toFixed(includeCents ? 2 : 0)).toLocaleString();
  } catch (error) {
    return '0';
  }
};

const sumProp = (objects, prop) => {
  return objects.reduce((acc, node) => acc + node[prop], 0);
};

const getWorkEntriesTotals = (nodes) => {
  return {
    days: sumProp(nodes, 'dayCount'),
    hours: (sumProp(nodes, 'sumDuration') / 60 / 60).toFixed(1),
    cost: sumProp(nodes, 'sumCost').toFixed(2),
    price: sumProp(nodes, 'sumPrice').toFixed(2),
  };
};

const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new window.Blob([u8arr], { type: mime });
};

const truncateString = (str, n) => {
  if (typeof str !== 'string') return '';
  const newStr = (str.length > n) ? <>{str.substr(0, n - 1)}&hellip;</> : str;
  return newStr;
};

const uploadFile = async ({ file, settings, onComplete, onProgress, roles: argsRoles, auth }) => {
  const endpoint = `${settings.public.kiskaOrigin}/kiska-api/v1/upload/xhr-upload`;
  const token = await auth.getAccessTokenSilently();
  const roles = argsRoles || ['authenticated'];

  const formData = new window.FormData();
  formData.append('files[]', file, file.name);
  formData.append('roles', roles);
  formData.append('type', file.type);
  formData.append('name', file.name);

  const xhr = new window.XMLHttpRequest();

  xhr.open('POST', endpoint, true);
  xhr.setRequestHeader('authorization', `Bearer ${token}`);

  xhr.upload.onprogress = (event) => {
    const percent = Math.floor(100 * (event.loaded / file.size) - 1);
    if (onProgress) onProgress(percent);
  };

  xhr.onreadystatechange = () => {
    if (xhr.readyState === window.XMLHttpRequest.DONE) {
      const resObj = JSON.parse(xhr.responseText);
      const nodeFile = resObj.dbFile;
      if (onComplete) onComplete(nodeFile);
    }
  };

  xhr.send(formData);
};

const freqOptions = [
  { value: RRule.DAILY, singular: 'Day', plural: 'Days' },
  { value: RRule.WEEKLY, singular: 'Week', plural: 'Weeks' },
  { value: RRule.MONTHLY, singular: 'Month', plural: 'Months' },
  { value: RRule.YEARLY, singular: 'Year', plural: 'Years' },
  // { value: RRule.HOURLY, singular: 'Hour', plural: 'Hours' },
  // { value: RRule.MINUTELY, singular: 'Minute', plural: 'Minutes' },
  // { value: RRule.SECONDLY, singular: 'Second', plural: 'Seconds' },
];

export {
  uploadFile,
  secondsToFormattedHms,
  secondsToHms,
  getDuration,
  findUsersPayRatesThatIncludesTask,
  findJobTasksThatUserHasAPayRateFor,
  findTaskOptionsForUserOnJob,
  extractDateRangeFromVariables,
  extractDateRangeStringFromVariables,
  formatDuration,
  formatCurrency,
  getWorkEntriesTotals,
  dataURLtoBlob,
  truncateString,
  freqOptions,
};
