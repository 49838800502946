import React, { useContext, createContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { generatePrimaryId } from 'src/kiska/utils';
import { useNodes } from 'src/kiska/hooks/useNode';
import { useLog } from 'src/kiska/components/contexts/LogContext';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { startOfDay, endOfDay } from 'date-fns';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import stopWorkSound from './open-can.mp3';
import switchJobsSound from './kerching.mp3';

const isSsr = typeof window === 'undefined';

const SWITCH_JOBS_MUTATION = gql`
  mutation SwitchJobsMutation (
    $oldWorkEntryIds: [String!]! 
    $newWorkEntryId: String! 
    $end: timestamptz! 
    $userId: String! 
    $jobId: String!
    $taskId: String!
    $payRate: jsonb!
  ){

    finishCurrentEntries: update_work_entry(
      where: {id: {_in: $oldWorkEntryIds}}
      _set: {
        end: $end,
      }
    ) { affected_rows }

    startNewWorkEntry: insert_work_entry(objects: [{
      id: $newWorkEntryId,
      userId: $userId,
      jobId: $jobId,
      payRate: $payRate,
      taskId: $taskId,
    }]){ affected_rows }
  }
`;

const STOP_WORK_MUTATION = gql`
  mutation StopWorkMutation (
    $oldWorkEntryIds: [String!]! 
    $end: timestamptz!){

    finishCurrentEntries: update_work_entry(
      where: {id: {_in: $oldWorkEntryIds}}
      _set: {
        end: $end,
      }
    ) { affected_rows }
  }
`;

export const WorkEntryControlsContext = createContext();
export const useWorkEntryControls = (args) => useContext(WorkEntryControlsContext);

export const WorkEntryControlsProvider = (props) => {
  const { children, where } = props;
  const log = useLog();
  const [switchJobsMutate] = useMutation(SWITCH_JOBS_MUTATION);
  const [stopWorkMutate] = useMutation(STOP_WORK_MUTATION);
  const auth = useAuth0();
  const { user: currentUser } = useUser();
  const switchJobsAudio = !isSsr ? new window.Audio(switchJobsSound) : null;
  const stopWorkAudio = !isSsr ? new window.Audio(stopWorkSound) : null;
  const [pendingMutation, setPendingMutation] = useState(false);
  const { appSettings } = useAppSettings();

  const handleMutationError = (message, variables, error) => {
    log.error('<JobSwitcher> Mutation error', { message, variables, error });
    console.error('Mutation Error', { message, variables, error });
  };

  const { nodes: openWorkEntryNodes = [] } = useNodes({
    type: 'work_entry',
    selectionSet: 'openEntries',
    orderBy: [{ start: 'asc' }],
    where: {
      end: { _is_null: true },
      userId: { _eq: currentUser.id },
    },
    queryOptions: {
      ssr: false,
      skip: !auth.isAuthenticated,
    },
  });

  const currentlyWorking = !!openWorkEntryNodes.length;
  const openJobIds = openWorkEntryNodes.map((n) => n.job.id);
  const openWorkEntryIds = openWorkEntryNodes.map((n) => n.id);

  const { nodes: availableJobNodes = [] } = useNodes({
    type: 'job',
    limit: 100,
    orderBy: [
      { title: 'asc' },
    ],
    where: {
      id: { _nin: openJobIds },
      _or: [
        { _not: { assignedUsers: {} } },
        { assignedUsers: { userId: { _eq: currentUser.id } } },
      ],
      status: { _in: appSettings.data.jobs.workableStatuses },
      ...where,
    },
    queryOptions: {
      ssr: false,
      skip: !auth.isAuthenticated,
    },
  });

  const now = new Date();
  const startDate = startOfDay(now);
  const endDate = endOfDay(now);
  const { nodes: scheduledWorkEvents } = useNodes({
    type: 'event',
    orderBy: [{ start: 'asc' }],
    where: {
      userId: { _eq: currentUser.id },
      type: { _eq: 'job-work' },
      job: { status: { _in: appSettings.data.jobs.workableStatuses } },
      _or: [
        {
          _and: [
            { start: { _gte: startDate } },
            { start: { _lte: endDate } },
          ],
        },
        {
          _and: [
            { end: { _gte: startDate } },
            { end: { _lte: endDate } },
          ],
        },
        {
          _and: [
            { start: { _lte: startDate } },
            { end: { _gte: endDate } },
          ],
        },
      ],
    },
  });

  const jobIsAssignedToUser = (job) => !!job.assignedUsers.find((n) => n.user.id === currentUser.id);
  const jobIsScheduledForUser = (job) => !!scheduledWorkEvents.find((event) => event.job && event.job.id === job.id);

  const assignedJobNodes = availableJobNodes.filter((node) => {
    return jobIsAssignedToUser(node) && !jobIsScheduledForUser(node);
  });
  const unassignedJobNodes = availableJobNodes.filter((node) => {
    return !jobIsAssignedToUser(node) && !jobIsScheduledForUser(node);
  });

  const unscheduledAvailableJobs = availableJobNodes.filter((node) => {
    return !jobIsScheduledForUser(node);
  });

  const switchToJob = (jobOrJobId, { taskId, payRate }) => {
    if (pendingMutation) return;
    setPendingMutation(true);

    if (_.get(currentUser, 'preferences.soundEfxOnSwitchJobs')) {
      switchJobsAudio.play();
    }

    const jobId = typeof job === 'string' ? jobOrJobId : jobOrJobId.id;

    const variables = {
      oldWorkEntryIds: openWorkEntryIds,
      newWorkEntryId: generatePrimaryId(),
      end: 'now()',
      userId: currentUser.id,
      jobId,
      taskId,
      payRate,
    };

    switchJobsMutate({
      variables,
    }).then(({ error }) => {
      if (error) {
        handleMutationError('Error switching jobs with variables: ', variables, error);
        return;
      }
      log.info('Switched jobs');
    }).catch((error) => {
      handleMutationError('Error switching jobs with variables: ', variables, error);
    }).finally(() => {
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500);
      setTimeout(() => setPendingMutation(false), 1000);
    });
  };

  const stopWork = () => {
    if (pendingMutation) return;
    setPendingMutation(true);

    if (_.get(currentUser, 'preferences.soundEfxOnStopWork')) {
      stopWorkAudio.play();
    }

    const variables = {
      oldWorkEntryIds: openWorkEntryIds,
      end: 'now()',
    };

    stopWorkMutate({
      variables,
    }).then(({ error }) => {
      if (error) {
        handleMutationError('Error stopping work with variables: ', variables, error);
        return;
      }
      log.info('Stopped work');
      console.log('Stopped work. Going to Netflix and chill now...');
    }).catch((error) => {
      handleMutationError('Error stopping work with variables: ', variables, error);
    }).finally(() => {
      setTimeout(() => setPendingMutation(false), 500);
    });
  };

  const isCurrentlyWorkingOnJob = (job) => {
    return !!openWorkEntryNodes.find((entry) => entry.job.id === job.id);
  };

  const value = {
    stopWork,
    switchToJob,
    availableJobs: availableJobNodes,
    openWorkEntries: openWorkEntryNodes,
    currentlyWorking,
    isCurrentlyWorkingOnJob,
    unassignedJobNodes,
    assignedJobNodes,
    pendingMutation,
    scheduledWorkEvents,
    unscheduledAvailableJobs,
  };

  return (
    <WorkEntryControlsContext.Provider value={value}>
      {children}
    </WorkEntryControlsContext.Provider>
  );
};
