import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNodeList } from 'src/kiska/components/NodeList';
import { Grid } from 'src/kiska/components/Grid';
import { Stat } from '../../Stat';

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: 0,
  },
  item: {
    '& > div': {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1, 1, 1),
    },
  },
}));

const ExpenseTotals = (props) => {
  const classes = useStyles(props);
  const { nodes } = useNodeList();
  const { displayParams } = props;

  const totals = useMemo(() => {
    return nodes.reduce((acc, node) => ({
      cost: node.cost + acc.cost,
      price: node.price + acc.price,
    }), { cost: 0, price: 0 });
  }, [nodes]);

  return (
    <>
      {displayParams.showCost && (
        <Grid item xs={12} md={6} className={classes.item}>
          <Paper>
            <Stat label="Total Cost" value={totals.cost} currency />
          </Paper>
        </Grid>
      )}
      {displayParams.showPrice && (
        <Grid item xs={12} md={6} className={classes.item}>
          <Paper>
            <Stat label="Total Price" value={totals.price} currency />
          </Paper>
        </Grid>
      )}
    </>
  );
};

ExpenseTotals.propTypes = {
};
ExpenseTotals.defaultProps = {
};

export { ExpenseTotals };
