import React, { useContext, useEffect } from 'react';
import { gql, useSubscription, useQuery } from '@apollo/client';
import _ from 'lodash';

import { SchemaContext } from 'src/kiska/components/contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import { useLog } from '../components/contexts/LogContext';

const buildQuery = ({ oneNode, type, schema, selections, subscription = true }) => {
  const schemaType = schema.types[type];
  const functionMode = schemaType.isFunctionTable;
  const root = subscription ? 'subscription' : 'query';

  const functionVars = functionMode ? `$args: ${type}_args!,` : '';
  const functionArgs = functionMode ? `args: $args` : '';

  const nonArgTypePrefix = functionMode ? `${type}_template` : type;

  if (oneNode) {
    return gql`
      ${root} OneNode($id: String!){
        node: ${type}_by_pk(id: $id){
          ${selections}
        }
      }
    `;
  }

  const query = gql`
    ${root} NodeList(
        $where: ${nonArgTypePrefix}_bool_exp, 
        $orderBy: [${nonArgTypePrefix}_order_by!], 
        $offset: Int, 
        $limit: Int,
        ${functionVars}
      ){

      returning: ${type}_aggregate(where: $where, order_by: $orderBy, offset: $offset, limit: $limit, ${functionArgs}){
        nodes {
          ${selections}
        }
        aggregate {
          count
        }
      }
    }
  `;

  return query;
};

const useNodeQueryOrSubscription = (props) => {
  const {
    skip,
    type,
    where = {},
    orderBy = [],
    offset = 0,
    limit = 25,
    id,
    selections: propsSelections,
    selectionSet = 'default',
    queryOptions,
    subscribe = true,
  } = props;

  const log = useLog();
  const { schema } = useContext(SchemaContext);
  const schemaType = _.get(schema, `types[${type}]`);
  if (!schemaType) throw new Error(`Could not find schema for type "${type}".`);
  const selections = propsSelections || _.get(schemaType, `selections.${selectionSet}`);
  const oneNode = !!id;

  const handleError = (error) => {
  };

  const query = buildQuery({ oneNode, schema, type, selections, subscription: subscribe });

  let variables;
  if (!oneNode) variables = { orderBy, where, limit, offset };
  else variables = { id };

  const commonQueryOptions = {
    skip: skip || (!id && !where), // || !Object.keys(where).length,
    variables,
    fetchPolicy: 'cache-and-network',
    // notifyOnNetworkStatusChange: true, // Not needed for now
    ...queryOptions,
  };

  return { commonQueryOptions, query };
};

const useNodesQuery = (options) => {
  const { where, id } = options;
  const { commonQueryOptions, query } = useNodeQueryOrSubscription({ ...options, subscribe: false });

  const result = useQuery(query, {
    ...commonQueryOptions,
  });

  const node = _.get(result, `data.node`, null);
  const nodes = _.get(result, `data.returning.nodes`, []);

  const returnValue = {
    result,
    node,
    nodes,
  };

  return returnValue;
};

const useNodesSubscription = (options) => {
  const { commonQueryOptions, query, where, id } = useNodeQueryOrSubscription({ ...options, subscribe: true });

  const handleSubscriptionData = (data) => {
  };

  const result = useSubscription(query, {
    ...commonQueryOptions,
    onSubscriptionData: handleSubscriptionData,
  });

  const node = _.get(result, `data.node`, null);
  const nodes = _.get(result, `data.returning.nodes`, []);

  const returnValue = {
    result,
    node,
    nodes,
  };

  return returnValue;
};

export { useNodesSubscription as useNodes, useNodesSubscription as useNode, useNodesQuery, useNodesQuery as useNodeQuery };
