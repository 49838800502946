import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '0 !important',
    margin: '0 !important',
    padding: '0 !important',
    minHeight: '0 !important',
  },
}));

const GridLineBreak = forwardRef((props, ref) => {
  const classes = useStyles();

  return <Grid item xs={12} className={classes.root} ref={ref} />;
});

GridLineBreak.propTypes = {
};
GridLineBreak.defaultProps = {
};
GridLineBreak.displayName = 'GridLineBreak';

export { GridLineBreak };
