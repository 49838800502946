import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormSection, GridLineBreak } from 'src/components/forms';
import { Grid } from 'src/kiska/components/Grid';
import { SelectField, TextField } from 'src/kiska/components/ViewOrUpdateFields';
import { Hideable } from 'src/kiska/components/Hideable';

const billStatusOptions = [
  { value: 'not-billed', label: 'Not Invoiced Yet' },
  { value: 'billed', label: 'Invoice Sent' },
  { value: 'payment-received', label: 'Payment Received' },
];

const yesNoOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

const permitDeclarationOptions = [
  { value: 'in-progress', label: 'Work in progress Assessment' },
  { value: '180-day', label: '180-day Safety Check Assessment' },
  { value: 'final', label: 'Final Assessment' },
  { value: 'rough-complete', label: 'Rough wiring complete assessment' },
  { value: 'rough-partial', label: 'Rough-wiring partial assessment' },
  { value: 'service-connection', label: 'Service Connection Assessment' },
];

const phaseOptions = [
  { value: 'single', label: 'Single-Phase' },
  { value: '3-phase', label: '3-Phase' },
];

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const stdWidths = { xs: 12, sm: 12, md: 6, lg: 3 };

const ElectricalJobForm = (props) => {
  const classes = useStyles(props);

  return (
    <Hideable>
      <FormSection title="New Serivce">
        <Grid item {...stdWidths}>
          <TextField name="specs.hydroOnline" label="Hydro Online" />
        </Grid>
        <Grid item {...stdWidths}>
          <TextField name="specs.hydroRequestNumber" label="Hydro Request #" />
        </Grid>
        <Grid item {...stdWidths}>
          <TextField name="specs.poleNumber" label="Pole #" />
        </Grid>
        <Grid item {...stdWidths}>
          <SelectField name="specs.serviceType" label="Service Type" options={[{ value: 'overhead', label: 'Overhead' }, { value: 'underground', label: 'Underground' }]} />
        </Grid>
        <GridLineBreak />
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField name="specs.distancePoleToBuilding" label="Distance From Pole To Building" />
        </Grid>
        <GridLineBreak />
      </FormSection>

      <FormSection title="Permits">
        <Grid item {...stdWidths}>
          <TextField name="specs.permitPrice" label="Permit Price" />
        </Grid>
        <Grid item {...stdWidths}>
          <SelectField name="specs.havePermit" label="Have Permit?" options={yesNoOptions} />
        </Grid>

        <Hideable dep="specs.havePermit" showIfEq="yes">
          <Grid item {...stdWidths}>
            <TextField name="specs.permitNumber" label="Permit #" />
          </Grid>
          <Grid item {...stdWidths}>
            <SelectField name="specs.billStatus" label="Bill Status" options={billStatusOptions} />
          </Grid>
          <GridLineBreak />
        </Hideable>

        <Grid item {...stdWidths}>
          <TextField name="specs.contractorAuthorization" label="Contractor Authorization" />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
          <SelectField name="specs.permitDeclaration" label="Permit Declaration" options={permitDeclarationOptions} />
        </Grid>
        <GridLineBreak />

        <Grid item {...stdWidths}>
          <TextField name="specs.amps" label="Amps" />
        </Grid>
        <Grid item {...stdWidths}>
          <TextField name="specs.volts" label="Volts" />
        </Grid>
        <Grid item {...stdWidths}>
          <SelectField name="specs.phase" label="Phase" options={phaseOptions} />
        </Grid>
      </FormSection>
    </Hideable>
  );
};

ElectricalJobForm.propTypes = {
};
ElectricalJobForm.defaultProps = {
};

export { ElectricalJobForm };
