import { ApolloClient } from '@apollo/client/core';
import { split, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import to from 'await-to-js';
import fetch from 'isomorphic-fetch';

const apiEndpointPath = '/hasura-api/v1/graphql';

const createApolloClient = async (settings, { getAccessTokenSilently }) => {
  const getAuthHeaders = async () => {
    const headers = {};

    if (getAccessTokenSilently) {
      const [tokenError, token] = await to(getAccessTokenSilently());

      if (tokenError) {
        const msg = `Error in createApolloClient()'s getAuthHeaders():`;
        console.error(msg, tokenError);
      } else if (token) {
        headers.authorization = `Bearer ${token}`;
      }
    }

    return headers;
  };

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    console.error('GraphQL Link Error');

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ));
    }

    if (networkError) console.error(`[Network error]: `, networkError);
  });

  const batchHttpLink = new BatchHttpLink({
    uri: `${settings.public.hasuraOrigin}${apiEndpointPath}`,
    batchMax: 20,
    batchInterval: 100,
  });

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...await getAuthHeaders(),
      },
    };
  });

  const httpLink = from([
    errorLink,
    authLink,
    batchHttpLink,
  ]);

  const buildSplitLink = async () => {
    let origin = new URL(settings.public.hasuraOrigin);
    const protocol = origin.protocol === 'https:' ? 'wss' : 'ws';
    origin = `${protocol}://${origin.hostname}:${origin.port}`;
    const uri = `${origin}${apiEndpointPath}`;

    const subscritionClient = new SubscriptionClient(uri, {
      reconnect: true,
      connectionParams: {
        headers: {
          ...await getAuthHeaders(),
        },
      },
    });

    const baseWsLink = new WebSocketLink(subscritionClient);

    const wsLink = from([
      errorLink,
      baseWsLink,
    ]);

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition'
          && definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );

    return { link: splitLink, subscritionClient };
  };

  const linkAndSub = await buildSplitLink();

  const cache = new InMemoryCache({
  });

  const client = new ApolloClient({
    ssrMode: false,
    fetch,
    link: linkAndSub.link,
    cache,
    connectToDevTools: settings.public.devMode,
    onError: (error) => {
      console.error('Error in createApolloClient');
    },
  });

  client.kiska = {
    subscritionClient: linkAndSub.subscritionClient,
  };

  window.__APOLLO_CLIENT__ = client;
  return client;
};

export { createApolloClient };
