function getSchemaAtPath(schema, path, type) {
  const parts = path.split('.');
  let schemaAtPath;
  if (type) {
    schemaAtPath = schema.types[type];
  } else {
    schemaAtPath = schema.types[parts[0]];
    parts.shift();
  }

  if (!schemaAtPath) {
    throw new Error(`Could not find schema for path "${path} and type "${type}".`);
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const part of parts) {
    const field = schemaAtPath.fields[part];

    if (field.isScaler) {
      schemaAtPath = field;
      break;
    } else {
      schemaAtPath = schema.types[field.type];
    }
  }

  return schemaAtPath;
}

export { getSchemaAtPath };
