import React, { Component, createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { settings } from 'src/kiska/utils/settings';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import { generatePrimaryId } from 'src/kiska/utils';

const BlockNavigationContext = createContext({});
const useSettings = () => useContext(BlockNavigationContext);

const BlockNavigationProvider = (props) => {
  const { children } = props;
  const [requiredIds, setRequiredIds] = useState([]);

  const handleBeforeUnload = useCallback((event) => {
    if (requiredIds.length) {
      event.preventDefault();
      event.returnValue = '';
    } else {
      delete event.returnValue;
    }
  }, [requiredIds.length]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  const unrequireId = useCallback((requestId) => {
    setRequiredIds((ids) => {
      const newIds = ids.filter((id) => id.requestId !== requestId);
      // console.log('After removing, IDs to block on are: ', newIds);
      return newIds;
    });
  }, []);

  const requireId = useCallback(({ id, message }) => {
    const requestId = generatePrimaryId();

    setRequiredIds((ids) => {
      const newIds = [...ids, { requestId, id, message }];
      // console.log('After adding, IDs to block on are: ', newIds);
      return newIds;
    });

    return () => unrequireId(requestId);
  }, [unrequireId]);

  const value = {
    requireId,
  };

  return (
    <BlockNavigationContext.Provider value={value}>
      <Prompt
        message={(location, action) => {
          const absentIds = requiredIds.filter(({ id }) => id && !location.search.includes(id));
          if (absentIds.length) {
            return absentIds[absentIds.length - 1].message;
          }
          return true;
        }}
      />
      {children}
    </BlockNavigationContext.Provider>
  );
};

const withBlockNavigation = (WrappedComponent) => (props) => (
  <BlockNavigationContext.Consumer>
    {(value) => <WrappedComponent requireId={value.requireId} {...props} />}
  </BlockNavigationContext.Consumer>
);

export { withBlockNavigation, BlockNavigationProvider, BlockNavigationContext, useSettings };
