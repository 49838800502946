import React, { useContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography, Grid } from '@material-ui/core';
import { ToggleButtonNodeFilter } from 'src/kiska/components/NodeList/ToggleButtonNodeFilter';
import { QueryVariablesProvider } from 'src/kiska/components/NodeList/QueryVariablesContext';
import { NodeList, useNodeList } from 'src/kiska/components/NodeList';
import { usePageMeta } from 'src/kiska/components/contexts/PageMetaContext';
import { extractDateRangeStringFromVariables, formatCurrency, truncateString } from 'src/utils';
import { ReportTopContent } from 'src/components/dashboards/ReportTopContent';
import { ReportWrapper } from 'src/components/dashboards/ReportWrapper';
import { ReportContent } from 'src/components/dashboards/ReportContent';
import { ReportingDates } from 'src/components/dashboards/ReportingDates';
import { GridLineBreak } from 'src/components/forms';
import { formatDate, parseDate } from 'src/kiska/utils';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { SimpleTableInner } from '../../dashboards/settings/SimpleTable';
import { ExpenseUpdateForm } from './ExpenseUpdateForm';
import { ToggleButtonOptionsFilter } from '../../../kiska/components/NodeList/ToggleButtonOptionsFilter';
import { ExpenseTotals } from './ExpenseTotals';
import { ExpenseListParams } from './ExpenseListParams';
import { ExpenseChartDataProvider } from './ExpenseChartDataContext';
import { ExpensesCharts } from './ExpensesCharts';
import { ExpenseViewDialog } from './ExpenseViewDialog';
import { useExpenseTableColumns } from './useExpenseTableColumns';
import { ExpenseQuickAdd } from './ExpenseQuickAdd';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    flexDirection: 'column !important',
    justifyContent: 'flex-start !important',
  },
  statusButton: {
    padding: theme.spacing(0.25, 1),
  },
}));

const ExpenseListInner = (props) => {
  const { fixtures, updateFixtures, hideCreateButton, insertFixtures, updateFormProps, customer, job } = props;
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const classes = useStyles(props);
  const { setTitles } = usePageMeta();
  const [displayParams, setDisplayParams] = useState({
    showNotes: true,
    showCharts: false,
    showPhotos: true,
    showPrice: false,
    showCost: true,
  });
  const { variables } = useNodeList();

  const { columns } = useExpenseTableColumns({ displayParams });

  const datesStr = extractDateRangeStringFromVariables(variables);

  let expensesFor = null;
  if (job) expensesFor = job.title;
  else if (customer) expensesFor = customer.company || customer.name;

  const shareProps = {
    name: `Expenses${expensesFor ? ` - ${expensesFor}` : ''}${datesStr ? ` ${datesStr}` : ''}.pdf`,
    emailData: {
      subject: `Expenses PDF`,
      body: `Hello,\n\nYou have been sent an expenses list${expensesFor ? ` for ${expensesFor}` : ''}${datesStr ? ` for ${datesStr}` : ''}. Please find the attched PDF file.\n\nThanks,\n~ Slick Systems`,
    },
  };

  const initial = {
    limit: 50,
    orderBy: [
      { updatedAt: 'desc' },
    ],
    where: {
    },
  };

  const handleDisplayParamsChange = (value) => {
    setDisplayParams(value);
  };

  setTitles(`Expenses`);
  useEffect(() => {
    const title = `Expenses${expensesFor ? ` - ${expensesFor}` : ''}`;
    setTitles(title);
  }, [customer, expensesFor, job, setTitles]);

  const availableFilters = ['status', 'category', 'vendor'];
  if (!job) availableFilters.push('job');
  if (!customer && !job) availableFilters.push('customer');

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify="flex-start" alignItems="center">
        <ExpenseListParams
          availableFilters={availableFilters}
          displayParams={displayParams}
          onDisplayParamsChange={handleDisplayParamsChange}
          initialDateRange="all-time"
          enabledDisplayParams={['showCharts', 'showCost', 'showPrice']}
          dateRangeOptions={['all-time', 'last-year', 'last-month', 'last-period', 'this-period', 'this-month', 'this-year']}
        />
      </Grid>

      <ReportWrapper shareProps={shareProps}>
        <ReportTopContent title={`${expensesFor || `Expenses`}`} subtitle={expensesFor ? `Expenses` : undefined}>
          <Grid item xs={12} md={6}>
            <ReportingDates />
          </Grid>
          <GridLineBreak />
          <Grid container spacing={2} justify="flex-end" style={{ margin: 0 }}>
            <ExpenseTotals displayParams={displayParams} />
            <GridLineBreak />
            <ExpensesCharts displayParams={displayParams} groupBy={['vendor', 'status', 'category']} />
          </Grid>
        </ReportTopContent>
        <ReportContent padding={2}>
          <SimpleTableInner
            type="expense"
            initial={initial}
            fixtures={fixtures}
            insertFixtures={insertFixtures}
            updateFixtures={updateFixtures}
            addLabel="Add Expense"
            columns={columns}
            UpdateForm={ExpenseUpdateForm}
            updateFormProps={updateFormProps}
            actionsOnBottom={false}
            actionsOnTop={false}
            floatingAdd
            enableAdd
            enableEdit
            showSearchBox={false}
            rowClickAction="view"
            ViewDialog={ExpenseViewDialog}
          />
        </ReportContent>
      </ReportWrapper>
    </div>
  );
};

ExpenseListInner.propTypes = {
  fixtures: PropTypes.any,
  insertFixtures: PropTypes.object,
  updateFixtures: PropTypes.object,
  updateFormProps: PropTypes.object,
};
ExpenseListInner.defaultProps = {
  fixtures: undefined,
  insertFixtures: undefined,
  updateFixtures: undefined,
  updateFormProps: undefined,
};

const ExpenseList = (props) => {
  const { job, customer, ...innerProps } = props;

  const initial = {
    limit: 10000,
    orderBy: [
      { date: 'desc' },
    ],
    where: {
    },
  };

  const fixtures = [];
  if (job) {
    fixtures.push({ path: 'where.jobId._eq', value: job.id });
  }
  if (customer) {
    fixtures.push({ path: 'where.job.customerId._eq', value: customer.id });
  }

  return (
    <>
      <QueryVariablesProvider type="expense" initial={initial} fixtures={fixtures}>
        <NodeList>
          <ExpenseChartDataProvider groupBy={['category', 'status', 'vendor', 'customer', 'job']}>
            <ExpenseListInner {...innerProps} job={job} customer={customer} />
          </ExpenseChartDataProvider>
        </NodeList>
      </QueryVariablesProvider>
    </>
  );
};

ExpenseList.propTypes = {
};
ExpenseList.defaultProps = {
};

export { ExpenseList };
