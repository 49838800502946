import csvIcon from './images/csv.png';
import excelIcon from './images/excel.png';
import fileIcon from './images/file.png';
import imageIcon from './images/image.png';
import pdfIcon from './images/pdf.png';
import powerpointIcon from './images/powerpoint.png';
import videoIcon from './images/video.png';
import wordIcon from './images/word.png';
import compressedIcon from './images/compressed.png';

const fileToIconSrc = (file) => {
  const type = file.mimeType || file.contentType || '';

  if (type.startsWith('image/')) return imageIcon;
  if (type.startsWith('video/')) return videoIcon;

  switch (type) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return wordIcon;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return excelIcon;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return powerpointIcon;
    case 'application/pdf':
      return pdfIcon;
    case 'application/gzip':
    case 'application/zip':
    case 'application/x-7z-compressed':
    case 'application/vnd.rar':
    case 'application/x-tar':
      return compressedIcon;
    case 'text/csv':
      return csvIcon;
    default:
      return fileIcon;
  }
};

export { fileToIconSrc };
