import React, { createContext, useContext } from 'react';
import { useMutationContext } from './MutationContext/MutationContext';

export const useViewOrUpdate = () => {
  const mutationContext = useMutationContext();
  const update = !!mutationContext;
  const view = !mutationContext;

  return { view, update };
};
