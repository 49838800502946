import React from 'react';
// import PropTypes from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import SelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import { FileField } from 'src/kiska/components/MutationContext/FormFields/FileField';
import CrudForm from 'src/components/updates/CrudForm';
import { GridLineBreak } from 'src/components/forms';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import DateField from 'src/kiska/components/MutationContext/FormFields/DatePicker';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  filesPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.default,
  },
}));

const ExpenseUpdateFormInner = (props) => {
  const { fixtures } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>

      <Grid container spacing={4}>

        <Grid item xs={12}>
          <DateField name="date" />
        </Grid>
        <GridLineBreak />

        {!(fixtures && fixtures.job) && (
          <>
            <Grid item xs={12}>
              <NodeField name="job" />
            </Grid>
            <GridLineBreak />
          </>
        )}

        <Grid item xs={12} sm={6}>
          <SelectField name="status" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField name="category" />
        </Grid>

        <Grid item xs={12}>
          <TextField name="description" />
        </Grid>
        <GridLineBreak />

        <Grid item xs={12} sm={6}>
          <TextField name="cost" realNumber helperText="What you paid" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="price" realNumber helperText="What you'll charge your customer" />
        </Grid>
        <GridLineBreak />

        {/* <Grid item xs={12}>
          <NodeField name="vendor" />
        </Grid> */}

        <Grid item xs={12}>
          <Paper className={classes.filesPaper}>
            <Typography variant="h6" align="left">
              Invoice/Reciept/PO/Slip
            </Typography>
            <FileField name="files" label="" />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

ExpenseUpdateFormInner.propTypes = {
  // variant: PropTypes.string,
};
ExpenseUpdateFormInner.defaultProps = {
  // variant: '',
};

const ExpenseUpdateForm = ({ id, trigger, onClose, open, onOpen, variant, initialValues: propsInitialValues, onSuccess, fixtures }) => {
  const initialValues = {
    category: 'other',
    status: 'none',
    ...propsInitialValues,
  };

  return (
    <CrudForm
      type="expense"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderTitle={(node) => (node ? node.description || 'Edit Expense' : 'Create Expense')}
      initialValues={initialValues}
      onSuccess={onSuccess}
      fixtures={fixtures}
      maxWidth="md"
      onOpen={onOpen}
    >
      <ExpenseUpdateFormInner variant={variant} fixtures={fixtures} />
    </CrudForm>
  );
};

export { ExpenseUpdateForm };
