import React, { useEffect, useState, useCallback, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog, IconButton, CircularProgress, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PdfIcon from 'mdi-material-ui/FilePdf';
import DownloadIcon from 'mdi-material-ui/Download';
import { useThemeProvider } from 'src/kiska/components/contexts/ThemeProviderContext';
import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import { Dialog } from 'src/kiska/components/Dialog';
import _ from 'lodash';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import { useAuth0 } from '@auth0/auth0-react';
import { SendEmail } from '../share/SendEmail';
import { useAppSettings } from '../contexts/AppSettingsContext';

const useStyles = makeStyles((theme) => ({
  button: {

  },
  dialog: {
    // [theme.breakpoints.up('md')]: {
    height: 'calc(100vh - 100px)',
    width: theme.breakpoints.values.md,
    // },
  },
  dialogTitle: {
    padding: theme.spacing(1, 2, 1, 2),
  },
  dialogContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pdfIframe: {
    flex: 1,
    border: 'none',
    width: '100%',
  },
}));

const usePrint = () => {
  const theme = useTheme();

  const value = {
    printMode: theme.kiska.type === 'print',
  };

  return value;
};

const getPdfBlob = async (data, { settings, auth }) => {
  const token = await auth.getAccessTokenSilently();

  const response = await window.fetch(`${settings.public.kiskaOrigin}/kiska-api/v1/generate-pdf`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    authorization: `Bearer ${token}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.blob();
};

const buildTargetHtmlDoc = ({ theme, targetRef, name }) => {
  let stylesheets = '';
  let styles = '';
  document.querySelectorAll('head > link[rel="stylesheet"]').forEach((s) => { stylesheets += `${s.outerHTML}\n`; });
  document.querySelectorAll('head > style').forEach((s) => { styles += `${s.outerHTML}\n`; });

  const bodyStyle = `
      color: ${theme.palette.text.primary};
      margin: 0;
      font-size: ${theme.typography.body2.fontSize};
      font-family: ${theme.typography.body2.fontFamily};
      font-weight: ${theme.typography.body2.fontWeight};
      line-height: ${theme.typography.body2.lineHeight};
      letter-spacing: ${theme.typography.body2.letterSpacing};
      background-color: ${theme.palette.background.default};
    `;

  const target = targetRef.current.outerHTML;
  const doc = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>${name}</title>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
          ${stylesheets}
          ${styles}
        </head>
        <body style="${bodyStyle}">
          ${target}
        </body>
      </html>
    `;
  return doc;
};

const downloadBlob = (blob, { targetWindow = window, name = 'SlickSystems.pdf', closeWindowOnComplete = false }) => {
  const dataUrl = URL.createObjectURL(blob);
  setTimeout(() => URL.revokeObjectURL(dataUrl), 60000);

  const a = targetWindow.document.createElement('a');
  a.href = dataUrl;
  a.setAttribute('download', name);
  targetWindow.document.body.appendChild(a);
  a.click();
  targetWindow.document.body.removeChild(a);
  if (closeWindowOnComplete) targetWindow.close();
};

const generatePdfBlob = async ({ theme, targetRef, name, settings, pageData, auth }) => {
  if (!targetRef.current) {
    console.error(`Can't generate PDF because target ref is null.`);
    return null;
  }

  const html = buildTargetHtmlDoc({ theme, targetRef, name, pageData });

  const data = { html, name };

  const blob = await getPdfBlob(data, { settings, auth });
  return blob;
};

const GeneratePdfButton = forwardRef((props, forwardedRef) => {
  const { appSettings } = useAppSettings();
  const { label: propsLabel, component, icon: propsIcon, mode, onClick, targetRef, children, name, emailData, onComplete, ...rest } = props;
  const iframeRef = useRef();
  const theme = useTheme();
  const settings = useSettings();
  const [timeoutElapsed, setTimeoutElapsed] = useState('idle');
  const { setActiveThemeName } = useThemeProvider();
  const [busy, setBusy] = useState(false);
  const classes = useStyles(props);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const auth = useAuth0();

  const clearPdfData = () => {
    setPdfBlob(null);
    URL.revokeObjectURL(pdfDataUrl);
    setPdfDataUrl(null);
  };

  const pdfViewerDialogControlProps = useLocationControl({ open: !!pdfDataUrl, onClose: () => { clearPdfData(); } });

  const label = propsLabel === undefined ? 'Download PDF' : propsLabel;
  let Icon = propsIcon;
  if (Icon === undefined) {
    if (mode === 'download') Icon = DownloadIcon;
    else Icon = PdfIcon;
  }
  let Component = component;
  if (!component) {
    if (!label) Component = IconButton;
    else Component = Button;
  }

  const handleComplete = useCallback(() => {
    setActiveThemeName('inherit');
    setBusy(false);
    if (onComplete) {
      onComplete();
    }
  }, [onComplete, setActiveThemeName]);

  useEffect(() => {
    let timeout;
    if (timeoutElapsed === 'waiting-to-start') {
      setTimeoutElapsed('elapsing');
      timeout = setTimeout(() => setTimeoutElapsed('elapsed'), 2000);
    }
  }, [timeoutElapsed]);

  useEffect(() => {
    if (timeoutElapsed === 'elapsed') {
      setTimeoutElapsed('idle');
      const pageData = {
        logo: _.get(appSettings.data, 'blackAndWhiteLogo.url'),
        companyName: appSettings.data.companyName,
        tagLine: appSettings.data.tagLine,
        footerLine1: appSettings.data.footerLine1,
        footerLine2: appSettings.data.footerLine2,
        footerLine3: appSettings.data.footerLine3,
        footerLine4: appSettings.data.footerLine4,
      };
      generatePdfBlob({ theme, targetRef, name, settings, pageData, auth })
        .then((blob) => {
          if (mode === 'download') {
            downloadBlob(blob, { name });
          } else if (['email'].includes(mode)) {
            setPdfBlob(blob);
          } else if (['view', 'print'].includes(mode)) {
            const dataUrl = URL.createObjectURL(blob);
            setTimeout(() => URL.revokeObjectURL(dataUrl), 60e3 * 10);
            setPdfDataUrl(dataUrl);
          }
        })
        .finally(() => {
          handleComplete();
        });
    }
  }, [mode, handleComplete, settings, targetRef, theme, timeoutElapsed, name, appSettings.data]);

  const handleIframeLoad = () => {
    if (mode === 'print') {
      const iframeWindow = iframeRef.current.contentWindow;
      setTimeout(() => {
        if (!iframeRef.current) {
          console.error(`Can't print PDF because iframeRef is null`);
          return;
        }
        try {
          iframeWindow.print();
        } catch (error) {
          // Browser might block attempt at access iframe??
        }
      }, 1000);
    }
  };

  const start = () => {
    setBusy(true);
    setActiveThemeName('print');
    setTimeoutElapsed('waiting-to-start');
  };

  const handleClick = (event) => {
    start();

    if (onClick) onClick(event);
  };

  return (
    <>
      <Component variant="contained" className={classes.button} ref={forwardedRef} {...rest} onClick={handleClick}>
        {children || (
          <>
            <Icon />
            {label && <span>{label}</span>}
          </>
        )}
      </Component>

      {['email'].includes(mode) && (
        <SendEmail emailData={emailData} open={!!pdfBlob} onClose={clearPdfData} attachments={[{ name, blob: pdfBlob, contentType: 'application/pdf' }]} />
      )}

      {['view', 'print'].includes(mode) && (
        <Dialog dialogProps={{ classes: { paper: classes.dialog } }} fullscreenBreakpoint="md" {...pdfViewerDialogControlProps}>
          <Typography variant="h6" color="textSecondary" align="center" className={classes.dialogTitle}>
            PDF Version
          </Typography>
          <div className={classes.dialogContent}>
            {pdfDataUrl && <iframe title={name} src={pdfDataUrl} className={classes.pdfIframe} ref={iframeRef} onLoad={handleIframeLoad} />}
          </div>
        </Dialog>
      )}

      <MuiDialog open={busy}>
        <div style={{ padding: 16 }}>
          <Grid container spacing={2} justify="center" alignItems="center" direction="column">
            <Grid item>
              <CircularProgress size={50} thickness={2} />
            </Grid>
            <Grid item>
              <Typography variant="body1" color="textSecondary">Generating PDF...</Typography>
            </Grid>
          </Grid>
        </div>
      </MuiDialog>
    </>
  );
});

GeneratePdfButton.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  onComplete: PropTypes.func,
  name: PropTypes.string,
  mode: PropTypes.oneOf(['view', 'download', 'print', 'email']),
  component: PropTypes.elementType,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
  targetRef: PropTypes.object.isRequired,
  emailData: PropTypes.object,
};
GeneratePdfButton.defaultProps = {
  children: undefined,
  label: undefined,
  name: 'PDF Document',
  mode: 'view',
  component: undefined,
  icon: undefined,
  onClick: undefined,
  onComplete: undefined,
  emailData: undefined,
};
GeneratePdfButton.displayName = 'GeneratePdfButton';

export { GeneratePdfButton, usePrint };
