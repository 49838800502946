import React, { cloneElement, useCallback, useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import { LinearProgress, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from 'mdi-material-ui/Cancel';
import classNames from 'classnames';
import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import { Dialog } from 'src/kiska/components/Dialog';
// import Webcam from 'react-webcam';
import FlipCameraIcon from 'mdi-material-ui/CameraFlip';
import { Camera as CaptureIcon } from '@material-ui/icons';
import { dataURLtoBlob, uploadFile } from 'src/utils';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import { useAuth0 } from '@auth0/auth0-react';
import { useLog } from 'src/kiska/components/contexts/LogContext';
import { OnMutationEvent } from '../../OnMutationEvent';
import shutterSound from './shutter.mp3';

const shutterAudio = new Audio(shutterSound);

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#222',
  },
  video: {
    objectFit: 'contain',
    height: '100vh',
    width: '100vw',
    '&.hidden': {
      display: 'none',
    },
  },
  actionButton: {
    position: 'absolute',
    bottom: theme.spacing(1),
    backgroundColor: 'hsla(0, 0%, 0%, .4)',
    color: '#FFF',
    '&:hover': {
      backgroundColor: 'hsla(0, 0%, 0%, .6)',
    },
    zIndex: 100,
  },
  cancelButton: {
    left: theme.spacing(1),
  },
  captureButton: {
    right: `calc(50% - 35px)`,
    backgroundColor: `hsla(0, 0%, 100%, .20)`,
    '& svg': {
      fontSize: 40,
    },
  },
  flipButton: {
    right: theme.spacing(1),
  },
  preview: {
    width: '100%',
    height: '100vh',
    objectFit: 'contain',
  },
  progress: {
    height: 16,
    borderRadius: 4,
    position: 'absolute',
    top: `calc(50% - 8px)`,
    width: `calc(100% - 32px)`,
    left: 16,
    opacity: 0.7,
  },
  playButton: {
    position: 'absolute',
    top: '50%',
    width: 200,
    left: 'calc(50% - 100px)',
  },
}));

const CameraDialog = (props) => {
  const settings = useSettings();
  const classes = useStyles(props);
  const videoRef = useRef();
  const canvasRef = useRef();
  const streamRef = useRef();
  const { trigger: propsTrigger, onUploadComplete, className } = props;
  const [facingMode, setFacingMode] = useState('environment');
  const [progress, setProgress] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const auth = useAuth0();
  const log = useLog();
  const [showPlayButton, setShowPlayButon] = useState(false);

  const teardown = useCallback(() => {
    setShowPlayButon(false);

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = '';
      videoRef.current.srcObject = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  }, []);

  const playVideo = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      const promise = video.play();
      promise.then(() => {
        setShowPlayButon(false);
      });
      promise.catch((error) => {
        setShowPlayButon(true);
        console.error('<CameraDialog> Failed to play video: ', error);
      });
    } else {
      log.error('<CameraDialog> Tried to play video but ref is null.');
    }
  }, [log]);

  const startup = useCallback(() => {
    setProgress(0);
    setPreviewSrc(null);

    navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: {
          ideal: 3000,
        },
        facingMode,
      },
    })
      .then((stream) => {
        const video = videoRef.current;
        const canvas = canvasRef.current;

        streamRef.current = stream;
        video.srcObject = stream;
        playVideo();
        video.addEventListener('canplay', (ev) => {
          const height = video.videoHeight;
          const width = video.videoWidth;
          canvas.setAttribute('width', width);
          canvas.setAttribute('height', height);
        }, false);
      })
      .catch((error) => {
        console.error(`Camera error: `, error);
      });
  }, [facingMode, playVideo]);

  const handleDialogClose = () => {
    teardown();
  };

  const dialogControlProps = useLocationControl({ onClose: handleDialogClose });

  const handleTriggerClick = (event) => {
    dialogControlProps.openDialog();
    startup();
  };

  const trigger = cloneElement(propsTrigger, { onClick: handleTriggerClick, className: propsTrigger.props.className || className });

  const handleMutationComplete = useCallback(() => {
  }, []);

  const handleFileUploadComplete = useCallback((nodeFile) => {
    setProgress(1000);
    dialogControlProps.closeDialog();
    onUploadComplete([nodeFile]);
  }, [dialogControlProps, onUploadComplete]);

  const handleProgress = (percent) => {
    setProgress(percent);
  };

  const handleCapture = useCallback(() => {
    shutterAudio.play();
    const video = videoRef.current;
    const canvas = canvasRef.current;

    const height = video.videoHeight;
    const width = video.videoWidth;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, width, height);
    const dataUri = canvas.toDataURL('image/jpeg');

    const file = dataURLtoBlob(dataUri);
    file.name = `camera_capture.${/^image\/(.*)/.exec(file.type)[1]}`;

    setPreviewSrc(dataUri);
    setProgress(0);

    uploadFile({
      auth,
      file,
      onComplete: handleFileUploadComplete,
      onProgress: handleProgress,
      settings,
    });
  }, [auth, handleFileUploadComplete, settings]);

  const handleFlipCamera = () => {
    let newMode;
    if (facingMode === 'environment') newMode = 'user';
    else newMode = 'environment';

    setFacingMode(newMode);
  };

  return (
    <>
      <OnMutationEvent onComplete={handleMutationComplete} />
      {trigger}
      <Dialog fullScreen classes={{ root: classes.dialog, paper: classes.paper }} showCloseButton={false} {...dialogControlProps}>
        {(!dialogControlProps.open || previewSrc) && (
          <>
            <img src={previewSrc} className={classes.preview} alt="Preview" />
            <LinearProgress value={progress} variant="determinate" className={classes.progress} thickness={16} />
          </>
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <video ref={videoRef} autoPlay muted playsInline className={classNames(classes.video, previewSrc && 'hidden')}>
          Camera not available.
        </video>
        {showPlayButton && (
          <Button variant="contained" color="primary" onClick={playVideo} className={classes.playButton}>
            Preview Webcam
          </Button>
        )}
        {!previewSrc && (
          <>
            <Button variant="outlined" color="default" onClick={dialogControlProps.closeDialog} className={classNames(classes.cancelButton, classes.actionButton)}>
              <CancelIcon /><span>Cancel</span>
            </Button>
            <IconButton variant="contained" color="primary" onClick={handleCapture} className={classNames(classes.captureButton, classes.actionButton)}>
              <CaptureIcon />
            </IconButton>
            <Button variant="outlined" color="default" onClick={handleFlipCamera} className={classNames(classes.flipButton, classes.actionButton)}>
              <FlipCameraIcon />
            </Button>
          </>
        )}
      </Dialog>
    </>
  );
};

/*
<Camera
            //   // onTakePhoto={handleTakePhoto}
            //   onTakePhotoAnimationDone={handleTakePhoto}
            //   onCameraError={handleCameraError}
            //   idealFacingMode={facingMode}
            //   // idealResolution={{ width: 640, height: 480 }}
            //   imageType={IMAGE_TYPES.JPG}
            //   imageCompression={0.95}
            //   isMaxResolution={true}
            //   isImageMirror={false}
            //   isSilentMode={false}
            //   isDisplayStartCameraError={true}
            //   isFullscreen={true}
            //   sizeFactor={1}
            //   onCameraStart={handleCameraStart}
            //   onCameraStop={handleCameraStop}
            // />
            */

CameraDialog.propTypes = {
};
CameraDialog.defaultProps = {
};

export { CameraDialog };
