import React, { useContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate, parseDate } from 'src/kiska/utils';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { extractDateRangeStringFromVariables, formatCurrency, truncateString } from 'src/utils';
import { useMediaQuery, Typography, Badge } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { FilePreview } from 'src/kiska/components/MutationContext/FormFields/FileField/FilePreview';
import { UpdateExpenseStatusButton } from './UpdateExpenseStatusButton';

const useStyles = makeStyles((theme) => ({
  files: {
    margin: theme.spacing(-0.5, 0),
  },
  filePreview: {

  },
  badge: {
    backgroundColor: '#282828',
    color: '#CCC',
    top: theme.spacing(1.3),
    right: theme.spacing(1),
  },
  statusButton: {
    padding: theme.spacing(0.25, 1),
  },
}));

const StatusColumn = (props) => {
  const { value, parentNode: node } = props;
  const classes = useStyles(props);
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <UpdateExpenseStatusButton node={node} variant="outlined" color="default" size="small" className={classes.statusButton} />
    </div>
  );
};

const useExpenseTableColumns = (props) => {
  const { displayParams } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const { getOption } = useSchema();

  const columns = [];

  if (!xs) {
    columns.push({
      label: 'Status',
      fieldName: 'status',
      Renderer: StatusColumn,
    });
  }

  if (!xs) {
    columns.push({
      label: 'Date',
      fieldName: 'date',
      Renderer: ({ value }) => (
        <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1.2 }}>
          {formatDate(value, `MMM d, ''yy`)}
        </Typography>
      ),
    });
  }

  columns.push({
    label: 'Category',
    fieldName: 'category',
    Renderer: ({ parentNode: node }) => getOption('expense', 'category', node).primary,
  });

  if (!xs) {
    columns.push({
      label: 'Description',
      fieldName: 'description',
      Renderer: ({ value }) => (
        <Typography variant="body2" color="textSecondary" style={{ lineHeight: 1.2 }}>
          {truncateString(value, xs ? 40 : 70)}
        </Typography>
      ),
    });
  }

  if (displayParams.showCost) {
    columns.push({
      label: 'Cost',
      fieldName: 'cost',
      Renderer: ({ value }) => `$${formatCurrency(value)}`,
    });
  }

  if (displayParams.showPrice) {
    columns.push({
      label: 'Price',
      fieldName: 'price',
      Renderer: ({ value }) => `$${formatCurrency(value)}`,
    });
  }

  columns.push({
    label: 'Files',
    fieldName: 'files',
    Renderer: ({ value }) => (
      <div className={classes.files}>
        {value && value.length
          ? (
            <Badge badgeContent={value.length} classes={{ badge: classes.badge }}>
              <FilePreview className={classes.filePreview} file={value[0]} size="small" showName={false} />
            </Badge>
          )
          : ''}
      </div>
    ),
    filters: null,
  });

  return { columns };
};

export { useExpenseTableColumns };
