import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from 'mdi-material-ui/Cancel';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import _ from 'lodash';

class ConfirmationDialog extends React.Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    cancelButton: PropTypes.node,
    trigger: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    confirmButton: PropTypes.node.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    onConfirm: null,
    disabled: false,
  }

  static defaultProps = {
    cancelButton: (
      <Button variant="outlined">
        <CancelIcon />
        <span>Cancel</span>
      </Button>
    ),
  }

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;
    if (onConfirm) {
      onConfirm();
    }
  }

  render() {
    const { open } = this.state;
    const { trigger: propsTrigger, content, title, cancelButton, confirmButton, onConfirm, fullScreen, disabled } = this.props;

    const triggerWithProps = React.cloneElement(propsTrigger, { onClick: this.handleOpen, disabled });
    const cancelWithProps = React.cloneElement(cancelButton, { onClick: this.handleClose });
    const confirmWithProps = React.cloneElement(confirmButton, { onClick: this.handleConfirm });

    return (
      <>
        {triggerWithProps}
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullScreen={fullScreen}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {cancelWithProps}
            {onConfirm ? confirmWithProps : confirmButton}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

ConfirmationDialog = _.flow(
  withMobileDialog(),
)(ConfirmationDialog);
export default ConfirmationDialog;
