/* eslint-disable max-classes-per-file */
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { VpnKey as LoginIcon } from '@material-ui/icons';
// import { makeStyles } from '@material-ui/core/styles';
import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
import { useAuth0 } from '@auth0/auth0-react';
import { SupportButton } from './SupportPage/SupportPage';

// const useStyles = makeStyles((theme) => ({
//   alert: {
//     // alignItems: 'center',
//     padding: theme.spacing(1, 1),
//     margin: theme.spacing(1),
//   },
// }));

const HomePage = () => {
  const history = useHistory();
  // const classes = useStyles();
  const auth = useAuth0();
  const { setTitles } = useContext(PageMetaContext);

  useEffect(() => {
    setTitles('Slick Systems');
  }, [setTitles]);

  if (auth.isAuthenticated) {
    history.push('/app');
  }

  return (
    <>
      {/* <Alert
        elevation={3}
        severity="warning"
        className={classes.alert}
      >
        <Typography variant="body1">
          Note: Slick Systems has been updated to a new version and your password has been reset to one you
          have received in an email.
          <br /><br />
          Please check your email for your new password.
        </Typography>
      </Alert> */}

      <Button
        color="primary"
        variant="contained"
        style={{ padding: `12px 80px`, fontSize: 20 }}
        onClick={() => auth.loginWithRedirect({ redirectPath: '/app/' })}
      >
        <LoginIcon />
        <span>Login</span>
      </Button>
      <br />
      <SupportButton label={<>Instructions</>} />
    </>
  );
};

export default HomePage;
