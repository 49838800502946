import React from 'react';
import { Timer as TimeIcon, Dashboard as DetailsIcon } from '@material-ui/icons';
import { TabPanel, ContentTabs } from 'src/kiska/components/ContentTabs';
import ReportIcon from 'mdi-material-ui/FileChart';
import ExpensesIcon from 'mdi-material-ui/CashRegister';
import { JobDetails } from './JobDetails';
import { JobReport } from './JobReport';
import { JobWorkEntries } from './JobWorkEntries';
import { JobExpenses } from './JobExpenses';

const JobTabs = ({ node, title, subtitle }) => {
  return (
    <ContentTabs
      urlPathIndex={3}
      title={title}
      subtitle={subtitle}
      defaultTab="details"
      tabs={[
        { value: 'details', label: 'Details', icon: <DetailsIcon /> },
        { value: 'work-log', label: 'Log', icon: <TimeIcon /> },
        { value: 'expenses', label: 'Expenses', icon: <ExpensesIcon /> },
        { value: 'report', label: 'Report', icon: <ReportIcon /> },
      ]}
    >
      <TabPanel value="details">
        <JobDetails node={node} />
      </TabPanel>
      <TabPanel value="work-log">
        <JobWorkEntries node={node} />
      </TabPanel>
      <TabPanel value="expenses">
        <JobExpenses job={node} />
      </TabPanel>
      <TabPanel value="report">
        <JobReport node={node} />
      </TabPanel>
    </ContentTabs>
  );
};

export default JobTabs;
