import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Palette as ThemeIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useThemeProvider } from './contexts/ThemeProviderContext';

const themes = [
  { name: 'dark', label: 'Dark' },
  { name: 'light', label: 'Light' },
  { name: 'print', label: 'Print' },
];

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const ThemeMenu = () => {
  const classes = useStyles();
  const { setActiveThemeName } = useThemeProvider();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTriggerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (name) => {
    setActiveThemeName(name);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleTriggerClick} color="inherit">
        <ThemeIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {themes.map(({ name, label }) => <MenuItem key={name} onClick={() => handleItemClick(name)}>{label}</MenuItem>)}
      </Menu>
    </div>
  );
};

ThemeMenu.propTypes = {
};
ThemeMenu.defaultProps = {
};

export { ThemeMenu };
