import React, { cloneElement, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import CancelIcon from 'mdi-material-ui/Cancel';
import classNames from 'classnames';
import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import { Dialog } from 'src/kiska/components/Dialog';
import mobile from 'is-mobile';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import { uploadFile } from 'src/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { FilePreviewsContainer } from './FilePreviewsContainer';

const isMobile = mobile({ tablet: true, featureDetect: true });

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    minWidth: 350,
  },
  content: {
    padding: theme.spacing(4),
    paddingTop: `${theme.spacing(4)}px !important`,
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  dropzone: {
    padding: theme.spacing(2),
    minHeight: 200,
    maxHeight: '90vh',
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'dashed 2px',
    borderColor: theme.palette.border.light,
    '&.active': {
      backgroundColor: '#79aac1',
    },
  },
  actions: {
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'flex-end',
  },
}));

const filesReducer = (files, action) => {
  switch (action.op) {
    case 'clear':
      return [];
    case 'push':
      return [...files, action.file];
    case 'merge':
      return files.map((file) => {
        if (file.id === action.file.id) {
          return { ...file, ...action.file };
        }
        return file;
      });
    default:
      throw new Error(`Unknown files reducer op "${action.op}"`);
  }
};

const DragndropDialog = (props) => {
  const mobileInputRef = useRef();
  const settings = useSettings();
  const classes = useStyles(props);
  const [files, dispatchFiles] = useReducer(filesReducer, []);
  const { trigger: propsTrigger, onUploadComplete, multiple, className, dialogMode } = props;
  const dialogControlProps = useLocationControl({});
  const auth = useAuth0();

  const finishAndClose = useCallback(() => {
    onUploadComplete(files.filter((f) => f.status === 'complete').map((f) => f.nodeFile));
    dialogControlProps.closeDialog();
    dispatchFiles({ op: 'clear' });
  }, [dialogControlProps, files, onUploadComplete]);

  useEffect(() => {
    const allDone = files.reduce((acc, f) => (f.status !== 'uploading') && acc, !!files.length);
    if (allDone) {
      finishAndClose();
    }
  }, [files, finishAndClose]);

  const handleFileUploadComplete = useCallback(({ file, nodeFile }) => {
    dispatchFiles({
      op: 'merge',
      file: {
        id: file.id,
        progress: 100,
        nodeFile,
        status: 'complete',
      },
    });
  }, []);

  const handleProgress = ({ file, percent }) => {
    dispatchFiles({
      op: 'merge',
      file: { id: file.id, progress: percent },
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const newFile = {
        id: `${file.name}_${file.size}`,
        file,
        nodeFile: null,
        src: null,
        progress: 0,
        status: 'uploading',
      };

      uploadFile({
        auth,
        file,
        onComplete: (nodeFile) => handleFileUploadComplete({ file: newFile, nodeFile }),
        onProgress: (percent) => handleProgress({ file: newFile, percent }),
        settings,
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        dispatchFiles({
          op: 'merge',
          file: {
            id: newFile.id,
            src: e.target.result,
          },
        });
      };
      reader.readAsDataURL(file);

      dispatchFiles({ op: 'push', file: newFile });
    });
  }, [auth, handleFileUploadComplete, settings]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple,
  });

  const handleMobileInputChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles || !newFiles.length) return;
    onDrop(newFiles);
    dialogControlProps.openDialog();
  };

  const commonTriggerProps = {
    className: propsTrigger.props.className || className,
  };

  const trigger = isMobile
    ? (
      <>
        <input type="file" multiple={multiple} style={{ display: 'none' }} onChange={handleMobileInputChange} ref={mobileInputRef} />
        {cloneElement(propsTrigger, { onClick: () => mobileInputRef.current.click(), ...commonTriggerProps })}
      </>
    )
    : cloneElement(propsTrigger, { onClick: dialogControlProps.openDialog, ...commonTriggerProps });

  const dropzoneClassName = classNames(
    classes.dropzone,
    isDragActive && 'active',
    isDragActive && 'accept',
    isDragActive && 'reject',
  );

  const dialogContent = (
    <div {...getRootProps()} className={dropzoneClassName}>
      <input {...getInputProps()} />
      {!files.length && (
        <Typography variant="h6" align="center">
          {isDragActive
            ? `Drop the files here...`
            : <>Drag 'n' drop files here<br />or click to select files</> }
        </Typography>
      )}
      <FilePreviewsContainer
        files={files.map((file) => ({
          id: file.nodeFile ? file.nodeFile.id : file.id,
          mimeType: file.file.type,
          name: file.file.name,
          url: file.url,
          src: file.src,
          progress: file.progress,
        }))}
        enableView={false}
      />
    </div>
  );

  if (!dialogMode) return dialogContent;

  return (
    <>
      {trigger}
      <Dialog classes={{ root: classes.dialog, paper: classes.paper }} {...dialogControlProps}>
        <DialogContent className={classes.content}>
          {dialogContent}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="outlined" color="default" className={classes.cancelButton} onClick={dialogControlProps.closeDialog}>
            <CancelIcon /><span>Cancel</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DragndropDialog.propTypes = {
  trigger: PropTypes.node.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  dialogMode: PropTypes.bool,
};
DragndropDialog.defaultProps = {
  multiple: false,
  dialogMode: true,
};

export { DragndropDialog };
