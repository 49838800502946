import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
// import _ from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';

import CrudForm from 'src/components/updates/CrudForm';
import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { LocalNodeProvider } from '../../../kiska/components/LocalNodeContext';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const FormUpdateFormInner = (props) => {
  const classes = useStyles(props);
  const { getAllFieldValues } = useContext(MutationContext);
  const node = getAllFieldValues({ jsonFieldsToObjects: true });
  const { form } = props;

  // if (!form) return null;

  return (
    <LocalNodeProvider node={node} type="form">
      <div className={classes.root}>
        <form.Form update />
      </div>
    </LocalNodeProvider>
  );
};

FormUpdateFormInner.propTypes = {
  // variant: PropTypes.string,
};
FormUpdateFormInner.defaultProps = {
  // variant: '',
};

const FormUpdateForm = ({ id, node, trigger, onClose, open, form, fixtures: propsFixtures, onSuccess, initialValues, allowDelete, ...rest }) => {
  const formTitle = form.title;

  const fixtures = form ? {
    ...propsFixtures,
    title: form.title,
    name: form.name,
    version: form.version,
    versionDate: form.versionDate,
  } : propsFixtures;

  return (
    <CrudForm
      key={form ? `${form.name}_${form.version}` : undefined}
      type="form"
      id={id || (node && node.id)}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderRichTitle={(n) => (n ? <>{formTitle}</> : `New ${formTitle}`)}
      renderTitle={(n) => (n ? `${formTitle}` : `New ${formTitle}`)}
      fixtures={fixtures}
      onSuccess={onSuccess}
      fullScreen={false}
      blurMode
      initialValues={initialValues}
      allowDelete={allowDelete}
      {...rest}
    >
      <FormUpdateFormInner form={form} />
    </CrudForm>
  );
};

export { FormUpdateForm };
