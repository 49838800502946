import React from 'react';
// import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { JobList } from 'src/components/dashboards/JobList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block !important',
    padding: theme.spacing(2),
  },
}));

const Jobs = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="center">Jobs</Typography>
      <JobList showCount={true}/>
    </div>
  );
};

Jobs.propTypes = {
};
Jobs.defaultProps = {
};

export default Jobs;
