import React, { Component, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useLog } from './LogContext';

const SchemaContext = createContext({});
const useSchema = () => useContext(SchemaContext);

const SchemaProvider = (props) => {
  const { schema, children } = props;
  const log = useLog();

  const getOptions = (type, name) => {
    const field = schema.types[type].fields[name];
    if (!field) {
      log.error(`Can't find schema for ${type}.${name}`);
      return [];
    }

    const options = field.options;
    if (!options) {
      log.error(`Could not find options for ${type}.${name}.`);
      return [];
    }

    return options;
  };

  const getOption = (type, name, valueOrNode) => {
    const value = typeof valueOrNode === 'string' ? valueOrNode : _.get(valueOrNode, name);
    const option = getOptions(type, name).find((o) => o.value === value);
    const defaultSafeOption = {
      value,
      primary: '',
      secondary: '',
    };

    if (!option) {
      console.error(`Could not find option "${value}" for "${type}.${name}"`);
      return defaultSafeOption;
    }
    return {
      ...defaultSafeOption,
      ...option,
    };
  };

  const value = {
    schema,
    getOptions,
    getOption,
  };

  return (
    <SchemaContext.Provider value={value}>
      {children}
    </SchemaContext.Provider>
  );
};

SchemaProvider.propTypes = {
  schema: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const withSchema = (WrappedComponent) => (props) => (
  <SchemaContext.Consumer>
    {(value) => <WrappedComponent schema={value.schema} {...props} />}
  </SchemaContext.Consumer>
);

export { withSchema, SchemaProvider, SchemaContext, useSchema };
