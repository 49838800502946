import React from 'react';
import PropTypes from 'prop-types';
// import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import { useNode } from 'src/kiska/hooks/useNode';
import NodeViewPage from 'src/components/layouts/NodeViewPage';
import JobTabs from './JobTabs';

// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

const JobPageView = (props) => {
  const { id } = useParams();
  const { result, node } = useNode({
    type: 'job',
    id,
  });
  const title = node ? `${node.title} - ${node.shortId}` : 'Loading...';
  const subtitle = node ? node.customer.company || node.customer.name : '';

  return (
    <NodeViewPage queryResult={result} component={JobTabs} title={title} subtitle={subtitle} />
  );
};

JobPageView.propTypes = {
};
JobPageView.defaultProps = {
};

export default JobPageView;
