import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from 'mdi-material-ui/Close';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider } from '@material-ui/pickers';
import { endOfDay, startOfDay } from 'date-fns/esm';
import { formatDate, parseTime } from 'src/kiska/utils';
import { NodeListContext } from '.';

const styles = (theme) => ({
  root: {
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
});

class DateAndOrTimeBetweenFilter extends React.Component {
  static contextType = NodeListContext

  static propTypes = {
    format: PropTypes.string,
    beforeLabel: PropTypes.string,
    afterLabel: PropTypes.string,
    showClear: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    label: PropTypes.string,
    component: PropTypes.elementType.isRequired,
    type: PropTypes.string.isRequired,
  }

  static defaultProps = {
    format: undefined,
    beforeLabel: 'Is before:',
    afterLabel: 'Is after:',
    label: '',
    showClear: true,
  }

  handleGteChange = (value) => this.handleChange('_gte', value)

  handleLteChange = (value) => this.handleChange('_lte', value)

  handleChange = (condition, value) => {
    const { updateVariables } = this.context;
    const { type, path } = this.props;

    if (value) {
      if (type === 'time') value = formatDate(value, 'HH:mm:ss');
      if (type === 'date') {
        if (condition === '_gte') value = startOfDay(value);
        else if (condition === '_lte') value = endOfDay(value);
        value = value.toISOString();
      }
    }

    updateVariables({
      replaceInArray: {
        rootPath: `where._and`,
        pathValuePairs: [
          { path: `${path}.${condition}`, value },
        ],
      },
    });
  }

  clear = (event) => {
    this.handleChange('_lte', undefined);
    this.handleChange('_gte', undefined);
  }

  render() {
    const { variables: { where } } = this.context;
    const { format, classes, path, beforeLabel, type, afterLabel, label, showClear, component: Component } = this.props;
    let lteValue = _.get(_.get(where, `_and`, []).find((c) => _.get(c, `${path}._lte`)), `${path}._lte`) || null;
    let gteValue = _.get(_.get(where, `_and`, []).find((c) => _.get(c, `${path}._gte`)), `${path}._gte`) || null;

    if (type === 'time') {
      lteValue = parseTime(lteValue);
      gteValue = parseTime(gteValue);
    }

    const commonPickerProps = {
      format,
      inputFormat: format,
      clearable: true,
      showTodayButton: true,
      allowKeyboardControl: true,
      autoOk: true,
      renderInput: (props) => <TextField variant="outlined" {...props} />,
    };

    return (
      <div className={classes.root}>
        <FormLabel>{label}</FormLabel>
        <div className={classes.dates}>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <>
              <Component value={gteValue} label={afterLabel} onChange={this.handleGteChange} {...commonPickerProps} />
              &nbsp;&nbsp;&nbsp;
              <Component value={lteValue} label={beforeLabel} onChange={this.handleLteChange} {...commonPickerProps} />
              {showClear && <Button color="secondary" onClick={this.clear} size="small"><CloseIcon /><span>Clear</span></Button>}
            </>
          </LocalizationProvider>
        </div>
      </div>
    );
  }
}

DateAndOrTimeBetweenFilter = withStyles(styles)(DateAndOrTimeBetweenFilter);
export default DateAndOrTimeBetweenFilter;
