import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import _ from 'lodash';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import produce from 'immer';
import { getSchemaAtPath } from 'src/kiska/schema/getSchemaAtPath';
import NodeSelector from '../NodeSelector';
import { NodeListContext } from '.';
import { QueryVariablesContext } from './QueryVariablesContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
}));

const NodeFilter = ({ className, label, path, rootPath, boolOp, ...nodeSelectorProps }) => {
  const client = useApolloClient();
  const queryVariablesContext = useContext(QueryVariablesContext);
  const nodeListContext = useContext(NodeListContext);
  const { replaceInArray, updateVariables, variables, loading, type, schema, schemaType } = nodeListContext || queryVariablesContext;
  const relatedSchema = useMemo(() => getSchemaAtPath(schema, `${type}.${path}`), [path, schema, type]);
  const relatedType = relatedSchema.name;
  const fullPath = `${rootPath}${rootPath && '.'}${path}`;
  const hasMany = schemaType.fields[path] ? schemaType.fields[path].hasMany : false;

  const handleChange = (value) => {
    if (hasMany) {
      if (!value) value = [];
      value = value.map((node) => node.id);

      updateVariables({
        reducer: (state) => {
          return produce(state, (draft) => {
            let boolArray = _.get(draft, `${rootPath}._${boolOp}`, []);
            boolArray = boolArray.filter((c) => !_.get(c, `${path}.${relatedType}.id._eq`));
            boolArray = boolArray.concat(value.map((v) => _.set({}, `${path}.${relatedType}.id._eq`, v)));
            _.set(draft, `${rootPath}._${boolOp}`, boolArray);
          });
        },
      });
    } else {
      if (!value) value = [];
      value = value.map((node) => node.id);
      if (!value.length) value = undefined;

      if (value) {
        updateVariables({ path: `${fullPath}.id._in`, value });
      } else {
        updateVariables({ path: `${fullPath}.id._in`, value: undefined });
      }
    }
  };

  const clear = (event) => {
    handleChange([]);
  };

  const classes = useStyles();
  let valueIds;

  if (hasMany) {
    valueIds = _.get(variables, `${rootPath}._${boolOp}`, []).map((c) => _.get(c, `${path}.${relatedType}.id._eq`)).filter((id) => id !== undefined);
  } else {
    valueIds = _.get(variables, `${fullPath}.id._in`, []);
  }
  const value = valueIds.map((id) => {
    const hash = `${relatedType}:${id}`;
    return client.cache.data.data[hash];
  });

  return (
    <div className={classNames(classes.root, className)}>
      <NodeSelector value={value} type={relatedType} isMulti onChange={handleChange} label={label} {...nodeSelectorProps} />
    </div>
  );
};

NodeFilter.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  rootPath: PropTypes.oneOf(['where', 'args.where']),
};

NodeFilter.defaultProps = {
  label: 'Is one of these:',
  className: '',
  rootPath: 'where',
};

export default NodeFilter;
export { NodeFilter };
