import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Hidden } from '@material-ui/core';
import EditIcon from 'mdi-material-ui/Pencil';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const EditButton = ({ children, className, hideLabelBreakpoint, icon: Icon, ...rest }) => {
  const classes = useStyles();

  let LabelWrapper = React.Fragment;
  const labelWrapperProps = {};
  if (hideLabelBreakpoint) {
    LabelWrapper = Hidden;
    labelWrapperProps[hideLabelBreakpoint] = true;
  }

  return (
    <Button className={classNames(className, classes.root)} color="primary" variant="contained" {...rest}>
      <Icon /><LabelWrapper {...labelWrapperProps}><span>{children}</span></LabelWrapper>
    </Button>
  );
};

EditButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hideLabelBreakpoint: PropTypes.string,
  icon: PropTypes.elementType,
};
EditButton.defaultProps = {
  children: null,
  className: '',
  hideLabelBreakpoint: undefined,
  icon: EditIcon,
};

export default EditButton;
