import React, { } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Pie } from 'react-chartjs-2';
import _ from 'lodash';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';

import { useLog } from 'src/kiska/components/contexts/LogContext';
import { getColorForKey, getDefaultChartOptions } from './utils';
import { formatCurrency, formatDuration } from 'src/utils';
import { useNodeList } from 'src/kiska/components/NodeList';
import { useWorkEntryChartData } from './WorkEntryChartDataContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0),
    backgroundColor: theme.palette.background.default,
  },
  canvasContainer: {
    width: '99%', // bug in how Chart.js calculates resize events, don't work at 100%??
    position: 'relative',
  },
}));

const tooltipLabel = (item, { datasets }) => {
  const set = datasets[item.datasetIndex];
  let valueLabel = set.labels[item.index];
  let value = set.data[item.index];
  if (set.name === 'hours') value = `${formatDuration(value)} hours`
  if (set.name === 'price') value = `$${formatCurrency(value)}`
  if (set.name === 'cost') value = `$${formatCurrency(value)}`
  return `${valueLabel}: ${value}`;
};

const tooltipTitle = (item, { datasets }) => {
  const set = datasets[item[0].datasetIndex];
  const setLabel = datasets.length > 1 ? `${set.label}` : undefined;

  return setLabel;
};



const HoursPieChart = (props) => {
  const { className, groupBy, height, title, enabledSets, papered } = props;
  const log = useLog();
  const classes = useStyles(props);
  const theme = useTheme();
  const defaultChartOptions = getDefaultChartOptions(theme);
  const allChartData = useWorkEntryChartData();

  const chartDataGrouping = _.get(allChartData, `pie.${groupBy}`);

  const chartData = chartDataGrouping 
  ? {
    ...chartDataGrouping,
    datasets: chartDataGrouping.datasets.filter((set) => enabledSets.includes(set.name)),
  }
  : null;

  if(!chartData){
    return null;
  }

  const options = _.merge({}, defaultChartOptions, {
    legend: {
      // display: chartData.datasets.length === 1,
      align: chartData.datasets.length <= 5 ? 'center' : 'start',
    },
    cutoutPercentage: 50 / chartData.datasets.length,
    animation: {
      animateRotate: true,
      animateScale: true,
    },
    scales: null,
    tooltips: {
      callbacks: { label: tooltipLabel, title: tooltipTitle },
    },
  });

  const Component = papered ? Paper : 'div';

  return (
    <Component className={classNames(classes.root, className)} >
      {title && <Typography variant="h6" align="center" color="textSecondary" >{title}</Typography>}
      <div className={classes.canvasContainer} style={{ height }}>
        <Pie options={options} data={chartData} />
      </div>
    </Component>
  );
};

HoursPieChart.propTypes = {
  groupBy: PropTypes.oneOf(['user', 'job', 'task', 'customer', 'payRate']).isRequired,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string,]),
  title: PropTypes.string,
  enabledSets: PropTypes.array,
  papered: PropTypes.bool,
};
HoursPieChart.defaultProps = {
  className: undefined,
  height: 200,
  title: undefined,
  papered: true,
  enabledSets: ['hours', 'cost', 'price'],
};

export default HoursPieChart;
