import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useServiceWorker } from 'src/ServiceWorkerContext';
import CancelIcon from 'mdi-material-ui/Cancel';
import { Check as CheckIcon } from '@material-ui/icons';
import { Dialog } from './Dialog';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const UpdateConfirmDialog = (props) => {
  const { nagInterval } = props;
  const [open, setOpen] = useState(false);
  const { update, updateAvailable, isUpdating } = useServiceWorker();
  const [prevNag, setPrevNag] = useState(null);
  const classes = useStyles(props);
  const interval = useRef(null);

  const handleUpdate = () => {
    update();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const nag = useCallback(() => {
    if (!updateAvailable) return;
    if (open) return;
    if (prevNag && ((new Date()).getTime() < (prevNag + nagInterval))) return;

    setOpen(true);
    setPrevNag((new Date()).getTime());
  }, [nagInterval, open, prevNag, updateAvailable]);

  useEffect(() => {
    interval.current = setInterval(nag, 3000);
    return () => {
      clearInterval(interval.current);
    };
  }, [nag]);

  return (
    <Dialog onClose={handleCancel} closeOnBackdropClick={false} maxWidth="xs" closeOnEscape={false} open={open} title="Update Available!" fullScreen={false} showCloseButton={false}>
      <DialogContent>
        <Typography variant="body1" align="left">
          Slick Systems has been updated and this is now an out-of-date version.<br /><br />
          Updating only takes a few seconds and it is highly recommended that you update as soon as possible.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="default" onClick={handleCancel} disabled={false}><CancelIcon /><span>Later</span></Button>
        {isUpdating && <CircularProgress size={40} />}
        <Button variant="contained" color="primary" onClick={handleUpdate} disabled={isUpdating}><CheckIcon /><span>Update Now</span></Button>
      </DialogActions>
    </Dialog>
  );
};

UpdateConfirmDialog.propTypes = {
  nagInterval: PropTypes.number,
};
UpdateConfirmDialog.defaultProps = {
  nagInterval: 1000 * 60 * 60 * 4,
};

export { UpdateConfirmDialog };
