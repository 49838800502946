import React, { useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Collapse, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { TransitionGroup } from 'react-transition-group';
import { Notifications as NotificationIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useServiceWorker } from 'src/ServiceWorkerContext';
import LightbulbIcon from 'mdi-material-ui/LightbulbOn';
import _ from 'lodash';
import mobile from 'is-mobile';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { useNodes } from 'src/kiska/hooks/useNode';
import { parseISO } from 'date-fns';
import { Notification } from './Notification';

const isMobile = mobile({ tablet: true, featureDetect: true });

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 2, 0, 2),
    flex: '0 !important',
    display: 'flex',
    flexDirection: 'column !important',
  },
  notification: {
    margin: theme.spacing(0, 0),
    overflow: 'hidden',
    '&:last-child': {
      marginBottom: theme.spacing(1.5),
    },
  },
}));

const SNOOZE_MUTATION = gql`
  mutation UpdateNotification($date: timestamptz!, $id: String!){
    update_notification_by_pk(
      pk_columns: {id: $id}
      _set: {
        snoozedUntil: $date,
      }
    ){
      data
    }
  }
`;

const DISMISS_MUTATION = gql`
  mutation UpdateNotification($date: timestamptz!, $id: String!){
    update_notification_by_pk(
      pk_columns: {id: $id}
      _set: {
        dismissedAt: $date,
      }
    ){
      data
    }
  }
`;

const NotificationsBanner = (props) => {
  const { className } = props;
  const [snoozeMutation] = useMutation(SNOOZE_MUTATION);
  const [dismissMutation] = useMutation(DISMISS_MUTATION);
  const classes = useStyles(props);
  const { user } = useUser();
  const { updateAvailable, update, isUpdating } = useServiceWorker();
  const [installPwaPrompt, setInstallPwaPrompt] = useState(null);
  const { nodes } = useNodes({
    type: 'notification',
    orderBy: [{ createdAt: 'desc' }],
    where: {
      userId: { _eq: user.id },
      dismissedAt: { _is_null: true },
      channels: { _has_key: 'in-app' },
    },
  });

  const notifications = nodes.filter((n) => {
    if (!n.snoozedUntil) return true;
    const snoozedUntil = parseISO(n.snoozedUntil);
    const now = new Date();
    if (snoozedUntil <= now) return true;
    return false;
  });

  useEffect(() => {
    let timeout;

    const checkForPrompt = () => {
      const prompt = _.get(window, 'KISKA_deferredA2HSPrompt');
      if (prompt) {
        setInstallPwaPrompt(prompt);
      } else {
        timeout = setTimeout(checkForPrompt, 10 * 1e3);
      }
    };

    checkForPrompt();

    return () => clearTimeout(timeout);
  }, [installPwaPrompt]);

  const handleSnooze = ({ notificationId, date }) => {
    snoozeMutation({ variables: { id: notificationId, date } })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDismiss = ({ notificationId, date }) => {
    dismissMutation({ variables: { id: notificationId, date } })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleInstallAppClick = () => {
    // Show the prompt
    window.KISKA_deferredA2HSPrompt.prompt();

    // Wait for the user to respond to the prompt
    window.KISKA_deferredA2HSPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      setInstallPwaPrompt(null);
      window.KISKA_deferredA2HSPrompt = null;
    });
  };

  return (
    <TransitionGroup className={classNames(classes.root, className)} appear>
      {isMobile && !!installPwaPrompt && (
        <Collapse timeout={1000} key="update">
          <Alert
            className={classes.notification}
            elevation={3}
            severity="success"
            icon={<LightbulbIcon fontSize="inherit" />}
            action={(
              <Button color="inherit" variant="outlined" size="small" onClick={handleInstallAppClick}>
                Install App
              </Button>
            )}
          >
            Slick Systems is better as an app.
          </Alert>
        </Collapse>
      )}
      {updateAvailable && (
        <Collapse timeout={1000} key="update">
          <Alert
            className={classes.notification}
            elevation={3}
            severity="warning"
            icon={<NotificationIcon fontSize="inherit" />}
            action={(
              <Button color="inherit" variant="outlined" size="small" onClick={update}>
                {isUpdating ? <CircularProgress size="1.5em" /> : 'Update Now'}
              </Button>
            )}
          >
            An update is availbe for Slick Systems. Please update as soon as possible.
          </Alert>
        </Collapse>
      )}
      {notifications.map((notification) => (
        <Collapse timeout={1000} key={notification.id}>
          <Notification
            onSnooze={handleSnooze}
            onDismiss={handleDismiss}
            notification={notification}
            className={classes.notification}
          />
        </Collapse>
      ))}
    </TransitionGroup>
  );
};

NotificationsBanner.propTypes = {
};
NotificationsBanner.defaultProps = {
};

export { NotificationsBanner };
