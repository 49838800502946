import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { NodeListContext } from '.';

class ResultsCount extends Component {
  static contextType = NodeListContext

  static propTypes = {
    verbose: PropTypes.bool,
  }

  static defaultProps = {
    verbose: true,
  }

  render() {
    const { variables: { offset, limit }, schemaType } = this.context;
    const count = this.context.count || 0;
    const { verbose, ...rest } = this.props;
    const pageCount = Math.min(limit + offset, count);
    const currentPage = pageCount ? offset + 1 : 0;

    return (
      <Typography variant="body2" color="textSecondary" {...rest}>
        {verbose ? `Showing ${schemaType.lowerCasePluralLabel} ` : null}
        {currentPage} - {pageCount} of {count}
      </Typography>
    );
  }
}

export default ResultsCount;
