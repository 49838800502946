import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { useNode, useNodes } from 'src/kiska/hooks/useNode';
import _ from 'lodash';
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { useLog } from 'src/kiska/components/contexts/LogContext';
import { ThemeProvider } from 'src/kiska/components/contexts/ThemeProviderContext';
import { JobUpdateForm } from 'src/components/modules/job/JobUpdateForm';
import { move, reorder } from './utils';
import { DraggableJob } from './DraggableJob';
import { Toolbar } from './Toolbar';

const groupingQueryString = `
  query FetchJobBoardGrouping{
    jobBoard: grouping_by_pk(id: "job-board"){
      id type groups
    }
  }
`;
const GROUPING_QUERY = gql`${groupingQueryString}`;
const GROUPING_SUBSCRIPTION = gql`${groupingQueryString.replace('query', 'subscription')}`;

const UPDATE_GROUPING_MUTATION = gql`
  mutation UpdateJobBoardGroups (
    $newGroups: jsonb!
  ){
    updateGrouping: update_grouping_by_pk(
      pk_columns: {id: "job-board"}
      _set: {
        groups: $newGroups,
      }
    ) { id type groups }
  }
`;

const INSERT_GROUPING_MUTATION = gql`
    mutation InsertJobBoardGrouping (
      $newGroups: jsonb!
    ){
      insertGrouping: insert_grouping_one(
        object: {
          id: "job-board"
          type: "job"
          groups: $newGroups,
        }
      ) { id type groups }
    }
`;

const UPDATE_JOB_STATUS_MUTATION = gql`
  mutation UpdateJobStatus (
    $id: String!
    $status: String!
  ){
    updateJob: update_job_by_pk(
      pk_columns: {id: $id}
      _set: {
        status: $status,
      }
    ) { id status }
  }
`;

const useStyles = makeStyles((theme) => {
  const headerHeight = 48;
  const footerHeight = 0;
  const backgroundColor = '#FFF';

  return {
    root: {
      flex: 'none !important',
      position: 'relative',
      display: 'flex !important',
      flexDirection: 'column !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      height: `calc(100vh - ${headerHeight}px)`,
      backgroundColor,
      [theme.breakpoints.down('md')]: {
        // height: `calc(100vh - ${headerHeight}px)`,
        height: 'auto',
      },
      // overflowY: 'hidden',
    },
    groups: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexDirection: 'row',
      flex: 1,
      width: '100%',
      height: `calc(100vh - ${headerHeight + footerHeight}px)`,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto',
      },
    },
    group: {
      flex: 1,
      overflowY: 'auto',
      overflowX: 'hidden',
      backgroundColor: `hsla(0, 0%, 98%, 100%)`,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      '&.draggingOver': {
        // backgroundColor: `hsla(0, 0%, 96%, 100%)`,
      },
    },
    actions: {
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: 0,
      height: footerHeight,
      [theme.breakpoints.down('md')]: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        left: 0,
      },
    },
    toolbar: {
      padding: theme.spacing(1, 0),
    },
    bins: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bin: {
      // width: `48px !important`,
      height: 48,
      color: '#FFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      padding: theme.spacing(0, 1),
    },
    binDroppable: {
      zIndex: 10000,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      borderRadius: 8,
      overflow: 'hidden',
      backgroundColor: `hsla(0, 100%, 50%, 20%)`,
      border: `dashed 2px hsla(0, 0%, 0%, 30%)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&.draggingOver': {
        backgroundColor: `hsla(0, 100%, 50%, 60%)`,
        // height: 200,
        // left: `calc(50% - 150px)`,
        // width: `300px !important`,
      },
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flex: 1,
    },
    binLabel: {
      color: 'hsla(0, 0%, 0%, 50%)',
      textTransform: 'uppercase',
      zIndex: 1000,
      transform: 'rotate(0deg)',
    },
  };
});

const JobBoard = (props) => {
  const { enableEdit } = props;
  const [activeUpdateNode, setActiveUpdateNode] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const classes = useStyles(props);
  const [updateJobStatusMutate] = useMutation(UPDATE_JOB_STATUS_MUTATION);
  const [updateGroupingMutate] = useMutation(UPDATE_GROUPING_MUTATION);
  const [insertGroupingMutate] = useMutation(INSERT_GROUPING_MUTATION);
  const log = useLog();
  const groupingQueryResult = useQuery(GROUPING_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const groupingSubscriptionResult = useSubscription(GROUPING_SUBSCRIPTION, {
    fetchPolicy: 'cache-and-network',
  });
  const { nodes: jobNodes, result: { loading: jobsLoading } } = useNodes({
    type: 'job',
    where: { status: { _neq: 'archived' } },
    selections: `
      id shortId title specs type status
      customer {id name company address1 address2 email phone city state postalCode country notes}
      tasks { id task { id label } }
    `,
  });

  const jobs = jobNodes;

  const jobMap = useMemo(() => {
    const map = {};
    jobs.forEach((job) => { map[job.id] = job; });
    return map;
  }, [jobs]);

  const grouping = _.get(groupingQueryResult, 'data.jobBoard');
  const groupsLoading = groupingQueryResult.loading && !groupingQueryResult.loading;
  let groups;
  if (grouping) {
    groups = [...grouping.groups];
  } else {
    groups = [jobs.map((job) => ({
      id: job.id,
    }))];
  }

  groups.forEach((group, index) => {
    groups[index] = group.filter((item) => item && item.id);
  });

  const groupedJobsMap = useMemo(() => {
    const map = {};
    groups.forEach((group) => group.forEach((item) => {
      map[item.id] = item;
    }));
    return map;
  }, [groups]);

  const updateJobStatus = useCallback((id, status) => {
    const variables = { id, status };
    const optimisticResponse = {
      __typename: 'Mutation',
      updateGrouping: {
        id,
        __typename: 'job',
        status,
      },
    };

    updateJobStatusMutate({ variables, optimisticResponse })
      .then(({ error }) => {
        if (error) {
          log.error('Error updating job status: ', { variables, error });
        }
      }).catch((error) => {
        log.error('Error updating job status: ', { variables, error });
      });
  }, [log, updateJobStatusMutate]);

  const setGroups = useCallback((newGroups) => {
    let mutate;
    const op = groupingQueryResult.data && groupingQueryResult.data.jobBoard ? 'update' : 'insert';
    if (op === 'update') mutate = updateGroupingMutate;
    else mutate = insertGroupingMutate;

    const optimisticResponse = op === 'update' ? {
      __typename: 'Mutation',
      updateGrouping: {
        id: 'job-board',
        __typename: 'grouping',
        groups: newGroups,
        type: 'job',
      },
    } : undefined;

    mutate({
      variables: { newGroups },
      optimisticResponse,
    }).then(({ error }) => {
      if (error) {
        log.error('Error updating job board groups: ', { newGroups, error });
      }
    }).catch((error) => {
      log.error('Error updating job board groups: ', { newGroups, error });
    });
  }, [groupingQueryResult.data, insertGroupingMutate, log, updateGroupingMutate]);

  useEffect(() => {
    if (groupingQueryResult.loading || jobsLoading) return;
    // Check for jobs that are not included in the grouping and add them
    const missingJobs = jobs.filter((job) => !groupedJobsMap[job.id]);
    if (missingJobs.length) {
      const newGroups = [...groups];
      missingJobs.forEach((job) => {
        newGroups[0] = [...newGroups[0], { id: job.id }];
      });
      setGroups(newGroups);
    }

    // Check for jobs that shouldn't be in groups
    const jobsToRemove = _.filter(groupedJobsMap, (item, id) => !jobMap[id]);
    if (jobsToRemove.length) {
      const newGroups = groups.map((group) => group.filter((item) => !jobsToRemove.find((job) => job.id === item.id)));
      setGroups(newGroups);
    }
  }, [groupedJobsMap, groupingQueryResult.loading, groups, jobMap, jobs, jobsLoading, setGroups]);

  const handleDragEnd = useCallback((result) => {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) return;

    const isBin = destination.droppableId.startsWith('bin:');

    if (isBin) {
      const status = destination.droppableId.replace(/^bin:/, '');
      updateJobStatus(draggableId, status);
      return;
    }

    const sourceGroupIndex = +source.droppableId;
    const destGroupIndex = +destination.droppableId;
    let newGroups;

    if (sourceGroupIndex === destGroupIndex) {
      // Re-order within same group
      const newItems = reorder(groups[sourceGroupIndex], source.index, destination.index);
      newGroups = [...groups];
      newGroups[sourceGroupIndex] = newItems;
    } else {
      // Move to different group
      const changedGroups = move(groups[sourceGroupIndex], groups[destGroupIndex], source, destination);
      newGroups = [...groups];
      newGroups[sourceGroupIndex] = changedGroups[sourceGroupIndex];
      newGroups[destGroupIndex] = changedGroups[destGroupIndex];
    }

    setGroups(newGroups);
  }, [groups, setGroups, updateJobStatus]);

  const handleAddColumn = useCallback(() => {
    const newGroups = [...groups, []];
    setGroups(newGroups);
  }, [groups, setGroups]);

  const handleRemoveColumn = useCallback(() => {
    if (groups.length < 2) return;

    const newGroups = [...groups];
    newGroups[groups.length - 2] = groups[groups.length - 2].concat(groups[groups.length - 1]);
    newGroups.pop();

    setGroups(newGroups);
  }, [groups, setGroups]);

  const handleAddJob = () => {
    setActiveUpdateNode(null);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setActiveUpdateNode(null);
  };

  const handleUpdate = (job) => () => {
    setUpdateDialogOpen(true);
    setActiveUpdateNode(job);
  };

  return (
    <div className={classes.root}>
      {enableEdit && (
        <JobUpdateForm
          id={activeUpdateNode && activeUpdateNode.id}
          open={updateDialogOpen}
          onClose={handleUpdateDialogClose}
          onNodeChange={(node) => setActiveUpdateNode(node)}
        />
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={classes.groups}>
          {groupsLoading || !jobs.length
            ? (
              <div className={classes.loading}>
                <CircularProgress size={100} thickness={2} />
                <Typography variant="body1" color="textSecondary" align="center">
                  <br /><br />
                  Waiting for jobs...
                </Typography>
              </div>
            )
            : groups.map((group, groupIndex) => (
              <Droppable key={groupIndex} droppableId={`${groupIndex}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={classNames(classes.group, snapshot.isDraggingOver && 'draggingOver')}
                    {...provided.droppableProps}
                  >
                    {group.map((item, index) => {
                      const job = jobMap[item.id];
                      if (!job) return null;
                      return (
                        <React.Fragment key={item.id}>
                          <ThemeProvider initialActiveThemeName="light">
                            <DraggableJob item={item} index={index} job={job} onUpdate={handleUpdate(job)} />
                          </ThemeProvider>
                        </React.Fragment>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
        </div>
        {enableEdit && (
          <div className={classes.actions}>
            {/* <div /> */}
            <Toolbar onAddColumn={handleAddColumn} onRemoveColumn={handleRemoveColumn} onAddJob={handleAddJob} className={classes.toolbar} />
            {/* <div className={classes.bins}>
              <div className={classes.bin}>
                <Typography variant="body1" align="center" color="inherit" className={classes.binLabel}>Archive</Typography>
                <Droppable droppableId="bin:archived">
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        className={classNames(classes.binDroppable, snapshot.isDraggingOver && 'draggingOver', snapshot.isDragging && 'dragging')}
                        {...provided.droppableProps}
                      >
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div> */}
          </div>
        )}
      </DragDropContext>
    </div>
  );
};

JobBoard.defaultProps = {
  enableEdit: true,
};

export { JobBoard };
