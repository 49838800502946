import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import _ from 'lodash';
import Switch from '@material-ui/core/Switch';

import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

class BooleanField extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    label: PropTypes.string,
    initialValue: PropTypes.bool,
    switchy: PropTypes.bool,
    schemaExtensions: PropTypes.object,
  }

  static defaultProps = {
    helperText: undefined,
    label: '',
    initialValue: undefined,
    switchy: true,
    schemaExtensions: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;

    registerField(props.name, { initialValue: props.initialValue, schemaExtensions: props.schemaExtensions });
  }

  handleChange = (event) => {
    const { onChange } = this.context;
    const { name } = this.props;
    const value = event.target.checked;

    onChange(name, value);
  }

  render() {
    const { name, classes, helperText, label: propsLabel, switchy } = this.props;
    const { getFieldValue, getFieldErrors, pendingMutation, schema, type } = this.context;
    const schemaField = schema.types[type].fields[name];
    const label = propsLabel || schemaField.label;
    const value = getFieldValue(name);
    const errors = getFieldErrors(name);

    const Component = switchy ? Switch : Checkbox;

    return (
      <FormControl error={!!errors.length} disabled={pendingMutation} className={classes.root}>
        <FormGroup>
          <FormControlLabel
            control={
              <Component checked={value} onChange={this.handleChange} value={name} color="primary" />
            }
            label={label}
          />
        </FormGroup>
        <HelperText text={helperText} errors={errors} />
      </FormControl>
    );
  }
}

BooleanField = _.flow(
  withStyles(styles),
)(BooleanField);

BooleanField.displayName = 'BooleanField';

export default BooleanField;
