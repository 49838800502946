import { startOfDay, parseISO, formatISO } from 'date-fns';
import _ from 'lodash';

const groupWorkEntries = (nodes, optionsArg) => {
  const options = {
    groupByCustomer: false,
    groupByJob: false,
    groupByUser: false,
    ...optionsArg,
  }

  let groups = {};

  // Group all nodes by time
  nodes.forEach((node) => {
    const startOfPeriod = formatISO(startOfDay(parseISO(node.minStart)));
    const timeGroupingKey = startOfPeriod;
    let group = groups[timeGroupingKey];

    if (!group) {
      group = {
        startOfPeriod,
        sumDuration: 0,
        sumCost: 0,
        sumPrice: 0,
        entries: [],
        users: options.groupByUser && {},
        customers: options.groupByCustomer && {},
        jobs: options.groupByJob && {},
      };
      groups[timeGroupingKey] = group;
    }

    if (options.groupByUser) {
      group.users[node.userId] = node;
    } else if (options.groupByCustomer) {
      group.customers[node.customerId] = node;
    } else if (options.groupByJob) {
      group.jobs[node.jobId] = node;
    }

    group.entries.push(node);
    group.sumDuration += node.sumDuration;
    group.sumCost += node.sumCost;
    group.sumPrice += node.sumPrice;
  });

  groups = _.map(groups, (group) => group);

  return { groups };
};

export { groupWorkEntries };
