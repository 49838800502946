import React, { createContext, useContext } from 'react';

export const LocalNodeContext = createContext();
export const useLocalNode = () => useContext(LocalNodeContext);

export const LocalNodeProvider = ({ children, node, type }) => {
  return (
    <LocalNodeContext.Provider value={{ node, type }}>
      {children}
    </LocalNodeContext.Provider>
  );
};

export const withLocalNode = (WrappedComponent) => (props) => (
  <LocalNodeContext.Consumer>
    {(value) => <WrappedComponent node={value.node} type={value.type} {...props} />}
  </LocalNodeContext.Consumer>
);
