import React, { } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography, Link } from '@material-ui/core';
// import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
import { CustomerUpdateForm } from '../modules/customer/CustomerUpdateForm';
import { SimpleTable } from './settings/SimpleTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    padding: theme.spacing(0.25, 1),
  },
}));

const CustomerList = ({ fixtures: propsFixtures, updateFixtures, hideCreateButton, insertFixtures, updateFormProps }) => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const md = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const classes = useStyles();
  const initial = {
    limit: 50,
    orderBy: [
      { updatedAt: 'desc' },
    ],
    where: {
    },
  };

  const fixtures = [
    ...(propsFixtures || []),
    { path: 'where.systemTags.system_tag.id._nin', value: ['contact:contact'] },
  ];

  const searchPaths = 'name company email phone';
  const searchPlaceholder = 'Search by company, name, email, or phone';

  const columns = [];

  if (md) {
    columns.push({
      fieldName: 'company',
    });
    columns.push({
      fieldName: 'name',
    });
  } else {
    columns.push({
      label: 'Company',
      fieldName: 'company',
      Renderer: ({ parentNode: node }) => (
        <>
          <Typography variant="body1" color="textPrimary">{node.company || node.name}</Typography>
          {node.company && node.name && <Typography variant="body2" color="textSecondary">{node.name}</Typography>}
        </>
      ),
    });
  }

  if (lg) {
    columns.push({
      label: 'Phone',
      fieldName: 'phone',
      Renderer: ({ value }) => value && <Link href={`tel:${value}`}>{value}</Link>,
    });
    columns.push({
      label: 'Email',
      fieldName: 'email',
      Renderer: ({ value }) => value && <Link href={`mailto:${value}`}>{value}</Link>,
    });
  }

  if (lg) {
    columns.push({
      label: 'Active Jobs',
      fieldName: 'activeJobs',
      Renderer: ({ parentNode: node }) => {
        return (
          <>
            <Typography variant="h6" color="textPrimary">
              {node.activeJobs.aggregate.count}
            </Typography>
          </>
        );
      },
      orderByPath: 'activeJobs.aggregate.count',
    });
    columns.push({
      label: 'Archived Jobs',
      fieldName: 'inactiveJobs',
      Renderer: ({ parentNode: node }) => {
        return (
          <>
            <Typography variant="h6" color="textSecondary">
              {node.inactiveJobs.aggregate.count}
            </Typography>
          </>
        );
      },
      orderByPath: 'inactiveJobs.aggregate.count',
    });
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="center" gutterBottom>Customers</Typography>
      <SimpleTable
        searchPlaceholder={searchPlaceholder}
        searchPaths={searchPaths}
        type="contact"
        initial={initial}
        fixtures={fixtures}
        insertFixtures={insertFixtures}
        updateFixtures={updateFixtures}
        addLabel="Add Customer"
        columns={columns}
        UpdateForm={CustomerUpdateForm}
        updateFormProps={updateFormProps}
        clickUrl={(node) => `/app/customers/${node.id}`}
        actionsOnBottom={false}
        actionsOnTop={false}
        enableAdd={!hideCreateButton}
        floatingAdd
        enableEdit
        showSearchBox
      />
    </div>
  );
};

CustomerList.propTypes = {
  fixtures: PropTypes.any,
  hideCreateButton: PropTypes.bool,
  insertFixtures: PropTypes.object,
  updateFixtures: PropTypes.object,
  updateFormProps: PropTypes.object,
};
CustomerList.defaultProps = {
  fixtures: undefined,
  hideCreateButton: false,
  insertFixtures: undefined,
  updateFixtures: undefined,
  updateFormProps: undefined,
};

export { CustomerList };
