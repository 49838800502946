import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { FormLabel, Menu, MenuItem, IconButton, ListItemIcon, Button, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const SimpleMenu = ({ trigger, items, onChange }) => {
  const classes = useStyles();
  const popupState = usePopupState({ variant: 'popover' });

  const handleClick = (item) => (event) => {
    popupState.close();
    if (item.onClick) item.onClick(event, item);
    if (onChange) {
      onChange(item);
    }
  };

  return (
    <>
      {cloneElement(trigger, bindTrigger(popupState))}
      <Menu {...bindMenu(popupState)}>
        {items.map((item) => {
          const { value, label, icon, key, id, selected, primary, secondary } = item;
          return (
            <MenuItem
              key={key || id || primary || label}
              onClick={handleClick(item)}
              selected={selected}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={primary || label} secondary={secondary} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

SimpleMenu.propTypes = {
  trigger: PropTypes.node.isRequired,
  items: PropTypes.array,
  onChange: PropTypes.func,
};
SimpleMenu.defaultProps = {
  items: [],
  onChange: undefined,
};

export default SimpleMenu;
