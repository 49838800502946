import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getWorkEntriesTotals } from 'src/utils';
import { useNodeList } from 'src/kiska/components/NodeList';
import { Grid } from 'src/kiska/components/Grid';
import { Stat } from '../Stat';

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: 0,
  },
  item: {
    '& > div': {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1, 1, 1),
    },
  },
}));

const ReportTotals = (props) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const classes = useStyles(props);
  const { variables, nodes } = useNodeList();
  const { displayParams } = props;
  const totals = useMemo(() => getWorkEntriesTotals(nodes), [nodes]);

  return (
    <>
      <Grid item xs={12} md={4} className={classes.item}>
        {displayParams.showFinancials && (
          <Paper>
            <Stat label="Total Cost" value={totals.cost} currency />
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} md={4} className={classes.item}>
        {displayParams.showFinancials && (
          <Paper>
            <Stat label="Total Price" value={totals.price} currency />
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} md={4} className={classes.item}>
        <Paper>
          <Stat label="Total Hours" value={totals.hours} />
        </Paper>
      </Grid>
    </>
  );
};

ReportTotals.propTypes = {
};
ReportTotals.defaultProps = {
};

export { ReportTotals };
