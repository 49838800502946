import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Hidden, Typography, Grid } from '@material-ui/core';
import { PlayArrow as PlayIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import classNames from 'classnames';
import { findTaskOptionsForUserOnJob } from 'src/utils';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { formatRelative, isSameDay, parseISO, format } from 'date-fns';
import { SelectDialog } from './SelectDialog';
import { useWorkEntryControls } from '../../work_entry/useWorkEntryControls';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: green[500],
    color: '#FFF',
    textTransform: 'none',
    textAlign: 'left',
    position: 'relative',

    '& p': {
      textAlign: 'left',
    },

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },

    '&:hover': {
      backgroundColor: green[700],
    },
    '&.big': {
      width: '100%',
      minHeight: 50,
      padding: theme.spacing(1, 2, 0.5, 0.5),

    },
  },
  icon: {
    '&.big': {
      fontSize: 30,
    },
  },
  label: {
    flex: 1,
    '&.big': {

    },
  },
  scheduledDate: {
    marginBottom: 0,
    width: '100%',
    lineHeight: 1,
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.2,
    color: '#FFF',
    '&.big': {
      fontSize: 18,
    },
  },
  customer: {
    fontWeight: 400,
    lineHeight: 1,
    color: '#383838',
    '&.big': {
      fontSize: 16,
    },
  },
  lastEntry: {
    // border: 'solid 1px hsla(0, 100%, 0%, .15)',
    // backgroundColor: 'hsla(0, 100%, 0%, .1)',
    borderRadius: 4,
    // padding: theme.spacing(0, 0.5),
    marginTop: 2,
    color: `hsla(0, 100%, 100%, .7)`,
  },
  currentlyBeingWorkedOn: {
    position: 'absolute',
    top: 4,
    right: 4,
    backgroundColor: red[500],
    width: 10,
    height: 10,
    borderRadius: '50%',
    animation: `$throb 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite`,
  },
  '@keyframes throb': {
    '0%': {
      opacity: 1,
    },
    '20%': {
      opacity: 0.1,
    },
    '60%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

const StartJobButton = (props) => {
  const { user } = useUser();
  const {
    switchToJob,
    isCurrentlyWorkingOnJob,
    pendingMutation,
  } = useWorkEntryControls();
  const classes = useStyles(props);
  const { job, hideLabelBreakpoint, showCustomer, showLabel, showScheduledDate, event, className, size, showLastEntry } = props;
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const taskOptions = findTaskOptionsForUserOnJob(user, job).map((o) => ({ ...o, Icon: PlayIcon }));

  // If there's no intersection between user's pay rates' tasks and the job's tasks then don't show the start button
  if (!taskOptions.length) return null;

  // If user is already working on job then don't show button
  if (isCurrentlyWorkingOnJob(job)) return null;

  const handleTaskDialogClose = (taskId) => {
    setTaskDialogOpen(false);
    if (!taskId) return;

    setSelectedTaskId(taskId);

    const taskOption = taskOptions.find((option) => option.id === taskId);
    if (!taskOption) {
      console.error(`User "${user.displayName}" has no pay rate for task "${taskId}".`);
      return;
    }
    switchToJob(job, { taskId, payRate: taskOption.payRate });
  };

  const handleClick = () => {
    if (!job.tasks.length) {
      console.error(`Job "${job.title}" has no tasks.`);
      return;
    }

    if (taskOptions.length === 1) {
      switchToJob(job, { taskId: taskOptions[0].id, payRate: taskOptions[0].payRate });
    } else {
      // User can do multiple tasks in job
      setTaskDialogOpen(true);
    }
  };

  let LabelWrapper = React.Fragment;
  const labelWrapperProps = {};
  if (hideLabelBreakpoint) {
    LabelWrapper = Hidden;
    labelWrapperProps[hideLabelBreakpoint] = true;
  }

  let lastEntry = job.lastWorkEntry[0];
  let currentlyBeingWorkedOn = false;
  if (lastEntry) {
    if (!lastEntry.end) currentlyBeingWorkedOn = true;
    const date = lastEntry.end ? parseISO(lastEntry.end) : new Date();
    const dateStr = formatRelative(date, new Date());
    // format(parseISO(lastEntry.end || lastEntry.start), `EEE MMM d, h:mmaaaaa'm`);
    lastEntry = `Last work: ${dateStr} by ${lastEntry.user.displayName}`;
  }

  let scheduledDate;
  if (event) {
    const start = parseISO(event.start);
    const end = parseISO(event.end);
    if (isSameDay(start, end)) {
      scheduledDate = `${format(start, `h:mmaaaaa'm`)} - ${format(end, `h:mmaaaaa'm`)}`;
    } else {
      scheduledDate = `${format(start, `MMM d`)} - ${format(end, `MMM d`)}`;
    }
  }

  return (
    <>
      <SelectDialog title="Which Task?" selectedValue={selectedTaskId} open={taskDialogOpen} onClose={handleTaskDialogClose} options={taskOptions} />

      <Button variant="contained" className={classNames(classes.root, size, className)} onClick={handleClick} disabled={pendingMutation}>
        <PlayIcon className={classNames(classes.icon, size)} />
        {showLabel && (
          <LabelWrapper {...labelWrapperProps}>
            <div className={classNames(classes.label, size)}>
              {showScheduledDate && (
                <Typography variant="body1" color="textPrimary" className={classes.scheduledDate}>{scheduledDate}</Typography>
              )}
              <Typography variant="body1" color="textPrimary" className={classNames(classes.title, size)}>{job.title}</Typography>
              {showCustomer && <Typography variant="body2" color="textSecondary" className={classNames(classes.customer, size)}>{job.customer.company || job.customer.name}</Typography>}
              {showLastEntry && lastEntry && (
                <Typography variant="body2" color="textSecondary" className={classNames(classes.lastEntry, size)}>
                  {lastEntry}
                </Typography>
              )}
            </div>
          </LabelWrapper>
        )}
        {currentlyBeingWorkedOn && <div className={classes.currentlyBeingWorkedOn} />}
      </Button>
    </>
  );
};

StartJobButton.propTypes = {
  hideLabelBreakpoint: PropTypes.string,
  showLabel: PropTypes.bool,
  showCustomer: PropTypes.bool,
  size: PropTypes.oneOf([undefined, 'big']),
  job: PropTypes.object.isRequired,
  className: PropTypes.string,
  showLastEntry: PropTypes.bool,
  showScheduledDate: PropTypes.bool,
};
StartJobButton.defaultProps = {
  showCustomer: true,
  hideLabelBreakpoint: undefined,
  showLabel: true,
  size: undefined,
  className: undefined,
  showLastEntry: true,
  showScheduledDate: true,
};

export { StartJobButton };
