import _ from 'lodash';
import { forms } from './forms';

const findForm = (name, version) => {
  const form = _.find(forms, (form) => {
    return ((form.name === name) && (form.version === version));
  });

  return form;
};

export { findForm };
