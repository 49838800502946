import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Calendar } from '../modules/calendar/Calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 4, 2),
  },
}));

const CalendarPage = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Calendar />
    </div>
  );
};

CalendarPage.propTypes = {
};
CalendarPage.defaultProps = {
};

export { CalendarPage };
