// No longer needed but kept here as example of what works

// import { getDuration } from 'src/utils';

// const willInsert = ({ variables }) => {
//   variables.objects.forEach((object) => {
//     object.duration = getDuration(object);
//   });
// };

// const willUpdate = ({ variables, node }) => {
//   const { set } = variables;

//   const endpoints = {
//     startTime: set.startTime || node.startTime,
//     startDate: set.startDate || node.startDate,
//     endTime: set.endTime || node.endTime || null,
//     endDate: set.endDate || node.endDate || null,
//   };

//   variables.set.duration = getDuration(endpoints);
// };

// export { willInsert, willUpdate };
