import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogActions, Button, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from 'src/kiska/components/Dialog';
import { FilePreviewsContainer } from 'src/kiska/components/MutationContext/FormFields/FileField/FilePreviewsContainer';
import EditIcon from 'mdi-material-ui/Pencil';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { formatCurrency } from 'src/utils';
import { format, parseISO } from 'date-fns';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import { ExpenseUpdateForm } from './ExpenseUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    marginRight: theme.spacing(1),
  },
}));

const LabelValue = (props) => {
  const { label, value } = props;
  const classes = useStyles(props);

  return (
    <div className="labelValue">
      <Typography variant="body1" color="textSecondary" component="span" className={classes.label}>{label}:</Typography>
      <Typography variant="body1" color="textPrimary" component="span">{value}</Typography>
    </div>
  );
};

const ExpenseViewDialog = (props) => {
  const { getOption } = useSchema();
  const classes = useStyles(props);
  const { node, onClose, open, ...dialogProps } = props;
  const dialogControlProps = useLocationControl({ open, onClose });

  return (
    <Dialog maxWidth="md" title={(node && node.description) || 'Expense'} {...dialogControlProps} {...dialogProps}>
      {node && (
        <>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} sm="auto">
                <LabelValue label="Status" value={getOption('expense', 'status', node).primary} />
                <LabelValue label="Category" value={getOption('expense', 'category', node).primary} />
              </Grid>
              <Grid item xs={6} sm="auto">
                <LabelValue label="Cost" value={`$${formatCurrency(node.cost, true)}`} />
                <LabelValue label="Price" value={`$${formatCurrency(node.price, true)}`} />
              </Grid>
              <Grid item xs={6} sm="auto">
                <LabelValue label="Date" value={node.date ? format(parseISO(node.date), `MMM d, yyyy`) : ''} />
                <LabelValue label="Vendor" value={node.vendor ? node.vendor.company || node.vendor.name : ''} />
              </Grid>
            </Grid>
            <FilePreviewsContainer enableView files={node.files} showName />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button variant="contained" color="default" onClick={dialogControlProps.closeDialog}><CloseIcon /><span>Close</span></Button>
            <ExpenseUpdateForm
              id={node.id}
              trigger={<Button variant="contained" color="primary"><EditIcon /><span>Edit</span></Button>}
            />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

ExpenseViewDialog.propTypes = {
};
ExpenseViewDialog.defaultProps = {
};

export { ExpenseViewDialog };
