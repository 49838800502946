import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from 'src/kiska/components/Grid';
import HoursPieChart from '../modules/work_entry/charts/HoursPieChart';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const WorkEntriesCharts = (props) => {
  const { displayParams: { showCharts, showFinancials }, groupBy } = props;
  const classes = useStyles(props);

  if (!showCharts) return null;

  const charts = {
    task: (
      <>
        {showFinancials && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <HoursPieChart groupBy="task" title="Cost by Task" enabledSets={['cost']} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <HoursPieChart groupBy="task" title="Price by Task" enabledSets={['price']} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <HoursPieChart groupBy="task" title="Hours by Task" enabledSets={['hours']} />
        </Grid>
      </>
    ),
    job: (
      <>
        {showFinancials && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <HoursPieChart groupBy="job" title="Cost by Job" enabledSets={['cost']} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <HoursPieChart groupBy="job" title="Price by Job" enabledSets={['price']} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <HoursPieChart groupBy="job" title="Hours by Job" enabledSets={['hours']} />
        </Grid>
      </>
    ),
    customer: (
      <>
        {showFinancials && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <HoursPieChart groupBy="customer" title="Cost by Customer" enabledSets={['cost']} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <HoursPieChart groupBy="customer" title="Price by Customer" enabledSets={['price']} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <HoursPieChart groupBy="customer" title="Hours by Customer" enabledSets={['hours']} />
        </Grid>
      </>
    ),
    user: (
      <>
        {showFinancials && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <HoursPieChart groupBy="user" title="Cost by Worker" enabledSets={['cost']} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <HoursPieChart groupBy="user" title="Price by Worker" enabledSets={['price']} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <HoursPieChart groupBy="user" title="Hours by Worker" enabledSets={['hours']} />
        </Grid>
      </>
    ),
  };

  return groupBy.map((grouper) => <React.Fragment key={grouper}>{charts[grouper]}</React.Fragment>);
};

WorkEntriesCharts.propTypes = {
  groupBy: PropTypes.array,
};
WorkEntriesCharts.defaultProps = {
  groupBy: ['user', 'task'],
};

export { WorkEntriesCharts };
