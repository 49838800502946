import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TextField, Button, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from 'mdi-material-ui/Close';

import { NodeListContext } from '.';

const styles = (theme) => ({
  root: {

  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
});

class NumberBetweenFilter extends Component {
  static contextType = NodeListContext

  static propTypes = {
    lteLabel: PropTypes.string,
    gteLabel: PropTypes.string,
    showClear: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    label: PropTypes.string,
  }

  static defaultProps = {
    lteLabel: 'Is less than:',
    gteLabel: 'Is greater than:',
    label: '',
    showClear: true,
  }

  handleGteChange = (event) => this.handleChange('_gte', event.target.value)

  handleLteChange = (event) => this.handleChange('_lte', event.target.value)

  handleChange = (condition, value) => {
    const filterValue = value || undefined;

    this.change(condition, filterValue);
  }

  change = (condition, value) => {
    const { updateVariables } = this.context;
    const { path } = this.props;
    value = Number(value);

    updateVariables({
      replaceInArray: {
        rootPath: `where._and`,
        pathValuePairs: [
          { path: `${path}.${condition}`, value },
        ],
      },
    });
  }

  clear = (event) => {
    this.change('_lte', undefined);
    this.change('_gte', undefined);
  }

  render() {
    const { variables: { where } } = this.context;
    const { classes, path, lteLabel, gteLabel, label, showClear } = this.props;
    const lteValue = _.get(_.get(where, `_and`, []).find((c) => _.get(c, `${path}._lte`)), `${path}._lte`) || null;
    const gteValue = _.get(_.get(where, `_and`, []).find((c) => _.get(c, `${path}._gte`)), `${path}._gte`) || null;

    return (
      <div className={classes.root}>
        <FormLabel>{label}</FormLabel>
        <div className={classes.dates}>
          <TextField value={gteValue || ''} label={gteLabel} onChange={this.handleGteChange} realNumber />
          &nbsp;&nbsp;&nbsp;
          <TextField value={lteValue || ''} label={lteLabel} onChange={this.handleLteChange} realNumber />
          {showClear ? <Button color="secondary" onClick={this.clear}><CloseIcon /><span>Clear</span></Button> : null}
        </div>
      </div>
    );
  }
}

NumberBetweenFilter = withStyles(styles)(NumberBetweenFilter);
export default NumberBetweenFilter;
