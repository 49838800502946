import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KiskaCrudForm from 'src/kiska/components/MutationContext/CrudForm';
import MutationSnackbars from 'src/kiska/components/MutationContext/MutationSnackbars';
import { AttachFile as AttachIcon } from '@material-ui/icons';
import MuiCameraIcon from 'mdi-material-ui/Camera';
import { FileField } from 'src/kiska/components/MutationContext/FormFields/FileField';
import classNames from 'classnames';
import _ from 'lodash';
import { OnMutationEvent } from 'src/kiska/components/MutationContext/OnMutationEvent';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  button: {

  },
}));

const ExpenseQuickAdd = (props) => {
  const classes = useStyles(props);
  const { job, ...rest } = props;
  const theme = useTheme();
  const { node, type, field, className, onlyCamera, cameraLabel: propsCameraLabel, cameraIcon: propsCameraIcon, hideLabelBreakpoint, uploadIcon: propsUploadIcon, uploadLabel: propsUploadLabel, ...buttonProps } = props;
  const hideLabel = useMediaQuery(theme.breakpoints.down(hideLabelBreakpoint), { noSsr: true });

  const cameraLabel = propsCameraLabel === undefined ? '' : propsCameraLabel;
  const CameraIcon = propsCameraIcon === undefined ? MuiCameraIcon : propsCameraIcon;
  const uploadLabel = propsUploadLabel === undefined ? '' : propsUploadLabel;
  const UploadIcon = propsUploadIcon === undefined ? AttachIcon : propsUploadIcon;

  const cameraTrigger = (
    <Button color="default" variant="contained" {...buttonProps} className={classNames(classes.button, className)}>
      <CameraIcon />
      {!hideLabel && cameraLabel && <span>{cameraLabel}</span>}
    </Button>
  );

  const dragndropTrigger = (
    <Button color="default" variant="contained" {...buttonProps} className={classNames(classes.button, className)}>
      <UploadIcon />
      {!hideLabel && uploadLabel && <span>{uploadLabel}</span>}
    </Button>
  );

  const successMessage = (mutation, result) => {
    return `Expense added`;
  };

  const fixtures = {
    category: 'other',
    status: 'none',
    job,
    date: new Date(),
  };

  return (
    <KiskaCrudForm resetOnSuccess type="expense" onChangeMutation="insert" onChangeMutationWait={1} fixtures={fixtures}>
      <FileField onlyButton="camera" name="files" cameraTrigger={cameraTrigger} />
      {!onlyCamera && <FileField onlyButton="upload" name="files" dragndropTrigger={dragndropTrigger} />}
      <MutationSnackbars successMessage={successMessage} />
    </KiskaCrudForm>
  );
};

ExpenseQuickAdd.propTypes = {
};
ExpenseQuickAdd.defaultProps = {
};

export { ExpenseQuickAdd };
