import React, { useContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate, parseDate, parseISO } from 'src/kiska/utils';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { extractDateRangeStringFromVariables, formatCurrency, truncateString } from 'src/utils';
import { useMediaQuery, Typography, Badge, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { FilePreview } from 'src/kiska/components/MutationContext/FormFields/FileField/FilePreview';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  files: {
    margin: theme.spacing(-0.5, 0),
  },
  filePreview: {

  },
  badge: {
    backgroundColor: '#282828',
    color: '#CCC',
    top: theme.spacing(1.3),
    right: theme.spacing(1),
  },
  statusButton: {
    padding: theme.spacing(0.25, 1),
  },
  tag: {
    marginRight: theme.spacing(0.5),
    height: 24,
  },
  expired: {
    color: '#D00',
  },
  notExpired: {
    color: '#0D0',
  },
}));

const useResourceTableColumns = (props = {}) => {
  const { showCreatedAt, showTags, showExpires, showUpdatedAt } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const xl = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const { getOption } = useSchema();

  const columns = [];

  // columns.push({
  //   label: 'Title',
  //   fieldName: 'title',
  //   Renderer: ({ value }) => (
  //     <Typography variant="body1" color="textPrimary" style={{ lineHeight: 1.2 }}>
  //       {value}
  //     </Typography>
  //   ),
  // });

  // if (!xs) {
  //   columns.push({
  //     label: 'Description',
  //     fieldName: 'description',
  //     Renderer: ({ value }) => (
  //       <Typography variant="body2" color="textSecondary" style={{ lineHeight: 1.2 }}>
  //         {truncateString(value, xs ? 40 : 70)}
  //       </Typography>
  //     ),
  //   });
  // }

  columns.push({
    label: 'Title',
    fieldName: 'title',
    Renderer: ({ parentNode: node }) => {
      return (
        <>
          <Typography variant="body1" color="textPrimary">
            {node.title}
          </Typography>
          {xl && node.description && (
            <Typography variant="body2" color="textSecondary" style={{ marginTop: -4 }}>
              {truncateString(node.description, xs ? 40 : 70)}
            </Typography>
          )}
        </>
      );
    },
  });

  if (showCreatedAt) {
    columns.push({
      label: 'Added',
      fieldName: 'createdAt',
      Renderer: ({ value }) => (
        <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1.2 }}>
          {formatDate(value, `MMM d, yyyy`)}
        </Typography>
      ),
    });
  }

  if (showUpdatedAt) {
    columns.push({
      label: 'Updated',
      fieldName: 'updatedAt',
      Renderer: ({ value }) => (
        <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1.2 }}>
          {formatDate(value, `MMM d, yyyy`)}
        </Typography>
      ),
    });
  }

  if (showExpires) {
    columns.push({
      label: 'Expires',
      fieldName: 'expires',
      Renderer: ({ value }) => {
        const date = parseISO(value);
        const expired = new Date() > date;
        return (
          <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1.2 }} className={classNames(expired && classes.expired, !expired && classes.notExpired)}>
            {formatDate(value, `MMM d, yyyy`)}
          </Typography>
        );
      },
    });
  }

  if (!sm && showTags) {
    columns.push({
      label: 'Tags',
      fieldName: 'tags',
      Renderer: ({ parentNode: node }) => {
        return (
          <div className={classes.tags}>
            {node.tags && node.tags.map(({ tag }) => (
              <Chip key={tag.id} className={classes.tag} label={tag.label} />
            ))}
          </div>
        );
      },
      filters: null,
      orderByPath: null,
    });
  }

  columns.push({
    label: '',
    fieldName: 'files',
    Renderer: ({ value }) => (
      <div className={classes.files}>
        {value && value.length
          ? (
            <Badge badgeContent={value.length} classes={{ badge: classes.badge }}>
              <FilePreview className={classes.filePreview} file={value[0]} size="small" showName={false} />
            </Badge>
          )
          : ''}
      </div>
    ),
    filters: null,
  });

  return { columns };
};

export { useResourceTableColumns };
