import React, { } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Accordion as MuiAccordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  summaryContent: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    // marginTop: theme.spacing(0),
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

const Accordion = (props) => {
  const { title, children, className, style, classes: classesWeDontNeed, ...panelProps } = props;
  const classes = useStyles(props);

  return (
    <MuiAccordion className={classNames(classes.root, className)} style={style} {...panelProps}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.summaryContent }}>
        {props.icon && <props.icon color="inherit" className={classes.icon} />}
        <Typography variant="h6" color="inherit">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  classes: PropTypes.object,
};
Accordion.defaultProps = {
  title: undefined,
  icon: undefined,
  children: undefined,
  className: undefined,
  style: undefined,
  classes: undefined,
};

export { Accordion };
