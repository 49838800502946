/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import _ from 'lodash';

import { MutationContext } from './MutationContext';
import OnMutationEvent from './OnMutationEvent';

class MutationSnackbars extends Component {
  static contextType = MutationContext;

  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    successMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    validationFailedMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    errorPosition: PropTypes.object,
    successPosition: PropTypes.object,
    validationFailedPosition: PropTypes.object,
  }

  static defaultProps = {
    errorMessage: null,
    successMessage: null,
    validationFailedMessage: null,
    errorPosition: { horizontal: 'center', vertical: 'top' },
    successPosition: { horizontal: 'right', vertical: 'bottom' },
    validationFailedPosition: { horizontal: 'center', vertical: 'top' },
  }

  static renderDefaultErrorMessage = (mutation, error) => 'Sorry, there was an unexpected error during that operation.'

  static renderDefaultSuccessMessage = (mutation, result) => 'Success!'

  static renderDefaultValidationFailedMessage = (mutation, validationErrors) => 'There are errors in the data you have entered.'

  handleEvent = (event) => (mutation, ...args) => {
    const { enqueueSnackbar } = this.props;
    const options = {
    };
    let message = '';

    const propsMessage = this.props[`${event}Message`];
    const mutationMessage = mutation[`render${_.upperFirst(_.camelCase(event))}Message`];
    const defaultMessage = MutationSnackbars[`renderDefault${_.upperFirst(_.camelCase(event))}Message`];

    message = propsMessage;
    if (message === false) return;

    if (typeof message === 'function') {
      message = message(mutation, ...args);
    } else if (!message) {
      if (mutationMessage) message = mutationMessage(mutation, ...args);
      else message = defaultMessage(mutation, ...args);
    }

    // Possible variants = success, error, warning, info
    if (event === 'success') {
      options.variant = 'success';
      options.autoHideDuration = 4000;
    }
    if (event === 'error') {
      options.variant = 'error';
      options.autoHideDuration = 7000;
    }
    if (event === 'validation-failed') {
      options.variant = 'error';
      options.autoHideDuration = 4000;
    }

    options.anchorOrigin = this.props[`${event}Position`];

    if (message) {
      enqueueSnackbar(message, options);
    }
  }

  render() {
    return (
      <OnMutationEvent
        onSuccess={this.handleEvent('success')}
        onError={this.handleEvent('error')}
        onValidationFailed={this.handleEvent('validation-failed')}
      />
    );
  }
}

MutationSnackbars = _.flow(
  withSnackbar,
)(MutationSnackbars);

export default MutationSnackbars;
