import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider as ApolloApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useSettings } from './contexts/SettingsContext';
import { createApolloClient } from '../utils/createApolloClient';

const graveyard = [];

const disposeClient = (client) => {
  if (!client) return;

  if (client.kiska.subscritionClient) {
    client.kiska.subscritionClient.unsubscribeAll();
    client.kiska.subscritionClient.close();
  }
  client.stop();
};

const ApolloProvider = (props) => {
  const { children } = props;
  const settings = useSettings();
  const { user, getAccessTokenSilently } = useAuth0();
  const userId = user ? user.id : undefined;
  const [client, setClient] = useState(null);

  useEffect(() => {
    // Setting state async...possible race bugs?
    (async () => {
      const newClient = await createApolloClient(settings, { getAccessTokenSilently });
      setClient(newClient);
    })();
  }, [getAccessTokenSilently, settings]);

  useEffect(() => {
    return () => {
      disposeClient(client);
    };
  // 9eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  if (!client) return null;

  return (
    <ApolloApolloProvider client={client} key={userId}>
      {children}
    </ApolloApolloProvider>
  );
};

export { ApolloProvider };
