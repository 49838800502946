import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const PageMetaContext = createContext({});
const usePageMeta = () => useContext(PageMetaContext);

const runNextTick = (func) => (...args) => {
  setTimeout(() => {
    func(...args);
  }, 0);
};

const PageMetaProvider = ({ children }) => {
  const [title, setTitle] = useState('');
  const [richTitle, setRichTitle] = useState('');

  const setTitlesNextTick = useCallback(
    runNextTick((t) => {
      setTitle(t);
      setRichTitle(t);
    }), [],
  );

  const setTitleNextTick = useCallback(
    runNextTick((t) => {
      setTitle(t);
    }), [],
  );

  const setRichTitleNextTick = useCallback(
    runNextTick((t) => {
      setRichTitle(t);
    }), [],
  );

  const pageMeta = {
    title,
    setTitle: setTitleNextTick,
    richTitle,
    setRichTitle: setRichTitleNextTick,
    setTitles: setTitlesNextTick,
  };

  return (
    <PageMetaContext.Provider value={pageMeta}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </PageMetaContext.Provider>
  );
};

PageMetaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const withPageMeta = (WrappedComponent) => (props) => (
  <PageMetaContext.Consumer>
    {(pageMeta) => <WrappedComponent pageMeta={pageMeta} {...props} />}
  </PageMetaContext.Consumer>
);

export { withPageMeta, PageMetaProvider, PageMetaContext, usePageMeta };
