import React, { Fragment } from 'react';
import _ from 'lodash';
import parseParenthesis from 'parenthesis';
import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import pluralize from 'pluralize';
import { customAlphabet } from 'nanoid';

const primaryKeyNanoid = customAlphabet('12346789ABCDEFGHJKMNPQRTUVWXYZ', 9);

const isSsr = typeof window === 'undefined';

const wrap = (x, m) => {
  // eslint-disable-next-line no-mixed-operators
  return (x % m + m) % m;
};

const generatePrimaryId = () => {
  return primaryKeyNanoid(10);
};

const pluralCount = (value, word, strong) => {
  let ret;
  const wrappedValue = strong ? <strong>{value}</strong> : value;

  if (value === 1) ret = <>{wrappedValue} {word}</>;
  else ret = <>{wrappedValue} {pluralize(word)}</>;

  return ret;
};

const fetchJson = ({ url, body }) => new Promise((resolve, reject) => {
  if (isSsr) {
    console.error('Attempted to fetch JSON in SSR mode, failing.');
    reject(new Error('Cannot fetch JSON in SSR mode.'));
    return;
  }

  window.fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      response.json()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          console.error(`Error fetching JSON from ${url}: `, error);
          reject(error);
        });
    })
    .catch((error) => {
      console.error(`Error fetching from URL ${url}: `, error);
      reject(error);
    });
});

const addIdsToSelections = (s) => {
  return s;
  // let selections = s;
  // if (selections.includes('id')) return selections;
  // selections += ' id';
  // selections = selections.replace(/}/g, ' id}');
  // return selections;
};

const getTopLevelSelections = (selections) => {
  // Parse to nested arrays
  let selectionsArray = parseParenthesis(selections);
  selectionsArray = selectionsArray
    .filter((s) => typeof s === 'string') // remove nested objects
    .map((s) => s.replace(/[{}]/g, '')) // strip braces
    .reduce((acc, s) => [...acc, ...s.split(/\s/)], []) // merge
    .filter((s) => !!s); // remove empties

  return selectionsArray;
};

const getRelationSchemaType = (field, type, schema) => {
  const relationSchemaType = _.get(schema, `types.${field.type}`);
  if (!relationSchemaType) {
    throw new Error(`Cannot find schema type "${field.type}" for ${type.name}.${field.name}`);
  }
  return relationSchemaType;
};

const getClosestDomParent = (passedElem, selector) => {
  let elem = passedElem;
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

const replaceNewLinesWithBreaks = (str) => {
  if (!str) return '';
  if (typeof str !== 'string') return str;
  return str.split('\n').map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
};

const formatDateOrDateString = (date, format) => {
  if (!date) return '';
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  const formattedDate = formatDate(parsedDate, format);
  return formattedDate;
};

const parseDateOrDateString = (date) => {
  if (!date) return date;
  if (typeof date !== 'string') return date;

  const parsedDate = parseISO(date);
  return parsedDate;
};

const parseTimeWithNmsPrecision = (time) => {
  if (!time) return time;
  if (typeof time !== 'string') return time;
  const truncatedTime = time.replace(/\.(\d{3})\d*/, '.$1'); // truncate millisecond precision to 3 digits
  let parsedTime = parseDate(truncatedTime, 'HH:mm:ss.SSSx', new Date());
  if (parsedTime.toString() === 'Invalid Date') {
    parsedTime = parseDate(truncatedTime, 'HH:mm:ss.SSS', new Date());
  }
  if (parsedTime.toString() === 'Invalid Date') {
    parsedTime = parseDate(truncatedTime, 'HH:mm:ss', new Date());
  }
  return parsedTime;
};

const formatTimeOrTimeString = (time, format) => {
  if (!time) return '';
  const parsedDate = parseTimeWithNmsPrecision(time);
  const formattedDate = formatDate(parsedDate, format);
  return formattedDate;
};

export {
  // kiskaDir,
  formatDateOrDateString as formatDate,
  parseTimeWithNmsPrecision as parseTime,
  parseDateOrDateString as parseDate,
  parseISO,
  formatTimeOrTimeString as formatTime,
  addIdsToSelections, getTopLevelSelections,
  getRelationSchemaType,
  getClosestDomParent, replaceNewLinesWithBreaks,
  fetchJson,
  generatePrimaryId,
  pluralCount,
  wrap,
};
