import React, { useMemo, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Color from 'color';
import classNames from 'classnames';

import UpdateTextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import { FileField as UpdateFileField } from 'src/kiska/components/MutationContext/FormFields/FileField';
import UpdateNodeField from 'src/kiska/components/MutationContext/FormFields/NodeField';
import UpdateBooleanField from 'src/kiska/components/MutationContext/FormFields/BooleanField';
import UpdateSelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import UpdateDateField from 'src/kiska/components/MutationContext/FormFields/DatePicker';
import UpdateDateTimeField from 'src/kiska/components/MutationContext/FormFields/DateTimePicker';
import { SchemaContext, useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { replaceNewLinesWithBreaks } from 'src/kiska/utils';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { format, parseISO } from 'date-fns';
import { useLocalNode } from './LocalNodeContext';
import { useViewOrUpdate } from './useViewOrUpdate';
import { FilePreviewsContainer } from './MutationContext/FormFields/FileField/FilePreviewsContainer';

const useStyles = makeStyles((theme) => {
  return ({
    root: {
      marginTop: theme.kiska.type === 'print' ? theme.spacing(-3) : undefined,
    },
    label: {
      fontSize: theme.kiska.type === 'print' ? 12 : 14,
      marginTop: '-1em',
      color: theme.palette.text.secondary,
    },
    value: {
      fontSize: theme.kiska.type === 'print' ? 12 : 14,
      color: theme.palette.text.primary,
      padding: `2px 8px 2px 8px`,
      background: theme.palette.background.formValue,
      '&.bordered': {
        border: `solid 1px`,
        borderColor: theme.palette.border.strong,
        borderRadius: 0,
      },
      '& pre': {
        fontFamily: '"Lucida Console", Monaco, "Courier New", Courier, monospace',
        whiteSpace: 'pre-wrap',
        margin: 0,
        padding: 0,
        lineHeight: 1.4,
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
      },
    },
    secondaryValue: {
      color: theme.palette.text.secondary,
      fontSize: '.9em',
    },
  });
});

const ViewOrUpdateField = ({ name, value, type, viewRenderer, secondaryValue, label: propsLabel, noBorder, highlightColor, printMinHeight }) => {
  const { printMode } = usePrint();
  const classes = useStyles();
  const { schema } = useContext(SchemaContext);
  const schemaField = schema.types[type].fields[name];
  const bordered = printMode && !noBorder;

  if (!schemaField) {
    throw new Error(`Cannot find schemaField for "${type}.${name}"`);
  }

  let label;
  if (propsLabel !== undefined) label = propsLabel;
  else label = schemaField.label;

  const valueStyle = {
    backgroundColor: highlightColor,
    minHeight: printMode && printMinHeight ? printMinHeight : undefined,
  };

  return (
    <div className={classNames(viewRenderer ? undefined : classes.root)}>
      <div className={classes.label}>{label}</div>
      <div className={classNames(classes.value, bordered && 'bordered')} style={valueStyle}>
        <pre>{value || <>&nbsp;</>}</pre>
        {secondaryValue && (
          <div className={classes.secondaryValue}>
            <pre>{secondaryValue}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

ViewOrUpdateField.propTypes = {
};
ViewOrUpdateField.defaultProps = {
};

export const TextField = (props) => {
  const { node, type } = useLocalNode();
  const { omitFromView, viewLabel, viewRenderer: ViewRenderer, ...updateProps } = props;
  const { view, update } = useViewOrUpdate();

  if (update) return <UpdateTextField {...updateProps} />;
  if (omitFromView) return null;

  let value = _.get(node, props.name, null);
  value = replaceNewLinesWithBreaks(value);

  return (
    <ViewOrUpdateField
      name={props.name}
      value={value}
      type={type}
      label={viewLabel || props.label}
      viewRenderer={ViewRenderer}
      printMinHeight={props.rows || props.multiline ? 120 : undefined}
    />
  );
};

export const SelectField = (props) => {
  const { getOptions } = useSchema();
  const { node, type } = useLocalNode();
  const { omitFromView, viewLabel, viewRenderer: ViewRenderer, ...updateProps } = props;
  const { view, update } = useViewOrUpdate();

  if (update) return <UpdateSelectField {...updateProps} />;
  if (omitFromView) return null;

  let value = _.get(node, props.name, null);
  let secondaryValue;
  let highlightColor;
  const options = getOptions(type, props.name) || props.options;
  if (value) {
    if (props.multiple) {
      if (!Array.isArray(value)) value = [value];
      value = value.map((v) => {
        const option = options.find((o) => o.value === v);
        return option ? (option.label || option.primary) : '';
      }).join(', ').replace(/,\s([^,]+)$/, ', and $1');
    } else {
      const option = options.find((o) => o.value === value);
      value = option ? (option.label || option.primary) : '';
      secondaryValue = option ? option.secondary : undefined;
      highlightColor = option ? option.color : undefined;
    }
  } else value = null;

  return <ViewOrUpdateField name={props.name} value={value} highlightColor={highlightColor} secondaryValue={secondaryValue} type={type} label={viewLabel || props.label} viewRenderer={ViewRenderer} />;
};

export const NodeField = (props) => {
  const { node, type } = useLocalNode();
  const { schema } = useContext(SchemaContext);
  const { view, update } = useViewOrUpdate();

  const { omitFromView, viewLabel, viewRenderer: ViewRenderer, ...updateProps } = props;
  if (update) return <UpdateNodeField {...updateProps} />;
  if (omitFromView) return null;

  const schemaField = schema.types[type].fields[props.name];
  const hasMany = schemaField.hasMany;

  const value = _.get(node, props.name, null);

  let renderedValue;
  if (ViewRenderer) renderedValue = <ViewRenderer node={hasMany ? undefined : value} nodes={hasMany ? value : undefined} />;
  else {
    renderedValue = <schemaField.renderers.inline node={hasMany ? undefined : value} nodes={hasMany ? value : undefined} />;
  }

  return <ViewOrUpdateField name={props.name} value={renderedValue} type={type} label={viewLabel || props.label} viewRenderer={ViewRenderer} />;
};

export const BooleanField = (props) => {
  const { node, type } = useLocalNode();
  const { view, update } = useViewOrUpdate();
  const { omitFromView, viewLabel, viewRenderer: ViewRenderer, name, ...updateProps } = props;
  const { schema } = useContext(SchemaContext);
  const schemaField = schema.types[type].fields[name];
  let label = viewLabel || props.label;
  if (label === undefined) label = schemaField.label;

  if (update) return <UpdateBooleanField name={name} {...updateProps} />;
  if (omitFromView) return null;

  let value = _.get(node, props.name, null);
  if (value === false) {
    value = (
      <>
        <span style={{ fontSize: '2em', lineHeight: 0, margin: `2px 8px -2px 0`, display: 'inline-block' }}>☐</span>
        <span style={{ margin: `3px 0px -3px 0`, display: 'inline-block' }}>{label}</span>
      </>
    );
  } if (value === true) {
    value = (
      <>
        <span style={{ fontSize: '2em', lineHeight: 0, margin: `2px 8px -2px 0`, display: 'inline-block' }}>☑</span>
        <span style={{ margin: `3px 0px -3px 0`, display: 'inline-block' }}>{label}</span>
      </>
    );
  }
  return <ViewOrUpdateField name={props.name} value={value} type={type} label="" viewRenderer={ViewRenderer} />;
};

export const DateField = (props) => {
  const { node, type } = useLocalNode();
  const { omitFromView, viewLabel, viewRenderer: ViewRenderer, ...updateProps } = props;
  const { view, update } = useViewOrUpdate();

  if (update) return <UpdateDateField {...updateProps} />;
  if (omitFromView) return null;

  let value = _.get(node, props.name, null);
  if (value) value = format(parseISO(value), 'MMM d, yyyy');
  return <ViewOrUpdateField name={props.name} value={value} type={type} label={viewLabel || props.label} viewRenderer={ViewRenderer} />;
};

export const DateTimeField = (props) => {
  const { node, type } = useLocalNode();
  const { omitFromView, viewLabel, viewRenderer: ViewRenderer, ...updateProps } = props;
  const { view, update } = useViewOrUpdate();

  if (update) return <UpdateDateTimeField {...updateProps} />;
  if (omitFromView) return null;

  let value = _.get(node, props.name, null);
  if (value) value = format(parseISO(value), `MMM d, yyyy h:mmaaaaa'm`);
  return <ViewOrUpdateField name={props.name} value={value} type={type} label={viewLabel || props.label} viewRenderer={ViewRenderer} />;
};

export const FileField = (props) => {
  const { node, type } = useLocalNode();
  const { omitFromView, viewLabel, name, viewRenderer: ViewRenderer, ...updateProps } = props;
  const { view, update } = useViewOrUpdate();
  const { schema } = useContext(SchemaContext);
  const schemaField = schema.types[type].fields[name];

  if (update) return <UpdateFileField {...updateProps} name={name} />;
  if (omitFromView) return null;

  const value = _.get(node, props.name, null);
  let files = value;
  if (schemaField.hasMany && !schemaField.embedded) {
    files = value && value.length ? value.map((file) => file.file) : [];
  } else if (!schemaField.hasMany && !schemaField.embedded) {
    files = value.file;
  }

  const renderedValue = <FilePreviewsContainer files={files} showName />;
  return <ViewOrUpdateField name={props.name} value={renderedValue} type={type} label={viewLabel || props.label} noBorder viewRenderer={ViewRenderer} />;
};
