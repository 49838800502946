import React from 'react';
import PropTypes from 'prop-types';
import { } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import CrudForm from '../../updates/CrudForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 1, 1, 1),
  },
}));

const TaskUpdateFormInner = (props) => {
  const classes = useStyles(props);
  // const { node } = useContext(MutationContext);
  // const { user } = useUser();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">

        <Grid item xs={12} sm={12}>
          <TextField name="label" label="Name" />
        </Grid>

      </Grid>
    </div>
  );
};

TaskUpdateFormInner.propTypes = {
  variant: PropTypes.string,
};
TaskUpdateFormInner.defaultProps = {
  variant: undefined,
};

const TaskUpdateForm = ({ id, trigger, onClose, open, variant }) => {
  return (
    <CrudForm
      type="task"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderRichTitle={(n) => (n ? <>Edit Task: {n.label}</> : 'Create Task')}
      renderTitle={(n) => (n ? `Edit Task - ${n.label}` : 'Create Task')}
      maxWidth="sm"
      allowDelete
    >
      <TaskUpdateFormInner variant={variant} />
    </CrudForm>
  );
};

TaskUpdateForm.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string,
};
TaskUpdateForm.defaultProps = {
  id: undefined,
  trigger: undefined,
  onClose: undefined,
  open: undefined,
  variant: '',
};

export { TaskUpdateForm };
