import React from 'react';
// import PropTypes from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import SelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import { FileField } from 'src/kiska/components/MutationContext/FormFields/FileField';
import CrudForm from 'src/components/updates/CrudForm';
import { GridLineBreak } from 'src/components/forms';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import DateField from 'src/kiska/components/MutationContext/FormFields/DatePicker';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  filesPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.default,
  },
}));

const TagUpdateFormInner = (props) => {
  const { fixtures } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField name="label" />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField name="description" />
        </Grid> */}
      </Grid>
    </div>
  );
};

TagUpdateFormInner.propTypes = {
  // variant: PropTypes.string,
};
TagUpdateFormInner.defaultProps = {
  // variant: '',
};

const TagUpdateForm = ({ id, trigger, onClose, open, onOpen, variant, initialValues: propsInitialValues, onSuccess, fixtures }) => {
  const initialValues = {
    ...propsInitialValues,
  };

  return (
    <CrudForm
      type="tag"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderTitle={(node) => (node ? 'Edit Tag' : 'Create Tag')}
      initialValues={initialValues}
      onSuccess={onSuccess}
      fixtures={fixtures}
      maxWidth="md"
      onOpen={onOpen}
    >
      <TagUpdateFormInner variant={variant} fixtures={fixtures} />
    </CrudForm>
  );
};

export { TagUpdateForm };
