import React from 'react';
import PropTypes from 'prop-types';
// import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

import { useNode } from 'src/kiska/hooks/useNode';
import NodeViewPage from 'src/components/layouts/NodeViewPage';
import UserTabs from './UserTabs';
import { useParams } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

const UserPageView = (props) => {
  const { id } = useParams();
  const { result, node } = useNode({ type: 'user', id });
  const title = node ? `${node.displayName}` : '';

  return (
    <NodeViewPage queryResult={result} component={UserTabs} title={title} />
  );
};

UserPageView.propTypes = {
};
UserPageView.defaultProps = {
};

export default UserPageView;
