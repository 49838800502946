import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField as MuiTextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

// import splitFieldName from '../splitFieldName';
import { withFormSection } from 'src/components/forms';
import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';
import { withHidden } from '../../Hideable';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
});

class TextField extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    formatter: PropTypes.func,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    label: PropTypes.string,
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    type: PropTypes.string,
    schemaExtensions: PropTypes.object,
    blurMode: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    helperText: undefined,
    multiline: undefined,
    rows: 0,
    formatter: null,
    label: undefined,
    initialValue: undefined,
    disabled: false,
    type: undefined,
    schemaExtensions: undefined,
    blurMode: true,
    onChange: undefined,
  }

  change = (value) => {
    const { onChange } = this.context;
    const { name, onChange: propsOnChange } = this.props;

    onChange(name, value);
    if (propsOnChange) propsOnChange(name, value);
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;
    const { initialValue, schemaExtensions } = props;

    this.state = {
      value: undefined,
    };

    this.debouncedChange = _.throttle((value) => this.change(value), 2000);

    registerField(props.name, {
      initialValue,
      schemaExtensions,
      transforms: {
        formValueToMutationVar: props.formValueToMutationVar,
        nodeValueToFormValue: props.nodeValueToFormValue,
      },
    });
  }

  componentWillUnmount = () => {
    const { unregisterField } = this.context;
    const { name } = this.props;
    this.debouncedChange.cancel();
    unregisterField(name);
  }

  normalizeEventValue = (event) => {
    const { formatter, type, realNumber, integer, phone } = this.props;
    let value = event.target.value;

    if (typeof value === 'string') {
      if (realNumber) {
        value = /[-+]?\d*\.?\d*/.exec(value)[0];
        // value = value.replace(/[^\d.-]/g, '');
      } else if (integer) {
        value = /[-+]?\d*/.exec(value)[0];
        // value = value.replace(/[^\d-]/g, '');
      } else if (type === 'tel') {
        value = value.replace(/([0-9]{3})[.-\s]*([0-9]{4})/gi, `$1-$2`);
        value = value.replace(/[+1\s-.]*[(\s]*([0-9]{3})[)\s]*[.-\s]*([0-9]{3})[.-\s]*([0-9]{4})/gi, `$1-$2-$3`);
      }
    }

    if (formatter) value = formatter(value);
    return value;
  }

  handleBlur = (event) => {
    const { blurMode: contextBlurMode } = this.context;
    const { blurMode: propsBlurMode } = this.props;
    const value = this.normalizeEventValue(event);
    const blurMode = contextBlurMode || propsBlurMode;

    if (blurMode) {
      this.debouncedChange.cancel();
      this.change(value);
    }
  }

  handleChange = (event) => {
    const { blurMode: contextBlurMode } = this.context;
    const { blurMode: propsBlurMode } = this.props;
    const value = this.normalizeEventValue(event);
    const blurMode = contextBlurMode || propsBlurMode;

    if (blurMode) {
      this.setState({ value });
      this.debouncedChange(value);
    } else {
      this.change(value);
    }
  }

  handleFocus = (event) => {
    const { selectAllOnFocus } = this.props;
    if (selectAllOnFocus) {
      event.target.setSelectionRange(0, event.target.value.length);
    }
  }

  handleKeyDown = (event) => {
    const { runDefaultMutation } = this.context;
    const { multiline, rows } = this.props;

    if ((!multiline && !rows) && event.key === 'Enter') {
      // Mysterious navigation and mutation cancel on some forms if these are omitted. Could be accessibility keys?
      event.preventDefault();
      event.stopPropagation();

      runDefaultMutation();
    }
  }

  render() {
    const { blurMode: propsBlurMode, name, hidden, collapsed, selectAllOnFocus, onChange: propsOnChange, realNumber, integer, classes, helperText, rows, formatter, inputProps, label: propsLabel, schemaExtensions, initialValue, type: inputType, disabled, multiline, ...rest } = this.props;
    const { getFieldValue, getFieldErrors, pendingMutation, schema, type, blurMode: contextBlurMode } = this.context;
    const blurMode = contextBlurMode || propsBlurMode;
    const errors = getFieldErrors(name);
    const schemaField = schema.types[type].fields[name] || schemaExtensions.types[type].fields[name];
    const label = propsLabel === undefined ? schemaField.label : propsLabel;
    const isMultiLine = multiline || !!rows;

    const contextValue = getFieldValue(name);
    let value;
    if (!blurMode) value = contextValue;
    else if (this.state.value !== undefined) value = this.state.value;
    else value = contextValue;
    if (!value && value !== 0) value = '';

    const commonProps = {
      name,
      value: `${value}`,
      label,
      error: !!errors,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      disabled,
      helperText: <HelperText text={helperText} errors={errors} />,
      onKeyDown: this.handleKeyDown,
      className: classes.root,
      type: inputType,
      variant: 'outlined',
      rows,
      multiline: isMultiLine,
      required: schemaField.validation.required,
      inputProps: { ...inputProps, tabIndex: hidden || collapsed ? -1 : 0 },
      ...rest,
    };

    return <MuiTextField {...commonProps} />;
  }
}

TextField = _.flow(
  withStyles(styles),
  withHidden,
  withFormSection,
)(TextField);

export default TextField;
