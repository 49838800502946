import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NodeList } from 'src/kiska/components/NodeList';
import { QueryVariablesProvider } from 'src/kiska/components/NodeList/QueryVariablesContext';
import _ from 'lodash';

const WorkEntryReportQuery = ({ initial, children, fixtures }) => {
  const mergedInitial = useMemo(() => _.merge({}, {
    orderBy: [
      { minStart: 'asc' },
    ],
  }, initial), [initial]);

  const mergedFixtures = fixtures;

  return (
    <QueryVariablesProvider type="work_entry_grouped" initial={mergedInitial} fixtures={mergedFixtures}>
      <NodeList>
        {children}
      </NodeList>
    </QueryVariablesProvider>
  );
};

WorkEntryReportQuery.propTypes = {
  initial: PropTypes.object,
  fixtures: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
};
WorkEntryReportQuery.defaultProps = {
  initial: undefined,
  fixtures: undefined,
  children: null,
};

export { WorkEntryReportQuery };
