import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
import { SettingsContext } from 'src/kiska/components/contexts/SettingsContext';
import Menu from 'src/kiska/components/Menu';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import MasterLayout from './MasterLayout';
import Header from './components/Header';
import Footer from './components/Footer';
import { buildMenus } from './components/menus';
import { NotificationsBanner } from './NotificationsBanner';

const drawerWidth = 60;

const styles = (theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  menuButton: {

  },
  content: {
    flex: 1,
    position: 'relative',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // background: `url(/images/diamond-plate.jpg)`,
  },
  // overlay: {
  //   position: 'absolute',
  //   top: 0,
  //   right: 0,
  //   bottom: 0,
  //   left: 0,
  //   backgroundColor: Color(theme.palette.background.default).fade(0.00).string(),
  // },
  drawer: {
    display: 'none',
    width: drawerWidth,
    flexShrink: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    zIndex: 100,
    transition: `width 200ms ease-out, box-shadow 200ms ease-out, border-right-color 200ms ease-out`,
    // boxShadow: theme.palette.type === 'dark' ? `2px 0px 10px 2px rgba(0,0,0,.5)` : `2px 0px 10px 2px rgba(0,0,0,.3)`,
    borderRight: `solid 1px ${theme.palette.border.strong}`,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: theme.palette.type === 'dark' ? `2px 0px 10px 5px rgba(0,0,0,.7)` : `2px 0px 10px 2px rgba(0,0,0,.4)`,
      width: 220,
      borderRightColor: 'transparent',
    },
    // borderRight: 'solid 1px rgba(0,0,0,.2)',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      flex: 1,
      '& > *': {
        width: '100%',
      },
    },

    position: 'relative',
    marginLeft: 0,
    width: '100%',
    '& > div:not(:last-child)': {
      width: '100%',
    },
  },
  [theme.breakpoints.up('lg')]: {
    drawer: {
      display: 'block',
    },
    main: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  footer: {

  },
  notificationsBanner: {
    backgroundColor: theme.palette.brand.appBar,
  },
});

const isSsr = typeof window === 'undefined';

const NormalLayout = (({ classes, children, hideFooter }) => {
  const history = useHistory();
  const settings = useContext(SettingsContext);
  const auth = useAuth0();
  const { richTitle } = React.useContext(PageMetaContext);
  const { user } = useUser();
  const { appSettings } = useAppSettings();
  const menus = buildMenus({ appSettings, settings, auth, history, user }); // ToDoBug?

  React.useEffect(() => {
    return () => {
    };
  }, []);

  return (
    <MasterLayout>
      <div className={classes.root}>
        <Header title={richTitle} />
        <div className={classes.content}>
          {/* <div className={classes.overlay} /> */}
          <div className={classes.drawer}>
            <Menu context="drawer" menu={menus.main} />
          </div>
          <main className={classes.main}>
            <NotificationsBanner className={classes.notificationsBanner} />
            {children}
          </main>
        </div>
        {!hideFooter && <Footer className={classes.footer} />}
      </div>
    </MasterLayout>
  );
});

NormalLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  hideFooter: PropTypes.bool,
};

NormalLayout.defaultProps = {
  children: undefined,
  hideFooter: false,
};

export default withStyles(styles)(NormalLayout);
