import React, { useContext, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNodeList } from 'src/kiska/components/NodeList';
import { Add as AddIcon } from '@material-ui/icons';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { groupWorkEntries } from './groupWorkEntries';
import { WorkEntryDayGroup } from '../WorkEntryDayGroup';
import { WorkEntryUpdateForm } from '../modules/work_entry/WorkEntryUpdateForm';
import { FloatingButton } from '../FloatingButton';
import { EmptyData } from '../modules/work_entry/charts/EmptyData';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
}));

const WorkEntryListByDay = (props) => {
  const classes = useStyles(props);
  const rootRef = useRef();
  const { nodes } = useNodeList();
  const { displayParams, addEntryProps } = props;
  const [updateFormEntryId, setUpdateFormEntryId] = useState(null);
  const { appSettings } = useAppSettings();
  const { user } = useUser();

  const canEdit = appSettings.data.workLog.editingRoles.includes(user.role.id);

  const handleRowClick = (row) => {
    if (!canEdit) return;
    setUpdateFormEntryId(row.workEntryId);
  };

  const { groups } = useMemo(() => {
    return groupWorkEntries(nodes);
  }, [nodes]);

  return (
    <div className={classes.root} ref={rootRef}>
      <WorkEntryUpdateForm
        open={!!updateFormEntryId}
        id={updateFormEntryId}
        onClose={() => setUpdateFormEntryId(null)}
      />
      {groups.map((group) => {
        return (
          <WorkEntryDayGroup key={group.startOfPeriod} group={group} displayParams={displayParams} onRowClick={handleRowClick} />
        );
      })}
      <EmptyData />
      {canEdit && (
        <WorkEntryUpdateForm
          {...addEntryProps}
          trigger={(
            <FloatingButton
              variant="extended"
              color="primary"
              containerRef={rootRef}
            >
              <AddIcon />&nbsp;<span>Add Entry</span>
            </FloatingButton>
          )}
        />
      )}
    </div>
  );
};

WorkEntryListByDay.propTypes = {
};
WorkEntryListByDay.defaultProps = {
};

export { WorkEntryListByDay };
