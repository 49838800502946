/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import pluralize from 'pluralize';
import produce from 'immer';
import deepFreeze from 'deep-freeze';

import { getSchemaAtPath } from './getSchemaAtPath';
import { typeIsScaler } from './scalerTypes';
import getFiltersForField from './getFiltersForField';
import inlineScalerRenderers from './inlineScalerRenderers';
import { getRelationSchemaType } from '../utils';

const typeLabel = (type) => {
  // Try to use user-supplied label first
  if (type.label) return type.label;

  // Otherwise just split the type name with spaces and capitilize
  // eg: UploadedFile --> Uploaded File
  return _.upperFirst(_.camelCase(type.name).replace(/([a-z])([A-Z])/g, '$1 $2'));
};

const fieldLabel = (field) => {
  // Try to use user-supplied label first
  if (field.label) return field.label;

  // If this is id, then capitilize the whole thing
  if (field.name === 'id') return 'ID';

  // Now just use the name
  // If it's a JSON deep path then only take the last word
  const label = field.name.split('.').pop();

  // Split the field name with spaces and capitilize
  return _.upperFirst(label.replace(/([a-z])([A-Z])/g, '$1 $2'));
};

const buildNonScalerRenderer = (field, type, relationType) => ({ node, nodes }) => {
  const OneNodeRenderer = relationType.renderers.inline;

  if ((!node && !nodes) || (nodes && !nodes.length)) {
    return 'none';
  }

  if (node) {
    return <OneNodeRenderer node={node} />;
  }

  return (
    <ul style={{ margin: 0, padding: 0, paddingLeft: 16 }}>
      {nodes.map((n) => {
        const isManyToMany = n.__typename.includes('__x__');
        if (isManyToMany) n = n[relationType.name];

        return <li key={n.id}><OneNodeRenderer node={n} /></li>;
      })}
    </ul>
  );
};

const normalizeField = (field, type, schema) => {
  field.label = fieldLabel(field);
  field.isScaler = typeIsScaler(field.type);
  field.isJsonChild = field.name.includes('.');
  field.filters = field.filters || getFiltersForField(field);
  field.clientOnly = field.clientOnly === undefined ? false : field.clientOnly;

  let renderers = {};
  if (field.isScaler) {
    renderers = {
      inline: inlineScalerRenderers[field.type],
    };
  } else {
    const relationType = getRelationSchemaType(field, type, schema);
    renderers = {
      inline: buildNonScalerRenderer(field, type, relationType),
    };
  }
  field.renderers = _.merge(renderers, field.renderers);

  // Add a default validation object if none exists
  field.validation = field.validation || {};
};

const normalizeType1stPass = (type, schema) => {
  // Add label
  type.label = typeLabel(type);
  type.pluralLabel = type.pluralLabel || pluralize(type.label);
  type.lowerCaseLabel = type.label.toLowerCase();
  type.lowerCasePluralLabel = type.pluralLabel.toLowerCase();
  type.pluralName = type.pluralName || pluralize(type.name);

  // Optional built-in fields
  if (type.fields.createdAt !== false) {
    type.fields.createdAt = {
      type: 'Timestamp',
      label: 'Date Created',
    };
  }
  if (type.fields.updatedAt !== false) {
    type.fields.updatedAt = {
      type: 'Timestamp',
      label: 'Last Updated',
    };
  }

  // Add id field
  type.fields.id = {
    type: 'String',
    unique: true,
  };

  // Add owner field
  if (type.name !== 'user' && type.fields.owner === true) {
    type.fields.owner = {
      type: 'user',
    };
  }

  // Add _search fields used to find strings
  type.searchFields = type.searchFields || [];
  if (!Array.isArray(type.searchFields)) type.searchFields = type.searchFields.split(/[,\s]+/);

  // Add default basic renderer for type
  type.renderers = _.merge({
    inline: ({ node }) => <>ID: {node.id}</>,
  }, type.renderers);

  // Add default hooks
  type.hooks = type.hooks || {};
};

const recursivelyNormalizeField = (type, schema, field, fieldName) => {
  if (typeof field === 'string') {
    field = { type: field };
    schema.types[type.name].fields[fieldName] = field;
  }
  field.name = fieldName;
  normalizeField(field, type, schema);

  if (field.type === 'Json' && field.fields) {
    _.forEach(field.fields, (innerField, innerFieldName) => {
      recursivelyNormalizeField(type, schema, innerField, innerFieldName);
    });
  }
};

const normalizeType2ndPass = (type, schema) => {
  // Add field-specific stuff
  _.forEach(type.fields, (field, fieldName) => {
    recursivelyNormalizeField(type, schema, field, fieldName);
  });

  // Add lists of fields
  type.allFields = _.map(type.fields, (field, fieldName) => fieldName);
  type.serverFields = type.allFields.filter((fieldName) => !type.fields[fieldName].clientOnly);
  type.scalerFields = type.allFields.filter((fieldName) => type.fields[fieldName].isScaler);
  type.nonScalerFields = type.allFields.filter((fieldName) => !type.fields[fieldName].isScaler);

  // Add default selection set
  type.selections = _.merge(
    {
      default: undefined,
    },
    type.selections,
  );
};

const normalizeSchema = (schema) => {
  const draft = schema;
  // const draft = produce(schema, () => { });

  _.forEach(draft.types, (type, typeName) => {
    type.name = typeName;
    normalizeType1stPass(type, draft);
  });
  _.forEach(draft.types, (type, typeName) => {
    normalizeType2ndPass(type, draft);
  });

  deepFreeze(draft);

  return draft;
};

const mergeSchemas = (...schemas) => {
  let merged = _.merge({}, ...schemas);
  merged = normalizeSchema(merged);
  return merged;
};

export default normalizeSchema;
export { normalizeSchema, mergeSchemas };
