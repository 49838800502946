import React, { forwardRef, useCallback, useEffect, useRef, useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { Collapse, useMediaQuery } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { FormHeading } from './FormHeading';

export const FormSectionContext = createContext({ collapsed: false });
export const useFormSection = () => useContext(FormSectionContext);

export const FormSectionContextProvider = ({ children, collapsed }) => {
  return (
    <FormSectionContext.Provider value={{ collapsed }}>
      {children}
    </FormSectionContext.Provider>
  );
};

export const withFormSection = (WrappedComponent) => (props) => (
  <FormSectionContext.Consumer>
    {(value) => <WrappedComponent collapsed={value.collapsed} {...props} />}
  </FormSectionContext.Consumer>
);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    '&:not(:last-child)': {
      marginBottom: -1,
    },
    // margin: theme.spacing(0, 0, theme.kiska.type === 'print' ? 0 : 3, 0),
    padding: theme.spacing(0),
    backgroundColor: theme.palette.background.formSection,
    borderRadius: theme.kiska.type === 'print' ? 2 : 2,
    overflow: 'hidden',
    breakInside: 'avoid-page',
    breakAfter: 'auto',
    breakBefore: 'auto',
    border: 'solid 1px',
    borderColor: theme.palette.border.formSection,
    // borderTop: theme.kiska.type === 'print' ? 'solid 1px' : undefined,
    '&.gutterBottom': {
      margin: theme.spacing(0, 0, theme.kiska.type === 'print' ? 0 : 3, 0),
    },
    '&.hiddenForPrint': {
      display: 'none',
    },
  },
  content: {
    padding: theme.spacing(3, 1, 1, 1),
  },
}));

const FormSection = forwardRef((props, forwardedRef) => {
  const mutationContext = useMutationContext();
  const localRef = useRef();
  const classes = useStyles(props);
  const [hasErrors, setHasErrors] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const { title, children, className, initiallyExpanded, icon, hideTitleWhenPrinting, alwaysInitiallyExpanded, gutterBottom, spacing, justify, alignItems, classes: unused, highlighted, ...rest } = props;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [expandedState, setExpandedState] = useState(alwaysInitiallyExpanded || (!sm && initiallyExpanded));
  const ref = forwardedRef || localRef;
  const { printMode } = usePrint();

  const expanded = hasErrors || expandedState;

  const handleClickHeading = () => {
    if (hasErrors) return;
    setExpandedState(!expanded);
  };

  const checkForErrors = useCallback(() => {
    const iHaveErrors = !!(mutationContext && ref && ref.current && ref.current.querySelector('.Mui-error'));
    setHasErrors(iHaveErrors);
  }, [mutationContext, ref]);

  const handleValidation = useCallback(() => {
    checkForErrors();
  }, [checkForErrors]);

  useEffect(() => {
    let interval;
    let failedId;
    let passedId;

    if (mutationContext) {
      interval = setInterval(checkForErrors, 1000);
      failedId = mutationContext.addEventListener('validation-failed', handleValidation);
      passedId = mutationContext.addEventListener('validation-passed', handleValidation);
    }

    return () => {
      if (interval) clearInterval(interval);
      if (failedId) mutationContext.removeEventListener('validation-failed', failedId);
      if (passedId) mutationContext.removeEventListener('validation-passed', passedId);
    };
  }, [checkForErrors, handleValidation, mutationContext]);

  // if (ref && ref.current) {
  //   const notHiddenElements = ref.current.querySelectorAll(`:scope :not(.hidden) input, :scope :not(.hidden) textarea`);
  //   notHiddenElements.forEach((input) => {
  //     input.setAttribute('tabindex', '0');
  //   });

  //   const hiddenElements = ref.current.querySelectorAll(`:scope .hidden input, :scope .hidden textarea`);
  //   hiddenElements.forEach((input) => {
  //     input.setAttribute('tabindex', '-1');
  //   });
  // }

  return (
    <FormSectionContextProvider collapsed={!expanded}>
      <div
        className={classNames(className, classes.root, gutterBottom && 'gutterBottom', !expanded && printMode && 'hiddenForPrint')}
        ref={ref}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        {...rest}
      >
        {(!printMode || !hideTitleWhenPrinting) && (
          <FormHeading title={title} expanded={expanded} onClick={handleClickHeading} highlighted={highlighted} className={classes.heading} icon={icon} />
        )}
        <Collapse in={expanded}>
          <div className={classes.content}>
            <Grid container spacing={spacing === undefined ? 4 : spacing} justify={justify} alignItems={alignItems}>
              {children}
            </Grid>
          </div>
        </Collapse>
      </div>
    </FormSectionContextProvider>
  );
});

FormSection.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  spacing: PropTypes.number,
  justify: PropTypes.string,
  alignItems: PropTypes.string,
  initiallyExpanded: PropTypes.bool,
  highlighted: PropTypes.bool,
  alwaysInitiallyExpanded: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  hideTitleWhenPrinting: PropTypes.bool,
};

FormSection.defaultProps = {
  classes: undefined,
  title: undefined,
  children: undefined,
  className: undefined,
  spacing: undefined,
  justify: undefined,
  alignItems: undefined,
  initiallyExpanded: true,
  highlighted: true,
  alwaysInitiallyExpanded: false,
  gutterBottom: false,
  hideTitleWhenPrinting: false,
};
FormSection.displayName = 'FormSection';

export { FormSection };
