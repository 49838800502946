import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { usePageMeta } from 'src/kiska/components/contexts/PageMetaContext';
import { WorkEntryReportQuery } from 'src/components/modules/work_entry/WorkEntryReportQuery';
import { Grid } from 'src/kiska/components/Grid';
import { useNodeList } from 'src/kiska/components/NodeList';
import { extractDateRangeStringFromVariables } from 'src/utils';
import { WorkEntryReportQueryParams } from 'src/components/dashboards/WorkEntryReportParams';
import { ReportWrapper } from 'src/components/dashboards/ReportWrapper';
import { ReportingDates } from 'src/components/dashboards/ReportingDates';
import { WorkEntryListByDay } from 'src/components/dashboards/WorkEntryListByDay';
import { ReportTopContent } from 'src/components/dashboards/ReportTopContent';
import { ReportContent } from 'src/components/dashboards/ReportContent';
import { ReportTotals } from 'src/components/dashboards/ReportTotals';
import { JobDates } from 'src/components/dashboards/JobDates';
import { WorkEntriesCharts } from 'src/components/dashboards/WorkEntriesCharts';
import { GridLineBreak } from 'src/components/forms';
import { WorkEntryChartDataProvider } from '../work_entry/charts/WorkEntryChartDataContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    flexDirection: 'column !important',
    justifyContent: 'flex-start !important',
  },
}));

const UserWorkEntriesInner = (props) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const { node: user, enabledDisplayParams: propsEnabledDisplayParams, initialDisplayParams, initialDateRange } = props;
  const { variables, nodes } = useNodeList();
  const classes = useStyles(props);
  const { setTitles } = usePageMeta();
  const [displayParams, setDisplayParams] = useState({
    showNotes: true,
    showCharts: true,
    showFinancials: !sm,
    showJob: true,
    showCustomer: true,
    showTask: true,
    showPayRate: false,
    showUser: false,
    showTimes: true,
    ...initialDisplayParams,
  });

  const enabledDisplayParams = propsEnabledDisplayParams || ['showNotes', 'showCharts', 'showFinancials'];

  const datesStr = extractDateRangeStringFromVariables(variables);

  const availableFilters = ['task', 'payRate', 'job', 'customer'];

  const shareProps = {
    name: `${user.displayName} Work Log ${datesStr ? ` ${datesStr}` : ''}.pdf`,
    emailData: {
      subject: `Employee Work Log PDF`,
      body: `Hello,\n\nYou have been sent a work log for ${user.displayName}${datesStr ? ` for ${datesStr}` : ''}. Please find the attched PDF file.\n\nThanks,\n~ Slick Systems`,
    },
  };

  const handleDisplayParamsChange = (value) => {
    setDisplayParams(value);
  };

  useEffect(() => {
    setTitles(`${user.displayName} - Work Log`);
  }, [user.displayName, setTitles]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <WorkEntryReportQueryParams
          availableFilters={availableFilters}
          displayParams={displayParams}
          onDisplayParamsChange={handleDisplayParamsChange}
          initialDateRange={initialDateRange}
          enabledDisplayParams={enabledDisplayParams}
          dateRangeOptions={['all-time', 'last-year', 'last-month', 'last-period', 'this-period', 'this-month', 'this-year']}
        />
      </Grid>

      <ReportWrapper shareProps={shareProps}>
        <ReportTopContent title="Work Log" subtitle={`for ${user.displayName}`}>
          <Grid item xs={12} md={6}>
            <ReportingDates />
          </Grid>
          <GridLineBreak />
          <ReportTotals displayParams={displayParams} />
          <WorkEntriesCharts displayParams={displayParams} groupBy={['task']} />
        </ReportTopContent>
        <ReportContent padding={2}>
          <WorkEntryListByDay displayParams={displayParams} addEntryProps={{ fixtures: { user } }} />
        </ReportContent>
      </ReportWrapper>
    </div>
  );
};

UserWorkEntriesInner.propTypes = {
  initialDateRange: PropTypes.string,
  node: PropTypes.object.isRequired,
  enabledDisplayParams: PropTypes.array,
  initialDisplayParams: PropTypes.object,
};
UserWorkEntriesInner.defaultProps = {
  initialDateRange: 'all-time',
  enabledDisplayParams: undefined,
  initialDisplayParams: undefined,
};

const UserWorkEntries = (props) => {
  const { node: user, ...innerProps } = props;

  const initial = {
    limit: 10000,
    orderBy: [
      { minStart: 'desc' },
    ],
    args: {
      where: {
      },
    },
  };

  const fixtures = [
    { path: 'where.user.id._eq', value: user.id },
    { path: 'args.groupById', value: true },
    { path: 'args.groupByJob', value: true },
    { path: 'args.groupByUser', value: true },
    { path: 'args.groupByTask', value: true },
    { path: 'args.groupByCustomer', value: true },
    { path: 'args.groupByPayRate', value: true },
  ];

  return (
    <>
      <WorkEntryReportQuery
        fixtures={fixtures}
        initial={initial}
      >
        <WorkEntryChartDataProvider groupBy={['task']}>
          <UserWorkEntriesInner {...innerProps} node={user} />
        </WorkEntryChartDataProvider>
      </WorkEntryReportQuery>
    </>
  );
};

UserWorkEntries.propTypes = {
  enabledDisplayParams: PropTypes.array,
};
UserWorkEntries.defaultProps = {
  enabledDisplayParams: undefined,
};

export { UserWorkEntries };
