import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { TabPanel } from 'src/kiska/components/ContentTabs';
import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import { FormSection } from 'src/components/forms';
import hasuraLogo from './hasura.jpg';
import grafanaLogo from './grafana.png';
import minioLogo from './minio.png';
import traefikLogo from './traefik.png';
import postgresLogo from './postgresql.png';
import digitalOceanLogo from './digitalOcean.jpg';
import postmarkLogo from './postmark.png';
import auth0Logo from './auth0.png';
import backblazeLogo from './backblaze.jpg';
import { SimpleTable } from './SimpleTable';
import { TriggerUpdateForm } from './TriggerUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  imageButton: {
    width: '100%',
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF',
    },
    '& img': {
      height: 60,
      objectFit: 'contain',
    },
  },
}));

const ImageButton = (props) => {
  const classes = useStyles(props);
  const { src, to, alt, href, ...buttonProps } = props;
  const component = 'a';

  return (
    <Button component={component} to={to} href={href} target="_blank" className={classes.imageButton} {...buttonProps}>
      <img src={src} alt={alt} />
    </Button>
  );
};

const AdminTab = (props) => {
  const classes = useStyles(props);
  const settings = useSettings();

  const triggerColumns = [
    'status',
    'label',
    'evaluatedAt',
  ];

  return (
    <div className={classes.root}>
      <FormSection title="Services" initiallyExpanded={false}>
        <Grid item xs={12}>
          <Typography variant="h4">System Services</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={digitalOceanLogo} href="https://cloud.digitalocean.com/networking/domains?i=81cd4d" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={auth0Logo} href="https://manage.auth0.com/dashboard/us/slick-systems/applications" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={traefikLogo} href="https://traefik.admin.slick.systems:7298/" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={grafanaLogo} href="https://grafana.admin.slick.systems" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={postgresLogo} href="https://pgadmin.admin.slick.systems:7298/" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={postmarkLogo} href="https://account.postmarkapp.com/servers/5542303/streams/outbound/overview" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={backblazeLogo} href="https://secure.backblaze.com/b2_buckets.htm" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Instance Services</Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="warning">Do not modify Hasura schema from here! Start a migration-capable console instance from up-to-date repo directory.</Alert>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={hasuraLogo} href={`${settings.public.hasuraOrigin}/hasura-api/console`} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ImageButton src={minioLogo} href={`${settings.public.websiteOrigin}/minio`} />
        </Grid>
      </FormSection>

      <FormSection title="Triggers" initiallyExpanded={false}>
        <Grid item xs={12}>
          <SimpleTable
            type="trigger"
            UpdateForm={TriggerUpdateForm}
            addLabel="Add Trigger"
            columns={triggerColumns}
            enableAdd
            enableEdit
            rowClickAction="edit"
            searchPaths="label description"
          />
        </Grid>
      </FormSection>
    </div>
  );
};

AdminTab.propTypes = {
};
AdminTab.defaultProps = {
};

export { AdminTab };
