import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogActions, Button, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from 'src/kiska/components/Dialog';
import { FilePreviewsContainer } from 'src/kiska/components/MutationContext/FormFields/FileField/FilePreviewsContainer';
import EditIcon from 'mdi-material-ui/Pencil';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { formatCurrency } from 'src/utils';
import { format, parseISO } from 'date-fns';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import { ResourceUpdateForm } from './ResourceUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '80vh',
  },
  iframe: {
    width: '100%',
    height: '100vh',
    border: 'none',
  },
  tag: {
    marginRight: theme.spacing(0.5),
    height: 24,
  },
}));

const ResourceViewDialog = (props) => {
  const { getOption } = useSchema();
  const classes = useStyles(props);
  const { node, onClose, open, updateFormProps, ...dialogProps } = props;
  const dialogControlProps = useLocationControl({ open, onClose });

  const isOneImage = node && node.files && node.files.length === 1 && node.files[0].mimeType.startsWith('image/');
  const isOnePdf = node && node.files && node.files.length === 1 && node.files[0].mimeType === 'application/pdf';

  return (
    <Dialog maxWidth="md" title={(node && node.title) || 'Resource'} fullScreen {...dialogControlProps} {...dialogProps}>
      {node && (
        <>
          <DialogContent>
            {/* <Typography variant="h5" color="textPrimary">{node.title}</Typography> */}
            {node.description && <Typography variant="body1" color="textPrimary" gutterBottom>{node.description}</Typography>}
            {/* <Typography variant="body2" color="textSecondary">Tags:</Typography> */}
            {node.tags && node.tags.map((tagOrXTag) => {
              const tag = tagOrXTag.tag || tagOrXTag;
              return <Chip key={tag.id} className={classes.tag} label={tag.label} />;
            })}
            <br /><br />

            {isOnePdf && <iframe src={node.files[0].url} className={classes.iframe} title={node.title} />}
            {isOneImage && <img className={classes.image} src={node.files[0].url} />}
            {node.files && node.files.length > 1 && <FilePreviewsContainer enableView files={node.files} showName />}
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button variant="contained" color="default" onClick={dialogControlProps.closeDialog}><CloseIcon /><span>Close</span></Button>
            <ResourceUpdateForm
              id={node.id}
              trigger={<Button variant="contained" color="primary"><EditIcon /><span>Edit</span></Button>}
              {...updateFormProps}
            />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

ResourceViewDialog.propTypes = {
};
ResourceViewDialog.defaultProps = {
};

export { ResourceViewDialog };
