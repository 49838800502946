import React, { Component, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNodeList } from 'src/kiska/components/NodeList';
import Color from 'color';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { getColorForKey } from './utils';

const WorkEntryChartDataContext = createContext({});
const useWorkEntryChartData = () => useContext(WorkEntryChartDataContext);

const groupByMap = {
  user: { propPath: 'workEntry.user.id', propLabel: 'Worker', getGroupLabel: (node) => _.get(node, 'workEntry.user.displayName', '') },
  task: { propPath: 'workEntry.task.id', propLabel: 'Task', getGroupLabel: (node) => _.get(node, 'workEntry.task.label', '') },
  job: { propPath: 'workEntry.job.id', propLabel: 'Job', getGroupLabel: (node) => _.get(node, 'workEntry.job.title', '') },
  payRate: { propPath: 'workEntry.payRate.id', propLabel: 'Pay Rate', getGroupLabel: (node) => _.get(node, 'workEntry.payRate.label', '') },
  customer: { propPath: 'workEntry.job.customer.id', propLabel: 'Customer', getGroupLabel: (node) => _.get(node, 'workEntry.job.customer.company', '') || _.get(node, 'job.customer.name', '') },
};

const buildPieData = (nodes, { theme, groupBy }) => {
  const groupBys = {};

  _.forEach(groupBy, (groupByProp) => {
    const groupingInfo = groupByMap[groupByProp];
    const groups = {};

    nodes.forEach((node) => {
      const groupId = _.get(node, groupingInfo.propPath);
      if (!groups[groupId]) {
        const newGroup = {
          duration: 0,
          cost: 0,
          price: 0,
          days: 0,
          label: groupingInfo.getGroupLabel(node),
          backgroundColor: Color(getColorForKey(`${groupByProp}:${groupId}`)).fade(0).string(),
        };
        groups[groupId] = newGroup;
      }

      const group = groups[groupId];
      group.duration += node.sumDuration;
      group.price += node.sumPrice;
      group.cost += node.sumCost;
      group.days += node.dayCount;
    });

    const datasets = [
      {
        label: 'Hours',
        data: _.map(groups, (group) => group.duration),
        name: 'hours',
      },
      {
        label: 'Cost',
        data: _.map(groups, (group) => group.cost),
        name: 'cost',
      },
      {
        label: 'Price',
        name: 'price',
        data: _.map(groups, (group) => group.price),
      },
    ];

    datasets.forEach((set) => {
      set.borderColor = Color(theme.palette.text.secondary).fade(0.2).string();
      set.labels = _.map(groups, (group) => group.label);
      set.backgroundColor = _.map(groups, (group) => group.backgroundColor);
    });

    groupBys[groupByProp] = {
      datasets,
      labels: datasets[0].labels,
    };
  });

  return groupBys;
};

const WorkEntryChartDataProvider = (props) => {
  const theme = useTheme();
  const { nodes } = useNodeList();
  const { children, groupBy } = props;

  const emptyData = !nodes || !nodes.length;
  let value = null;

  if (!emptyData) {
    value = {
      pie: buildPieData(nodes, { groupBy, theme }),
      time: {},
    };
  }

  return (
    <WorkEntryChartDataContext.Provider value={value}>
      {children}
    </WorkEntryChartDataContext.Provider>
  );
};

WorkEntryChartDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  groupBy: PropTypes.array.isRequired,
};

WorkEntryChartDataProvider.defaultProps = {

};

export { WorkEntryChartDataProvider, WorkEntryChartDataContext, useWorkEntryChartData };
