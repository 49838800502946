import _ from 'lodash';

const schema = _.merge(
  require('./app_setting').default,
  require('./user').default,
  require('./pay_rate').default,

  require('./contact').default,
  require('./task').default,
  require('./job').default,
  require('./work_entry').default,
  require('./work_entry_grouped').default,
  require('./event').default,

  require('./file').default,

  require('./tag').default,

  require('./form').default,
  require('./signature').default,

  require('./trigger').default,

  require('./expense').default,
  require('./resource').default,

  require('./grouping').default,
);

export default schema;
