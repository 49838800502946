import React, { useContext, createRef, useState } from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import SignIcon from 'mdi-material-ui/Signature';
import StopIcon from 'mdi-material-ui/AlertOctagon';
import { red } from '@material-ui/core/colors';

import CrudForm from 'src/components/updates/CrudForm';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import DateField from 'src/kiska/components/MutationContext/FormFields/DatePicker';
import { SignatureField } from 'src/kiska/components/MutationContext/FormFields/SignatureField';
import { GridLineBreak } from 'src/components/forms';

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
  },

  root: {
    padding: theme.spacing(2),
  },

  stop: {
    color: 'hsla(0, 100%, 40%, 1)',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontWeight: 700,
    fontSize: '1.5em',
    '& > span': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },

  trigger: {
    color: '#FFF',
    backgroundColor: 'hsla(300, 100%, 35%, 100%)',
    '&:hover': {
      backgroundColor: 'hsla(300, 100%, 45%, 100%)',
    },
  },

  warning: {
    border: 'solid 2px hsla(0, 100%, 30%, 1)',
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
    background: '#510000',
    borderRadius: 4,
    color: '#FFF',
  },
}));

const SignatureUpdateFormInner = (props) => {
  const [sigRef] = useState(createRef());
  const classes = useStyles(props);
  const { node } = useContext(MutationContext);
  const { } = props;
  const isNew = !(node && node.id);

  return (
    <div className={classes.root}>
      <div className={classes.warning}>
        {/* <Typography component="span" variant="body1" className={classes.stop} align="center">
          <StopIcon /><span>Stop!</span>
        </Typography> */}
        <Typography variant="body1" component="span" gutterBottom color="inherit">
          You are about to <strong>permanently</strong> sign this form.<br /><br />
          If you have not already done so, close this popup and thoroughly read the form before signing.
          Once signed, you cannot remove your signature from this form.
        </Typography>
      </div>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
          <SignatureField name="file" label="Signature" />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <TextField name="name" disabled={!isNew} />
        </Grid>
        <GridLineBreak />
        <Grid item xs={12} md={6} lg={8}>
          <TextField name="title" />
        </Grid>
        <GridLineBreak />
        <Grid item xs={12} md={6} lg={8}>
          <DateField name="date" disabled initialValue={new Date()} />
        </Grid>
      </Grid>
    </div>
  );
};

SignatureUpdateFormInner.propTypes = {
};
SignatureUpdateFormInner.defaultProps = {
};

const SignatureUpdateForm = ({ id, trigger: propsTrigger, onClose, open, fixtures: propsFixtures, onSuccess }) => {
  const classes = useStyles();

  const fixtures = propsFixtures;

  const trigger = propsTrigger === undefined
    ? (
      <Button variant="contained" className={classes.trigger}>
        <SignIcon /><span>Add Signature</span>
      </Button>
    )
    : propsTrigger;

  return (
    <CrudForm
      classes={{ content: classes.dialogContent }}
      type="signature"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderRichTitle={(n) => `Sign Form`}
      renderTitle={(n) => `Sign Form`}
      fixtures={fixtures}
      onSuccess={onSuccess}
      maxWidth="md"
      embeddedMode
    >
      <SignatureUpdateFormInner />
    </CrudForm>
  );
};

export { SignatureUpdateForm };
