import React, { useContext, forwardRef, createContext } from 'react';
// import PropTypes from 'prop-types';
import { } from '@material-ui/core';
import { } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import { useLocalNode } from './LocalNodeContext';

export const HideableContext = createContext({ hidden: false });
export const useHideable = () => useContext(HideableContext);

export const HideableProvider = ({ hidden, children }) => {
  const value = { hidden };

  return (
    <HideableContext.Provider value={value}>
      {children}
    </HideableContext.Provider>
  );
};

export const withHidden = (WrappedComponent) => (props) => (
  <HideableContext.Consumer>
    {(value) => <WrappedComponent hidden={value.hidden} {...props} />}
  </HideableContext.Consumer>
);

const duration = '.5s';

const useStyles = makeStyles((theme) => ({
  root: {
    transition: `height ${duration}, margin ${duration}, padding ${duration}, border ${duration}, opacity ${duration}`,
  },
  hidden: {
    overflow: 'hidden',
    height: `0 !important`,
    margin: `0 !important`,
    padding: `0 !important`,
    border: `0 !important`,
    opacity: `0 !important`,
  },
}));

const isHidden = (criteria, node, contextHidden) => {
  const { hidden: propsHidden, dep, showIfEq, showIfOneOf, hideIfOneOf, hideIfEq, showIfnEq, hideIfnEq, show, showIfTruthy, showIfFalsey, hideIfTruthy, hideIfFalsey } = criteria;

  let hidden = false;
  const depValue = _.get(node, dep);

  if (showIfTruthy) hidden = !depValue;
  if (showIfFalsey) hidden = !!depValue;
  if (hideIfTruthy) hidden = !depValue;
  if (hideIfFalsey) hidden = !!depValue;
  if (showIfEq) hidden = showIfEq !== depValue;
  if (hideIfEq) hidden = hideIfEq === depValue;
  if (showIfnEq) hidden = showIfnEq !== depValue;
  if (hideIfnEq) hidden = hideIfnEq === depValue;
  if (showIfOneOf) {
    let oneOfArray = showIfOneOf;
    if (typeof oneOfArry === 'string') oneOfArray = oneOfArray.split(/[,\s+]/);
    hidden = !depValue || !oneOfArray.includes(depValue);
  }
  if (hideIfOneOf) {
    let oneOfArray = showIfOneOf;
    if (typeof oneOfArry === 'string') oneOfArray = oneOfArray.split(/[,\s+]/);
    hidden = oneOfArray.includes(depValue);
  }

  if (show) {
    hidden = !show({ value: depValue });
  }
  if (propsHidden !== undefined) hidden = propsHidden;
  if (contextHidden) hidden = true;

  return hidden;
};

const HideableComponent = forwardRef((props, forwardedRef) => {
  const { hidden } = useHideable();
  const { style, children, component, className, ...rest } = props;
  const classes = useStyles(props);

  const Component = component;

  return (
    <Component
      ref={forwardedRef}
      className={classNames(className, classes.root, hidden && classes.hidden)}
      {...rest}
    >
      {children}
    </Component>
  );
});

HideableComponent.propTypes = {
};
HideableComponent.defaultProps = {
};

const Hideable = (props) => {
  const localNode = useLocalNode();
  const node = localNode ? localNode.node : undefined;
  const { hidden: contextHidden } = useHideable();
  const { children, hidden: propsHidden, dep, showIfEq, showIfOneOf, hideIfOneOf, hideIfEq, showIfnEq, hideIfnEq, show, showIfTruthy, showIfFalsey, hideIfTruthy, hideIfFalsey, ...hideableComponentProps } = props;

  if (!children) return null;

  const items = React.Children.map(children, (child) => {
    let content;

    if (!child) {
      content = null;
    } else if (child.type === React.Fragment) {
      content = <Hideable children={child.props.children} {...hideableComponentProps} />;
    } else if (child.type === Hideable) {
      content = child;
    } else {
      content = <HideableComponent {...child.props} {...hideableComponentProps} component={child.type} />;
    }

    return content;
  });

  const hidden = isHidden(props, node, contextHidden);

  return (
    <HideableProvider hidden={hidden}>
      {items}
    </HideableProvider>
  );
};

Hideable.displayName = 'Hideable';
HideableComponent.displayName = 'HideableComponent';

export { HideableComponent, Hideable };
