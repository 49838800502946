import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { TextField, DateField, FileField, NodeField, BooleanField, SelectField } from 'src/kiska/components/ViewOrUpdateFields';
import _ from 'lodash';
import { useLocalNode } from 'src/kiska/components/LocalNodeContext';
import { Grid } from 'src/kiska/components/Grid';
import { FormSection, GridLineBreak } from 'src/components/forms';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import { ArrayFieldEditor } from 'src/components/ArrayFieldEditor';
import { FormUpdateForm } from '../FormUpdateForm';
import { Signature } from '../Signature';
import { SignatureUpdateForm } from '../SignatureUpdateForm';
import { FormSignatures } from './FormSignatures';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  signatureWarning: {
    color: theme.palette.text.danger,
    margin: theme.spacing(2, 0, 1, 0),
    lineHeight: 1.2,
  },
  saveThenSign: {
    margin: theme.spacing(2, 0, 1, 0),
    lineHeight: 1.2,
  },
}));

const form = {
  title: 'Change Order',
  name: 'ChangeOrderForm',
  version: '1',
  versionDate: new Date('2020-12-13'),
  subtitle: (node) => undefined,
};

const ChangeOrderForm = (props) => {
  const { view, update } = useViewOrUpdate();
  const { node } = useLocalNode();
  const classes = useStyles(props);
  const { job } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DateField name="data.changeOrder.date" label="Today's Date" initialValue={new Date()} />
      </Grid>

      <Grid item xs={12}>
        <TextField name="data.changeOrder.description" rows={6} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormSection title="Files & Photos" spacing={2}>
          <Grid item xs={12}>
            <FileField name="data.changeOrder.files" label="" helperText="Include relavent photos, sketches, etc." />
          </Grid>
        </FormSection>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormSignatures />
      </Grid>
    </Grid>
  );
};

ChangeOrderForm.propTypes = {
};
ChangeOrderForm.defaultProps = {
};

form.Form = ChangeOrderForm;
form.UpdateForm = (props) => {
  return <FormUpdateForm form={form} {...props} allowDelete={true} />;
};

export default form;
export { ChangeOrderForm };
