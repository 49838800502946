import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { SchemaContext } from '../contexts/SchemaContext';
import { MutationProvider } from './MutationContext';
import { insertMutationBuilderFactory, updateMutationBuilderFactory, deleteMutationBuilderFactory } from './mutationBuilderFactories';

export default class CrudForm extends Component {
  static displayName = 'KiskaCrudForm';

  static contextType = SchemaContext;

  static propTypes = {
    modifyVars: PropTypes.func,
    node: PropTypes.object,
    onSuccess: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.string.isRequired,
    fixtures: PropTypes.object,
    initialValues: PropTypes.object,
    resetOnSuccess: PropTypes.bool,
    updateFunction: PropTypes.func,
    onNodeChange: PropTypes.func,
    forcedInitialValues: PropTypes.object,
  }

  static defaultProps = {
    modifyVars: null,
    node: undefined,
    onSuccess: null,
    children: null,
    fixtures: undefined,
    initialValues: undefined,
    resetOnSuccess: false,
    updateFunction: undefined,
    onNodeChange: undefined,
    forcedInitialValues: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { schema } = context;

    const builderArg = {
      modifyVars: props.modifyVars,
    };

    this.state = {
      resetKey: (new Date()).getTime(),
    };

    this.mutations = {
      insert: insertMutationBuilderFactory(builderArg),
      update: updateMutationBuilderFactory(builderArg),
      delete: deleteMutationBuilderFactory(builderArg),
    };
  }

  handleSuccess = (mutation, result) => {
    const { onSuccess, resetOnSuccess, onNodeChange, node } = this.props;
    const returnedNode = _.get(result, `data.${mutation.alias}.returning[0]`);
    if (onNodeChange) onNodeChange(returnedNode);

    if (resetOnSuccess) {
      this.setState({ resetKey: (new Date()).getTime() });
    }

    if (onSuccess) {
      onSuccess(mutation, result, returnedNode || node);
    }
  }

  render() {
    const { modifyVars, node, children, updateFunction, forcedInitialValues, type, initialValues, fixtures, embeddedMode, ...rest } = this.props;
    const { resetKey } = this.state;

    const isNew = !(node && node.id);
    const defaultMutation = isNew ? 'insert' : 'update';

    return (
      <MutationProvider
        {...rest}
        key={node ? node.id : resetKey}
        type={type}
        node={node}
        onSuccess={this.handleSuccess}
        mutationBuilders={this.mutations}
        defaultMutation={defaultMutation}
        fixtures={fixtures}
        initialValues={initialValues}
        updateFunction={updateFunction}
        embeddedMode={embeddedMode}
        forcedInitialValues={forcedInitialValues}
      >
        {children}
      </MutationProvider>
    );
  }
}
