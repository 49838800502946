import React from 'react';
// import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useUser } from '../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column !important',
    justifyContent: 'flex-start !important',
  },
  tabsPaper: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 10,
    position: 'relative',
  },
  tabButton: {
    padding: theme.spacing(0, 1),
    minHeight: 64,
    '& svg': {
      marginBottom: `0 !important`,
    },
  },
  tabsContainer: {
    zIndex: 10,
    position: 'relative',
  },
  panelsContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  title: {
    padding: theme.spacing(0.5, 2, 0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitle: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -4,
  },
}));

const TabPanel = ({ children, activeValue, value }) => {
  return children;
};

const userCanSeeTab = (user, tab) => {
  if (user.role.id === 'admin') return true;
  if (tab.roles) return tab.roles.includes(user.role.id);
  if (tab.role) return tab.role === user.role.id;
  return true;
};

const ContentTabs = ({ children, value, onChange, tabs: propsTabs, subtitle, urlPathIndex, defaultTab, defaultTabIndex, title, titleAdornment }) => {
  const { user } = useUser();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const childrenArray = React.Children.toArray(children);
  const selfControlViaUrl = urlPathIndex !== undefined;

  const tabs = propsTabs.filter((tab) => userCanSeeTab(user, tab));

  let activeTabName = selfControlViaUrl ? location.pathname.split('/')[urlPathIndex + 1] : value;
  if (!activeTabName) {
    if (defaultTab) activeTabName = defaultTab;
    else if (defaultTabIndex) activeTabName = tabs[defaultTabIndex].value;
    else activeTabName = tabs[0].value;
  }

  const handleChange = (event, newValue) => {
    if (selfControlViaUrl) {
      const urlPath = location.pathname.split('/');
      urlPath[urlPathIndex + 1] = newValue;
      history.push(urlPath.join('/'));
    } else {
      onChange(newValue);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabsPaper}>
        {title && (
          <div className={classes.title}>
            {titleAdornment}{titleAdornment && <>&nbsp;&nbsp;</>}
            <Typography variant="h5" align="center">{title}</Typography>
          </div>
        )}
        {subtitle && <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>{subtitle}</Typography>}
        <Tabs
          className={classes.tabsContainer}
          value={activeTabName}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
        >
          {tabs.map((tab) => {
            return (
              <Tab key={tab.value} label={tab.label} value={tab.value} icon={tab.icon} className={classes.tabButton} />
            );
          })}
        </Tabs>
      </div>
      <div className={classes.panelsContainer}>
        {childrenArray.map((child) => {
          const tab = tabs.find((t) => t.value === child.props.value);
          if (!tab) {
            // This tab must have been filtered out because user isn't allowed to see it
            return null;
          }

          const isActive = child.props.value === activeTabName;

          if (isActive) return child;
          return null;
        })}
      </div>
    </div>
  );
};

export { TabPanel, ContentTabs };
