/* eslint-disable react/prop-types */
import React from 'react';
import { fragments } from './fragments';

export default {
  types: {
    event: {
      renderers: {
        inline: ({ node }) => <>{node.user ? node.user.displayName : ''}</>,
      },

      selections: {
        default: `
          ${fragments.event.basic}
        `,
      },

      fields: {
        start: { type: 'Date', validation: { required: true } },
        end: { type: 'Date', validation: { required: true } },
        title: {
          type: 'String',
          validation: {
            requiredIf: { dep: 'type', oneOf: ['other', 'stat-holiday'] },
          },
        },
        description: { type: 'String' },
        job: {
          type: 'job',
          validation: {
            requiredIf: {
              dep: 'type',
              oneOf: ['job-work'],
            },
          },
        },
        user: {
          type: 'user',
          label: 'Worker',
          validation: { requiredIf: {
            dep: 'type',
            oneOf: ['job-work', 'worker-not-available'],
          } },
        },
        truck: {
          type: 'String',
          label: 'Truck',
          validation: { required: false },
          options: [
            { value: 'truck-101', primary: 'Truck 101' },
            { value: 'truck-102', primary: 'Truck 102' },
            { value: 'truck-106', primary: 'Truck 106 - Porty' },
            { value: '1-ton-roro', primary: '1-Ton - Roll On/Off' },
            { value: 'pickup-truck-brent', primary: 'Pickup - Brent' },
            { value: 'pickup-truck-dan', primary: 'Pickup - Dan' },
            { value: 'truck-and-trailer', primary: 'Truck & Trailer' },
            { value: 'mom-303.5', primary: 'MOB 303.5' },
            { value: 'other', primary: 'Other Truck' },
            { value: 'none', primary: 'No Truck' },
          ],
        },
        type: {
          type: 'String',
          label: 'Event Type',
          options: [
            { value: 'job-work', primary: 'Scheduled Job Work' },
            { value: 'worker-not-available', primary: 'Worker Not Available' },
            { value: 'other', primary: 'Reminder' },
            { value: 'porta-billing', primary: 'Porta Billing' },
            { value: 'stat-holiday', primary: 'Stat Holiday' },
          ],
          validation: { required: true },
        },
        allDay: { type: 'Boolean', validation: { required: false } },
        data: { type: 'Json', validation: { required: false } },
        rdata: { type: 'Json', validation: { required: false } },

        'rdata.rRuleSet': { type: 'String', validation: { required: false } },
      },
    },
  },
};
