import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import EditIcon from 'mdi-material-ui/Pencil';
import { Print as PrintIcon } from '@material-ui/icons';
import { GeneratePdfButton } from 'src/kiska/components/PdfGenerator';
import { buildPaperStyles } from './styles';
import { JobBoardJobView } from './JobBoardJobView';

const useStyles = makeStyles((theme) => {
  const paperStyles = buildPaperStyles(theme);

  return {
    root: {
      padding: theme.spacing(1.5),
      margin: theme.spacing(0),
      position: 'relative',
      transform: `rotate(0)`,
      width: '100%',
      maxWidth: 500,
      '&:focus': {
        outlineColor: `hsla(60, 100%, 50%, 100%)`,
        outlineStyle: 'dashed',
        outlineWeight: `1px`,
      },
      // perspective: 500,
    },
    inner: {
      cursor: 'move',
      backgroundColor: 'hsla(60, 100%, 94%)',
      position: 'relative',
      padding: theme.spacing(0, 0),
      transform: `translateZ(0px)`,
      transformStyle: 'preserve-3d',
      transition: `transform 300ms ease-in-out`,
      ...paperStyles.box,
      '& .before, & .after': paperStyles.beforeAndAfter,
      '& .after': paperStyles.after,
      '& .before': paperStyles.before,
      '& .topRightFold': paperStyles.topRightFold,
      '&.dragging': {
        // transform: `rotate(0deg) scale(1) rotateX(10deg)`,
      },
    },
    content: {
      padding: theme.spacing(1, 1),
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(-1),
    },
    actionButton: {
      opacity: 0.4,
      '&:hover': {
        opacity: 1,
      },
    },
  };
});

const DraggableJob = (props) => {
  const { index, item, job, onUpdate } = props;
  const classes = useStyles(props);
  const printRef = useRef();

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classNames(snapshot.isDragging && 'dragging', classes.root)}
          style={{
            ...provided.draggableProps.style,
          }}
        >
          <div className={classNames(classes.inner, snapshot.isDragging && 'dragging')}>

            <div className="before" />
            <div className="after" />
            <div className="topRightFold" />

            <div className={classes.content} ref={printRef}>
              <JobBoardJobView job={job} />
            </div>

            <div className={classes.actions}>
              <GeneratePdfButton
                mode="print"
                component={IconButton}
                targetRef={printRef}
                name={job.title}
                className={classes.actionButton}
              >
                <PrintIcon />
              </GeneratePdfButton>

              <IconButton className={classes.actionButton} onClick={onUpdate}><EditIcon /></IconButton>
            </div>

          </div>
        </div>
      )}
    </Draggable>
  );
};

DraggableJob.propTypes = {
  job: PropTypes.object,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
DraggableJob.defaultProps = {
  job: undefined,
};

export { DraggableJob };
