import React from 'react';
import PropTypes from 'prop-types';
// import { Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { green, red, purple, blue, orange, yellow, lightGreen, lightBlue, pink, deepOrange, deepPurple, amber, indigo, teal, cyan, lime } from '@material-ui/core/colors';

const textHeight = 7;

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: textHeight,
  },
}));

const DiagramSvg = ({ className, mode, text, thicknessText }) => {
  const theme = useTheme();
  const classes = useStyles();

  const marginTop = 0;
  const materialWidth = 15;

  let dimensionHeight;
  let dimensionPoints;

  if (mode === 'inside') {
    dimensionHeight = (50 - materialWidth - marginTop) / 2 + marginTop;
    dimensionPoints = `
      ${materialWidth}, ${dimensionHeight}
      ${100 - materialWidth}, ${dimensionHeight}
    `;
  } else if (mode === 'outside') {
    dimensionHeight = (50 - materialWidth - marginTop) / 2 + marginTop;
    dimensionPoints = `
      0, ${dimensionHeight}
      100, ${dimensionHeight}
    `;
  } else {
    console.error(`Unknown SVG mode "${mode}"`);
    return null;
  }

  const dimensionFill = theme.palette.text.secondary;

  return (
    <svg className={classNames(className)} viewBox="0 0 100 50">
      <defs>
        <marker
          id="arrow"
          viewBox="0 0 10 10"
          refX="10"
          refY="5"
          markerWidth="5"
          markerHeight="5"
          orient="auto-start-reverse"
        >
          <path stroke={dimensionFill} fill={dimensionFill} d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
      </defs>

      {/* Material */}
      <g fill={orange[800]}>
        <polyline
          points={`
            0, ${marginTop}
            0, 100
            100, 50
            100, ${marginTop}
            ${100 - materialWidth}, ${marginTop}
            ${100 - materialWidth}, ${50 - materialWidth}
            ${materialWidth}, ${50 - materialWidth}
            ${materialWidth}, ${marginTop}
          `}
        />
      </g>

      {/* Measure lines */}
      <g stroke={dimensionFill} fill={dimensionFill} strokeWidth=".75">
        <polyline points={dimensionPoints} markerStart="url(#arrow)" markerEnd="url(#arrow)" />
        {thicknessText && <polyline points={`50,${50 - materialWidth} 50,50`} markerStart="url(#arrow)" markerEnd="url(#arrow)" />}
      </g>

      {/* Text */}
      <text
        className={classes.text}
        fill={dimensionFill}
        textAnchor="middle"
        x="50"
        y={dimensionHeight - textHeight / 2}
      >
        {text}
      </text>
      {thicknessText && (
        <text
          className={classes.text}
          fill={dimensionFill}
          textAnchor="left"
          dominantBaseline="text-after-edge"
          x="55"
          y={50 - (materialWidth / 2) + (textHeight / 2)}
        >
          {thicknessText}
        </text>
      )}
    </svg>
  );
};

DiagramSvg.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string.isRequired,
  text: PropTypes.string,
  thicknessText: PropTypes.string,
};
DiagramSvg.defaultProps = {
  className: '',
  text: '',
  thicknessText: undefined,
};

export default DiagramSvg;
