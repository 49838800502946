import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { usePrint } from 'src/kiska/components/PdfGenerator';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    '&.bottom-right': {
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
    '&.fixed': {
      position: 'fixed',
    },
    '&.hidden': {
      display: 'none',
    },
    zIndex: 100,
  },
  marker: {
    width: 0,
    flexBasis: 0,
    height: theme.kiska.type === 'print' ? 0 : 74,
  },
}));

const isScrolledIntoView = (el, partial = true) => {
  const { top, bottom } = el.getBoundingClientRect();
  let isVisible;

  if (partial) isVisible = top < window.innerHeight && bottom >= 0;
  else isVisible = (top >= 0) && (bottom <= window.innerHeight);

  return isVisible;
};

const FloatingButton = (props) => {
  const classes = useStyles(props);
  const markerRef = useRef();
  const { printMode } = usePrint();
  const [containerInView, setContainerInView] = useState(null);
  const [markerInView, setMarkerInView] = useState(null);
  const { position, containerRef, className, ...fabProps } = props;

  const handleScroll = useCallback(() => {
    if (!markerRef.current || !containerRef.current) return;
    setMarkerInView(isScrolledIntoView(markerRef.current));
    setContainerInView(isScrolledIntoView(containerRef.current));
  }, [containerRef]);

  useEffect(() => {
    handleScroll();
    const intervalHandle = setInterval(handleScroll, 1000);
    return () => clearInterval(intervalHandle);
  }, [handleScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, markerRef]);

  if (printMode) return null;

  const style = {};
  if (!markerInView && containerRef.current) {
    const rect = containerRef.current.getBoundingClientRect();
    style.right = window.document.body.clientWidth - rect.right + 8;
  }

  return (
    <>
      <Fab
        className={classNames(className, classes.root, position, !markerInView && 'fixed', !containerInView && 'hidden')}
        style={style}
        {...fabProps}
      />
      <div ref={markerRef} className={classes.marker} />
    </>
  );
};

FloatingButton.propTypes = {
  position: PropTypes.string,
  containerRef: PropTypes.object.isRequired,
  className: PropTypes.string,
};
FloatingButton.defaultProps = {
  position: 'bottom-right',
  className: undefined,
};

export { FloatingButton };
