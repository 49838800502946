import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton, ListItemText, ListItemIcon, ListSubheader, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Menu as MenuIcon, ChevronRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from './contexts/UserContext';

// const useStyles = makeStyles((theme) => ({
// }));

const PopoverMenu = React.forwardRef(({ trigger: propsTrigger, menu, _closeParent, _parentRoles, context }, ref) => {
  // const classes = useStyles();
  const menuRoles = menu.roles || _parentRoles;
  const auth = useAuth0();
  const { user } = useUser();

  if (!menuRoles.includes(user.role.id)) return null;

  const valueOrFunc = (value) => {
    if (typeof value === 'function') return value({ auth, user });
    return value;
  };

  return (
    <PopupState variant="popover" popupId="popup-menu">
      {(popupState) => {
        const trigger = React.cloneElement(propsTrigger, bindTrigger(popupState));

        const handleClick = (item) => () => {
          popupState.close();
          if (_closeParent) _closeParent();

          if (item.action) {
            item.action({ auth, user });
          }
        };

        const itemsList = menu.items.map((item, index) => {
          const itemRoles = item.roles || menuRoles;

          if (item.contexts) {
            const contexts = Array.isArray(item.contexts) ? item.contexts : item.contexts.split(/[\s,]+/);
            if (context && !contexts.includes(context)) return null;
          }
          if (!itemRoles.includes(user.role.id)) return null;

          // Menu Divider
          if (item.divider) {
            return <Divider key={index} />;
          }

          // Menu Header
          if (item.header) {
            return (
              <ListSubheader key={index}>{valueOrFunc(item.header)}</ListSubheader>
            );
          }

          // Now we'll need to render an actual menu item so prepare the content
          const menuItemContent = (
            <>
              {item.icon && <ListItemIcon>{valueOrFunc(item.icon)}</ListItemIcon>}
              <ListItemText primary={valueOrFunc(item.primary)} secondary={item.secondary} />
              {item.items && <ChevronRight />}
            </>
          );

          // Nested menu, render the next level
          if (item.items) {
            return (
              <PopoverMenu
                // onClick={handleClick(item)}
                key={index}
                menu={item}
                trigger={<MenuItem> {menuItemContent} </MenuItem>}
                _closeParent={handleClick(item)}
                _parentRoles={itemRoles}
              />
            );
          }

          // Normal menu item link
          const localLink = !!item.to;
          const linkComponent = localLink ? Link : 'a';

          return (
            <MenuItem
              onClick={handleClick(item)}
              key={index}
              to={valueOrFunc(item.to)}
              href={valueOrFunc(item.href)}
              component={linkComponent}
            >
              {menuItemContent}
            </MenuItem>
          );
        });

        if (menu.group) {
          return itemsList;
        }

        return (
          <>
            {trigger}
            <Menu {...bindMenu(popupState)}>
              {itemsList}
            </Menu>
          </>
        );
      }}
    </PopupState>
  );
});

PopoverMenu.propTypes = {
  menu: PropTypes.object.isRequired,
  trigger: PropTypes.node,
  context: PropTypes.string,
  _closeParent: PropTypes.func,
  _parentRoles: PropTypes.array,
};
PopoverMenu.defaultProps = {
  trigger: <IconButton color="inherit"><MenuIcon /></IconButton>,
  context: undefined,
  _closeParent: undefined,
  _parentRoles: undefined,
};
PopoverMenu.displayName = 'PopoverMenu';

export default PopoverMenu;
