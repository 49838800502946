import React, { cloneElement, forwardRef, useContext } from 'react';
// import PropTypes from 'prop-types';
// import _ from 'lodash';
import { makeStyles } from '@material-ui/core';

import CrudForm from 'src/components/updates/CrudForm';
import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { JobForm } from './JobForm';
import { LocalNodeProvider } from '../../../kiska/components/LocalNodeContext';

export const useStyles = makeStyles((theme) => ({

}));

const JobUpdateFormInner = (props) => {
  const classes = useStyles(props);
  const { getAllFieldValues } = useContext(MutationContext);
  const node = getAllFieldValues({ jsonFieldsToObjects: true, includeNode: true });

  return (
    <LocalNodeProvider node={node} type="job">
      <br />
      <JobForm />
    </LocalNodeProvider>
  );
};

JobUpdateFormInner.propTypes = {
  // variant: PropTypes.string,
};
JobUpdateFormInner.defaultProps = {
  // variant: '',
};

const JobUpdateForm = forwardRef((props, forwardedRef) => {
  const { schema } = useSchema();
  const classes = useStyles(props);
  const { id, onNodeChange, trigger: propsTrigger, forcedInitialValues, className, onClose, open, variant, fixtures: propsFixtures, onSuccess, initialValues: propsInitialValues } = props;

  const fixtures = {
    ...propsFixtures,
  };

  const initialValues = {
    ...propsInitialValues,
  };

  if (!id) {
    fixtures.status = schema.types.job.fields.status.defaultValue;
  }

  const trigger = propsTrigger ? cloneElement(propsTrigger, { className, ref: forwardedRef }) : propsTrigger;

  return (
    <CrudForm
      type="job"
      id={id}
      onNodeChange={onNodeChange}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderRichTitle={(node) => (node ? <>{node.shortId} - {node.title}</> : 'Create Job')}
      renderTitle={(node) => (node ? `${node.title} - ${node.shortId}` : 'Create Job')}
      fixtures={fixtures}
      initialValues={initialValues}
      onSuccess={onSuccess}
      fullScreen
      classes={{ content: classes.dialogContent }}
      blurMode
      maxWidth="md"
      forcedInitialValues={forcedInitialValues}
    >
      <JobUpdateFormInner variant={variant} />
    </CrudForm>
  );
});

JobUpdateForm.displayName = 'JobUpdateForm';
export { JobUpdateForm };
