import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormSection } from 'src/components/forms';
import { Grid } from 'src/kiska/components/Grid';
import TagIcon from 'mdi-material-ui/Tag';
import { SimpleTable } from './SimpleTable';
import { TagUpdateForm } from './TagUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
}));

const DataTab = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <FormSection title="Tags" icon={TagIcon}>
        <Grid item xs={12}>
          <SimpleTable
            searchPlaceholder="Search by label"
            searchPaths="label"
            type="tag"
            initial={{ limit: 200 }}
            columns={['label']}
            UpdateForm={TagUpdateForm}
            updateFormProps={{}}
            actionsOnBottom={false}
            actionsOnTop={false}
            enableAdd={false}
            enableEdit
            showSearchBox
            rowClickAction="edit"
          />
        </Grid>
      </FormSection>
    </div>
  );
};

DataTab.propTypes = {
};
DataTab.defaultProps = {
};

export { DataTab };
