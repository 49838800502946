import React, { useContext, useCallback, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { People as UsersIcon } from '@material-ui/icons';
import { startOfToday, formatISO } from 'date-fns';
import { pluralCount } from 'src/kiska/utils';
import { useNodes } from 'src/kiska/hooks/useNode';
import { NodeListWithVars } from 'src/kiska/components/NodeList/NodeListWithVars';
import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { List } from './List';
import UserAvatar from '../modules/user/UserAvatar';
import { UserUpdateForm } from '../modules/user/UserUpdateForm';

// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

const UserListInner = () => {
  // const classes = useStyles();
  const { setTitles } = useContext(PageMetaContext);
  const { user: currentUser } = useUser();

  const allowedToAddUsers = ['admin', 'manager', 'office-admin'].includes(currentUser.role.id);
  const allowedToEditUsers = ['admin', 'manager', 'office-admin'].includes(currentUser.role.id);

  useEffect(() => {
    setTitles('Users');
  }, [setTitles]);

  const { nodes: userHoursData } = useNodes({
    type: 'user',
    where: {},
    selections: `
      id
      workEntries_aggregate(where: {
        start: { _gte: "${formatISO(startOfToday())}" }
      }){
        aggregate{
          jobs: count(columns: jobId, distinct: true)
          sessions: count(columns: id, distinct: true)
          sum{duration}
        }
      }
    `,
  });

  const getUser = useCallback((node) => {
    if (!userHoursData || !userHoursData.length) return 0;
    const user = userHoursData.find((u) => u.id === node.id);
    return user;
  }, [userHoursData]);

  const getStat = useCallback((node) => {
    const user = getUser(node);
    if (!user) return null;
    const duration = user.workEntries_aggregate.aggregate.sum.duration;
    return (duration / 60 / 60).toFixed(1);
  }, [getUser]);

  const getSubtitle = useCallback((node) => {
    const user = getUser(node);
    if (!user) return null;
    const jobs = user.workEntries_aggregate.aggregate.jobs;
    const sessions = user.workEntries_aggregate.aggregate.sessions;
    return <>{pluralCount(sessions, 'work session', true)} on {pluralCount(jobs, 'job', true)} today</>;
  }, [getUser]);

  return (
    <List
      listTitle="Users"
      listIcon={UsersIcon}
      searchPaths="email displayName"
      title={(node) => node.displayName}
      titleIcon={(node) => <UserAvatar node={node} size={36} />}
      subtitle={getSubtitle}
      stat={getStat}
      substat={(node) => `Hours Today`}
      clickUrl={(node) => `/app/users/${node.id}/work-log`}
      createText="New User"
      createForm={allowedToAddUsers && <UserUpdateForm />}
      updateForm={allowedToEditUsers && <UserUpdateForm />}
    />
  );
};

const UserList = (props) => {
  const { user } = useUser();
  const initial = {
    orderBy: [{ updatedAt: 'desc' }],
  };

  const fixtures = [];
  if (user.role.id !== 'admin') fixtures.push({ path: 'where._not.options._contains', value: { systemAccount: true } });

  return (
    <NodeListWithVars type="user" initial={initial} fixtures={fixtures}>
      <UserListInner {...props} />
    </NodeListWithVars>
  );
};

UserList.propTypes = {
};
UserList.defaultProps = {
};

export { UserList };
