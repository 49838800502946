import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import { generatePrimaryId } from 'src/kiska/utils';
import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import produce from 'immer';
import { FilePreview } from './FilePreview';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
}));

const FilePreviewsContainer = (props) => {
  const classes = useStyles(props);
  const { files: propsFiles, className, enableView, onDelete, ...previewProps } = props;
  const { open, openDialog, closeDialog } = useLocationControl();
  const settings = useSettings();
  const [lightboxIndex, setLightboxIndex] = useState(0);

  let files;
  if (!propsFiles) files = [];
  else if (!Array.isArray(propsFiles)) files = [propsFiles];
  else files = propsFiles;
  files = files.filter((file) => !!file);

  const handleDelete = (file) => () => {
    onDelete(file);
  };

  if (!files || !files.length) return null;

  const images = files.filter((file) => file.mimeType.startsWith('image/'));

  const handlePreviewClick = (file) => () => {
    if (file.mimeType.startsWith('image/')) {
      const index = images.findIndex((image) => image.id === file.id);

      openDialog();
      setLightboxIndex(index);
    } else {
      console.log(`${settings.public.kiskaOrigin}${file.url}`);
      window.open(`${settings.public.kiskaOrigin}${file.url}`, '_blank');
    }
  };

  const prevIndex = (lightboxIndex + images.length - 1) % images.length;

  return (
    <div className={classNames(className, classes.root)}>
      {files.map((file) => (
        <FilePreview
          key={file.id}
          file={file}
          className={classes.preview}
          onClick={enableView ? handlePreviewClick(file) : undefined}
          onDelete={onDelete ? handleDelete(file) : undefined}
          {...previewProps}
        />
      ))}
      {enableView && open && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          mainSrc={images[lightboxIndex].url}
          nextSrc={images[(lightboxIndex + 1) % images.length].url}
          prevSrc={images[prevIndex].url}
          onCloseRequest={closeDialog}
          onMovePrevRequest={() => setLightboxIndex(lightboxIndex ? ((lightboxIndex - 1) % images.length) : images.length - 1)}
          onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
          reactModalProps={{
            shouldReturnFocusAfterClose: false,
            shouldFocusAfterRender: false,
          }}
        />
      )}
    </div>
  );
};

FilePreviewsContainer.propTypes = {
  showProgress: PropTypes.bool,
  showName: PropTypes.bool,
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  enableView: PropTypes.bool,
  onDelete: PropTypes.func,
};
FilePreviewsContainer.defaultProps = {
  showProgress: true,
  showName: true,
  files: undefined,
  enableView: true,
  onDelete: undefined,
};

export { FilePreviewsContainer };
