import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import CrudForm from '../../updates/CrudForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
}));

const PayRateUpdateFormInner = (props) => {
  const classes = useStyles(props);
  // const { node } = useContext(MutationContext);
  // const { user } = useUser();

  const rateAdornments = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
    endAdornment: <InputAdornment position="start">/ hour</InputAdornment>,
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4} alignItems="flex-start">

        <Grid item xs={12} sm={12}>
          <TextField name="label" label="Name" />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            name="price"
            helperText="How much your customers pay for this service. You can choose to show this on customer and job statements for billable hours."
            InputProps={rateAdornments}
            realNumber
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            name="cost"
            helperText="How much these hours cost your business or how much you pay the employee/contractor for these hours."
            InputProps={rateAdornments}
            realNumber
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <NodeField name="tasks" helperText="All the job tasks that can be done for this rate." />
        </Grid>

        {/* <Grid item xs={12} sm={12}>
        <TextField name="description" rows={3} helperText="This is helpful" />
      </Grid> */}

      </Grid>
    </div>
  );
};

PayRateUpdateFormInner.propTypes = {
  variant: PropTypes.string,
};
PayRateUpdateFormInner.defaultProps = {
  variant: undefined,
};

const PayRateUpdateForm = ({ id, trigger, onClose, open, variant }) => {
  return (
    <CrudForm
      type="pay_rate"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderRichTitle={(n) => (n ? <>Edit Pay Rate: {n.label}</> : 'Create Pay Rate')}
      renderTitle={(n) => (n ? `Edit Pay Rate - ${n.label}` : 'Create Pay Rate')}
      maxWidth="md"
      allowDelete
    >
      <PayRateUpdateFormInner variant={variant} />
    </CrudForm>
  );
};

PayRateUpdateForm.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string,
};
PayRateUpdateForm.defaultProps = {
  id: undefined,
  trigger: undefined,
  onClose: undefined,
  open: undefined,
  variant: '',
};

export { PayRateUpdateForm };
