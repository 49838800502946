import { green, red, purple, blue, orange, yellow, lightGreen, lightBlue, pink, deepOrange, deepPurple, amber, indigo, teal, cyan, lime } from '@material-ui/core/colors';
import { startOfDay, addDays, parseISO, differenceInCalendarDays } from 'date-fns';
import _ from 'lodash';
import Color from 'color';
import { parseDate } from 'src/kiska/utils';

const makeDayArray = (start, end) => {
  const nStart = parseISO(start);
  const nEnd = addDays(parseISO(end), 1);
  const count = differenceInCalendarDays(nEnd, nStart) || 1;
  const days = new Array(count).fill(0);
  const daysDateIndex = {};
  const daysDates = new Array(count);

  days.forEach((a, i) => {
    const day = addDays(nStart, i);
    const dateString = day.toISOString().slice(0, 10);
    daysDates[i] = dateString;
    daysDateIndex[dateString] = i;
  });

  return { days, daysDateIndex, daysDates };
};

const getDefaultChartOptions = (theme) => {
  const fontFamily = '"Exo 2", sans-serif';
  const fontColor = theme.palette.text.secondary;
  const lineColor = theme.palette.border.light;

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      titleFontFamily: fontFamily,
      bodyFontFamily: fontFamily,
      titleFontSize: 16,
      bodyFontSize: 16,
    },
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        fontColor,
        boxWidth: 20,
        fontSize: 14,
        fontFamily,
        usePointStyle: false,
      },
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
          color: lineColor,
          borderDash: [5, 10],
        },
        scaleLabel: {
          display: true,
          fontFamily,
          fontColor,
          fontSize: 16,
        },
        ticks: {
          fontFamily,
          color: lineColor,
          fontColor,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          fontFamily,
          fontColor,
          fontSize: 16,
        },
        gridLines: {
          zeroLineColor: lineColor,
          borderDash: [5, 10],
          color: lineColor,
        },
        ticks: {
          min: 0,
          fontFamily,
          fontColor,
          color: lineColor,
        },
      }],
    },
  };

  return defaultOptions;
};

const colors = [
  green[500],
  orange[500],
  blue[500],
  red[500],
  yellow[500],
  lightGreen[500],
  lightBlue[500],
  pink[500],
  deepOrange[500],
  deepPurple[500],
  amber[500],
  indigo[500],
  teal[500],
  cyan[500],
  purple[500],
  lime[500],
  // green[100],
  // orange[100],
  // blue[100],
  // red[100],
  // yellow[100],
  // lightGreen[100],
  // lightBlue[100],
  // pink[100],
  // deepOrange[100],
  // deepPurple[100],
  // amber[100],
  // indigo[100],
  // teal[100],
  // cyan[100],
  // purple[100],
  // lime[100],
  green[900],
  orange[900],
  blue[900],
  red[900],
  yellow[900],
  lightGreen[900],
  // lightBlue[900],
  pink[900],
  deepOrange[900],
  deepPurple[900],
  amber[900],
  indigo[900],
  teal[900],
  cyan[900],
  purple[900],
  lime[900],
];

let nextColorNumericIndex = 0;
const colorsIndex = {};

const getColorForKey = (key) => {
  if (!colorsIndex[key]) {
    colorsIndex[key] = colors[nextColorNumericIndex % colors.length];
    // colorsIndex[key] = colors[Math.floor(Math.random() * (colors.length - 1 - 0 + 1) + 0)];
    nextColorNumericIndex++;
  }
  return colorsIndex[key];
};

const condenseArray = (input) => {
  const output = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(input)) output.push(value);
  return output;
};

const stackByDatasetLabel = (stackBy, node) => {
  switch (stackBy) {
    case 'userId': return _.get(node, 'user.displayName', '');
    case 'jobId': return _.get(node, 'job.title', '');
    case 'customerId': return _.get(node, 'customer.company') || _.get(node, 'customer.name') || '';
    default: return 'Total';
  }
};

const guessAtBarStackByFrom = (variables) => {
  // Order here is important
  if (variables.args.groupByJob) return 'jobId';
  if (variables.args.groupByCustomer) return 'customerId';
  if (variables.args.groupByUser) return 'userId';
  return 'all';
};

const guessAtPieStackByFrom = (variables) => {
  // Order here is important
  if (!variables.args.groupByCustomer && !variables.args.groupByJob && variables.args.groupByUser) return 'all';
  if (variables.args.groupByUser) return 'userId';
  return 'all';
};

const guessAtPieLabelColumn = (variables, node) => {
  const stackBy = guessAtBarStackByFrom(variables);
  return stackByDatasetLabel(stackBy, node);
};

export { guessAtPieLabelColumn, guessAtPieStackByFrom, colors, getDefaultChartOptions, makeDayArray, getColorForKey, condenseArray, stackByDatasetLabel, guessAtBarStackByFrom };
