/* eslint-disable react/prop-types */
import React from 'react';

export default {
  types: {
    signature: {

      renderers: {
        inline: ({ node }) => <>{node.title} - v{node.version}</>,
      },

      selections: {
        default: 'id name title date file',
      },

      fields: {
        date: {
          type: 'Date',
          validation: {
            required: false,
          },
        },

        name: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        title: {
          type: 'String',
          validation: {
            required: false,
          },
        },

        file: {
          type: 'Json',
          label: 'Signature',
          validation: {
            required: true,
          },
        },

        formId: {
          type: 'String',
          validation: { required: true },
        },

        form: {
          type: 'form',
        },

      },

    },
  },
};
