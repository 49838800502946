import React from 'react';
import _ from 'lodash';

const fractions = {};

const maxDenominator = 128;

const FormattedFraction = ({ whole, fraction }) => (
  <span style={{}}>
    {whole && `${whole} `}
    <sup style={{ fontSize: '.5em' }}>{fraction.numerator}</sup>
    <span style={{ fontSize: '1em', fontWeight: 100 }}>/</span>
    <sub style={{ fontSize: '.5em', verticalAlign: 'baseline' }}>{fraction.denominator}</sub>
  </span>
);

const reduce = (numerator, denominator) => {
  let gcd = function gcd(a, b) {
    return b ? gcd(b, a % b) : a;
  };
  gcd = gcd(numerator, denominator);
  return [numerator / gcd, denominator / gcd];
};

for (let i = 1; i < maxDenominator; i++) {
  const value = i / maxDenominator;
  const [n, d] = reduce(i, maxDenominator);
  const label = `${n}/${d}`;
  fractions[label] = {
    label,
    value,
    denominator: d,
    numerator: n,
    formattedFraction: <FormattedFraction fraction={{ numerator: n, denominator: d }} />,
  };
}

const closestFractionUnformatted = (x, precisionDemoninator) => {
  const fracs = _.pickBy(fractions, (f) => f.denominator <= precisionDemoninator);
  const rem = x % 1;

  let bestFrac;
  let bestError = 10;

  _.forEach(fracs, (f) => {
    const error = f.value - rem;
    if (Math.abs(error) < Math.abs(bestError)) {
      bestFrac = f;
      bestError = error;
    }
  });

  return bestFrac;
};

const closestFraction = (x, precisionDemoninator) => {
  const whole = Math.floor(x);
  const fraction = closestFractionUnformatted(x, precisionDemoninator);
  const nextBestFraction = closestFractionUnformatted(x, precisionDemoninator * 2);

  let modifier = '';
  // let modifier = '○';
  // let modifier = '⦵';

  if (nextBestFraction.denominator === fraction.denominator * 2) {
    if (nextBestFraction.value > fraction.value) modifier = '+';
    else if (nextBestFraction.value < fraction.value) modifier = '-';
  }

  return (
    <span>
      <FormattedFraction fraction={fraction} whole={whole} />&nbsp;
      <span style={{ fontSize: '.5em' }}>{modifier}</span>
    </span>
  );
};

export { closestFraction, reduce, fractions };
