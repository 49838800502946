import React, { useState, useContext, useCallback, cloneElement, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery, Typography, Dialog, Button, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MobileDatePicker, LocalizationProvider, StaticDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { format, isWithinInterval } from 'date-fns';
import { green, orange, purple, blue, red } from '@material-ui/core/colors';
import Color from 'color';

import { getPayPeriod, periodIndexInSet, buildPayPeriodConfig } from './pay-periods';
import { useAppSettings } from '../contexts/AppSettingsContext';

const useStyles = makeStyles((theme) => ({
  '@global': {
    'button[aria-label="calendar view is open, go to text input view"]': {
      display: 'none',
    },
  },
  label: {
    textTransform: 'none',
  },
  labelTitle: {
    textTransform: 'capitalize',
  },
  day: {
    textAlign: 'center',
    width: 38,
    height: 36,
    // borderRadius: '50%',
    display: 'inline-flex',
    // cursor: 'pointer',
    // outline: 0,
    // position: 'relative',
    // alignItems: 'center',
    // userSelect: 'none',
    color: '#FFF',
    '&:hover': {

    },
  },
}));

const periodColors = [
  green[400],
  orange[400],
  // purple[300],
  // blue[300],
  // red[300],
];

const PayPeriodPicker = ({ onChange, onClose, onOpen, open: propsOpen, trigger: propsTrigger, staticMode }) => {
  const { appSettings } = useAppSettings();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [stateOpen, setStateOpen] = useState(false);
  const [value, setValue] = useState(new Date());
  const pickerFormat = 'MMM d, yyyy';
  const controlledMode = propsOpen !== undefined;
  const [hoverPeriod, setHoverPeriod] = useState(null);

  const open = controlledMode ? propsOpen : stateOpen;

  const payPeriodConfig = appSettings.data.payPeriodConfig;

  const Component = staticMode ? StaticDatePicker : MobileDatePicker;

  const close = useCallback(() => {
    if (controlledMode) {
      if (onClose) onClose();
    } else {
      setStateOpen(false);
      if (onClose) onClose();
    }
  }, [controlledMode, onClose]);

  const trigger = propsTrigger ? cloneElement(propsTrigger, {
    onClick: () => {
      if (controlledMode) {
        if (onOpen) onOpen();
      } else {
        setStateOpen(true);
        if (onOpen) onOpen();
      }
    },
  }) : null;

  const handleChange = useCallback((newValue) => {
    let period = getPayPeriod(payPeriodConfig, newValue);
    period = {
      gte: period.start,
      lte: period.end,
    };

    console.log('Pay period config start = ', format(payPeriodConfig.start, 'EEEE MMM d hh:mm.sss'));
    console.log('Pay period start = ', format(period.gte, 'EEEE MMM d HH:mm.sss'));
    console.log('Pay period end = ', format(period.lte, 'EEEE MMM d HH:mm.sss'));
    setValue(newValue);
    onChange(period);
    close();
  }, [close, onChange, payPeriodConfig]);

  const renderDay = useCallback((day, selectedDates, dayProps) => {
    const { key, disabled, inCurrentMonth, onDaySelect, today } = dayProps;
    const colorsIndex = periodIndexInSet(payPeriodConfig, day, periodColors.length);
    const color = periodColors[colorsIndex];
    const inPeriodColor = Color(color).darken(0.2).saturate(0).string();
    const thisPeriod = getPayPeriod(payPeriodConfig, day);
    const inHoverPeriod = hoverPeriod ? isWithinInterval(day, hoverPeriod) : false;

    // console.log('hover period: ', hoverPeriod);
    // console.log('in period?', inHoverPeriod);

    return (
      <ButtonBase
        key={key}
        className={classes.day}
        disabled={disabled}
        onClick={() => onDaySelect(day)}
        style={{
          backgroundColor: inHoverPeriod ? inPeriodColor : color,
          opacity: inCurrentMonth || inHoverPeriod ? 1 : 0.8,
        }}
        onMouseEnter={() => {
          setHoverPeriod(thisPeriod);
        }}
        onMouseLeave={() => setHoverPeriod(null)}
      >
        <Typography
          style={{
            fontWeight: today || inHoverPeriod ? 500 : 400,
            fontSize: inHoverPeriod ? '1.5em' : undefined,
          }}
          color="inherit"
          variant="body2"
          component="span"
        >
          {format(day, 'd')}
        </Typography>
      </ButtonBase>
    );
  }, [classes.day, hoverPeriod, payPeriodConfig]);

  return (
    <>
      {trigger}
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <Component
          open={open}
          onClose={close}
          value={value}
          // label={(
          //   <div className={classes.label}>
          //     <Typography variant="h5" align="center" className={classes.labelTitle}>Select Pay Period</Typography>
          //     <Typography variant="body1" className={classes.titleContent} style={{ fontWeight: 500 }}>
          //       Pick any day that falls within the desired pay period.
          //       The start and end dates will automatically be calculated for you.
          //     </Typography>
          //   </div>
          // )}
          label=""
          renderInput={() => null}
          allowKeyboardControl={false}
          onChange={handleChange}
          className={classes.picker}
          format={pickerFormat}
          autoOk
          onAccept={handleChange}
          renderDay={renderDay}
          ampmInClock
          shouldDisableDate={false}
          shouldDisableYear={false}
          showToolbar={false}
        />
      </LocalizationProvider>
    </>

  );
};

PayPeriodPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  trigger: PropTypes.node,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  staticMode: PropTypes.bool,
};
PayPeriodPicker.defaultProps = {
  onOpen: undefined,
  onClose: undefined,
  trigger: undefined,
  open: undefined,
  staticMode: false,
};

export { PayPeriodPicker };
