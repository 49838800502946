import React from 'react';
import { useApolloClient } from '@apollo/client';

const withApolloClient = (WrappedComponent) => (props) => {
  const client = useApolloClient();

  return <WrappedComponent {...props} client={client} />;
};

export {withApolloClient};
