import React from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';

import { addIdsToSelections, generatePrimaryId } from '../../utils';

const defaultValidationFailedMessage = (mutation, validationErrors) => 'There are errors in the data you have entered.';
const pickServerFieldValuesOnly = (values, schemaType) => _.pick(values, schemaType.serverFields);

const errorMessage = (crudType, mutation, error) => {
  const { schemaType } = mutation;
  const typeLabel = schemaType.label;

  const uniqueViolation = /violates unique constraint "(.*)_(.*)_.*"/.exec(error);
  if (uniqueViolation) {
    return (
      <span>
        Sorry, we can't {crudType} that {_.lowerCase(typeLabel)} because you already have
        a <strong>{uniqueViolation[1]}</strong> with that <strong>{_.lowerCase(uniqueViolation[2])}</strong>
      </span>
    );
  }

  if (crudType === 'create') return `Sorry, there was an error creating ${typeLabel}`;
  if (crudType === 'update') return `Sorry, there was an error updating that ${typeLabel}`;
  if (crudType === 'delete') return `Sorry, there was an error creating ${typeLabel}`;
  return `Sorry, there was an unexpected error processing that operation`;
};

const getSelections = (mutationName, schemaType) => {
  const mutations = schemaType.selections.mutations;
  let selections = '';

  if (typeof mutations === 'string') {
    selections = mutations;
  } if (typeof mutations === 'object' && mutations[mutationName]) {
    selections = mutations[mutationName];
  } else {
    selections = schemaType.selections.default;
  }

  selections = addIdsToSelections(selections);

  return selections;
};

const transformOneToOneRelations = ({ type, schemaType, schema, object, node }) => {
  _.forEach(object, (value, field) => {
    const schemaField = schemaType.fields[field];
    if (schemaField.isScaler || schemaField.hasMany) return;
    if (value === undefined) return;

    if (value.connect) {
      object[`${field}Id`] = value.connect.id;
    } else {
      object[`${field}Id`] = null;
    }

    delete object[field];
  });
};

const addSearchField = ({ schemaType, node, object }) => {
  if (!schemaType.searchFields || !schemaType.searchFields.length) return;
  object._search = schemaType.searchFields.reduce((acc, path) => {
    let value = _.get(object, path);
    if (value === undefined) value = _.get(node, path);
    if (value) return `${acc} ${value}`;
    return acc;
  }, '');
};

const getManyToManyRelationQueries = ({ type, schemaType, schema, object, node }) => {
  const relations = {
    queries: [],
    variables: {},
    variableDeclarations: [],
  };

  _.forEach(object, (value, field) => {
    if (!schemaType.fields[field].hasMany) return;
    if (schemaType.fields[field].embedded) return;

    value.connect = value.connect || [];
    value.disconnect = value.disconnect || [];

    const relatedSchemaType = schema.types[schemaType.fields[field].type];
    const types = [relatedSchemaType.name, type].sort();
    const table = `${types[0]}__x__${types[1]}`;

    const connectAlias = `connect${_.upperFirst(types[0])}to${_.upperFirst(types[1])}`;
    const connectVariableName = `${field}ConnectObjects`;
    const connectVariableDeclaration = `$${connectVariableName}: [${table}_insert_input!]!`;

    const connectQuery = `
      ${connectAlias}: insert_${table}(objects: $${connectVariableName}){
        returning{id}
      }
    `;

    const connectVariable = value.connect.map(({ id: relatedId }) => ({
      id: generatePrimaryId(),
      [`${type}Id`]: node ? node.id : object.id,
      [`${relatedSchemaType.name}Id`]: relatedId,
    }));

    const disconnectAlias = `disconnect${_.upperFirst(types[0])}to${_.upperFirst(types[1])}`;
    const disconnectVariableName = `${field}DisconnectWhere`;
    const disconnectVariableDeclaration = `$${disconnectVariableName}: ${table}_bool_exp!`;

    const disconnectQuery = `
      ${disconnectAlias}: delete_${table}(where: $${disconnectVariableName}){
        returning{id}
      }
    `;

    const disconnectVariable = {
      id: { _in: value.disconnect.map(({ id }) => id) },
    };

    relations.queries.push(connectQuery, disconnectQuery);
    relations.variables[connectVariableName] = connectVariable;
    relations.variables[disconnectVariableName] = disconnectVariable;
    relations.variableDeclarations.push(connectVariableDeclaration, disconnectVariableDeclaration);

    delete object[field];
  });

  return relations;
};

/** ******************* Insert  *************************** */
const insertMutationBuilderFactory = ({ selections }) => async ({ fields, type, node, schema, values, valuesAsMutationVars }) => {
  const schemaType = schema.types[type];
  const typeLabel = schemaType.label;
  const pascalType = _.upperFirst(type);
  const alias = `insert${pascalType}`;
  const object = pickServerFieldValuesOnly(valuesAsMutationVars, schemaType);
  selections = selections || getSelections('insert', schemaType);

  object.id = generatePrimaryId();
  if (schemaType.fields.createdAt) object.createdAt = (new Date()).toISOString();
  if (schemaType.fields.updatedAt) object.updatedAt = (new Date()).toISOString();

  const relations = getManyToManyRelationQueries({ type, schemaType, schema, object, node });
  transformOneToOneRelations({ type, schemaType, schema, object, node });

  addSearchField({ schemaType, node, object });

  const query = gql`
    mutation Insert${pascalType}(
      $objects: [${type}_insert_input!]!
      ${relations.variableDeclarations.join(', \n')}
      ){

      ${alias}: insert_${type}(objects: $objects){
        returning {
          ${selections}
        }
      }

      ${relations.queries.join('\n\n')}

    }
  `;

  const variables = {
    objects: [object],
    ...relations.variables,
  };

  const hooks = schemaType.hooks;
  if (hooks.willInsert) {
    await hooks.willInsert({ variables, fields, type, node, schema, values, valuesAsMutationVars });
  }

  return {
    query,
    alias,
    variables,
    type,
    schema,
    schemaType,
    operation: 'insert',
    renderSuccessMessage: (mutation, result) => `${typeLabel} created`,
    renderErrorMessage: (mutation, error) => errorMessage('create', mutation, error),
    renderValidationFailedMessage: defaultValidationFailedMessage,
  };
};

/** ******************* Update  *************************** */
const updateMutationBuilderFactory = ({ selections }) => async ({ fields, type, node, schema, values, valuesAsMutationVars }) => {
  const schemaType = schema.types[type];
  const typeLabel = schemaType.label;
  const pascalType = _.upperFirst(type);
  const alias = `update${pascalType}`;
  const object = pickServerFieldValuesOnly(valuesAsMutationVars, schemaType);
  delete object.id;
  selections = selections || getSelections('update', schemaType);

  const relations = getManyToManyRelationQueries({ type, schemaType, schema, object, node });
  transformOneToOneRelations({ type, schemaType, schema, object, node });

  if (schemaType.fields.updatedAt) object.updatedAt = (new Date()).toISOString();

  addSearchField({ schemaType, node, object });

  const set = _.pickBy(object, (field, fieldName) => {
    const schemaField = _.get(schemaType.fields, `${fieldName}`);
    if (schemaField && schemaField.type === 'Json' && !schemaField.hasMany) return false;
    return true;
  });

  const append = _.pickBy(object, (field, fieldName) => {
    const schemaField = _.get(schemaType.fields, `${fieldName}`);
    if (schemaField && schemaField.type === 'Json' && !schemaField.hasMany) return true;
    return false;
  });

  const hasAppend = !!Object.keys(append || {}).length;

  const query = gql`
    mutation Update${pascalType}(
      $where: ${type}_bool_exp!, 
      $set: ${type}_set_input, 
      ${hasAppend ? `$append: ${type}_append_input,` : '# No Append Required'} 
      ${relations.variableDeclarations.join(', \n')}
      ){

      ${relations.queries.join('\n\n')}
      
      ${alias}: update_${type}(where: $where, _set: $set${hasAppend ? `, _append: $append` : ''}){
        returning {
          ${selections}
        }
        affected_rows
      }
    }
  `;

  const variables = {
    set,
    append: hasAppend ? append : undefined,
    where: { id: { _eq: node.id } },
    ...relations.variables,
  };

  const hooks = schemaType.hooks;
  if (hooks.willUpdate) {
    await hooks.willUpdate({ variables, fields, type, node, schema, values, valuesAsMutationVars });
  }

  return {
    query,
    alias,
    variables,
    type,
    schema,
    schemaType,
    operation: 'update',
    renderSuccessMessage: (mutation, result) => `${typeLabel} updated`,
    renderErrorMessage: (mutation, error) => `Error updating ${typeLabel}`,
    renderValidationFailedMessage: defaultValidationFailedMessage,
  };
};

/** ******************* Delete  *************************** */
const deleteMutationBuilderFactory = ({ selections }) => ({ fields, type, node, schema, values, valuesAsMutationVars }) => {
  const schemaType = schema.types[type];
  const typeLabel = schemaType.label;
  const pascalType = _.upperFirst(type);
  const alias = `delete${pascalType}`;
  selections = selections || getSelections('delete', schemaType);

  const query = gql`
    mutation Delete${pascalType}($where: ${type}_bool_exp!){
      ${alias}: delete_${type}(where: $where){
        returning {
          ${selections}
        }
        affected_rows
      }
    }
  `;

  const variables = {
    where: { id: { _eq: node.id } },
  };

  return {
    query,
    alias,
    variables,
    requiresValidation: false,
    type,
    schema,
    schemaType,
    operation: 'delete',
    renderSuccessMessage: (mutation, result) => `${typeLabel} deleted`,
    renderErrorMessage: (mutation, error) => `Error deleting ${typeLabel}`,
    renderValidationFailedMessage: defaultValidationFailedMessage,
  };
};

export { insertMutationBuilderFactory, updateMutationBuilderFactory, deleteMutationBuilderFactory };
