import React, { useContext, useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Paper, IconButton, ButtonBase } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import Color from 'color';
import EditIcon from 'mdi-material-ui/Pencil';
import { Add as AddIcon } from '@material-ui/icons';

import LikeFilter from 'src/kiska/components/NodeList/LikeFilter';
import { NodeListContext } from 'src/kiska/components/NodeList';
import { EmptyList } from './EmptyList';

const useStyles = makeStyles((theme) => {
  const highlightColor = Color(theme.palette.primary.main).fade(0.9).string();

  return {
    root: {
      width: 700,
      maxWidth: '100%',
    },
    paper: {
      padding: theme.spacing(0, 0, 2, 0),
    },
    listTitle: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 0),
      margin: theme.spacing(0, 0, 1, 0),
    },
    listIcon: {
      marginRight: theme.spacing(2),
      // marginLeft: '-1.6em',
    },
    search: {
      padding: theme.spacing(0, 0, 0, 0),
      '& svg': {
        marginLeft: theme.spacing(1),
      },
    },
    searchInput: {
      padding: theme.spacing(2, 2, 2, 0),
      fontSize: 24,
    },
    rows: {
      margin: theme.spacing(0, 1, 0, 0),
      padding: theme.spacing(1, 1),
      overflowY: 'auto',
    },
    row: {
      textAlign: 'left',
      margin: theme.spacing(0, 0),
      padding: theme.spacing(0.5, 0, 0.5, 1),
      cursor: 'pointer',
      flexWrap: 'nowrap',
      borderBottom: 'solid 1px rgba(0,0,0,.1)',
      '&.highlight': {
        backgroundColor: highlightColor,
      },
      '&:last-child': {
        borderBottom: 'none',
      },
      '&:hover': {
        backgroundColor: highlightColor,
      },
    },
    title: {
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    subtitle: {
      marginTop: theme.spacing(-0.4),
    },
    statContainer: {
      width: 'auto',
    },
    stat: {
      color: theme.palette.success.main,
      fontSize: '1.7em',
      fontWeight: 600,
    },
    substat: {
      color: theme.palette.text.secondary,
      fontSize: '.8em',
      fontWeight: 300,
      marginTop: theme.spacing(-1),
      lineHeight: 1,
    },
    rowIcon: {
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 1, 0, -1),
    },
  };
});

const Row = ({ node, title, subtitle, stat, substat, onClick, onEdit, clickUrl, highlight, titleIcon, rowIcon }) => {
  const history = useHistory();
  const classes = useStyles();
  const nTitleIcon = titleIcon(node);

  const handleEditClick = (event) => {
    event.stopPropagation();
    onEdit(node);
  };

  const RowIcon = rowIcon ? rowIcon(node) : undefined;

  return (
    <Grid
      component={ButtonBase}
      onClick={() => {
        if (onClick) onClick(node);
        if (clickUrl) history.push(clickUrl(node));
      }}
      container
      justify="space-between"
      alignItems="center"
      className={classNames(classes.row, highlight && 'highlight')}
      style={{ justifyContent: 'space-between' }} // sort of a bug, style sheet order between Button base and Grid not deterministic
    >
      {RowIcon && (
        <Grid item className={classes.rowIcon}>
          <RowIcon color="inherit" />
        </Grid>
      )}
      <Grid item style={{ flex: 1, marginLeft: 0 }}>
        <div className={classes.titleWrapper}>
          {nTitleIcon && <div style={{ marginRight: 8 }}>{nTitleIcon}</div>}
          <div>
            <Typography variant="body1" className={classes.title}>
              {title(node)}
            </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.subtitle}>{subtitle(node)}</Typography>
          </div>
        </div>
      </Grid>
      <Grid container alignItems="center" direction="column" className={classes.statContainer}>
        <Typography variant="subtitle1" className={classes.stat}>{stat(node)}</Typography>
        <Typography variant="subtitle2" className={classes.substat}>{substat(node)}</Typography>
      </Grid>
      {onEdit && (
        <Grid item style={{ marginLeft: 4 }}>
          <IconButton color="inherit" onClick={handleEditClick} component="div">
            <EditIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

const List = (props) => {
  const history = useHistory();
  const { title, subtitle, stat, substat, searchPaths, searchPlaceholder, updateForm, onClick, clickUrl, listTitle, titleIcon, rowIcon, createForm, createText, maxHeight } = props;
  const classes = useStyles();
  const [hasFocus, setHasFocus] = useState(false);
  const [updateFormNode, setUpdateFormNode] = useState(null);
  const { nodes, type, schemaType } = useContext(NodeListContext);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && nodes && nodes.length) {
      if (onClick) onClick(nodes[0]);
      if (clickUrl) history.push(clickUrl(nodes[0]));
    }
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = () => {
    setHasFocus(false);
  };

  let nCreateForm;
  if (createForm) {
    nCreateForm = cloneElement(createForm, {
      trigger: (
        <Button variant="contained" color="primary" style={{ lineHeight: 1.1 }}><AddIcon /><span>{createText}</span></Button>
      ),
    });
  }

  let nUpdateForm;
  if (updateForm) {
    const id = updateFormNode ? updateFormNode.id : undefined;
    nUpdateForm = cloneElement(updateForm, {
      key: id,
      id,
      node: updateFormNode,
      open: !!updateFormNode,
      onClose: () => { setUpdateFormNode(null); },
    });
  }

  return (
    <>
      {nUpdateForm}
      <div className={classes.root}>
        {listTitle && (
          <div className={classes.listTitle}>
            <Typography variant="h4" align="center" color="textSecondary" style={{ paddingRight: 16 }}>
              {props.listIcon && <props.listIcon className={classes.listIcon} color="inherit" />}
              {listTitle}
            </Typography>
            {nCreateForm}
          </div>
        )}
        <Paper className={classes.paper}>
          <div className={classes.search}>
            <LikeFilter
              paths={searchPaths}
              search
              label=""
              placeholder={searchPlaceholder || 'Search'}
              searchInputClassName={classes.searchInput}
              textFieldProps={{
                onKeyPress: handleKeyPress,
                onBlur: handleBlur,
                onFocus: handleFocus,
              }}
            />
          </div>
          <div className={classes.rows} style={{ maxHeight: `min(50vh, ${maxHeight}` }}>
            {nodes && nodes.length
              ? nodes.map((node, index) => (
                <Row
                  key={node.id}
                  node={node}
                  titleIcon={titleIcon}
                  highlight={hasFocus && !index}
                  title={title}
                  subtitle={subtitle}
                  stat={stat}
                  substat={substat}
                  clickUrl={clickUrl}
                  onClick={onClick}
                  createForm={createForm}
                  createText={createText}
                  onEdit={setUpdateFormNode}
                  rowIcon={rowIcon}
                />
              ))
              : <EmptyList />}
          </div>
        </Paper>
      </div>
    </>
  );
};

List.propTypes = {
  title: PropTypes.func.isRequired,
  subtitle: PropTypes.func,
  stat: PropTypes.func,
  substat: PropTypes.func,
  searchPaths: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func,
  clickUrl: PropTypes.func,
  listTitle: PropTypes.string,
  listIcon: PropTypes.elementType,
  titleIcon: PropTypes.func,
  createForm: PropTypes.node,
  createText: PropTypes.node,
  maxHeight: PropTypes.number,
  updateForm: PropTypes.node,
  rowIcon: PropTypes.func,
  searchPlaceholder: PropTypes.string,
};
List.defaultProps = {
  subtitle: () => null,
  stat: () => null,
  substat: () => null,
  titleIcon: () => null,
  searchPaths: undefined,
  onClick: undefined,
  clickUrl: undefined,
  listTitle: undefined,
  listIcon: undefined,
  createForm: undefined,
  createText: undefined,
  maxHeight: 500,
  updateForm: undefined,
  rowIcon: undefined,
  searchPlaceholder: undefined,
};

export { List };
