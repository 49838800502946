import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddColumnIcon from 'mdi-material-ui/TableColumnPlusAfter';
import RemoveColumnIcon from 'mdi-material-ui/TableColumnRemove';
import classNames from 'classnames';
import MenuDotsIcon from 'mdi-material-ui/DotsVertical';
import AddIcon from 'mdi-material-ui/Plus';
import SimpleMenu from 'src/kiska/components/SimpleMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main,
    color: `hsla(0, 0%, 100%, 90%)`,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const Toolbar = (props) => {
  const { className, onAddColumn, onRemoveColumn, onAddJob, ...rest } = props;
  const classes = useStyles(props);

  return (
    <div className={classNames(classes.root, className)} {...rest}>
      <Tooltip title="Add New Job">
        <IconButton className={classes.button} onClick={onAddJob}><AddIcon /></IconButton>
      </Tooltip>
      <SimpleMenu
        trigger={<Tooltip title="More Actions..."><IconButton className={classes.button}><MenuDotsIcon /></IconButton></Tooltip>}
        items={[
          { primary: `Add Column`, icon: <AddColumnIcon />, onClick: onAddColumn },
          { primary: `Remove Last Column`, icon: <RemoveColumnIcon />, onClick: onRemoveColumn },
        ]}
      />
    </div>
  );
};

// Toolbar.propTypes = {
// };
// Toolbar.defaultProps = {
// };

export { Toolbar };
