import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { FormUpdateForm } from './FormUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: green[700],
    padding: theme.spacing(1, 1),
    '&:hover': {
      backgroundColor: green[900],
    },
    '& span': {
      display: 'block',
      textTransform: 'none',
      lineHeight: 1.3,
    },
  },
  top: {
    color: 'rgba(255,255,255,1)',
    fontSize: '1.3em',
  },
  bottom: {
    color: 'rgba(255,255,255,.8)',
  },
}));

const StartFormButton = ({ form }) => {
  const classes = useStyles();

  const trigger = (
    <Button color="default" variant="contained" className={classes.root}>
      <span className={classes.top}>{form.title}</span>
      {/* <span className={classes.bottom}>version {form.version}</span> */}
    </Button>
  );

  return <FormUpdateForm formName={form.name} trigger={trigger} />;
};

StartFormButton.propTypes = {
  form: PropTypes.object.isRequired,
};
StartFormButton.defaultProps = {
};

export { StartFormButton };
