import React from 'react';
import ReactDOM from 'react-dom';
import { settings } from 'src/kiska/utils/settings';
import { LogContextProvider } from 'src/kiska/components/contexts/LogContext';
import { App } from './App';

import 'src/utils/polyfills';
import 'src/utils/addToHomescreen';

const handleTheUnhandled = (event) => {
  if (!settings.public.devMode) {
    window.setTimeout(() => { window.location.href = '/'; }, 2000); // give logger some time to finishing logging requests
  }
};

window.addEventListener('error', handleTheUnhandled);
window.addEventListener('unhandledrejection', handleTheUnhandled);

ReactDOM.render((
  // <ServiceWorkerProvider>
  //   <div>
  //     <button style={{ display: 'none' }} className="pwaAddToHomescreenButton" type="button">Add To Homescreen</button>
  //     <h2>Settings:</h2>
  //     <pre dangerouslySetInnerHTML={{ __html: JSON.stringify(settings, null, 2).replace(/\n/g, '<br />') }} />
  //     <h2>Code:</h2>
  //     <p>
  //       Branch: {process.env.REACT_APP_KISKA_CODE_BRANCH}<br />
  //       Version: {process.env.REACT_APP_KISKA_CODE_VERSION}
  //     </p>
  //   </div>
  // </ServiceWorkerProvider>
  // <React.StrictMode>
  <LogContextProvider>
    <App />
  </LogContextProvider>
  // </React.StrictMode>
), document.getElementById('root'));
