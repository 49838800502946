import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Menu, MenuItem, Button, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Snooze as SnoozeIcon, Delete as DeleteIcon, Notifications as NotificationIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { addMinutes } from 'date-fns';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  alertMessage: {
    flex: 1,
    padding: theme.spacing(0.5, 0),
  },
  message: {
    flex: 1,
    paddingRight: theme.spacing(1),
  },
  action: {
    marginRight: theme.spacing(1),
  },
}));

const Notification = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles(props);
  const { notification, className, onDismiss, onSnooze, ...rest } = props;
  const { data } = notification;

  const closeSnoozeMenu = () => {
    setAnchorEl(null);
  };

  const handleSnooze = (minutes) => () => {
    onSnooze({ notificationId: notification.id, date: addMinutes(new Date(), minutes) });
    closeSnoozeMenu();
  };

  const handleDismiss = () => {
    onDismiss({ notificationId: notification.id, date: new Date() });
    closeSnoozeMenu();
  };

  const openSnoozeMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Alert
      elevation={3}
      severity="warning"
      classes={{ root: classNames(classes.root, className), message: classes.alertMessage }}
      icon={<NotificationIcon fontSize="inherit" />}
      {...rest}
    >
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeSnoozeMenu}
      >
        <MenuItem onClick={handleSnooze(60)}>
          <ListItemIcon><SnoozeIcon /></ListItemIcon>
          <ListItemText primary="Snooze for an hour" />
        </MenuItem>
        <MenuItem onClick={handleSnooze(60 * 24)}>
          <ListItemIcon><SnoozeIcon /></ListItemIcon>
          <ListItemText primary="Snooze for a day" />
        </MenuItem>
        <MenuItem onClick={handleSnooze(60 * 24 * 7)}>
          <ListItemIcon><SnoozeIcon /></ListItemIcon>
          <ListItemText primary="Snooze for a week" />
        </MenuItem>
        <MenuItem onClick={handleDismiss}>
          <ListItemIcon><DeleteIcon /></ListItemIcon>
          <ListItemText primary="Dismiss forever" />
        </MenuItem>
      </Menu>
      <div className={classes.content}>
        <Typography variant="body1" className={classes.message}>{data.message}</Typography>
        {data.actionLabel && (
          <Button variant="outlined" size="small" component={Link} to={data.actionUrl} className={classes.action}>
            {data.actionLabel}
          </Button>
        )}
        <IconButton color="inherit" size="small" onClick={openSnoozeMenu}>
          <CloseIcon />
        </IconButton>
      </div>
    </Alert>
  );
};

Notification.propTypes = {
};
Notification.defaultProps = {
};

export { Notification };
