import React from 'react';
// import PropTypes from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import SelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import { FileField } from 'src/kiska/components/MutationContext/FormFields/FileField';
import CrudForm from 'src/components/updates/CrudForm';
import { GridLineBreak } from 'src/components/forms';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import DateField from 'src/kiska/components/MutationContext/FormFields/DatePicker';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  filesPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.default,
  },
}));

const ResourceUpdateFormInner = (props) => {
  const { fixtures, showExpires } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <TextField name="title" />
        </Grid>
        <Grid item xs={12}>
          <TextField name="description" rows={3} />
        </Grid>
        {showExpires && (
          <Grid item xs={12}>
            <DateField name="expires" />
          </Grid>
        )}
        <GridLineBreak />
        <Grid item xs={12}>
          <NodeField name="tags" creatableInputFieldMapping="label" creatableFixtures={{ type: 'resource' }} where={{ type: { _eq: 'resource' } }} />
        </Grid>
        <Grid item xs={12}>
          <FileField name="files" dialogMode={false} />
        </Grid>
      </Grid>
    </div>
  );
};

ResourceUpdateFormInner.propTypes = {
  // variant: PropTypes.string,
};
ResourceUpdateFormInner.defaultProps = {
  // variant: '',
};

const ResourceUpdateForm = ({ showExpires, id, trigger, node, onClose, open, onOpen, variant, initialValues: propsInitialValues, onSuccess, fixtures, embeddedMode }) => {
  const initialValues = {
    ...propsInitialValues,
  };

  return (
    <CrudForm
      type="resource"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderTitle={(n) => (n ? 'Edit Resource' : 'Create Resource')}
      initialValues={initialValues}
      onSuccess={onSuccess}
      fixtures={fixtures}
      maxWidth="md"
      onOpen={onOpen}
      embeddedMode={embeddedMode}
      node={node}
    >
      <ResourceUpdateFormInner variant={variant} fixtures={fixtures} showExpires={showExpires} />
    </CrudForm>
  );
};

export { ResourceUpdateForm };
