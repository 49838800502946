/* eslint-disable react/prop-types */
import React from 'react';

export default {
  types: {
    trigger: {
      renderers: {
        inline: ({ node }) => <>{node.label}</>,
      },

      selections: {
        default: `
          id label description status type evaluatedAt
          users {id user {id displayName avatar {url}}}
          roles {id role {id label}}
          config
        `,
      },

      fields: {
        label: { type: 'String', validation: { required: true } },
        description: { type: 'String', validation: { required: false } },
        status: { type: 'String', defaultValue: 'active', validation: { required: true } },
        type: { type: 'String', validation: { required: true } },
        evaluatedAt: { type: 'Date', validation: { required: true } },

        users: { type: 'user', hasMany: true, validation: { required: false } },
        roles: { type: 'role', hasMany: true, validation: { required: false } },

        config: { type: 'Json', validation: { required: true } },

        'config.notifications.channels': { type: 'Json', defaultValue: ['in-app'], validation: { required: true } },
        'config.notifications.snoozable': { type: 'Boolean', defaultValue: true, validation: { required: true } },

        'config.message': { type: 'String', validation: { required: true } },
        'config.actionLabel': { type: 'String', validation: { required: false } },
        'config.actionUrl': { type: 'String', validation: { required: false } },
        'config.type': { type: 'String', validation: { required: false } },
        'config.field': { type: 'String', validation: { required: false } },
        'config.anyOldValue': { type: 'Boolean', validation: { required: false } },
        'config.oldValue': { type: 'String', validation: { required: false } },
        'config.anyNewValue': { type: 'Boolean', validation: { required: false } },
        'config.newValue': { type: 'String', validation: { required: false } },
      },
    },

    notification: {
      renderers: {
        inline: ({ node }) => <>{node.id}</>,
      },

      selections: {
        default: `
          id snoozedUntil dismissedAt createdAt
          user {id displayName avatar {url}}
          trigger {id label}
          data
        `,
      },

      fields: {
        label: { type: 'String', validation: { required: true } },
        snoozedUntil: { type: 'Date', validation: { required: false } },
        dismissedAt: { type: 'Date', validation: { required: false } },

        user: { type: 'user', validation: { required: true } },
        trigger: { type: 'trigger', validation: { required: true } },

        data: { type: 'Json', validation: { required: true } },
        'data.message': { type: 'String', validation: { required: true } },
        'data.actions': { type: 'Json', validation: { required: false } },

      },
    },
  },
};
