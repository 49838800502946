import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { usePageMeta } from 'src/kiska/components/contexts/PageMetaContext';
import { WorkEntryReportQuery } from 'src/components/modules/work_entry/WorkEntryReportQuery';
import { useNodeList } from 'src/kiska/components/NodeList';
import { WorkEntryReportQueryParams } from 'src/components/dashboards/WorkEntryReportParams';
import { WorkEntryListByDay } from 'src/components/dashboards/WorkEntryListByDay';
import { GridLineBreak } from 'src/components/forms';
import { getWorkEntriesTotals } from 'src/utils';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { Stat } from './Stat';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1),
    flexDirection: 'column !important',
    justifyContent: 'flex-start !important',
  },
  stats: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
}));

const MyWorkLogInner = (props) => {
  const { user, initialDisplayParams, initialDateRange } = props;
  const { nodes } = useNodeList();
  const classes = useStyles(props);
  const { setTitles } = usePageMeta();
  const [displayParams] = useState({
    showNotes: true,
    showCharts: false,
    showFinancials: false,
    showJob: true,
    showCustomer: false,
    showTask: true,
    showPayRate: false,
    showUser: false,
    showTimes: true,
    ...initialDisplayParams,
  });
  const totals = useMemo(() => getWorkEntriesTotals(nodes), [nodes]);

  const handleDisplayParamsChange = () => {};

  useEffect(() => {
    setTitles(`My Work Log`);
  }, [user.displayName, setTitles]);

  return (
    <div className={classes.root}>
      <WorkEntryReportQueryParams
        availableFilters={[]}
        displayParams={displayParams}
        onDisplayParamsChange={handleDisplayParamsChange}
        initialDateRange={initialDateRange}
        enabledDisplayParams={[]}
        dateRangeOptions={['all-time', 'last-year', 'last-month', 'last-period', 'this-period', 'this-month', 'this-year']}
      />
      <GridLineBreak />
      <div className={classes.stats}>
        <Stat label="Total Hours" value={totals.hours} />
      </div>
      <WorkEntryListByDay displayParams={displayParams} addEntryProps={{ fixtures: { user } }} />
    </div>
  );
};

MyWorkLogInner.propTypes = {
  initialDateRange: PropTypes.string,
  user: PropTypes.object.isRequired,
  initialDisplayParams: PropTypes.object,
};
MyWorkLogInner.defaultProps = {
  initialDateRange: 'this-period',
  initialDisplayParams: undefined,
};

const MyWorkLog = (props) => {
  const { user } = useUser();
  const { ...innerProps } = props;

  const initial = {
    limit: 10000,
    orderBy: [
      { minStart: 'desc' },
    ],
    args: {
      where: {
      },
    },
  };

  const fixtures = [
    { path: 'where.user.id._eq', value: user.id },
    { path: 'args.groupById', value: true },
    { path: 'args.groupByJob', value: true },
    { path: 'args.groupByUser', value: true },
    { path: 'args.groupByTask', value: true },
    { path: 'args.groupByCustomer', value: true },
    { path: 'args.groupByPayRate', value: true },
  ];

  return (
    <>
      <WorkEntryReportQuery
        fixtures={fixtures}
        initial={initial}
      >
        <MyWorkLogInner {...innerProps} user={user} />
      </WorkEntryReportQuery>
    </>
  );
};

MyWorkLog.propTypes = {
  enabledDisplayParams: PropTypes.array,
};
MyWorkLog.defaultProps = {
  enabledDisplayParams: undefined,
};

export { MyWorkLog };
