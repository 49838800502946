import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EditButton from 'src/components/updates/EditButton';
import { CustomerUpdateForm } from 'src/components/modules/customer/CustomerUpdateForm';
import { JobList } from 'src/components/dashboards/JobList';
import LabelValueTable from '../../views/LabelValueTable';
import { ContactMapLink } from './ContactMapLink';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  content: {
    padding: theme.spacing(2),
  },
  actions: {
    marginTop: theme.spacing(2),
  },
}));

const CustomerDetails = ({ node }) => {
  const classes = useStyles();

  const { address1, address2, city, state, postalCode, country, email, phone } = node || {};

  const fields = [
    'company', 'name',
    {
      label: 'Email',
      value: email && <Link href={`mailto:${email}`}>{email}</Link>,
    },
    {
      label: 'Phone',
      value: phone && <Link href={`tel:${phone}`}>{phone}</Link>,
    },
    {
      label: 'Address',
      value: (
        <span>
          {address1}<br />
          {address2 && <>{address2}<br /></>}
          {city && `${city}, `}{state && `${state}, `}{postalCode}<br />
          {country}
        </span>
      ),
    },
    {
      label: 'Map',
      value: <ContactMapLink contact={node} />,
    },
    'notes',
  ];

  return (
    <div className={classes.content}>
      <Grid container spacing={4} justify="center" alignItems="flex-start">

        {/* Left Column */}
        <Grid container spacing={2} justify="center" item xs={12} md={6}>
          <Grid item xs={12}><LabelValueTable type="contact" node={node} fields={fields} /></Grid>

          <Grid item>
            <CustomerUpdateForm id={node.id} trigger={<EditButton>Edit Customer</EditButton>} />
          </Grid>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6} container justify="center">
          <Typography variant="h6" align="center">Jobs for {node.company || node.name}</Typography>
          <JobList fixtures={{ path: 'where.customerId', value: { _eq: node.id } }} insertFixtures={{ customer: node }} />
        </Grid>

      </Grid>
    </div>
  );
};

CustomerDetails.propTypes = {
  node: PropTypes.object.isRequired,
};
CustomerDetails.defaultProps = {
};

export { CustomerDetails };
