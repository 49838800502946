import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ChromePicker } from 'react-color';

import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';

const styles = (theme) => ({
  root: {
  },
});

class ColorField extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    label: PropTypes.string,
    initialValue: PropTypes.any,
    schemaExtensions: PropTypes.object,
  }

  static defaultProps = {
    helperText: undefined,
    label: undefined,
    initialValue: undefined,
    schemaExtensions: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;

    registerField(props.name, { initialValue: props.initialValue, schemaExtensions: props.schemaExtensions });
  }

  handleChange = (color) => {
    const { onChange } = this.context;
    const { name } = this.props;
    const rgba = color.rgb;
    const value = `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;

    onChange(name, value);
  }

  render() {
    const { name, classes, helperText, label: propsLabel, multiple, options, schemaExtensions } = this.props;
    const { getFieldValue, getFieldErrors, pendingMutation, schema, type } = this.context;
    let value = getFieldValue(name);
    const errors = getFieldErrors(name);

    const schemaField = schema.types[type].fields[name] || schemaExtensions.types[type].fields[name];
    const label = propsLabel === '' ? '' : propsLabel || schemaField.label;

    if (value === undefined || value === null) value = 'transparent';

    return (
      <FormControl error={!!errors} disabled={pendingMutation} className={classes.root}>
        <Typography variant="body2" color="textSecondary">{label}</Typography>
        <ChromePicker
          color={value}
          onChangeComplete={this.handleChange}
        />
        <FormHelperText>
          <HelperText text={helperText} errors={errors} />
        </FormHelperText>
      </FormControl>
    );
  }
}

ColorField = _.flow(
  withStyles(styles),
)(ColorField);

export { ColorField };
