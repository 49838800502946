import React, { Component, createContext, useState, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import produce from 'immer';

import { SchemaContext } from 'src/kiska/components/contexts/SchemaContext';

const QueryVariablesContext = createContext(null);

/*
  Updates elements in a deep array such that other objects elements in that array do not share the same path
  eg: If previous state is:
    {
      where: {
        _and: [
          {duration: {_gte: 42}}
        ]
      }
    }

    And you pass rootPath = 'where._and' and pathValuePairs = [{path: 'duration._gte', value: 99}]

    Then the existing duration element will be removed and replaced by the 99
 */
const replaceInArray = (state, rootPath, pathValuePairs, { order = 'prepend' } = {}) => {
  const pairs = Array.isArray(pathValuePairs) ? pathValuePairs : [pathValuePairs];

  const newState = produce(state, (draft) => {
    let root = _.get(draft, rootPath, []);

    root = root.filter((c) => {
      for (let i = 0; i < pairs.length; i++) {
        if (_.get(c, pairs[i].path) !== undefined) return false;
      }
      return true;
    });

    const newItems = pairs.filter((p) => p.value !== undefined).map((p) => {
      return _.set({}, p.path, p.value);
    });

    if (order === 'prepend') root = [...newItems, ...root];
    else if (order === 'append') root = [...root, newItems];
    else throw new Error(`Unknown replaceinArray() order argument "${order}"`);

    if (root.length > 0) _.set(draft, rootPath, root);
    else _.set(draft, rootPath, undefined);
  });

  return newState;
};

const applyVariablesReducerUpdateArg = (state, updateArg) => {
  if (!updateArg) return state;
  if (Array.isArray(updateArg)) updateArg = { pathValuePairs: updateArg };
  else if (typeof updateArg === 'function') updateArg = updateArg(state);

  const { path, value, pathValuePairs, reducer } = updateArg;
  let newState = state;

  if (reducer) {
    newState = reducer(state);
  } else if (updateArg.replaceInArray) {
    newState = replaceInArray(
      state,
      updateArg.replaceInArray.rootPath,
      updateArg.replaceInArray.pathValuePairs,
      updateArg.replaceInArray.options,
    );
  } else if (path) {
    newState = produce(state, (draft) => {
      _.set(draft, path, value);
    });
  } else if (pathValuePairs) {
    newState = produce(state, (draft) => {
      pathValuePairs.forEach((p) => _.set(draft, p.path, p.value));
    });
  }

  return newState;
};

const variablesReducer = ({ fixtures }) => (state, updateArg) => {
  let newState = applyVariablesReducerUpdateArg(state, updateArg);
  if (fixtures) {
    newState = applyVariablesReducerUpdateArg(newState, fixtures);
  }

  return newState;
};

const QueryVariablesProvider = (props) => {
  const { schema } = useContext(SchemaContext);
  const { fixtures, initial, type, children } = props;

  const [variables, updateVariables] = useReducer(variablesReducer({ fixtures }), initial, () => {
    return applyVariablesReducerUpdateArg(initial, fixtures);
  });

  const contextValue = {
    variables,
    updateVariables,
    type,
    schema,
  };

  return (
    <QueryVariablesContext.Provider value={contextValue}>
      {children}
    </QueryVariablesContext.Provider>
  );
};

QueryVariablesProvider.propTypes = {
  fixtures: PropTypes.any,
  initial: PropTypes.object,
  children: PropTypes.node,
  type: PropTypes.string.isRequired,
};
QueryVariablesProvider.defaultProps = {
  fixtures: undefined,
  initial: {},
  children: null,
};

export { QueryVariablesContext, QueryVariablesProvider };
