import to from 'await-to-js';
import _ from 'lodash';

const evaluateRule = ({ key, rule, node, name, type, values, schema, schemaField }) => {
  const value = values[name];
  const schemaType = schema.types[type];
  const fieldLabel = schemaType.fields[name].label;
  const typeLabel = schema.types[type].label;

  const buildError = (message, isGlobal) => {
    if (rule.message) {
      if (typeof rule.message === 'function') message = rule.message({ key, rule, node, name, type, values, schema, schemaField });
      if (typeof rule.message === 'string') message = rule.message;
    }
    if (isGlobal) return { globalErrors: [{ type: key, message }] };
    return { fieldErrors: { [`${name}`]: [{ type: key, message }] } };
  };

  switch (key) {
    case 'number': {
      if (value === undefined || value === null || value === '') break;
      const number = Number(value);
      if (Number.isNaN(number)) return buildError(`${fieldLabel} must be a number.`);
      if (rule.gte !== undefined && !(number >= rule.gte)) return buildError(`${fieldLabel} must be greater than or equal to ${rule.gte}.`);

      break;
    }

    case 'required': {
      if (!rule) break;

      let missing = false;
      if (value === '' || value === null || value === undefined) missing = true;
      if (Array.isArray(value) && !value.length) missing = true;

      if (missing) return buildError(`${fieldLabel} is a required field.`);
      break;
    }

    case 'requiredIf': {
      if (!rule) break;
      let required = false;

      const depValue = values[rule.dep];
      if (rule.eq && (depValue === rule.eq)) required = true;
      if (rule.isTruthy && depValue) required = true;
      if (rule.oneOf && rule.oneOf.includes(depValue)) required = true;

      if (!required) break;
      if (!(value === '' || value === null || value === undefined)) break;

      return buildError(`${fieldLabel} is a required field.`);
    }

    case 'oneOf': {
      const valuesArray = Array.isArray(value) ? value : [value];
      const errors = [];
      const options = rule.options || rule;
      valuesArray.forEach((v) => {
        if (!options.find((o) => o.value === v)) errors.push(buildError(`${v} is not a valid value for ${fieldLabel}`));
      });
      break;
    }

    case 'email':
      if (!value) break;
      if (!/[^@]+@[^.]+\..+/g.test(value)) {
        return buildError(`${fieldLabel} must be a valid email address.`);
      }
      break;

    case 'password':
      // eslint-disable-next-line no-case-declarations
      let validPassword = true;
      if (rule.minChars) validPassword = validPassword && value.length >= rule.minChars;
      if (rule.digit) validPassword = validPassword && RegExp(`(?=.*[0-9])`).test(value);
      if (rule.upperCase) validPassword = validPassword && RegExp(`(?=.*[A-Z])`).test(value);
      if (rule.lowerCase) validPassword = validPassword && RegExp(`(?=.*[a-z])`).test(value);
      if (rule.symbol) validPassword = validPassword && RegExp(`(?=.[!@#$%^&])`).test(value);
      if (!validPassword) {
        return buildError('Invalid password');
      }
      break;

    case 'equalTo':
      if (rule.value && rule.value !== value) {
        return buildError(`${fieldLabel} must be equal to ${rule.value}.`);
      } if (rule.field && values[rule.field] !== value) {
        return buildError(`${fieldLabel} must be the same as ${schemaType.fields[rule.field].label}.`);
      }
      break;

    default:
      throw new Error(`Unknown validation rule "${key}"`);
  }

  return undefined;
};

const validateField = ({ name, type, values, schema, node, promises }) => {
  const schemaField = schema.types[type].fields[name];
  if (!schemaField) return; // Nothing to do, no schema provided for this field
  const validation = schemaField.validation;

  if (!validation) return; // Nothing to do

  _.forEach(validation, (rule, key) => {
    if (key === 'callbacks') {
      rule.forEach((func) => promises.push(func({ name, node, type, values, schema, schemaField })));
    } else {
      const oneOrMoreNewPromises = evaluateRule({ key, rule, node, name, type, values, schema, schemaField });
      const newPromises = Array.isArray(oneOrMoreNewPromises) ? oneOrMoreNewPromises : [oneOrMoreNewPromises];
      promises.push(...newPromises);
    }
  });
};

const mergeValidationErrors = (errors) => {
  const globalErrors = errors.reduce((acc, e) => (e && e.globalErrors ? [...acc, ...e.globalErrors] : acc), []);
  const fieldErrors = errors.reduce((acc, e) => {
    if (!e || !e.fieldErrors) return acc;
    return _.mergeWith(acc, e.fieldErrors, (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
      return undefined;
    });
  }, {});
  return { globalErrors, fieldErrors };
};

const validate = async ({ type, values, schema, node }) => {
  const promises = [];

  _.forEach(values, (value, name) => {
    validateField({ name, type, values, schema, node, promises });
  });

  const [unexpectedError, errors] = await to(Promise.all(promises));
  if (unexpectedError) {
    console.error(`Unexpected error while validating for type "${type}":`, unexpectedError);
  }

  const mergedErrors = mergeValidationErrors(errors);
  return mergedErrors;
};

const atLeastOneError = (errors) => {
  if (!errors) return false;
  if (errors.globalErrors && errors.globalErrors.length) return true;
  if (!errors.fieldErrors) return false;

  let hasError = false;
  _.forEach(errors.fieldErrors, (fieldErrors) => {
    if (!fieldErrors) return;
    if (fieldErrors.length) hasError = true;
  });
  return hasError;
};

export { validate, atLeastOneError };
