import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrayFieldEditor } from 'src/components/ArrayFieldEditor';
import CrudForm from 'src/kiska/components/MutationContext/CrudForm';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import { useLocalNode } from 'src/kiska/components/LocalNodeContext';
import { FormSection } from 'src/components/forms';
import { Grid } from 'src/kiska/components/Grid';
import { Signature } from '../Signature';
import { SignatureUpdateForm } from '../SignatureUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const FormSignatures = (props) => {
  const classes = useStyles(props);
  const { view, update } = useViewOrUpdate();
  const { node } = useLocalNode();
  const { onSuccess } = props;

  const handleSuccess = (...args) => {
    if (onSuccess) onSuccess(...args);
  };

  const signaturesEditor = (
    <ArrayFieldEditor
      name="signatures"
      updateForm={SignatureUpdateForm}
      updateFormProps={{ embeddedMode: true }}
      insertFormProps={{ embeddedMode: true }}
      saveOnChange={!update}
    >
      {({ values, onUpdate, onDelete }) => (
        <Grid container className={classes.signatures} spacing={2} style={{ marginBottom: 16 }}>
          {values && values.map((sig) => (
            <Grid key={sig.id} item xs={12} sm={12} md={6}>
              <Signature node={sig} />
            </Grid>
          ))}
        </Grid>
      )}
    </ArrayFieldEditor>
  );

  return (
    <FormSection title="Signatures" spacing={1} justify="flex-end" alwaysInitiallyExpanded>
      <Grid item xs={12}>
        {update ? signaturesEditor : (
          <CrudForm type="form" node={node} onChangeMutation="update" onChangeMutationWait={100} onSuccess={handleSuccess}>
            {signaturesEditor}
          </CrudForm>
        )}
      </Grid>
    </FormSection>
  );
};

FormSignatures.propTypes = {
};
FormSignatures.defaultProps = {
};

export { FormSignatures };
