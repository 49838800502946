import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import {  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TaskUpdateForm } from './TaskUpdateForm';
import { SimpleTable } from './SimpleTable';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const TasksPanel = () => {
  const classes = useStyles();
  const initial = { orderBy: [{ label: 'asc' }] };

  const columns = [
    {
      fieldName: 'label',
    },
  ];

  const tableProps = {
    hideFilterButtons: true,
    hideOrderByButtons: true,
    hideHeader: true,
    hidePageControls: true,
  };

  return (
    <>
      <SimpleTable
        type="task"
        rowClickAction="edit"
        initial={initial}
        addLabel="Add Task"
        UpdateForm={TaskUpdateForm}
        columns={columns}
        tableProps={tableProps}
      />
    </>
  );
};

TasksPanel.propTypes = {
};
TasksPanel.defaultProps = {
};

export { TasksPanel };
