import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider } from '@material-ui/pickers';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';

import { parseISO } from 'date-fns';
import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';

const styles = (theme) => ({
  '@global': {
    'button[aria-label="calendar view is open, go to text input view"]': {
      display: 'none',
    },
  },
});

class DatePicker extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    format: PropTypes.string,
    label: PropTypes.string,
    initialValue: PropTypes.instanceOf(Date),
    component: PropTypes.elementType.isRequired,
    schemaExtensions: PropTypes.object,
    pickerProps: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    format: `MMM d, yyyy h:mmaaaaa'm`,
    helperText: undefined,
    label: undefined,
    initialValue: undefined,
    schemaExtensions: undefined,
    pickerProps: undefined,
    disabled: false,
    onChange: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;

    registerField(props.name, { initialValue: props.initialValue, schemaExtensions: props.schemaExtensions });
  }

  handleChange = (value) => {
    const { onChange } = this.context;
    const { name, onChange: propsOnChange } = this.props;

    onChange(name, value);
    if (propsOnChange) propsOnChange(name, value);
  }

  render() {
    const { name, classes, helperText, format, label: propsLabel, component: Component, pickerProps, disabled } = this.props;
    const { getFieldValue, getFieldErrors, pendingMutation, schema, type } = this.context;
    const schemaField = schema.types[type].fields[name];
    let label = propsLabel === undefined ? schemaField.label : propsLabel;
    let value = getFieldValue(name);
    const errors = getFieldErrors(name);
    const required = schemaField.validation.required;

    if (label && required) label = <>{label}&nbsp;*</>;

    if (value && (typeof value === 'string')) value = parseISO(value);

    if (!value) {
      value = null;
    }

    return (
      <FormControl error={!!errors} disabled={pendingMutation || disabled} className={classes.root}>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <Component
            DialogProps={{ className: classes.picker }}
            name={name}
            value={value}
            label={label}
            error={!!errors}
            onChange={this.handleChange}
            className={classes.root}
            clearable
            inputFormat={format}
            format={format}
            autoOk={false}
            defaultValue={null}
            allowKeyboardControl={false}
            ampmInClock
            disabled={pendingMutation || disabled}
            renderInput={(props) => <TextField variant="outlined" {...props} />}
            {...pickerProps}
          />
        </LocalizationProvider>
        <FormHelperText>
          <HelperText text={helperText} errors={errors} />
        </FormHelperText>
      </FormControl>
    );
  }
}

DatePicker = withStyles(styles)(DatePicker);

export default DatePicker;
