/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
// import PropTypes from 'prop-types';
import { Typography, Button, Accordion, Link as MuiLink, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SupportIcon from 'mdi-material-ui/Lifebuoy';
import { Link as RouterLink } from 'react-router-dom';
import { ExpandMore as ExpandMoreIcon, Phone as PhoneIcon, MailOutline as EmailIcon, Room as AddressIcon } from '@material-ui/icons';

import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import androidIcon from './images/android.png';
import chromeIcon from './images/chrome.png';
import dotsIcon from './images/dots.png';
import iosIcon from './images/ios.png';
import safariIcon from './images/safari.png';
import android1 from './images/android1.png';
import android2 from './images/android2.png';
import ios1 from './images/ios1.png';
import ios2 from './images/ios2.png';
import ios3 from './images/ios3.png';
import desktopIcon from './images/desktop.png';
// import sampleVideo from './sample.mp4';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block !important',
    padding: theme.spacing(0, 2, 6, 2),
  },
  button: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  faqSummary: {
    minHeight: 0,
  },
  faqSummaryContent: {
    margin: theme.spacing(0, 0),
    display: 'flex',
    alignItems: 'center',
  },
  faqTitle: {
    fontSize: '1.1rem',
    fontWeight: 300,
    lineHeight: 1.2,
  },
  screencast: {
    display: 'flex',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    maxHeight: '80vh',
  },
  link: {
    color: 'hsla(195, 100%, 50%)',
  },
  titleImg: {
    height: '2.5em',
    marginRight: 8,
  },
  inlineImg: {
    height: '1.5em',
    verticalAlign: 'middle',
  },
  faqDetails: {
    display: 'block',
  },
  screenshot: {
    height: 450,
    margin: theme.spacing(1),
    borderRadius: 8,
  },
  hint: {
    opacity: 0.8,
  },
  phone: {
    letterSpacing: 1.5,
  },
}));

const Screencast = (props) => {
  const classes = useStyles(props);
  const { src } = props;

  return (
    <div className={classes.screencast}>
      <video className={classes.video} controls>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

const Faq = (props) => {
  const classes = useStyles(props);
  const { children, title, videoSrc, titleImg } = props;

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.faqSummary, content: classes.faqSummaryContent }}>
        {titleImg && <img src={titleImg} className={classes.titleImg} alt="" />}<Typography className={classes.faqTitle}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.faqDetails}>
        {!children && !videoSrc && <Typography variant="body1">Coming soon...</Typography> }
        {children}
        {videoSrc && <Screencast src={videoSrc} />}
      </AccordionDetails>
    </Accordion>
  );
};

const SupportButton = (props) => {
  const classes = useStyles(props);
  const { label: propsLabel, ...buttonProps } = props;
  const label = propsLabel === undefined ? 'Support' : propsLabel;

  return (
    <Button variant="outlined" component={RouterLink} to="/support" className={classes.button} {...buttonProps}>
      <SupportIcon color="inherit" />
      <span>{label}</span>
    </Button>
  );
};

const SupportPage = (props) => {
  const classes = useStyles(props);
  const settings = useSettings();
  const appUrl = `${settings.public.websiteOrigin}`;

  return (
    <div className={classes.root}>
      <Typography variant="h3" align="center" gutterBottom>Support</Typography>
      <Typography variant="h5" gutterBottom color="textSecondary">
        Installation Guide:
      </Typography>
      <Faq title="How to Install the App on Android Devices" titleImg={androidIcon}>
        <Typography variant="body1" component="div">
          Most Android devices will automatically prompt you to install the app when you go to your company's Slick Systems website.
          Using your Chrome <img src={chromeIcon} className={classes.inlineImg} alt="" /> browser, go
          to <MuiLink href={appUrl}>{appUrl}</MuiLink> and look for the prompt to install the app.
          <br /><br />
          If you do not see the install prompt, follow these steps:
          <ol>
            <li>Click the <img src={dotsIcon} className={classes.inlineImg} style={{ background: '#FFF' }} alt="" /> menu button</li>
            <li>
              Select "Install App" or "Add to Home screen"<br />
              <img src={android1} className={classes.screenshot} alt="" />
              <img src={android2} className={classes.screenshot} alt="" />
            </li>
          </ol>
        </Typography>
      </Faq>
      <Faq title="How to Install the App on iPhones and iPads" titleImg={iosIcon}>
        <Typography variant="body1" component="div">
          <ol>
            <li>
              Using your Safari <img src={safariIcon} className={classes.inlineImg} alt="" /> browser, go
              to <MuiLink href={appUrl}>{appUrl}</MuiLink>
            </li>
            <li>
              Click the share button at the bottom<br />
              <img src={ios1} className={classes.screenshot} alt="" />
            </li>
            <li>
              Find the "Add to Home Screen" option in the menu<br />
              <img src={ios2} className={classes.screenshot} alt="" />
            </li>
            <li>
              Click "Add"<br />
              <img src={ios3} className={classes.screenshot} alt="" />
            </li>
          </ol>
        </Typography>
      </Faq>
      <Faq title="How to Use On Desktop and Laptop Computers" titleImg={desktopIcon}>
        <Typography variant="body1" component="div">
          On desktop and laptop computers or other devices you can simply bookmark/favourite the Slick Systems website for quick access.
          Using your Chrome <img src={chromeIcon} className={classes.inlineImg} alt="" /> browser,
          go to <MuiLink href={appUrl}>{appUrl}</MuiLink> and add a bookmark or favourite.
        </Typography>
      </Faq>
      <br />
      <br />
      {/*
      <Typography variant="h5" gutterBottom color="textSecondary">
        Quick Videos Showing You How To...
      </Typography>
      <Faq title="Add a New Job" videoSrc={undefined} />
      <Faq title="Add a New Customer" videoSrc={undefined} />
      <Faq title="Make Corrections to a Time Entry/Work Log" videoSrc={undefined} />
      <Faq title="Send a Payroll Report to the Bookkeepper" videoSrc={undefined} />
      <Faq title="See All the Hours Worked on a Job" videoSrc={undefined} />
      <Faq title="See All the Expenses for a Job" videoSrc={undefined} />
      <Faq title="Add an Expense to a Job" videoSrc={undefined} />
      <Faq title="Add a Change Order to a Job" videoSrc={undefined} />
      <Faq title="Schedule a Job" videoSrc={undefined} />
      <Faq title="Assign a Job to a Worker" videoSrc={undefined} />
      <Faq title="Add Photos and Files to a Job" videoSrc={undefined} />
      <Faq title="Print/Email an Hours Report for a Job" videoSrc={undefined} />
      <Faq title="Print/Email an Expenses Report for a Job" videoSrc={undefined} />
      <Faq title="Add a New User" videoSrc={undefined} />
      <br />
      <br /> */}

      <Typography variant="h5" gutterBottom color="textSecondary">
        Contact Us:
      </Typography>
      <List>
        <ListItem button component="a" href="tel:1-855-447-5425">
          <ListItemIcon><PhoneIcon /></ListItemIcon>
          <ListItemText
            primary={(
              <span>
                Toll-Free: <MuiLink href="tel:1-855-447-5425" className={classes.phone}>1-855-44-SLICK</MuiLink>
                <br /><span className={classes.hint}>(1-855-447-5425)</span>
              </span>
            )}
            secondary={(
              <>
                7am - 6pm, Monday - Friday
              </>
            )}
          />
        </ListItem>
        <ListItem button component="a" href="mailto:support@slick.systems" target="_blank">
          <ListItemIcon><EmailIcon /></ListItemIcon>
          <ListItemText
            primary={(
              <span>
                Support: <MuiLink href="mailto:support@slick.systems" target="_blank">support@slick.systems</MuiLink>
              </span>
            )}
          />
        </ListItem>
        <ListItem button component="a" href="mailto:sales@slick.systems" target="_blank">
          <ListItemIcon />
          <ListItemText
            primary={(
              <span>
                Sales: <MuiLink href="mailto:sales@slick.systems" target="_blank">sales@slick.systems</MuiLink>
              </span>
            )}
          />
        </ListItem>
        <ListItem button component="a" href="mailto:billing@slick.systems" target="_blank">
          <ListItemIcon />
          <ListItemText
            primary={(
              <span>
                Billing: <MuiLink href="mailto:billing@slick.systems" target="_blank">billing@slick.systems</MuiLink>
              </span>
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon><AddressIcon /></ListItemIcon>
          <ListItemText
            primary="7-3965 Yaroshuk Road"
            secondary={(
              <>
                Powell River, BC<br />
                V8A 0P8
              </>
            )}
          />
        </ListItem>
      </List>

    </div>
  );
};

SupportPage.propTypes = {
};
SupportPage.defaultProps = {
};

export { SupportPage, SupportButton };
