import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
// import { useSnackbar } from 'notistack';
import { useLog } from 'src/kiska/components/contexts/LogContext';
import { Link } from 'src/kiska/components/Link';
import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import { Route } from 'react-router-dom';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { useAuth0 } from '@auth0/auth0-react';
import NormalLayout from './NormalLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {

  },
}));

const AccessDenied = ({ }) => {
  const classes = useStyles();
  const { setTitles } = useContext(PageMetaContext);

  useEffect(() => {
    setTitles('Access Denied');
  }, [setTitles]);

  return (
    <div className={classes.root}>
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        You must login before accessing this page.
      </Alert>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 24 }}>
          <Typography variant="body1">
            You are being redirected to the <Link to="/">Login Page</Link>...
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const AuthenticatedRoute = (props) => {
  const settings = useSettings();
  const log = useLog();
  const { roles, role, isPublic, layout, layoutProps, ...rest } = props;
  const auth = useAuth0();
  const { user } = useUser();

  let allow = false;

  // Allow anybody if route is public
  if (isPublic) allow = true;

  // We now need some sort of user
  else if (auth.loading) allow = false;

  // If not public but no roles passed, allow any authenticated user
  else if (!role && !roles && auth.isAuthenticated) allow = true;

  // Allow if user has exactly the same role as passed
  if (role && role === user.role.id) allow = true;

  // Allow if user has one of the roles passed
  else if (roles && roles.includes(user.role.id)) allow = true;

  if (allow) {
    const Layout = layout || NormalLayout;

    return (
      <Layout {...layoutProps}>
        <Route {...rest} />
      </Layout>
    );
  }

  log.warning('<AuthenticatedRoute> Denying Access', { auth, props });

  if (!settings.public.devMode) {
    auth.logout();
  }
  return <AccessDenied />;
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  roles: PropTypes.array,
  role: PropTypes.string,
  isPublic: PropTypes.bool,
  layout: PropTypes.elementType,
  layoutProps: PropTypes.object,
};
AuthenticatedRoute.defaultProps = {
  roles: undefined,
  role: undefined,
  isPublic: false,
  layout: undefined,
  layoutProps: undefined,
};

export default AuthenticatedRoute;
