import { startOfDay, endOfDay, addDays, addMilliseconds, format, parseISO, differenceInCalendarDays } from 'date-fns';

const parse = (dateOrString) => (typeof dateOrString === 'string' ? parseISO(dateOrString) : dateOrString);

const buildIsGoalStart = (config) => (testDate) => {
  switch (config.type) {
    case 'day-count': {
      const diff = Math.abs(differenceInCalendarDays(config.start, testDate));
      if (diff % config.count === 0) return true;
      return false;
    }
    default:
      throw new Error(`Unknown pay period config type "${config.type}".`);
  }
};

const buildIsGoalEnd = (config) => (testDate) => {
  switch (config.type) {
    case 'day-count': {
      const diff = Math.abs(differenceInCalendarDays(config.end, testDate));
      if (diff % config.count === 0) return true;
      return false;
    }
    default:
      throw new Error(`Unknown pay period config type "${config.type}".`);
  }
};

const buildPayPeriodConfig = (settings) => {
  const config = {
    type: settings.type,
    start: startOfDay(parseISO(settings.start)),
    count: settings.count,
  };

  if (config.type === 'day-count') {
    config.end = endOfDay(addDays(parseISO(settings.start), (settings.count || 14) - 1));
  }

  config.isGoalStart = buildIsGoalStart(config);
  config.isGoalEnd = buildIsGoalEnd(config);

  return config;
};

const getPayPeriod = (config, containedDate) => {
  let start = parse(containedDate);
  start = startOfDay(start);
  let end = parse(containedDate);
  end = endOfDay(end);

  let loopGuard = 0;
  while (!config.isGoalStart(start)) {
    start = addDays(start, -1);
    loopGuard += 1;
    if (loopGuard > 500) throw new Error(`Could not find start date for "${containedDate}" with config: `, config);
  }
  loopGuard = 0;
  while (!config.isGoalEnd(end)) {
    end = addDays(end, 1);
    loopGuard += 1;
    if (loopGuard > 500) throw new Error(`Could not find start date for "${containedDate}" with config: `, config);
  }

  return { start, end };
};

const periodIndexInSet = (config, containedDate, itemsInSetCount) => {
  switch (config.type) {
    case 'day-count': {
      const { end } = getPayPeriod(config, containedDate);
      const diff = Math.abs(differenceInCalendarDays(config.end, end));
      const periodCount = diff / config.count;
      const index = periodCount % itemsInSetCount;
      return index;
    }
    default:
      throw new Error(`Unknown pay period config type "${config.type}".`);
  }
};

const getNextPayPeriod = (config, containedDate) => {
  let startOfNext = parse(containedDate);

  if (config.isGoalStart(startOfNext)) startOfNext = addDays(startOfNext, 1);

  let loopGuard = 0;
  while (!config.isGoalStart(startOfNext)) {
    startOfNext = addDays(startOfNext, 1);
    loopGuard += 1;
    if (loopGuard > 500) throw new Error(`Could not get into next period for "${containedDate}" with config: `, config);
  }

  return getPayPeriod(config, startOfNext);
};

const getPrevPayPeriod = (config, containedDate) => {
  const isGoalEnd = buildIsGoalEnd(config);

  let endOfNext = parse(containedDate);

  if (isGoalEnd(endOfNext)) endOfNext = addDays(endOfNext, -1);

  let loopGuard = 0;
  while (!isGoalEnd(endOfNext)) {
    endOfNext = addDays(endOfNext, -1);
    loopGuard += 1;
    if (loopGuard > 500) throw new Error(`Could not get into previous period for "${containedDate}" with config: `, config);
  }

  return getPayPeriod(config, endOfNext);
};

const getPayPeriodArray = (config, containedDate, countEitherSide) => {
  const periods = [];
  periods[0] = getPayPeriod(config, containedDate);

  for (let i = 1; i <= 3; i++) {
    periods[i] = getNextPayPeriod(config, periods[i - 1].end);
    periods[-i] = getPrevPayPeriod(config, periods[-i + 1].end);
  }

  return periods;
};

export { getPayPeriodArray, getPrevPayPeriod, getNextPayPeriod, getPayPeriod, periodIndexInSet, buildPayPeriodConfig };
