import React, { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

import NodeFilter from 'src/kiska/components/NodeList/NodeFilter';
import { QueryVariablesContext } from 'src/kiska/components/NodeList/QueryVariablesContext';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // alignItems: 'baseline',
  },
  rightSide: {
  },
  selector: {
    display: 'flex',
    alignItems: 'center',
  },
  filters: {
    position: 'relative',
    overflow: 'hidden',
  },
  filterContainer: {
    width: '100%',
  },
  button: {
    // '&:hover, &.selected:hover': {
    //   backgroundColor: theme.palette.secondary.light,
    //   color: theme.palette.secondary.contrastText,
    // },
    // '&.selected': {
    //   backgroundColor: theme.palette.secondary.main,
    //   color: theme.palette.secondary.contrastText,
    // },
  },
}));

const FilterContainer = ({ children, activeValue, value }) => {
  const classes = useStyles();
  const isActive = activeValue.includes(value);

  const hidden = { opacity: 0, height: 0, marginTop: 0 };
  const visible = { opacity: 1, height: 'auto', marginTop: 4 };

  return (
    <motion.div
      className={classes.filterContainer}
      initial={hidden}
      animate={isActive ? visible : hidden}
      transition={{
        type: 'spring',
        stiffness: 180,
        damping: 40,
      }}
    >
      {children}
    </motion.div>
  );
};

const FilterBySelector = ({ availableFilters: propsAvailableFilters }) => {
  const { updateVariables } = useContext(QueryVariablesContext);
  const classes = useStyles();
  const availableFilters = Array.isArray(propsAvailableFilters) ? propsAvailableFilters : propsAvailableFilters.split(/[,\s]+/);
  const [value, setValue] = useState([]);

  const handleChange = useCallback((event, newValue) => {
    const indexOfJob = newValue.indexOf('job');
    const indexOfCustomer = newValue.indexOf('customer');

    // Don't allow filtering by both customer and job since their exclusive
    if (indexOfJob > -1 && indexOfCustomer > -1) {
      newValue.splice(Math.min(indexOfJob, indexOfCustomer), 1);
    }

    const reducerArgs = availableFilters
      .filter((f) => !newValue.includes(f))
      .map((name) => ({ path: `args.where.${name}`, value: null }));

    updateVariables(reducerArgs);

    setValue(newValue);
  }, []);

  const commonButtonProps = {
    classes: { root: classes.button, selected: 'selected' },
  };

  if (!availableFilters.length) return null;
  const nValue = availableFilters.length > 1 ? value : availableFilters[0];

  return (
    <div className={classes.root}>
      {(availableFilters.length > 1) && (
        <Typography variant="body1" color="textSecondary" style={{ marginRight: 8 }}>Only show these:</Typography>
      )}
      <div className={classes.rightSide}>
        {(availableFilters.length > 1) && (
          <div className={classes.selector}>
            <ToggleButtonGroup
              value={nValue}
              onChange={handleChange}
              size="small"
              className={classes.buttons}
            >
              {availableFilters.includes('task') && <ToggleButton value="task" {...commonButtonProps}>Tasks</ToggleButton>}
              {availableFilters.includes('payRate') && <ToggleButton value="payRate" {...commonButtonProps}>Pay Rates</ToggleButton>}
              {availableFilters.includes('user') && <ToggleButton value="user" {...commonButtonProps}>Workers</ToggleButton>}
              {availableFilters.includes('job') && <ToggleButton value="job" {...commonButtonProps}>Jobs</ToggleButton>}
              {availableFilters.includes('customer') && <ToggleButton value="customer" {...commonButtonProps}>Customers</ToggleButton>}
            </ToggleButtonGroup>
          </div>
        )}
        <div className={classes.filters}>
          <FilterContainer activeValue={nValue} value="task">
            <NodeFilter rootPath="args.where" path="task" label="" placeholder="Choose Tasks" />
          </FilterContainer>
          <FilterContainer activeValue={nValue} value="payRate">
            <NodeFilter rootPath="args.where" path="payRate" label="" placeholder="Choose Pay Rates" />
          </FilterContainer>
          <FilterContainer activeValue={nValue} value="user">
            <NodeFilter rootPath="args.where" path="user" label="" placeholder="Choose Workers" />
          </FilterContainer>
          <FilterContainer activeValue={nValue} value="job">
            <NodeFilter rootPath="args.where" path="job" label="" placeholder="Choose Jobs" />
          </FilterContainer>
          <FilterContainer activeValue={nValue} value="customer">
            <NodeFilter rootPath="args.where" path="job.customer" label="" placeholder="Choose Customers" />
          </FilterContainer>
        </div>
      </div>
    </div>
  );
};

FilterBySelector.propTypes = {
  availableFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
FilterBySelector.defaultProps = {
  availableFilters: ['task', 'payRate', 'user', 'job', 'customer'],
};

export default FilterBySelector;
