import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { DateRangePicker } from 'src/kiska/components/NodeList/DateRangePicker';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Hideable } from 'src/kiska/components/Hideable';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import produce from 'immer';
import { useNodeList } from 'src/kiska/components/NodeList';
import FilterIcon from 'mdi-material-ui/Filter';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import FilterBySelector from '../modules/work_entry/FilterBySelector';

// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

const BooleanDisplayParam = ({ name, label, displayParams, onChange }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={displayParams[name]} onChange={(event) => onChange(name, event.target.checked)} />}
      label={label}
    />
  );
};

const WorkEntryReportQueryParams = (props) => {
  // const classes = useStyles(props);
  const { availableFilters, displayParams, onDisplayParamsChange, initialDateRange, enabledDisplayParams: propsEnabledDisplayParams, dateRangeOptions } = props;
  const [laundry, setLaundry] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const { updateVariables } = useNodeList();
  const { user } = useUser();

  const enabledDisplayParams = propsEnabledDisplayParams.filter((param) => {
    if (param === 'showFinancials' && !user.permissions.canViewPayRates()) return false;
    return true;
  });

  const handleChange = (name) => () => {
    setLaundry(produce((draft) => {
      draft[name] = true;
    }));
  };

  const handleDisplayParamsChange = (name, value) => {
    const newDisplayParams = produce(displayParams, (draft) => {
      draft[name] = value;
    });
    onDisplayParamsChange(newDisplayParams);
  };

  const clearFilters = () => {
    const reducerArgs = availableFilters.map((name) => ({ path: `args.where.${name}`, value: null }));
    updateVariables(reducerArgs);
  };

  const handleShowFiltersChange = (event, value) => {
    setShowFilters(value);
    if (!value) {
      clearFilters();
    }
  };

  const areAllDirty = (names) => names.reduce((acc, name) => {
    if (!laundry[name]) return false;
    return acc;
  }, true);

  const displayParamsProps = {
    onChange: handleDisplayParamsChange,
    displayParams,
  };

  const displayParamProps = {};

  return (
    <>
      <Grid item>
        <DateRangePicker rootPath="args.where" path="start" onChange={handleChange('dates')} initial={initialDateRange} options={dateRangeOptions} />
      </Grid>
      <Hideable hidden={!areAllDirty([])}>
        {availableFilters && availableFilters.length && (
          <Grid item>
            <ToggleButtonGroup value={showFilters} exclusive size="small" onChange={handleShowFiltersChange}>
              <ToggleButton value={true}><FilterIcon color="inherit" />Filter Hours</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
        <Hideable hidden={!showFilters}>
          <Grid item xs={12}>
            <FilterBySelector availableFilters={availableFilters} />
          </Grid>
        </Hideable>
      </Hideable>
      {!!enabledDisplayParams.length && (
        <>
          {enabledDisplayParams.includes('showEntries') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showEntries" label="Show Work Log" {...displayParamsProps} />
            </Grid>
          )}
          {enabledDisplayParams.includes('showNotes') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showNotes" label="Show Notes" {...displayParamsProps} />
            </Grid>
          )}
          {enabledDisplayParams.includes('showCharts') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showCharts" label="Show Charts" {...displayParamsProps} />
            </Grid>
          )}
          {enabledDisplayParams.includes('showFinancials') && (
            <Grid item {...displayParamProps}>
              <BooleanDisplayParam name="showFinancials" label="Show Financials" {...displayParamsProps} />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

WorkEntryReportQueryParams.propTypes = {
  availableFilters: PropTypes.array.isRequired,
  onDisplayParamsChange: PropTypes.func.isRequired,
  displayParams: PropTypes.object.isRequired,
  initialDateRange: PropTypes.string,
  enabledDisplayParams: PropTypes.array,
  dateRangeOptions: PropTypes.array,
};
WorkEntryReportQueryParams.defaultProps = {
  initialDateRange: 'all-time',
  enabledDisplayParams: [],
  dateRangeOptions: undefined,
};

export { WorkEntryReportQueryParams };
