import React, { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { useLocalNode } from 'src/kiska/components/LocalNodeContext';
import _ from 'lodash';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import { generatePrimaryId } from 'src/kiska/utils';
import { usePrint } from 'src/kiska/components/PdfGenerator';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const ArrayFieldEditor = (props) => {
  const classes = useStyles(props);
  const { updateForm, updateFormProps, initialValue, children, saveOnChange, insertFormProps, allowDelete, allowEdit, allowInsert, insertLabel, name } = props;
  const mutationContext = useMutationContext();
  const UpdateForm = updateForm;
  const { node } = useLocalNode();
  const { update } = useViewOrUpdate();
  const { onChange, getFieldValue, registerField, unregisterField, mutate } = update ? mutationContext : {};
  const values = getFieldValue ? getFieldValue(name) : _.get(node, name);
  const [activeUpdateValue, setActiveUpdateValue] = useState(undefined);
  const inserting = activeUpdateValue === null;
  const updating = !!activeUpdateValue;
  const formProps = {
    onClose: () => setActiveUpdateValue(undefined),
    node: activeUpdateValue,
  };
  const { printMode } = usePrint();

  const handleUpdate = (mutation, { valuesAsMutationVars }) => {

  };

  const handleInsert = (mutation, { valuesAsMutationVars }) => {
    valuesAsMutationVars.id = generatePrimaryId();
    onChange(name, [...(values || []), valuesAsMutationVars]);
  };

  useEffect(() => {
    if (update) {
      registerField(name, { initialValue });

      return () => {
        unregisterField(name);
      };
    }
    return undefined;
  }, [initialValue, name, registerField, unregisterField, update]);

  const onUpdate = (newValue) => {
    const newValues = values.map((value) => {
      if (value.id === newValue.id) return newValue;
      return value;
    });
    onChange(name, newValues);
  };

  const onDelete = (deletedValue) => {
    const newValues = values.map((value) => {
      if (value.id === deletedValue.id) return undefined;
      return value;
    }).filter((value) => value !== undefined);
    onChange(name, newValues);
  };

  return (
    <>
      <UpdateForm open={updating} {...formProps} {...updateFormProps} trigger={null} onSuccess={handleUpdate} embeddedMode />
      <div className={classes.root}>
        <div className={classes.values}>
          {children({ values, onUpdate, onDelete })}
        </div>
        {!printMode && (
          <div className={classes.actions}>
            <UpdateForm {...formProps} {...insertFormProps} onSuccess={handleInsert} embeddedMode />
          </div>
        )}
      </div>
    </>
  );
};

ArrayFieldEditor.propTypes = {
};
ArrayFieldEditor.defaultProps = {
};

export { ArrayFieldEditor };
