import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import EditButton from 'src/components/updates/EditButton';
import { UserUpdateForm } from 'src/components/modules/user/UserUpdateForm';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { SimpleTable, SimpleTableInner } from 'src/components/dashboards/settings/SimpleTable';
import { LocalNodeProvider } from 'src/kiska/components/LocalNodeContext';
import CrudForm from 'src/kiska/components/MutationContext/CrudForm';
import NodeFilter from 'src/kiska/components/NodeList/NodeFilter';
import LabelValueTable from '../../views/LabelValueTable';
import UserAvatar from './UserAvatar';
import { ResourceUpdateForm } from '../resource/ResourceUpdateForm';
import { ResourceViewDialog } from '../resource/ResourceViewDialog';
import { useResourceTableColumns } from '../resource/useResourceTableColumns';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  avatar: {
    height: 300,
    maxWidth: 300,
  },
  content: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  actions: {
    marginTop: theme.spacing(2),
  },
}));

const UserProfile = ({ node }) => {
  const { logout } = useAuth0();
  const { user } = useUser();
  const classes = useStyles();
  const { columns } = useResourceTableColumns({ showExpires: true, showCreatedAt: true, showTags: true });

  const handleLogout = () => {
    logout();
  };

  const fields = [
    'displayName', 'firstName', 'lastName', 'preferences.title', 'email', 'phone', 'role',
    { label: 'Pay Rates', value: node.payRates.map(({ pay_rate: payRate }) => payRate.label).join(', ') },
  ];

  const resourceFilters = (
    <>
      <Grid item xs={12}>
        <NodeFilter rootPath="where" path="tags" label="Filter by tags" boolOp="and" where={{ type: { _eq: 'resource' } }} />
      </Grid>
    </>
  );

  return (
    <LocalNodeProvider node={node} type="user">
      <div className={classes.content}>
        <Grid container spacing={2} justify="center" alignItems="flex-start">
          <Grid item container xs={12} md={6} justify="center">
            <Grid item xs={12} container justify="center">
              <UserAvatar node={node} size={200} />
            </Grid>
            <Grid item>
              <LabelValueTable type="user" node={node} fields={fields} />
            </Grid>
            <Grid item container justify="space-around" xs={12} className={classes.actions}>
              {user.id === node.id ? (
                <Button color="default" variant="contained" onClick={handleLogout}>
                  {/* <LogoutIcon /> */}
                  <span>Logout</span>
                </Button>
              ) : <div />}
              <UserUpdateForm id={node.id} trigger={<EditButton>Edit Profile</EditButton>} />
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6} justify="center">
            <Grid item>
              <Typography variant="h4" color="textSecondary" align="center" gutterBottom>
                Qualifications & Safety Profile
              </Typography>
              <br />
              <SimpleTable
                type="resource"
                addLabel="Add"
                columns={columns}
                UpdateForm={ResourceUpdateForm}
                updateFormProps={{ showExpires: true }}
                updateFixtures={{ userId: node.id }}
                insertFixtures={{ userId: node.id }}
                fixtures={[
                  { path: 'where.userId._eq', value: node.id },
                ]}
                actionsOnBottom={false}
                actionsOnTop={false}
                floatingAdd
                enableAdd
                enableEdit={false}
                showSearchBox={false}
                rowClickAction="view"
                ViewDialog={ResourceViewDialog}
                viewDialogProps={{ updateFormProps: { fixtures: { userId: node.id }, showExpires: true } }}
                // filters={resourceFilters}
              />
            </Grid>
          </Grid>

        </Grid>
      </div>
    </LocalNodeProvider>
  );
};

UserProfile.propTypes = {
  node: PropTypes.object.isRequired,
};
UserProfile.defaultProps = {
};

export default UserProfile;
