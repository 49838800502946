import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, Button, Paper, ButtonGroup } from '@material-ui/core';
import { AttachFile as AttachIcon } from '@material-ui/icons';
import CameraIcon from 'mdi-material-ui/Camera';
import _ from 'lodash';
import { withSettings } from 'src/kiska/components/contexts/SettingsContext';
import HelperText from '../HelperText';
import { MutationContext } from '../../MutationContext';
import { DragndropDialog } from './DragndropDialog';
import { FilePreviewsContainer } from './FilePreviewsContainer';
import { CameraDialog } from './CameraDialog';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
});

class FileField extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    allowedFileTypes: PropTypes.array,
    restrictions: PropTypes.object,
    roles: PropTypes.array,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    editMode: PropTypes.bool,
    showDelete: PropTypes.bool,
    onlyButton: PropTypes.string,
    dialogMode: PropTypes.bool,
  }

  static defaultProps = {
    allowedFileTypes: undefined,
    restrictions: {},
    roles: ['authenticated', 'manager'],
    helperText: undefined,
    label: undefined,
    height: undefined,
    editMode: true,
    showDelete: true,
    onlyButton: undefined,
    dialogMode: true,
  }

  constructor(props, context) {
    super(props, context);

    context.registerField(props.name);
  }

  isMultiple = () => {
    const { schema, type } = this.context;
    const { name } = this.props;
    const schemaField = schema.types[type].fields[name];
    const isMultiple = !!schemaField.hasMany;

    return isMultiple;
  }

  handleUploadComplete = (files) => {
    const { onChange, getFieldValue } = this.context;
    const { name } = this.props;
    const multiple = this.isMultiple();

    let value = getFieldValue(name);

    if (multiple) {
      if (!value) value = [];
      value = [...files, ...value];
    } else {
      value = files[0];
    }

    onChange(name, value);
  }

  handleDelete = (file) => {
    const { onChange, getFieldValue } = this.context;
    const { name } = this.props;
    const multiple = this.isMultiple();

    let value = getFieldValue(name);

    if (multiple) {
      value = value.filter((f) => f.id !== file.id);
    } else {
      value = null;
    }

    onChange(name, value);
  }

  render() {
    const { onlyButton, name, classes, helperText, label: propsLabel, dialogMode, dragndropTrigger: propsDragndropTrigger, cameraTrigger: propsCameraTrigger, allowedFileTypes, restrictions, roles, height, editMode, showDelete } = this.props;
    const { getFieldValue, getFieldErrors, pendingMutation, schema, type } = this.context;
    const schemaField = schema.types[type].fields[name];
    const label = propsLabel !== undefined ? propsLabel : schemaField.label;
    const value = getFieldValue(name);
    const errors = getFieldErrors(name);
    const multiple = this.isMultiple();

    const dragndropTrigger = propsDragndropTrigger || (
      <Button variant="outlined" color="default">
        <AttachIcon /><span>Upload File{multiple && 's'}</span>
      </Button>
    );

    const cameraTrigger = propsCameraTrigger || (
      <Button variant="outlined" color="default">
        <CameraIcon /><span>Add Photo</span>
      </Button>
    );

    if (onlyButton === 'upload') return <DragndropDialog trigger={dragndropTrigger} onUploadComplete={this.handleUploadComplete} multiple={multiple} />;
    if (onlyButton === 'camera') return <CameraDialog trigger={cameraTrigger} onUploadComplete={this.handleUploadComplete} />;

    return (
      <FormControl error={!!errors.length} disabled={pendingMutation} className={classes.root}>
        <FormLabel className={classes.label}>{label}</FormLabel>
        <HelperText text={helperText} errors={errors} />
        <div className={classes.actions}>
          <ButtonGroup>
            <CameraDialog trigger={cameraTrigger} onUploadComplete={this.handleUploadComplete} />
            {dialogMode && <DragndropDialog trigger={dragndropTrigger} onUploadComplete={this.handleUploadComplete} multiple={multiple} dialogMode={dialogMode} />}
          </ButtonGroup>
        </div>
        {!dialogMode && (
          <DragndropDialog trigger={dragndropTrigger} onUploadComplete={this.handleUploadComplete} multiple={multiple} dialogMode={dialogMode} />
        )}
        <FilePreviewsContainer files={value} onDelete={this.handleDelete} enableView={true} />
      </FormControl>
    );
  }
}

FileField = _.flow(
  withSettings,
  withStyles(styles),
)(FileField);

export { FileField };
