import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { UserList } from 'src/components/dashboards/UserList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

const Users = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <UserList />
    </div>
  );
};

Users.propTypes = {
};
Users.defaultProps = {
};

export default Users;
