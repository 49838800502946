/* eslint-disable react/prop-types */
import React from 'react';
import { fragments } from './fragments';

export default {
  types: {
    work_entry_grouped: {
      isFunctionTable: true,

      renderers: {
        inline: ({ node }) => <>[work entry]</>,
      },

      selections: {
        default: `
          id workEntryId userId customerId jobId
          userCount customerCount jobCount workEntryCount
          sumDuration avgDuration minDuration maxDuration
          minStart maxEnd minStartTime maxEndTime
          job {
            ${fragments.job.basic}
            tasks {id task{id label}}
          }
          user {
            id displayName 
            avatar {id url mimeType name }
            role { id label }
            payRates {
              id pay_rate { 
                id label price cost tasks { 
                  id task { id label description }
                }
              }
            }
          }
          customer {
            id name company
          }
          workEntry {
            id notes
            start end
            task { id label}
            job {id title customer{id name company}}
            user {
              id displayName avatar{id url}
            }
            payRate cost price
            duration
          }
          payRate payRateId
          sumCost sumPrice dayCount
        `,
      },

      fields: {
        user: { type: 'user' },
        job: { type: 'job' },
        customer: { type: 'contact' },
        task: { type: 'task' },
        payRate: { type: 'pay_rate' },
      },
    },
  },
};
