import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { usePageMeta } from 'src/kiska/components/contexts/PageMetaContext';
import { WorkEntryReportQuery } from 'src/components/modules/work_entry/WorkEntryReportQuery';
import { Grid } from 'src/kiska/components/Grid';
import { useNodeList } from 'src/kiska/components/NodeList';
import { extractDateRangeStringFromVariables } from 'src/utils';
import { purple } from '@material-ui/core/colors';
import { WorkEntryReportQueryParams } from './WorkEntryReportParams';
import { PayrollWorkEntriesTable } from './PayrollWorkEntriesTable';
import { ReportingDates } from './ReportingDates';
import { ReportWrapper } from './ReportWrapper';
import { ReportTopContent } from './ReportTopContent';
import { ReportContent } from './ReportContent';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    flexDirection: 'column !important',
    justifyContent: 'flex-start !important',
  },
  tableWrapper: {
    margin: theme.spacing(0, -4),
  },
  shareButton: {
    color: '#FFFFFF',
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}));

const PayrollReportInner = (props) => {
  const { } = props;
  const { variables, nodes } = useNodeList();
  const classes = useStyles(props);
  const { setTitles } = usePageMeta();
  const [displayParams, setDisplayParams] = useState({});

  const datesStr = extractDateRangeStringFromVariables(variables);

  const availableFilters = ['task', 'payRate', 'user', 'job', 'customer'];
  const shareProps = {
    name: `Payroll Report${datesStr ? ` ${datesStr}` : ''}.pdf`,
    emailData: {
      subject: `Payroll Report PDF`,
      body: `Hello,\n\nYou have been sent a Payroll report ${datesStr ? `for ${datesStr}` : ''}. Please find the attched PDF file.\n\nThanks,\n~ Slick Systems`,
    },
    className: classes.shareButton,
  };

  const handleDisplayParamsChange = (value) => {
    setDisplayParams(value);
  };

  useEffect(() => {
    setTitles('Payroll Report');
  }, [setTitles]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify="flex-start">
        <WorkEntryReportQueryParams
          availableFilters={availableFilters}
          displayParams={displayParams}
          onDisplayParamsChange={handleDisplayParamsChange}
          initialDateRange="last-period"
          enabledDisplayParams={[]}
          dateRangeOptions={['all-time', 'last-year', 'last-month', 'last-period', 'this-period', 'this-month', 'this-year']}
        />
      </Grid>

      <ReportWrapper shareProps={shareProps}>
        <ReportTopContent title="Payroll Report">
          <Grid item xs={12} md={6}>
            <ReportingDates />
          </Grid>
        </ReportTopContent>
        <ReportContent>
          <PayrollWorkEntriesTable />
        </ReportContent>
      </ReportWrapper>
    </div>
  );
};

PayrollReportInner.propTypes = {
};
PayrollReportInner.defaultProps = {
};

const PayrollReport = (props) => {
  const { ...innerProps } = props;

  const initial = {
    orderBy: [
      { user: { displayName: 'asc' } },
      { minStart: 'desc' },
    ],
    args: {
      where: {
      },
    },
  };

  const fixtures = [
    // { path: 'args.where.user.id._eq', value: node.id },
    { path: 'args.groupByUser', value: true },
    { path: 'args.groupByPayRate', value: true },
  ];

  return (
    <>
      <WorkEntryReportQuery
        fixtures={fixtures}
        initial={initial}
      >
        <PayrollReportInner {...innerProps} />
      </WorkEntryReportQuery>
    </>
  );
};

PayrollReport.propTypes = {
};
PayrollReport.defaultProps = {
};

export { PayrollReport };
