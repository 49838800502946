import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from 'src/kiska/components/Dialog';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import FormView from './FormView';
import { findForm } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  content: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(0, 0, 0, 0),
  },
}));

const FormViewDialog = (props) => {
  const classes = useStyles(props);
  const { node, open, onClose, ...rest } = props;
  const dialogControlProps = useLocationControl({ open, onClose });

  if (!node || !node.id) return null;

  const handleSigning = () => {
    dialogControlProps.closeDialog();
  };

  return (
    <Dialog title={node.title} {...rest} {...dialogControlProps}>
      <DialogContent className={classes.content}>
        <FormView node={node} onSigning={handleSigning} />
      </DialogContent>
    </Dialog>
  );
};

FormViewDialog.propTypes = {
};
FormViewDialog.defaultProps = {
};

export { FormViewDialog };
