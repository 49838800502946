import React, { } from 'react';
// import PropTypes from 'prop-types';
import { DialogContent, Typography, DialogActions, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Send as SendIcon } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import PdfIcon from 'mdi-material-ui/FilePdf';
import FileIcon from 'mdi-material-ui/File';
import AttachmentIcon from 'mdi-material-ui/Attachment';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from 'src/kiska/components/Dialog';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import { ContactUpdateForm } from 'src/components/modules/customer/CustomerUpdateForm';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import { MutationProvider } from '../MutationContext/MutationContext';
import { MutateButton } from '../MutationContext/MutateButton';
import { useSettings } from '../contexts/SettingsContext';
import MutationSnackbars from '../MutationContext/MutationSnackbars';
import { useUser } from '../contexts/UserContext';
import { fileToIconSrc } from '../MutationContext/FormFields/FileField/fileToIcon';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  attachmentsHeading: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.border.light,

    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
  attachment: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
  sendButton: {
    backgroundColor: green[500],
    color: '#FFF',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(0),
    padding: theme.spacing(2, 3, 2, 3),
    backgroundColor: '#333',
  },
  icon: {
    height: 40,
    marginRight: theme.spacing(1),
  },
}));

const blobToBase64 = (blob) => new Promise((resolve) => {
  const reader = new window.FileReader();
  reader.onload = () => {
    const base64 = reader.result.replace(/^data:.+;base64,/, '');
    resolve(base64);
  };
  reader.readAsDataURL(blob);
});

const schemaExtensions = {
  types: {
    email_form: {
      fields: {
        fromEmail: { type: 'String', validation: { required: true, email: true } },
        fromName: { type: 'String', validation: { required: true } },
        to: { type: 'contact', hasMany: true, validation: { required: true } },
        subject: { type: 'String', validation: { required: true } },
        body: { type: 'String', validation: { required: false } },
      },
    },
  },
};

const SendEmail = (props) => {
  const settings = useSettings();
  const { user } = useUser();
  const classes = useStyles(props);
  const { trigger, title: propsTitle, onClose, open, emailData, attachments } = props;
  const dialogControlProps = useLocationControl({ open, onClose });
  const auth = useAuth0();

  let title = propsTitle;
  if (title === undefined) title = 'Send Email';

  const sendEmail = async ({ variables }) => {
    const token = await auth.getAccessTokenSilently();

    console.log(variables);

    const base64Promises = attachments.map((a) => blobToBase64(a.blob));
    const base64s = await Promise.all(base64Promises);

    const data = {
      templateModel: {
        subject: variables.subject,
        body: variables.body.replace(/\n/g, '<br />'),
      },
      textBody: variables.body,
      to: variables.to.map((contact) => ({
        email: contact.email,
        name: contact.name || contact.company,
      })),
      from: {
        name: variables.fromName,
        email: variables.fromEmail,
      },
      attachments: attachments.map((a, i) => ({
        name: a.name,
        contentType: a.contentType,
        base64: base64s[i],
      })),
    };

    const response = await window.fetch(`${settings.public.kiskaOrigin}/kiska-api/v1/send-email`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      authorization: `Bearer ${token}`,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return response.json();
  };

  const mutationBuilders = {
    send: ({ fields, type, node, schema, values, valuesAsMutationVars }) => {
      return {
        mutate: sendEmail,
        variables: values,
        requiresValidation: true,
        type: 'email_form',
        renderSuccessMessage: (mutation, result) => `Email sent`,
        renderErrorMessage: (mutation, error) => `Error sending email`,
      };
    },
  };

  const handleSuccess = () => {
    dialogControlProps.closeDialog();
  };

  const handleError = (mutation, error) => {
    console.error('Email error', error);
  };

  const renderContactOption = (c) => {
    if (!c) return null;
    return <>{c.name || c.company || '<no contact name>'}<br />{c.email}</>;
  };

  const filterContactOptions = (options) => {
    return options.filter((o) => !!o.email);
  };

  return (
    <>
      <Dialog title={title} trigger={trigger} {...dialogControlProps}>
        <MutationProvider schemaExtensions={schemaExtensions} type="email_form" onSuccess={handleSuccess} onError={handleError} mutationBuilders={mutationBuilders}>
          <MutationSnackbars />
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={4} alignItems="flex-start">
              <Grid item xs={12}>
                <NodeField
                  name="to"
                  label="To:"
                  filterOptions={filterContactOptions}
                  renderOption={renderContactOption}
                  creatableUpdateForm={ContactUpdateForm}
                  creatableInputFieldMapping="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField name="fromName" label="From Name:" initialValue={user.displayName} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="fromEmail" label="Reply To Email:" initialValue={user.email} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="subject" label="Subject:" initialValue={emailData.subject} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="body" rows={6} label="Message" initialValue={emailData.body} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" color="textSecondary" className={classes.attachmentsHeading} gutterBottom><AttachmentIcon />Attachments</Typography>
                {attachments.map((file) => {
                  const iconSrc = fileToIconSrc(file);
                  return (
                    <div key={file.name} className={classes.attachment}>
                      <img src={iconSrc} className={classes.icon} /><Typography variant="body1" color="textPrimary">{file.name}</Typography>
                    </div>
                  );
                })}
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <MutateButton mutationName="send" className={classes.sendButton}>
              <SendIcon /><span>Send</span>
            </MutateButton>
          </DialogActions>
        </MutationProvider>
      </Dialog>
    </>
  );
};

SendEmail.propTypes = {
};
SendEmail.defaultProps = {
};

export { SendEmail };
