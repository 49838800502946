import { green, red, purple, blue, orange, yellow, lightGreen, lightBlue, pink, deepOrange, deepPurple, amber, indigo, teal, cyan, lime } from '@material-ui/core/colors';

const thicknesses = {
  '10 ga': { value: '.1345', label: '10 ga' },
  '3/16"': { value: 3 / 16, label: '3/16"' },
  '1/4"': { value: 1 / 4, label: '1/4"' },
  '3/8"': { value: 3 / 8, label: '3/8"' },
  '1/2"': { value: 1 / 2, label: '1/2"' },
  '5/8"': { value: 5 / 8, label: '5/8"' },
};

const dies = {
  blue: {
    label: 'Blue 1-1/4"',
    color: blue[800],
    formedRadius: {
      '10 ga': 3 / 16,
      '3/16"': 7 / 32,
      '1/4"': 5 / 16,
    },
  },
  tan: {
    label: 'Tan 2"',
    color: orange[400],
    formedRadius: {
      '10 ga': 3 / 16,
      '3/16"': 1 / 4,
      '1/4"': 5 / 16,
    },
  },
  black: {
    label: 'Black 2-1/4"',
    color: 'black',
    formedRadius: {
      '10 ga': 1 / 4,
      '3/16"': 5 / 16,
      '1/4"': 11 / 32,
      '3/8"': 25 / 64,
    },
  },
  steel: {
    label: 'Steel 5"',
    color: '#AAA',
    formedRadius: {
      '1/4"': 25 / 32,
      '3/8"': 25 / 32,
      '1/2"': 5 / 8,
      '5/8"': 11 / 16,
    },
  },
};

export { dies, thicknesses };
