import React from 'react';
import { Timer as TimeIcon } from '@material-ui/icons';
import { TabPanel, ContentTabs } from 'src/kiska/components/ContentTabs';
import ReportIcon from 'mdi-material-ui/FileChart';
import WorkerIcon from 'mdi-material-ui/AccountHardHat';
import { useDisplayParamsPermsFilter } from 'src/components/useDisplayParamsPermsFilter';
import UserProfile from './UserProfile';
import { UserWorkEntries } from './UserWorkEntries';
import UserAvatar from './UserAvatar';
import { UserReport } from './UserReport';

const UserTabs = ({ node, title }) => {
  const initialParams = useDisplayParamsPermsFilter({
    showFinancials: false, showCharts: false,
  });

  return (
    <ContentTabs
      urlPathIndex={3}
      title={title}
      titleAdornment={<UserAvatar node={node} size={40} />}
      defaultTab="profile"
      tabs={[
        { value: 'profile', label: 'Profile', icon: <WorkerIcon /> },
        { value: 'work-log', label: 'Log', icon: <TimeIcon /> },
        { value: 'report', label: 'Report', icon: <ReportIcon /> },
      ]}
    >
      <TabPanel value="profile">
        <UserProfile node={node} />
      </TabPanel>
      <TabPanel value="work-log">
        <UserWorkEntries
          node={node}
          enabledDisplayParams={['showNotes', 'showCharts', 'showFinancials']}
          initialDisplayParams={initialParams}
          initialDateRange="this-period"
        />
      </TabPanel>
      <TabPanel value="report">
        <UserReport node={node} />
      </TabPanel>
    </ContentTabs>
  );
};

export default UserTabs;
