import React, { Component, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNodeList } from 'src/kiska/components/NodeList';
import Color from 'color';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { getColorForKey } from 'src/components/modules/work_entry/charts/utils';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';

const ExpenseChartDataContext = createContext({});
const useExpenseChartData = () => useContext(ExpenseChartDataContext);

const buildPieData = (nodes, { theme, groupBy, getOption }) => {
  const groupByMap = {
    status: { propPath: 'status', propLabel: 'Status', getGroupLabel: (node) => getOption('expense', 'status', node).primary || 'None' },
    category: { propPath: 'category', propLabel: 'Category', getGroupLabel: (node) => getOption('expense', 'category', node).primary || 'None' },
    vendor: { propPath: 'vendor.id', propLabel: 'Vendor', getGroupLabel: (node) => (node.vendor ? (node.vendor.company || node.vendor.name) : 'None') },
    customer: { propPath: 'job.customer.id', propLabel: 'Customer', getGroupLabel: (node) => (node.job ? (node.job.customer.company || node.job.customer.name) : 'None') },
    job: { propPath: 'job.id', propLabel: 'Job', getGroupLabel: (node) => (node.job ? node.job.title : 'None') },
  };

  const groupBys = {};

  _.forEach(groupBy, (groupByProp) => {
    const groupingInfo = groupByMap[groupByProp];
    const groups = {};

    nodes.forEach((node) => {
      const groupId = _.get(node, groupingInfo.propPath);
      if (!groups[groupId]) {
        const newGroup = {
          cost: 0,
          price: 0,
          label: groupingInfo.getGroupLabel(node),
          backgroundColor: Color(getColorForKey(`${groupByProp}:${groupId}`)).fade(0).string(),
        };
        groups[groupId] = newGroup;
      }

      const group = groups[groupId];
      group.price += node.price;
      group.cost += node.cost;
    });

    const datasets = [
      {
        label: 'Cost',
        data: _.map(groups, (group) => group.cost),
        name: 'cost',
      },
      {
        label: 'Price',
        name: 'price',
        data: _.map(groups, (group) => group.price),
      },
    ];

    datasets.forEach((set) => {
      set.borderColor = Color(theme.palette.text.secondary).fade(0.2).string();
      set.labels = _.map(groups, (group) => group.label);
      set.backgroundColor = _.map(groups, (group) => group.backgroundColor);
    });

    groupBys[groupByProp] = {
      datasets,
      labels: datasets[0].labels,
    };
  });

  return groupBys;
};

const ExpenseChartDataProvider = (props) => {
  const theme = useTheme();
  const { getOption } = useSchema();
  const { nodes } = useNodeList();
  const { children, groupBy } = props;

  const emptyData = !nodes || !nodes.length;
  let value = null;

  if (!emptyData) {
    value = {
      pie: buildPieData(nodes, { groupBy, theme, getOption }),
      time: {},
    };
  }

  return (
    <ExpenseChartDataContext.Provider value={value}>
      {children}
    </ExpenseChartDataContext.Provider>
  );
};

ExpenseChartDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  groupBy: PropTypes.array.isRequired,
};

ExpenseChartDataProvider.defaultProps = {

};

export { ExpenseChartDataProvider, ExpenseChartDataContext, useExpenseChartData };
