import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { generatePrimaryId } from '.';
import { usePrevious } from '../hooks/usePrevious';

const useLocationControl = ({ open: propsOpen, onOpen, onClose } = {}) => {
  const [dialogId] = useState(() => generatePrimaryId());
  const history = useHistory();
  const controlledMode = propsOpen !== undefined;
  const prevPropsOpen = usePrevious(propsOpen);
  const routerLocation = useLocation();

  const isIdInQueryString = useCallback(() => {
    return (queryString.parse(window.location.search).dlgs || '').includes(dialogId);
    // NO! Do not do this. Bad. Breaks. Too many update. Doubles the goBack() calls
    // return (queryString.parse(routerLocation.search).dlgs || '').includes(dialogId);
  }, [dialogId]);

  if (controlledMode && !onClose) console.error(`useLocationControl() in controlled mode was not passed an onClose() function. Bad Things will happen.`);

  const open = controlledMode ? propsOpen : isIdInQueryString();
  const prevOpen = useRef(open);

  const removeIdFromQs = useCallback(() => {
    const qs = { dlgs: '', ...queryString.parse(window.location.search) };
    if (!qs.dlgs.includes(dialogId)) return;

    qs.dlgs = qs.dlgs.replace(dialogId, '');
    if (!qs.dlgs) delete qs.dlgs;

    const qsStringified = _.isEmpty(qs) ? '' : `?${queryString.stringify(qs)}`;

    const url = `${window.location.pathname}${qsStringified}${window.location.hash}`;
    history.replace(url);
  }, [dialogId, history]);

  const addIdToQs = useCallback(() => {
    const qs = { dlgs: '', ...queryString.parse(window.location.search) };
    if (qs.dlgs.includes(dialogId)) return;
    qs.dlgs += dialogId;
    const url = `${window.location.pathname}?${queryString.stringify(qs)}${window.location.hash}`;
    history.push(url);
  }, [dialogId, history]);

  const closeDialog = useCallback(() => {
    setTimeout(() => {
      if (isIdInQueryString()) {
        // console.log(`Going back to close dialog ${dialogId}`);
        history.goBack();
      }
    }, 1);
  }, [dialogId, history, isIdInQueryString]);

  const openDialog = useCallback(() => {
    if (onOpen) onOpen();
    addIdToQs();
  }, [addIdToQs, onOpen]);

  useLayoutEffect(() => {
    const idInQs = isIdInQueryString();

    if (controlledMode) {
      if (propsOpen !== prevPropsOpen) {
        // Controlling parent made a change to open state
        if (propsOpen) {
          // console.log(`Controlling parent opened dialog ${dialogId}`);
          if (onOpen) onOpen();
          addIdToQs();
        } else {
          // console.log(`Controlling parent closed dialog ${dialogId}`);
          if (isIdInQueryString()) history.goBack();
          if (onClose) onClose();
        }
      } else if (!idInQs && propsOpen) {
        // Browser navigated to close dialog
        // console.log(`Browser navigated to close dialog ${dialogId}`);
        if (onClose) onClose();
      } else if (idInQs && !propsOpen) {
        // Browser navigated to open dialog
        // console.log(`Browser navigated to open dialog ${dialogId}`);
        if (onOpen) onOpen();
      }
    }

    if (!idInQs && prevOpen.current) {
      // console.log(`ID not in qs but prevOpen === true for dialog ${dialogId}`);
      if (onClose) onClose();
    } else if (idInQs && !prevOpen.current) {
      // console.log(`ID is in qs but prevOpen === false for dialog ${dialogId}`);
      if (onOpen) onOpen();
    }
    prevOpen.current = open;
  }, [addIdToQs, closeDialog, controlledMode, dialogId, history, isIdInQueryString, onClose, onOpen, open, openDialog, prevPropsOpen, propsOpen, removeIdFromQs, routerLocation.search]);

  useEffect(() => {
    return () => {
      removeIdFromQs();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // really intentionally empty

  return { dialogId, open, openDialog, closeDialog };
};

export { useLocationControl };
