const yes = () => true;
const no = () => false;

const getPermissions = (role, user) => {
  const permissions = {
    anon: {
      // Pay Rates
      canViewPayRates: no,
      canInsertPayRate: no,
      canUpdatePayRate: no,
      // Users
      canInsertUser: no,
      canDeleteUser: no,
      // Work Entries
      canSpecialUpdateWorkEntry: yes,
      canSpecialInsertWorkEntry: yes,
      canDeleteWorkEntry: yes,
      // Others
      canViewReports: no,
      canViewSettings: no,
      canDoAnything: no,
    },
    employee: {
      // Pay Rates
      canViewPayRates: no,
      canInsertPayRate: no,
      canUpdatePayRate: no,
      // Users
      canInsertUser: no,
      canDeleteUser: no,
      // Work Entries
      canSpecialUpdateWorkEntry: yes,
      canSpecialInsertWorkEntry: yes,
      canDeleteWorkEntry: yes,
      // Others
      canViewReports: no,
      canViewSettings: no,
      canDoAnything: no,
    },
    'office-admin': {
      // Pay Rates
      canViewPayRates: yes,
      canInsertPayRate: yes,
      canUpdatePayRate: yes,
      // Users
      canInsertUser: yes,
      canDeleteUser: no,
      // Work Entries
      canSpecialUpdateWorkEntry: yes,
      canSpecialInsertWorkEntry: yes,
      canDeleteWorkEntry: yes,
      // Others
      canViewReports: yes,
      canViewSettings: yes,
      canDoAnything: no,
    },
    manager: {
      // Pay Rates
      canViewPayRates: yes,
      canInsertPayRate: yes,
      canUpdatePayRate: yes,
      // Users
      canInsertUser: yes,
      canDeleteUser: no,
      // Work Entries
      canSpecialUpdateWorkEntry: yes,
      canSpecialInsertWorkEntry: yes,
      canDeleteWorkEntry: yes,
      // Others
      canViewReports: yes,
      canViewSettings: yes,
      canDoAnything: no,
    },
    admin: {
      // Pay Rates
      canViewPayRates: yes,
      canInsertPayRate: yes,
      canUpdatePayRate: yes,
      // Users
      canInsertUser: yes,
      canDeleteUser: yes,
      // Work Entries
      canSpecialUpdateWorkEntry: yes,
      canSpecialInsertWorkEntry: yes,
      canDeleteWorkEntry: yes,
      // Others
      canViewReports: yes,
      canViewSettings: yes,
      canDoAnything: yes,
    },
  };

  return permissions[role.id];
};

export { getPermissions };
