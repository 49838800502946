export default {
  types: {
    app_setting: {

      selections: {
        default: `
          id name label data 
        `,
      },

      fields: {
        name: {
          type: 'String',
        },
        label: {
          type: 'String',
        },
        data: {
          type: 'Json',
        },
        file: {
          type: 'file',
        },
        files: {
          type: 'file',
          hasMany: true,
        },

        'data.companyName': { type: 'String' },
        'data.tagLine': { type: 'String' },
        'data.address': { type: 'String' },
        'data.phone': { type: 'String' },
        'data.email': { type: 'String' },
        'data.website': { type: 'String' },
        'data.logo': { type: 'Json' },
        'data.blackAndWhiteLogo': { type: 'Json' },

        'data.jobs.allJobsAllTasks': { label: 'All Tasks Allowed on All Jobs', type: 'Boolean' },
        'data.jobs.useScheduling': { label: 'Use Scheduling', type: 'Boolean' },
        'data.jobs.useResources': { label: 'Use Resources', type: 'Boolean' },
        'data.jobs.useAssignments': { label: 'Assign Specific Workers to Jobs', type: 'Boolean' },
        'data.jobs.useDailySafetyRecords': { label: 'Use Daily Safety Records', type: 'Boolean' },
        'data.jobs.useChangeOrders': { label: 'Use Change Orders', type: 'Boolean' },
        'data.jobs.enableJobsBoard': { label: 'Enable Job Board', type: 'Boolean' },
        'data.jobs.enableJobSwitcherTags': { label: 'Enable job categories in Job Switcher', type: 'Boolean' },
        'data.jobs.workableStatuses': { type: 'Json' },

        'data.jobs.statusColors.active.dark.background': { type: 'String' },
        'data.jobs.statusColors.completed.dark.background': { type: 'String' },
        'data.jobs.statusColors.archived.dark.background': { type: 'String' },
        'data.jobs.statusColors.active.light.background': { type: 'String' },
        'data.jobs.statusColors.completed.light.background': { type: 'String' },
        'data.jobs.statusColors.archived.light.background': { type: 'String' },

        'data.reports.watermarkText': { type: 'String' },
        'data.reports.footerLine1': { type: 'String' },
        'data.reports.footerLine2': { type: 'String' },
        'data.reports.footerLine3': { type: 'String' },
        'data.reports.footerLine4': { type: 'String' },

        'data.payPeriods.type': { type: 'Int', validation: { required: true } },
        'data.payPeriods.count': { type: 'String', validation: { required: true } },
        'data.payPeriods.start': { type: 'Date', validation: { required: true } },

        'data.workLog.editingRoles': { type: 'Json', label: 'Who Can Edit Work Log', validation: { required: true } },

      },
    },
  },
};
