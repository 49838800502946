import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from 'mdi-material-ui/Pencil';

import { useNodes } from 'src/kiska/hooks/useNode';
import Table from 'src/kiska/components/NodeList/Table';
import { NodeListWithVars } from 'src/kiska/components/NodeList/NodeListWithVars';
import { useNodeList } from 'src/kiska/components/NodeList';
import { Accordion } from 'src/components/Accordion';
import { FormSection } from 'src/components/forms';
import { TaskUpdateForm } from './TaskUpdateForm';
import { TasksPanel } from './TasksPanel';
import { PayRatesPanel } from './PayRatesPanel';
import { UsersPanel } from './UsersPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  panel: {

  },
  step: {
    textTransform: 'uppercase',
    width: '2.5em',
    display: 'inline-block',
    marginRight: theme.spacing(4),
    fontWeight: 500,
  },
}));

const PayRatesTabInner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormSection title={<><span className={classes.step}>Step&nbsp;&nbsp;1:</span> Define your job tasks</>} className={classes.panel} initiallyExpanded={false}>
        <Grid item xs={12}>
          <TasksPanel />
        </Grid>
      </FormSection>
      <FormSection title={<><span className={classes.step}>Step&nbsp;&nbsp;2:</span> Define your pay rates</>} className={classes.panel} initiallyExpanded={false}>
        <Grid item xs={12}>
          <PayRatesPanel />
        </Grid>
      </FormSection>
      <FormSection title={<><span className={classes.step}>Step&nbsp;&nbsp;3:</span> Assign pay rates to users</>} className={classes.panel} initiallyExpanded={false}>
        <Grid item xs={12}>
          <UsersPanel />
        </Grid>
      </FormSection>
    </div>
  );
};

const PayRatesTab = () => {
  const initial = { orderBy: [{ label: 'asc' }] };

  return (
    <NodeListWithVars type="pay_rate" initial={initial}>
      <PayRatesTabInner />
    </NodeListWithVars>
  );
};

PayRatesTab.propTypes = {
};
PayRatesTab.defaultProps = {
};

export { PayRatesTab };
