import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Room as AddressIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { usePrint } from 'src/kiska/components/PdfGenerator';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1.0em',
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
    },
  },
}));

const ContactMapLink = (props) => {
  const classes = useStyles(props);
  const { contact } = props;
  const { printMode } = usePrint();

  if (!contact || printMode) return null;

  const { address1, address2, city, state, postalCode, company, country, name, phone, email, notes } = contact;

  const mapQuery = [address1, address2, city, state, postalCode, country].filter((v) => !!v).join(', ');
  const mapUrl = encodeURI(`https://www.google.com/maps/search/?api=1&query=${mapQuery}`);

  return (
    <Link href={mapUrl} target="_blank" className={classes.link}>
      <AddressIcon />Open in Google Maps&nbsp;&nbsp;<OpenInNewIcon />
    </Link>
  );
};

ContactMapLink.propTypes = {
};
ContactMapLink.defaultProps = {
};

export { ContactMapLink };
