import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import KiskaCrudForm from 'src/kiska/components/MutationContext/CrudForm';
import MutationSnackbars from 'src/kiska/components/MutationContext/MutationSnackbars';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { ArrowDropDown as DownArrowIcon } from '@material-ui/icons';
import CrudForm from '../../updates/CrudForm';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const UpdateJobStatusButton = (props) => {
  const { getOption } = useSchema();
  const { node, labelPrefix, ...rest } = props;
  const classes = useStyles(props);

  const trigger = (
    <Button {...rest}>
      {labelPrefix && <>{labelPrefix} &nbsp;</>}
      {getOption('job', 'status', node).primary}
      &nbsp;
      <DownArrowIcon />
    </Button>
  );

  return (
    <KiskaCrudForm type="job" node={node} onChangeMutation="update" onChangeMutationWait={1}>
      <SelectField name="status" trigger={trigger} />
      <MutationSnackbars />
    </KiskaCrudForm>
  );
};

UpdateJobStatusButton.propTypes = {
  node: PropTypes.object,
  labelPrefix: PropTypes.string,
};
UpdateJobStatusButton.defaultProps = {
  node: undefined,
  labelPrefix: undefined,
};

export { UpdateJobStatusButton };
