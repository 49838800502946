import React from 'react';
// import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { PageMetaProvider } from 'src/kiska/components/contexts/PageMetaContext';
import { ThemeProvider } from 'src/kiska/components/contexts/ThemeProviderContext';
import { SettingsProvider } from 'src/kiska/components/contexts/SettingsContext';
import { SchemaProvider } from 'src/kiska/components/contexts/SchemaContext';
import { normalizeSchema } from 'src/kiska/schema/normalizeSchema';
import { themes } from 'src/style/themes';
import rawSchema from 'src/schema';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { settings } from 'src/kiska/utils/settings';
import { AppSettingsProvider } from 'src/kiska/components/contexts/AppSettingsContext';
import { Auth0Provider } from '@auth0/auth0-react';
import { WorkEntryControlsProvider } from './components/modules/work_entry/useWorkEntryControls';
import { Router } from './Router';
import { ServiceWorkerProvider } from './ServiceWorkerContext';
import { UpdateConfirmDialog } from './kiska/components/UpdateConfirmDialog';
import { ApolloProvider } from './kiska/components/ApolloProvider';
import { UserProvider } from './kiska/components/contexts/UserContext';

const schema = normalizeSchema(rawSchema);

const useStyles = makeStyles((theme) => ({
  snackbar: {
    '& > div': {
      fontSize: '1.1rem',
      lineHeight: 1.2,
      padding: theme.spacing(0.5, 1),
    },
  },
}));

const App = (props) => {
  const auth0Settings = settings.public.auth.auth0;
  // const {} = props;
  const classes = useStyles(props);

  return (
    <SettingsProvider settings={settings}>
      <ServiceWorkerProvider>
        <HelmetProvider>
          <Auth0Provider
            domain={auth0Settings.domain}
            clientId={auth0Settings.clientId}
            redirectUri={`${settings.public.websiteOrigin}/app/`}
            audience="hasura"
          >
            <ApolloProvider>
              <SchemaProvider schema={schema}>
                <UserProvider>
                  <AppSettingsProvider>
                    <ThemeProvider themes={themes}>
                      <SnackbarProvider maxSnack={5} classes={{ root: classes.snackbar }}>
                        <PageMetaProvider>
                          <WorkEntryControlsProvider>
                            <Router>
                              {/* <UpdateConfirmDialog nagInterval={1000 * 60 * 60 * 4} /> */}
                            </Router>
                          </WorkEntryControlsProvider>
                        </PageMetaProvider>
                      </SnackbarProvider>
                    </ThemeProvider>
                  </AppSettingsProvider>
                </UserProvider>
              </SchemaProvider>
            </ApolloProvider>
          </Auth0Provider>
        </HelmetProvider>
      </ServiceWorkerProvider>
    </SettingsProvider>
  );
};

App.propTypes = {
};
App.defaultProps = {
};

export { App };
