/* eslint-disable camelcase */
import React, { useMemo, forwardRef } from 'react';
import { Typography, Hidden, Link } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
// import { Phone as PhoneIcon, MailOutline as EmailIcon } from '@material-ui/icons';

import { useLocalNode } from 'src/kiska/components/LocalNodeContext';
import { TextField, FileField, NodeField, BooleanField, SelectField, DateField } from 'src/kiska/components/ViewOrUpdateFields';
import { GridLineBreak } from 'src/components/forms';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { FormHeading } from 'src/components/forms/FormHeading';
import { FormSection } from 'src/components/forms/FormSection';
import { SignatureUpdateForm } from '../SignatureUpdateForm';
import { FormUpdateForm } from '../FormUpdateForm';
import { FormSignatures } from './FormSignatures';

export const useStyles = makeStyles((theme) => ({
  root: {

  },
  riskFieldRoot: {
    '&:not(:last-child)': {
      borderBottom: 'solid 1px rgba(0,0,0,.3)',
    },
  },
  fieldLabel: {
    lineHeight: 1.2,
  },
}));

const form = {
  title: 'Steep Slope Logging - Risk Assessment & Site Pre-Work',
  name: 'SteepSlopeLoggingRiskAssessmentSitePreWork',
  version: '1',
  versionDate: new Date('2020-06-20'),
  subtitle: (node) => {
    const parts = [];
    const data = node.data || {};
    if (data.block) parts.push(`${data.block}`);
    if (data.owner) parts.push(`${data.owner}`);
    if (data.contractor) parts.push(`${data.contractor}`);
    return parts.join(' | ');
  },
};

const stabilities = [
  {
    name: 'data.prework.map',
    label: `Steep Slopes and NO GO areas are easily identified, mapped and map is provided to workers`,
  },
  {
    name: 'data.prework.tree',
    label: 'Manual Tree Falling Required to harvest NO GO Areas',
  },
  {
    name: 'data.prework.approach',
    label: 'Approach Steep Slopes from Below',
  },
  {
    name: 'data.prework.operations',
    label: 'Operations During Daylight Hours Only',
  },
];

const StabilityField = ({ stability, ...rest }) => {
  const classes = useStyles();
  const { node } = useLocalNode();
  const { name, label } = stability;

  return (
    <Grid item xs={12} className={classes.stabilityFieldRoot}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={5}>
          <Typography variant="body1" className={classes.fieldLabel}>{label}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectField name={name} label="" {...rest} />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField name={`${name}Procedures`} label="" rows={2} {...rest} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const risks = [
  {
    name: 'data.slope.tracked',
    label: 'Tracked Machines: Slope & Slope Length',
    low: <>40 to 50% <strong>and slope length &lt; 30 m</strong></>,
    moderate: <>40 to 50% <strong>and slope length &gt; 30 m</strong></>,
    high: <>&gt; 50% <strong>and slope length &lt; 10 m</strong></>,
  },
  {
    name: 'data.slope.wheeled',
    label: 'Wheeled Machines: Slope & Slope Length',
    low: <>35 to 45% and Slope Length &lt; 30 m</>,
    moderate: <>35 to 45% and Slope Length &gt; 30 m</>,
    high: <>&gt; 45% and Slope Length &gt; 15 m</>,
  },
  {
    name: 'data.slope.unstable',
    label: 'Unstable Ground / Terrain Stability (Slumps, Talus)',
    low: <>NO instability Indicators and slopes &lt; 50%</>,
    moderate: <>Instability Indicators and slopes &lt; 50%</>,
    high: <>Instability Indicators and slopes &gt; 50%</>,
  },
  {
    name: 'data.slope.roughness',
    label: 'Ground Roughness (boulders, rock outcrops, gullies, etc.)',
    low: `< 30% of steep slope area covered by roughness features`,
    moderate: `30% to 50% of steep slope area covered by roughness features`,
    high: `50% of steep slope area covered by roughness features`,
  },
];

const RiskField = ({ risk, ...rest }) => {
  const classes = useStyles();
  const { node } = useLocalNode();
  const { name, label, low, moderate, high } = risk;

  const options = [
    { value: 'low', primary: 'Low Risk', secondary: low, color: 'hsla(120, 100%, 50%, .2)' },
    { value: 'moderate', primary: 'Moderate Risk', secondary: moderate, color: 'hsla(60, 100%, 50%, .2)' },
    { value: 'high', primary: 'High Risk', secondary: high, color: 'hsla(0, 100%, 50%, .2)' },
  ];

  return (
    <Grid item xs={12} className={classes.riskFieldRoot}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography variant="body1" className={classes.fieldLabel}>{label}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField options={options} name={name} label="" {...rest} />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField name={`${name}Comments`} label="" rows={2} {...rest} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const SteepSlopeLoggingRiskAssessmentSitePreWork = (props) => {
  const { printMode } = usePrint();
  const theme = useTheme();
  const classes = useStyles();
  const { node } = useLocalNode();
  const { view, update } = props;

  const isNew = !node || !node.id;

  const col2 = { xs: 12, sm: 6 };

  return (
    <>
      <Grid container spacing={3}>
        {printMode && (
          <Grid item xs={12}>
            <Typography variant="h4" align="center">{node.title}</Typography>
          </Grid>
        )}

        {/* Top Stuff */}
        <Grid item {...col2}>
          <DateField name="data.date" initialValue={new Date()} />
        </Grid>
        <Grid item {...col2}>
          <TextField name="data.owner" />
        </Grid>

        <Grid item {...col2}>
          <TextField name="data.cuttingPermit" />
        </Grid>
        <Grid item {...col2}>
          <TextField name="data.contractor" />
        </Grid>

        <Grid item {...col2}>
          <TextField name="data.block" />
        </Grid>
        <Grid item {...col2}>
          <TextField name="data.site" rows={3} />
        </Grid>

        <Grid item xs={12} container spacing={1} alignItems="center" justify="flex-end" wrap="nowrap">
          <Grid item>
            <Typography variant="body1">Appropriate Information Supplied by Owner (map, slope %, hazards)?</Typography>
          </Grid>
          <Grid item>
            <SelectField name="data.infoSupplied" label="" />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems="center" justify="flex-end" wrap="nowrap">
          <Grid item>
            <Typography variant="body1">Assessor Qualified to Conduct Steep Slope Assessment?</Typography>
          </Grid>
          <Grid item>
            <SelectField name="data.assessorQualified" label="" />
          </Grid>
        </Grid>
      </Grid>

      <FormSection title="Steep Slope Risk Assessment" spacing={1}>
        <Hidden smDown>
          <Grid item md={7}>
            <Typography variant="h6" color="textSecondary">Machine Stability Risk Factor</Typography>
          </Grid>
          <Grid item md={5}>
            <Typography variant="h6" color="textSecondary">Comments</Typography>
          </Grid>
        </Hidden>
        {risks.map((risk) => (
          <RiskField risk={risk} key={risk.name} />
        ))}
      </FormSection>

      <FormSection title="Steep Slope Pre-Work" spacing={1}>
        <Hidden smDown>
          <Grid item md={7}>
            <Typography variant="h6" color="textSecondary">Machine Stability Risk Factor</Typography>
          </Grid>
          <Grid item md={5}>
            <Typography variant="h6" color="textSecondary">Site Specific Procedures</Typography>
          </Grid>
        </Hidden>

        {stabilities.map((stability) => (
          <StabilityField key={stability.name} stability={stability} />
        ))}
      </FormSection>

      <FormSection title="Attachements" spacing={1}>
        <Grid item xs={12}>
          <FileField name="files" label="" />
        </Grid>
      </FormSection>

      <Grid item xs={12} sm={12}>
        <FormSignatures />
      </Grid>
    </>
  );
};

form.Form = SteepSlopeLoggingRiskAssessmentSitePreWork;
form.UpdateForm = (props) => {
  return <FormUpdateForm form={form} {...props} allowDelete={true} />;
};

export default form;
export { SteepSlopeLoggingRiskAssessmentSitePreWork };
