import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NodeSelector from 'src/kiska/components/NodeSelector';
import { useHistory } from 'react-router-dom';
import { ExpenseUpdateForm } from '../modules/expense/ExpenseUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  form: {
    maxWidth: 700,
  },
}));

const UserReports = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  const handleChange = (node) => {
    if (!node) return;

    history.push(`/app/users/${node.id}/report`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Typography variant="h6" align="center">
          Choose Worker:
        </Typography>
        <NodeSelector type="user" onChange={handleChange} />
      </div>
    </div>
  );
};

UserReports.propTypes = {
};
UserReports.defaultProps = {
};

export { UserReports };
