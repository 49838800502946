import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import MasterLayout from './MasterLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    minHeight: '100vh',
    padding: theme.spacing(4),
    background: `url(/images/diamond-plate.jpg)`,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.8)',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: 80,
    marginBottom: theme.spacing(2),
    zIndex: 10,
  },
  content: {
    position: 'relative',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  clientLogo: {
    width: '100%',
    height: 150,
    objectFit: 'contain',
    marginBottom: theme.spacing(4),
    zIndex: 10,
  },
}));

const AnonLayout = ({ children }) => {
  const classes = useStyles();
  const auth = useAuth0();

  return (
    <MasterLayout>
      <div className={classes.root}>
        <div className={classes.overlay} />
        <img src="/images/logo-portrait-blue-orange.png" alt="Slick Systems logo" className={classes.logo} />
        {/* <img src={clientLogo} alt="Client logo" className={classes.clientLogo} /> */}
        <div className={classes.content}>
          {children}
        </div>
        <span
          onClick={(event) => {
            event.preventDefault();
            auth.logout();
          }}
          style={{ opacity: 0.2, cursor: 'pointer' }}
        >
          Logout
        </span>
      </div>
    </MasterLayout>
  );
};

AnonLayout.propTypes = {
  children: PropTypes.node,
};
AnonLayout.defaultProps = {
  children: null,
};

export { AnonLayout };
