import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery, IconButton, Dialog as MuiDialog, DialogTitle } from '@material-ui/core';
import { HighlightOff as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ControlledLocationProvider } from './contexts/ControlledLocationContext';

const useStyles = makeStyles((theme) => ({
  dialog: {

  },
  paper: {
    overflowX: 'hidden',
    // display: 'flex',
    // flexDirection: 'column',
  },
  title: {
    padding: theme.spacing(0.5, 6),
    backgroundColor: theme.palette.background.default,
    boxShadow: `0 2px 20px -5px hsl(0deg 0% 0% / 33%)`,
    zIndex: 10,
    width: '100%',
    textAlign: 'center',
    '& > h2': {
      fontSize: 18,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
    padding: 6,
    color: 'hsla(4deg, 85%, 57%, .8);',
  },
}));

const Dialog = (props) => {
  const { title, dialogProps, open, openDialog, closeDialog, dialogId, showCloseButton, fullScreen: propsFullScreen, children, closeOnBackdropClick, closeOnEscape, className, fullscreenBreakpoint, trigger, maxWidth } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(fullscreenBreakpoint), { noSsr: true });

  const handleTriggerClick = () => {
    openDialog();
  };

  const handleEscapeDown = () => {
    if (closeOnEscape) {
      closeDialog();
    }
  };

  const handleBackdropClick = () => {
    if (closeOnBackdropClick) {
      closeDialog();
    }
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
  };

  const nTrigger = trigger ? cloneElement(trigger, { onClick: handleTriggerClick }) : null;

  // console.log('Dialog rendering. open: ', open);

  return (
    <ControlledLocationProvider id={dialogId}>
      {nTrigger}
      <MuiDialog
        onEscapeKeyDown={handleEscapeDown}
        onBackdropClick={handleBackdropClick}
        open={open}
        classes={{
          root: classNames(classes.dialog, className),
          paper: classes.paper,
        }}
        maxWidth={maxWidth}
        fullScreen={propsFullScreen === undefined ? fullScreen : propsFullScreen}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={handleKeyDown}
        {...dialogProps}
      >
        {showCloseButton && (
          <IconButton
            onClick={closeDialog}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        )}
        {title !== undefined && (
          <DialogTitle className={classes.title}>{title}</DialogTitle>
        )}
        {children}
      </MuiDialog>
    </ControlledLocationProvider>
  );
};

Dialog.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  trigger: PropTypes.node,
  open: PropTypes.bool,
  fullScreen: PropTypes.bool,
  className: PropTypes.string,
  fullscreenBreakpoint: PropTypes.string,
  closeOnBackdropClick: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  title: PropTypes.node,
  dialogProps: PropTypes.object,
  showCloseButton: PropTypes.bool,
};

Dialog.defaultProps = {
  children: null,
  maxWidth: 'md',
  trigger: undefined,
  open: undefined,
  fullScreen: undefined,
  className: undefined,
  fullscreenBreakpoint: 'sm',
  closeOnBackdropClick: true,
  closeOnEscape: true,
  title: undefined,
  dialogProps: undefined,
  showCloseButton: true,
};

Dialog.displayName = 'SlickDialog';

export { Dialog };
