import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ListItemText, useMediaQuery, Typography, DialogTitle, Dialog, ListItem, List, ListItemIcon, Divider } from '@material-ui/core';
import { red, green, grey } from '@material-ui/core/colors';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
  },
  listItem: {
    color: green[500],
  },
  listItemIcon: {
    color: green[500],
  },
});

const SelectDialog = (props) => {
  const classes = useStyles(props);
  const { onClose, selectedValue, open: propsOpen, options, title, primaryTypographyProps } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const { dialogId, openDialog, closeDialog, open } = useLocationControl({ open: propsOpen, onClose });

  const handleCancelClose = () => {
    onClose(null);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={closeDialog} open={open} fullScreen={fullScreen}>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <List>
        {options.map(({ value, label, Icon }, index) => (
          <React.Fragment key={value}>
            <ListItem button onClick={() => handleListItemClick(value)} className={classes.listItem}>
              {Icon && <ListItemIcon className={classes.listItemIcon}><Icon color="inherit" /></ListItemIcon>}
              <ListItemText primary={label} primaryTypographyProps={{ variant: 'h6', color: 'inherit', ...primaryTypographyProps }} />
            </ListItem>
            {index < (options.length - 1) && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Dialog>
  );
};

SelectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  primaryTypographyProps: PropTypes.object,
};
SelectDialog.defaultProps = {
  primaryTypographyProps: undefined,
  selectedValue: null,
};

export { SelectDialog };
