/* eslint-disable react/prop-types */
import React from 'react';

export default {
  types: {
    expense: {
      renderers: {
        inline: ({ node }) => <>{node.description || node.category || node.cost}</>,
      },

      selections: {
        default: `
          id description category createdAt updatedAt date price cost item markup files status
          vendor { 
            id name company address1 address2 email phone city state postalCode country notes
            tags {id tag {id label type color icon}}
            systemTags {id system_tag {id label data}}
          }              
          job {id shortId title customer {id name company}}
        `,
      },

      fields: {
        description: { type: 'String' },
        category: {
          type: 'String',
          options: [
            { value: 'materials', primary: 'Materials' },
            { value: 'rentals', primary: 'Rentals' },
            { value: 'permits', primary: 'Permits' },
            { value: 'freight', primary: 'Freight' },
            { value: 'other', primary: 'Other' },
          ],
        },
        status: {
          type: 'String',
          options: [
            { value: 'none', primary: 'None' },
            { value: 'submitted', primary: 'Submitted' },
            { value: 'invoiced', primary: 'Invoiced' },
            { value: 'paid', primary: 'Paid' },
          ],
        },

        vendor: { type: 'contact' },

        date: { type: 'Date' },
        price: { type: 'Float' },
        cost: { type: 'Float' },
        markup: { type: 'Float' },
        files: { type: 'file', hasMany: true, embedded: true },
        item: { type: 'Json' },
        job: { type: 'job', validation: { required: true } },
      },
    },
  },
};
