import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';
import SignatureCanvas from 'react-signature-canvas';
import { dataURLtoBlob, uploadFile } from 'src/utils';
import CancelIcon from 'mdi-material-ui/Cancel';
import { withAuth0 } from '@auth0/auth0-react';
import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';
import { withSettings } from '../../contexts/SettingsContext';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  sigCanvas: {
    backgroundColor: theme.palette.type === 'dark' ? '#FFF' : 'rgba(0,0,0,.04)',
    border: 'solid 1px',
    borderColor: theme.palette.border.strong,
    width: 1000,
    height: 200,
    // maxHeight: '33vh',
    maxWidth: '100%',
    borderRadius: 4,
  },
  clearButton: {
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0.5),
  },
});

class SignatureField extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    label: PropTypes.string,
    initialValue: PropTypes.any,
    schemaExtensions: PropTypes.object,
    settings: PropTypes.object.isRequired,
  }

  static defaultProps = {
    helperText: undefined,
    label: undefined,
    initialValue: undefined,
    schemaExtensions: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;

    this.sigRef = createRef();
    registerField(props.name, {
      initialValue: props.initialValue,
      schemaExtensions: props.schemaExtensions,
      transforms: {
        formValueToMutationVar: this.formValueToMutationVar,
        nodeValueToFormValue: this.nodeValueToFormValue,
      },
    });
  }

  formValueToMutationVar = ({ formValue, fieldName, node, type, schema }) => new Promise((resolve, reject) => {
    const { settings, auth0: auth } = this.props;

    if (!formValue) resolve(null);

    const file = dataURLtoBlob(formValue);
    file.name = 'signature.png';

    uploadFile({
      auth,
      file,
      settings,
      onComplete: (nodeFile) => resolve(nodeFile),
    });
  })

  nodeValueToFormValue = ({ nodeValue, node, fieldName, type, schema }) => {
    return nodeValue;
  }

  handleChange = () => {
    const { onChange } = this.context;
    const { name } = this.props;
    const dataUrl = this.sigRef.current.getTrimmedCanvas().toDataURL('image/png');

    onChange(name, dataUrl);
  }

  handleClearClick = () => {
    const { onChange } = this.context;
    const { name } = this.props;

    this.sigRef.current.clear();
    onChange(name, null);
  }

  render() {
    const { name, classes, helperText, label: propsLabel, schemaExtensions } = this.props;
    const { getFieldValue, getFieldErrors, pendingMutation, schema, type } = this.context;
    const value = getFieldValue(name);
    const errors = getFieldErrors(name);

    const schemaField = schema.types[type].fields[name] || schemaExtensions.types[type].fields[name];
    const label = propsLabel === '' ? '' : propsLabel || schemaField.label;

    return (
      <FormControl error={!!errors} disabled={pendingMutation} className={classes.root}>
        <Typography variant="body2" color="textSecondary">{label}</Typography>
        <SignatureCanvas
          onEnd={this.handleChange}
          ref={this.sigRef}
          penColor="rgba(0,0,0,.8)"
          clearOnResize={false}
          canvasProps={{ className: classes.sigCanvas }}
        />
        <FormHelperText>
          <HelperText text={helperText} errors={errors} />
        </FormHelperText>
        <Button
          onClick={this.handleClearClick}
          className={classes.clearButton}
          variant="outlined"
          color="default"
          size="small"
        >
          <CancelIcon color="inherit" /><span>Clear & Try Again</span>
        </Button>
      </FormControl>
    );
  }
}

SignatureField = _.flow(
  withStyles(styles),
  withSettings,
  withAuth0,
)(SignatureField);

export { SignatureField };
