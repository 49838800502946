import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NodeSelector from 'src/kiska/components/NodeSelector';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  form: {
    maxWidth: 700,
  },
}));

const JobReports = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  const handleChange = (node) => {
    if (!node) return;

    history.push(`/app/jobs/${node.id}/report`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Typography variant="h6" align="center">
          Choose Job:
        </Typography>
        <NodeSelector type="job" onChange={handleChange} />
      </div>
    </div>
  );
};

JobReports.propTypes = {
};
JobReports.defaultProps = {
};

export { JobReports };
