import React from 'react';
// import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ResourceList } from './ResourceList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block !important',
    padding: theme.spacing(2),
  },
}));

const Resources = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="center">Resources</Typography>
      <ResourceList />
    </div>
  );
};

Resources.propTypes = {
};
Resources.defaultProps = {
};

export { Resources };
