import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { } from 'src/kiska/components/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { JobUpdateForm } from 'src/components/modules/job/JobUpdateForm';
import EditIcon from 'mdi-material-ui/Pencil';
import { ShareMenu } from 'src/kiska/components/share/ShareMenu';
import { FloatingButton } from 'src/components/FloatingButton';
import { AddFilesButtons } from 'src/components/AddFilesButtons';
import { UpdateJobStatusButton } from './UpdateJobStatusButton';
import { useWorkEntryControls } from '../work_entry/useWorkEntryControls';
import { StartJobButton } from './JobSwitcher/StartJobButton';
import { JobViewForm } from './JobViewForm';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: theme.palette.background.secondary,
    '& > div': {
      display: 'inline-flex',
    },
    '& > div > button': {
      borderRadius: 0,
      lineHeight: 1.2,
      boxShadow: 'none',
      borderRight: 'solid 1px',
      borderRightColor: 'hsla(0, 0%, 0%, .2)',
      padding: theme.spacing(0.5, 1),
    },
    '& > div:first-child > button:last-child': {
      borderBottomRightRadius: 8,
      borderRight: 'none',
    },
    '& > div:last-child > button:first-child': {
      borderBottomLeftRadius: 8,
    },
    '& > div:last-child > button:last-child': {
      borderBottomLeftRadius: 8,
      borderRight: 'none',
    },
  },
  formContainer: {
  },
}));

const JobDetails = ({ node }) => {
  const jobViewRef = useRef();
  const classes = useStyles();
  const { isCurrentlyWorkingOnJob } = useWorkEntryControls();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (
    <>
      {/* Job Actions Toolbar */}
      <div className={classes.actions}>
        <div>
          {!sm && <UpdateJobStatusButton node={node} color="default" variant="contained" job={node} />}

          {/* {isCurrentlyWorkingOnJob(node) && <StopWorkButton showLabel={false} />} */}
          <AddFilesButtons node={node} type="job" />
          {/* <ExpenseQuickAdd job={node} cameraLabel="Photo Expense" cameraIcon={ExpensesIcon} uploadLabel="Upload Expense" uploadIcon={ExpensesIcon} /> */}
          <StartJobButton showLabel={false} job={node} />
        </div>

        <div>
          <ShareMenu
            name={`${node.title}.pdf`}
            targetRef={jobViewRef}
            label="Print / Email"
            emailData={{
              subject: `Work Order PDF`,
              body: `Hello,\n\nYou have been sent a Work Order for job "${node.title}". Please find the attached PDF file.`,
            }}
          />
        </div>

      </div>

      {/* Job Form */}
      <div className={classes.formContainer}>
        <JobViewForm node={node} ref={jobViewRef} />
        <JobUpdateForm
          id={node.id}
          trigger={(
            <FloatingButton
              variant="extended"
              color="primary"
              containerRef={jobViewRef}
            >
              <EditIcon />&nbsp;<span>Edit Job</span>
            </FloatingButton>
          )}
        />
      </div>

      <div className={classes.relatedForms}>
        {/* <SimpleTable
          type="form"
          enableAdd={false}
          enableEdit={false}
          columns={['title']}
        /> */}
      </div>
    </>
  );
};

JobDetails.propTypes = {
  node: PropTypes.object.isRequired,
};
JobDetails.defaultProps = {
};

export { JobDetails };
