import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess as ExpandedIcon, ExpandMore as CollapsedIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { usePrint } from 'src/kiska/components/PdfGenerator';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(0),
    padding: theme.kiska.type === 'print' ? theme.spacing(0.2, 1) : theme.spacing(1, 1),
    // borderBottom: theme.kiska.type === 'print' ? 'solid 1px' : undefined,
    borderBottomColor: theme.palette.border.formSection,
    cursor: 'pointer',
    position: 'relative',
    color: theme.palette.text.secondary,
    // backgroundColor: theme.palette.background.secondary,
    backgroundColor: 'hsla(0, 0%, 0%, .2)',
    '&.highlighted': {
      backgroundColor: theme.palette.background.formHeading,
      color: theme.palette.text.formHeading,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&.noIcon': {
      paddingLeft: 47,
    },
  },
  title: {
    lineHeight: 1.2,
    fontWeight: theme.kiska.type === 'print' ? 500 : 300,
    fontSize: theme.kiska.type === 'print' ? 14 : undefined,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  collapseIcon: {

  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const FormHeading = (props) => {
  const { title, expanded, highlighted, className, icon, ...rest } = props;
  const Icon = icon;
  const classes = useStyles(props);
  const { printMode } = usePrint();

  return (
    <div className={classNames(className, classes.root, highlighted && 'highlighted', !icon && 'noIcon')} {...rest}>

      <Typography variant="h6" align="left" color="inherit" className={classes.title}>
        {Icon && <Icon color="inherit" className={classes.icon} />}
        {title}
      </Typography>
      {!printMode && (
        expanded ? <ExpandedIcon className={classes.collapseIcon} /> : <CollapsedIcon className={classes.collapseIcon} />
      )}
    </div>
  );
};

FormHeading.propTypes = {
  title: PropTypes.any,
  highlighted: PropTypes.bool,
  className: PropTypes.string,
};
FormHeading.defaultProps = {
  title: undefined,
  highlighted: true,
  className: undefined,
};

export { FormHeading };
