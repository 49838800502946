import { gql } from '@apollo/client';
import { addIdsToSelections } from '../../utils';

const buildQuery = ({ type, schema, selections, operation, subscription = true }) => {
  const schemaType = schema.types[type];
  const functionMode = schemaType.isFunctionTable;

  const functionVars = functionMode ? `$args: ${type}_args!,` : '';
  const functionArgs = functionMode ? `args: $args` : '';

  const nonArgTypePrefix = functionMode ? `${type}_template` : type;

  selections = addIdsToSelections(selections);

  const query = gql`
    ${subscription ? 'subscription' : 'query'} ${operation || 'NodeList'}(
        $where: ${nonArgTypePrefix}_bool_exp, 
        $orderBy: [${nonArgTypePrefix}_order_by!], 
        $offset: Int, 
        $limit: Int,
        ${functionVars}
      ){

      returning: ${type}_aggregate(where: $where, order_by: $orderBy, offset: $offset, limit: $limit, ${functionArgs}){
        nodes {
          ${selections}
        }
        aggregate {
          count
        }
      }
    }
  `;

  return query;
};

export default buildQuery;
