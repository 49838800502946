import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { NodeListWithVars } from 'src/kiska/components/NodeList/NodeListWithVars';
import FormIcon from 'mdi-material-ui/ClipboardCheckOutline';

import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
import { forms } from 'src/components/modules/form/forms';
import { FormUpdateForm } from '../modules/form/FormUpdateForm';
import { List } from './List';

// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

const FormListInner = ({ maxHeight, hideCreateButton, insertFixtures }) => {
  // const classes = useStyles();
  const { setTitles } = useContext(PageMetaContext);
  // const { nodes, type, schemaType } = useContext(NodeListContext);

  useEffect(() => {
    setTitles('Forms');
  }, [setTitles]);

  return (
    <List
      listTitle=""
      // listIcon={FormIcon}
      searchPaths="_search"
      searchPlaceholder="Search by title"
      title={(node) => <>{node.title}</>}
      subtitle={(node) => {
        const form = forms[node.name];
        return form.subtitle(node);
      }}
      // substat={(node) => `Hours`}
      clickUrl={(node) => `/app/forms/${node.id}`}
      createText="New Form"
      // createForm={!hideCreateButton && <FormUpdateForm fixtures={insertFixtures} />}
      updateForm={<FormUpdateForm fixtures={insertFixtures} />}
      maxHeight={maxHeight}
    />
  );
};

const FormList = ({ fixtures, maxHeight, hideCreateButton, insertFixtures }) => {
  const initial = {
    orderBy: [
      { updatedAt: 'desc' },
    ],
  };

  return (
    <NodeListWithVars type="form" initial={initial} fixtures={fixtures}>
      <FormListInner maxHeight={maxHeight} hideCreateButton={hideCreateButton} insertFixtures={insertFixtures} />
    </NodeListWithVars>
  );
};

FormList.propTypes = {
  fixtures: PropTypes.object,
  hideCreateButton: PropTypes.bool,
  maxHeight: PropTypes.number,
  insertFixtures: PropTypes.object,
};
FormList.defaultProps = {
  fixtures: undefined,
  hideCreateButton: false,
  maxHeight: undefined,
  insertFixtures: undefined,
};

export { FormList };
