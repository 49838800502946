import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from 'src/kiska/components/Grid';
import classNames from 'classnames';
import { useNodeList } from 'src/kiska/components/NodeList';
import { ReportTitle } from './ReportTitle';
import { EmptyData } from '../modules/work_entry/charts/EmptyData';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2, 2),
    position: 'relative',
  },
  padding0: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  padding1: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  padding2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  padding3: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  padding4: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const ReportContent = (props) => {
  const classes = useStyles(props);
  const { children, padding } = props;
  const { nodes } = useNodeList();

  return (
    <div className={classNames(classes.root, classes[`padding${padding}`])}>
      {children}
    </div>
  );
};

ReportContent.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.number,
};
ReportContent.defaultProps = {
  children: undefined,
  padding: 0,
};

export { ReportContent };
