import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Typography, TextField, Select, ListItemText, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { freqOptions } from 'src/utils';
import { MutationContext } from '../MutationContext';

const styles = (theme) => ({

});

class EventRepeatField extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    initialValue: PropTypes.any,
    schemaExtensions: PropTypes.object,
  }

  static defaultProps = {
    label: undefined,
    initialValue: undefined,
    schemaExtensions: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;

    registerField(props.name, {
      initialValue: props.initialValue,
      schemaExtensions: props.schemaExtensions,
    });
  }

  buildDefaultValue = () => {
    const stateValue = {
      doesRepeat: false,
      freq: RRule.WEEKLY,
      interval: 1,
      endsAfterCount: false,
      count: 3,
      exdates: [],
      rdates: [],
    };
    return stateValue;
  }

  handleIntervalChange = (e) => {
    const { name } = this.props;
    const { getFieldValue, onChange } = this.context;
    // const value = { ...this.buildDefaultValue(), ...getFieldValue(name) };
    const value = getFieldValue(name) || this.buildDefaultValue();
    const newInterval = Number(e.target.value);

    onChange(name, { ...value, interval: newInterval });
  }

  handleDoesRepeatChange = (e) => {
    const { name } = this.props;
    const { getFieldValue, onChange } = this.context;
    // const value = { ...this.buildDefaultValue(), ...getFieldValue(name) };
    const value = getFieldValue(name) || this.buildDefaultValue();

    onChange(name, { ...value, doesRepeat: e.target.checked });
  }

  handleEndsAfterCountChange = (e) => {
    const { name } = this.props;
    const { getFieldValue, onChange } = this.context;
    // const value = { ...this.buildDefaultValue(), ...getFieldValue(name) };
    const value = getFieldValue(name) || this.buildDefaultValue();

    onChange(name, { ...value, endsAfterCount: e.target.checked });
  }

  handleCountChange = (e) => {
    const { name } = this.props;
    const { getFieldValue, onChange } = this.context;
    // const value = { ...this.buildDefaultValue(), ...getFieldValue(name) };
    const value = getFieldValue(name) || this.buildDefaultValue();
    const newCount = Number(e.target.value);

    onChange(name, { ...value, count: newCount });
  }

  handleFreqChange = (e) => {
    const { name } = this.props;
    const { getFieldValue, onChange } = this.context;
    // const value = { ...this.buildDefaultValue(), ...getFieldValue(name) };
    const value = getFieldValue(name) || this.buildDefaultValue();
    const newFreq = Number(e.target.value);

    onChange(name, { ...value, freq: newFreq });
  }

  render() {
    const { name, classes, label: propsLabel, schemaExtensions } = this.props;
    const { getFieldValue, getFieldErrors, pendingMutation, schema, type, onChange } = this.context;
    // const value = { ...this.buildDefaultValue(), ...getFieldValue(name) };
    const value = getFieldValue(name) || this.buildDefaultValue();
    const errors = getFieldErrors(name);

    const schemaField = schema.types[type].fields[name] || schemaExtensions.types[type].fields[name];
    const label = propsLabel === '' ? '' : propsLabel || schemaField.label;

    return (
      <FormControl error={!!errors} disabled={pendingMutation} className={classes.root}>
        <FormControlLabel
          control={<Checkbox checked={value.doesRepeat} onChange={this.handleDoesRepeatChange} />}
          label="Repeat this event"
        />
        {value.doesRepeat && (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography variant="body1" style={{ marginRight: 8 }}>Repeat every</Typography>
              <TextField name="interval" label="" value={value.interval} onChange={this.handleIntervalChange} variant="outlined" type="number" inputProps={{ min: '1' }} style={{ maxWidth: 70 }} />
          &nbsp;
              <Select name="frequency" label="" value={value.freq} onChange={this.handleFreqChange} variant="outlined" type="number">
                {freqOptions.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    <ListItemText primary={value.interval > 1 ? o.plural : o.singular} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <FormControlLabel
                control={<Checkbox checked={value.endsAfterCount} onChange={this.handleEndsAfterCountChange} />}
                label="End after"
              />
              {value.endsAfterCount && (
                <>
                  <TextField name="count" label="" value={value.count} onChange={this.handleCountChange} variant="outlined" type="number" inputProps={{ min: '1' }} style={{ maxWidth: 70 }} />
              &nbsp; events
                </>
              )}
            </div>
          </>
        )}
      </FormControl>
    );
  }
}

EventRepeatField = _.flow(
  withStyles(styles),
)(EventRepeatField);

export { EventRepeatField as RRuleSetField };
