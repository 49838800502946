import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { serializeError } from 'serialize-error';
import _ from 'lodash';
import {
  Replay as RetryIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';

//
const useStyles = makeStyles((theme) => ({
  progressRoot: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(8, 2),
  },
  errorRoot: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(6, 2, 6, 2),
    width: '100%',
  },
  alert: {
    width: '100%',
    maxWidth: 800,
  },
  empty: {
    padding: theme.spacing(2),
  }
}));

const QueryError = ({ queryResult }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const classes = useStyles();

  const stack = queryResult.error.stack;
  const jsonError = JSON.stringify(serializeError(_.omit(queryResult.error, ['stack'])), null, 2);

  const hidden = { opacity: 0, height: 0, position: 'relative' };
  const visible = { opacity: 1, height: 'auto', position: 'relative' };

  return (
    <>
      <AnimatePresence>
        {true && (
          <motion.div
            className={classes.errorRoot}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'spring',
              stiffness: 20,
              damping: 5,
            }}
          >
            <Alert
              className={classes.alert}
              severity="error"
              variant="outlined"
              action={(
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => window.location.reload()}
                >
                  <RetryIcon /><span>Retry</span>
                </Button>
              )}
            >
              <AlertTitle>Data Fetching Error</AlertTitle>
              Sorry, there was an error fetching that data.
              <div>
                <Button
                  style={{ paddingLeft: 0 }}
                  variant="text"
                  onClick={() => setDetailsVisible((prevValue) => !prevValue)}
                >
                  <Typography variant="caption">
                    Details&nbsp;&nbsp;
                    {detailsVisible ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
                  </Typography>
                </Button>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <motion.div
                    initial={hidden}
                    animate={detailsVisible ? visible : hidden}
                    transition={{
                      type: 'spring',
                      stiffness: 200,
                      damping: 50,
                    }}
                  >
                    {/* <Typography variant="caption"> */}
                    {queryResult.error.toString()}<br />
                    <strong>JSON Error:</strong>
                    <pre>
                      {jsonError}
                    </pre>
                    <strong>Stack Trace:</strong>
                    <pre style={{ width: '100%', whiteSpace: 'pre-wrap', overflow: 'auto' }}>
                      {stack}
                    </pre>
                    {/* </Typography> */}
                  </motion.div>
                </div>
              </div>
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const QueryLoading = ({ queryResult }) => {
  const classes = useStyles();

  return (
    <div className={classes.progressRoot}>
      <CircularProgress thickness={2} size={100} />
    </div>
  );
};

const QueryEmpty = ({ queryResult }) => {
  const classes = useStyles();

  return (
    <div className={classes.empty} >
      <Alert severity="error" variant="standard" >
        <AlertTitle>Sorry, that data can't be found right now!.</AlertTitle>
      </Alert>
    </div>
  );
};

const QueryData = (props) => {
  return (
    <>
      <props.component {...props} />
    </>
  );
};

const NodeViewPage = (props) => {
  const { queryResult, title } = props;
  const { setTitle } = useContext(PageMetaContext);
  // const classes = useStyles();
  const { loading, error, data } = queryResult;

  const node = data ? data.node : undefined;
  let content = null;

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  if (error) {
    content = <QueryError queryResult={queryResult} />;
  } else if (node) {
    content = <QueryData node={node} {...props} />;
  } else if (!loading && !node) {
    content = <QueryEmpty queryResult={queryResult} />;
  } else if (!node && loading) {
    content = <QueryLoading queryResult={queryResult} />;
  }

  return (
    <>
      {content}
    </>
  );
};

NodeViewPage.propTypes = {
  queryResult: PropTypes.object.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  component: PropTypes.elementType.isRequired,
};
NodeViewPage.defaultProps = {
  children: null,
  title: undefined,
};

export default NodeViewPage;
