import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';

import { SchemaContext } from 'src/kiska/components/contexts/SchemaContext';
import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { getSchemaAtPath } from 'src/kiska/schema/getSchemaAtPath';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const LabelValueTable = ({ pairs, type, fields, node }) => {
  const classes = useStyles();
  const { schema: mainSchema } = React.useContext(SchemaContext);
  const mutationContext = React.useContext(MutationContext);

  const schema = mutationContext ? mutationContext.schema : mainSchema;

  let calculatedPairs = pairs;

  if (!pairs) {
    let normalizedFields = fields;
    if (!Array.isArray(fields)) normalizedFields = fields.split(/[,\s]+/);

    calculatedPairs = normalizedFields.map((field) => {
      if (typeof field === 'object') {
        return field;
      }

      const fieldSchemaType = getSchemaAtPath(schema, field, type);
      const schemaField = schema.types[type].fields[field];
      const renderer = schemaField.renderers.inline;

      const value = _.get(node, field);
      let renderedValue = renderer({ value, node: value });

      if (schemaField.type === 'Timestamp') {
        const date = parseISO(value);
        renderedValue = format(date, `MMM d, yyyy h:mmaaaaa'm`);
      }

      return {
        label: schemaField.label,
        value: renderedValue,
      };
    });
  }

  return (
    <Table size="small">
      <TableBody>
        {calculatedPairs.map(({ label, value }) => {
          return (
            <TableRow hover key={label}>
              <TableCell align="right">
                <Typography variant="body1"><strong>{label}:</strong></Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body1">{value}</Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

LabelValueTable.propTypes = {
  pairs: PropTypes.array,
  type: PropTypes.string,
  fields: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  node: PropTypes.object,
};
LabelValueTable.defaultProps = {
  pairs: undefined,
  type: undefined,
  fields: undefined,
  node: undefined,
};

export default LabelValueTable;
