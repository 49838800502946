import React, { } from 'react';
import PropTypes from 'prop-types';
import { Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KiskaCrudForm from 'src/kiska/components/MutationContext/CrudForm';
import MutationSnackbars from 'src/kiska/components/MutationContext/MutationSnackbars';
import { AttachFile as AttachIcon } from '@material-ui/icons';
import CameraIcon from 'mdi-material-ui/Camera';
import { FileField } from 'src/kiska/components/MutationContext/FormFields/FileField';
import classNames from 'classnames';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  button: {

  },
}));

const AddFilesButtons = (props) => {
  const theme = useTheme();
  const { node, type, field, className, onlyCamera, hideLabelBreakpoint, ...buttonProps } = props;
  const hideLabel = useMediaQuery(theme.breakpoints.down(hideLabelBreakpoint), { noSsr: true });
  const classes = useStyles();

  const cameraTrigger = (
    <Button color="default" variant="contained" {...buttonProps} className={classNames(classes.button, className)}>
      <CameraIcon />
      {!hideLabel && <span>Add Photo</span>}
    </Button>
  );

  const dragndropTrigger = (
    <Button color="default" variant="contained" {...buttonProps} className={classNames(classes.button, className)}>
      <AttachIcon />
      {!hideLabel && <span>Upload Files</span>}
    </Button>
  );

  const successMessage = (mutation, result) => {
    const files = _.get(mutation, 'variables.filesConnectObjects');

    if (files) return `Added ${files.length} file${files.length > 1 ? 's' : ''}.`;
    return 'Added files';
  };

  return (
    <KiskaCrudForm type={type} node={node} onChangeMutation="update" onChangeMutationWait={1}>
      <FileField onlyButton="camera" name="files" cameraTrigger={cameraTrigger} />
      {!onlyCamera && <FileField onlyButton="upload" name="files" dragndropTrigger={dragndropTrigger} />}
      <MutationSnackbars successMessage={successMessage} />
    </KiskaCrudForm>
  );
};

AddFilesButtons.propTypes = {
  node: PropTypes.object,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  hideLabelBreakpoint: PropTypes.string,
};
AddFilesButtons.defaultProps = {
  node: undefined,
  name: 'files',
  hideLabelBreakpoint: 'sm',
};

export { AddFilesButtons };
