import React from 'react';
import { Timer as TimeIcon, Dashboard as DetailsIcon } from '@material-ui/icons';
import { TabPanel, ContentTabs } from 'src/kiska/components/ContentTabs';
import ReportIcon from 'mdi-material-ui/FileChart';
import { CustomerWorkEntries } from './CustomerWorkEntries';
import { CustomerReport } from './CustomerReport';
import { CustomerDetails } from './CustomerDetails';

const CustomerTabs = ({ node, title, subtitle }) => {
  return (
    <ContentTabs
      urlPathIndex={3}
      title={title}
      subtitle={subtitle}
      defaultTab="details"
      tabs={[
        { value: 'details', label: 'Details', icon: <DetailsIcon /> },
        { value: 'work-log', label: 'Log', icon: <TimeIcon /> },
        { value: 'report', label: 'Report', icon: <ReportIcon /> },
      ]}
    >
      <TabPanel value="details">
        <CustomerDetails node={node} />
      </TabPanel>
      <TabPanel value="work-log">
        <CustomerWorkEntries node={node} />
      </TabPanel>
      <TabPanel value="report">
        <CustomerReport node={node} />
      </TabPanel>
    </ContentTabs>
  );
};

export default CustomerTabs;
