import { parseTime, parseDate, formatDate } from 'src/kiska/utils';

const transformNodeValueToFormValue = ({ nodeValue, node, fieldName, type, schema }) => {
  const field = schema.types[type].fields[fieldName];
  if (!field) return undefined;

  // Times
  if (field.type === 'Time') {
    return parseTime(nodeValue);
  }
  if (field.type === 'Date') {
    return parseDate(nodeValue);
  }

  // All other scaler fields
  if (field.isScaler) {
    if (field.type === 'String') {
      return nodeValue || '';
    }
    return nodeValue;
  }

  // Many relationships
  if (field.hasMany && !field.embedded) {
    return nodeValue.map((v) => v[field.type]);
  }

  // One relationships
  return nodeValue;
};

const transformRelationFormValueToMuationVar = async ({ formValue, fieldName, node, type, schema }) => {
  const schemaField = schema.types[type].fields[fieldName];
  // const relatedSchemaType = schema.types[schemaField.type];

  // Embedded non-scalers do not need to be connected
  if (schemaField.embedded) {
    return formValue;
  }

  // Connections to many other objects
  if (schemaField.hasMany) {
    const nvKey = schemaField.type;

    const formValues = Array.isArray(formValue) ? formValue : [];
    const formValuesNotInNodeValues = node && node[fieldName] ? formValues.filter((fv) => !node[fieldName].find((nv) => nv[nvKey].id === fv.id)) : formValues;
    const nodeValuesNotInFormValues = node && node[fieldName] ? node[fieldName].filter((nv) => !formValues.find((fv) => fv.id === nv[nvKey].id)) : [];
    const connect = formValuesNotInNodeValues;
    const disconnect = nodeValuesNotInFormValues;
    // const connect = formValuesNotInNodeValues.map((v) => v[schemaField.type]);
    // const disconnect = nodeValuesNotInFormValues.map((v) => v[schemaField.type]);
    const isNew = !(node && node.id);

    const result = { connect, disconnect: !isNew ? disconnect : undefined };

    return result;
  }

  // Connection to one other object
  if (formValue) {
    const id = typeof formValue === 'object' ? formValue.id : formValue;
    return { connect: { id } };
  } if (node && node[fieldName]) {
    return { disconnect: true };
  }
  return undefined;
};

const transformFormValueToMutationVar = async (arg) => {
  const { formValue, fieldName, node, type, schema } = arg;
  const field = schema.types[type].fields[fieldName];

  switch (field.type) {
    case 'Date':
      return formValue;
    case 'Time':
      // return formValue;
      return formValue ? formatDate(formValue, 'HH:mm:ss.SSSx') : null;

    case 'Int':
    case 'Float':
      return Number(formValue);

    case 'ID':
    case 'String':
    case 'Timestamp':
    case 'Boolean':
    case 'Json':
      return formValue;

    // All other types, should be non-scalar here
    default:
      return transformRelationFormValueToMuationVar(arg);
  }
};

export { transformNodeValueToFormValue, transformFormValueToMutationVar };
