/* eslint-disable max-classes-per-file */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

class Link extends React.Component {
  render() {
    return <MuiLink component={RouterLink} {...this.props} />;
  }
}

export { Link };
