import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ShareMenu } from 'src/kiska/components/share/ShareMenu';

const useStyles = makeStyles((theme) => ({
  reportWrapper: {
    marginTop: theme.spacing(2),
  },
  reportPaper: {
    padding: theme.spacing(1, 0, 0, 0),
    borderTopRightRadius: 0,
  },
  reportToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

const ReportWrapper = (props) => {
  const classes = useStyles(props);
  const printableRef = useRef();
  const { shareProps, children } = props;

  return (
    <div className={classes.reportWrapper}>
      <div className={classes.reportToolbar}>
        <ShareMenu label="Print / Email" disableElevation {...shareProps} targetRef={printableRef} />
      </div>
      <Paper className={classes.reportPaper}>
        <div ref={printableRef}>
          {children}
        </div>
      </Paper>
    </div>
  );
};

ReportWrapper.propTypes = {
  shareProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
ReportWrapper.defaultProps = {
};

export { ReportWrapper };
