import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Hidden, Typography } from '@material-ui/core';
import { Stop as StopIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, grey } from '@material-ui/core/colors';
import Color from 'color';
import classNames from 'classnames';

import { findTaskOptionsForUserOnJob } from 'src/utils';
import { SelectDialog } from './SelectDialog';
import { useWorkEntryControls } from '../../work_entry/useWorkEntryControls';
import DurationTicker from '../../user/DurationTicker';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: red[500],
    color: '#FFF',
    textTransform: 'none',
    textAlign: 'center',
    padding: theme.spacing(0.75, 0.5, 0.75, 0.5),
    minWidth: 0,

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },

    '&:hover': {
      backgroundColor: red[700],
    },
    '&.big': {
      minHeight: 50,
      padding: theme.spacing(1, 2, 1, 1),
    },
  },
  icon: {
    marginRight: '0 !important',
    '&.big': {
      fontSize: 30,
    },
  },
  label: {
    flex: 1,
    '&.big': {

    },
  },
  title: {
    fontWeight: 400,
    lineHeight: 1.2,
    color: '#FFF',
    '&.big': {
      fontSize: 20,
    },
  },
  job: {
    fontWeight: 700,
    lineHeight: 1,
    color: '#FFF',
    '&.big': {
      fontSize: 16,
    },
  },
}));

const StopWorkButton = (props) => {
  const classes = useStyles(props);
  const {
    stopWork,
    openWorkEntries,
  } = useWorkEntryControls();
  const { hideLabelBreakpoint, showLabel, showJob, showTime, size, ...rest } = props;

  const activeEntry = openWorkEntries.length ? openWorkEntries[0] : null;

  if (!activeEntry) return null;

  const handleClick = () => {
    stopWork();
  };

  let LabelWrapper = React.Fragment;
  const labelWrapperProps = {};
  if (hideLabelBreakpoint) {
    LabelWrapper = Hidden;
    labelWrapperProps[hideLabelBreakpoint] = true;
  }

  return (
    <Button variant="contained" className={classNames(classes.root, size)} onClick={handleClick} {...rest}>
      <StopIcon className={classNames(classes.icon, size)} />
      <LabelWrapper {...labelWrapperProps}>
        <div className={classNames(classes.label, size)}>
          {showLabel && <Typography variant="body1" color="textPrimary" className={classNames(classes.title, size)}>Stop Work</Typography>}
          {(showJob || showTime) && (
            <Typography variant="body2" color="textSecondary" className={classNames(classes.job, size)}>
              {showJob && activeEntry.job.title}
              {showTime && <DurationTicker start={activeEntry.start} includeSeconds />}
            </Typography>
          )}
        </div>
      </LabelWrapper>
    </Button>
  );
};

StopWorkButton.propTypes = {
  hideLabelBreakpoint: PropTypes.string,
  showLabel: PropTypes.bool,
  showJob: PropTypes.bool,
  showTime: PropTypes.bool,
  size: PropTypes.oneOf([undefined, 'big']),
};
StopWorkButton.defaultProps = {
  hideLabelBreakpoint: undefined,
  showLabel: false,
  showJob: false,
  showTime: true,
  size: undefined,
};

export { StopWorkButton };
