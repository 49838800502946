import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { Calendar } from '../modules/calendar/Calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 4, 2),
  },
}));

const MyCalendar = (props) => {
  const classes = useStyles(props);
  const { user } = useUser();

  return (
    <div className={classes.root}>
      <Calendar initialUsers={[user]} />
    </div>
  );
};

MyCalendar.propTypes = {
};
MyCalendar.defaultProps = {
};

export { MyCalendar };
