import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parseISO } from 'src/kiska/utils';
import { isToday } from 'date-fns';
import { forms } from 'src/components/modules/form/forms';
import SignIcon from 'mdi-material-ui/Signature';
import MedicalIcon from 'mdi-material-ui/Ambulance';
// import MedicalIcon from 'mdi-material-ui/HardHat';
// import MedicalIcon from 'mdi-material-ui/SafetyGoggles';
// import MedicalIcon from 'mdi-material-ui/MedicalBag';
import { Add as AddIcon } from '@material-ui/icons';
import { usePrevDailySafetyFormValues } from './usePrevDailySafetyFormValues';
import { FormViewDialog } from '../FormViewDialog';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const DailySafetyFormButton = (props) => {
  const classes = useStyles(props);
  const { job, ...buttonProps } = props;
  const { ready, initialValues, latestNode, required } = usePrevDailySafetyFormValues({ job });

  if (!required) return null;
  if (!ready) return null;

  if (latestNode && isToday(parseISO(latestNode.data.dailySafety.date))) {
    return (
      <FormViewDialog
        node={latestNode}
        trigger={(
          <Button className={classes.toolboxButton} {...buttonProps}>
            <SignIcon /><span>Sign Daily Safety Record</span>
          </Button>
        )}
      />
    );
  }

  return (
    <forms.DailySafetyForm_v1.UpdateForm
      job={job}
      node={null}
      initialValues={initialValues}
      fixtures={{ jobs: [{ id: job.id }] }}
      trigger={(
        <Button className={classes.toolboxButton} {...buttonProps}>
          <MedicalIcon /><span>Start Daily Safety Record</span>
        </Button>
      )}
    />
  );
};

DailySafetyFormButton.propTypes = {
};
DailySafetyFormButton.defaultProps = {
};

export { DailySafetyFormButton };
