import React, { } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography, Chip, Grid } from '@material-ui/core';
// import { PageMetaContext } from 'src/kiska/components/contexts/PageMetaContext';
import { format, parseISO } from 'date-fns';
import { ToggleButtonOptionsFilter } from 'src/kiska/components/NodeList/ToggleButtonOptionsFilter';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { SimpleTable } from 'src/components/dashboards/settings/SimpleTable';
import { NodeFilter } from 'src/kiska/components/NodeList/NodeFilter';
import { ResourceUpdateForm } from './ResourceUpdateForm';
import { ResourceViewDialog } from './ResourceViewDialog';
import { useResourceTableColumns } from './useResourceTableColumns';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const ResourceList = ({ fixtures, updateFixtures, hideCreateButton, insertFixtures, updateFormProps }) => {
  const { appSettings } = useAppSettings();
  const theme = useTheme();
  const { getOption } = useSchema();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const md = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const classes = useStyles();
  const { columns } = useResourceTableColumns({ showExpires: false, showCreatedAt: true, showTags: true, showUpdatedAt: true });
  const initial = {
    limit: 50,
    orderBy: [
      { updatedAt: 'desc' },
    ],
    where: {
    },
  };

  const searchPaths = 'title description link';
  const searchPlaceholder = 'Search by title or description';

  const filters = (
    <>
      <Grid item xs={12} lg={6}>
        <NodeFilter rootPath="where" path="tags" label="Filter by tags" boolOp="and" where={{ type: { _eq: 'resource' } }} />
      </Grid>
    </>
  );

  return (
    <div className={classes.root}>
      <SimpleTable
        searchPlaceholder={searchPlaceholder}
        searchPaths={searchPaths}
        type="resource"
        initial={initial}
        insertFixtures={insertFixtures}
        updateFixtures={updateFixtures}
        fixtures={[
          ...(fixtures || []),
          { path: 'where.userId._is_null', value: true },
        ]}
        addLabel="New Resource"
        columns={columns}
        UpdateForm={ResourceUpdateForm}
        updateFormProps={updateFormProps}
        ViewDialog={ResourceViewDialog}
        actionsOnBottom={false}
        actionsOnTop={false}
        enableAdd={!hideCreateButton}
        floatingAdd
        enableEdit
        showSearchBox
        filters={filters}
      />
    </div>
  );
};

ResourceList.propTypes = {
  fixtures: PropTypes.any,
  hideCreateButton: PropTypes.bool,
  insertFixtures: PropTypes.object,
  updateFixtures: PropTypes.object,
  updateFormProps: PropTypes.object,
};
ResourceList.defaultProps = {
  fixtures: undefined,
  hideCreateButton: false,
  insertFixtures: undefined,
  updateFixtures: undefined,
  updateFormProps: undefined,
};

export { ResourceList };
