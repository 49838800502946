import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  title: {
    fontSize: theme.kiska.type === 'print' ? 20 : '1.4rem',
  },
  subtitle: {
    fontSize: theme.kiska.type === 'print' ? 16 : '1.4rem',
    fontWeight: 200,
    marginTop: theme.spacing(-0.5),
  },
}));

const ReportTitle = (props) => {
  const classes = useStyles(props);
  const { title, subtitle } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="center" className={classes.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle1" align="center" color="textPrimary" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

ReportTitle.propTypes = {
};
ReportTitle.defaultProps = {
};

export { ReportTitle };
