import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';

const MasterLayout = ({ classes, children }) => {
  return (
    <>
      <CssBaseline />
      {children}
    </>
  );
};

MasterLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

MasterLayout.defaultProps = {
};

export default MasterLayout;
